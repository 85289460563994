import {
  Assessment,
  ASSESSMENTS,
  ASSESSMENT_THREADS,
  getAssessmentName,
  getDateString,
  getFullName,
  getInitials,
  getThreadMessageGroupCollectionPath,
  getTimeString,
  MessageThread,
  MessagingWidgetState,
  MESSAGING_WIDGET,
  objectToArray,
  PopulatedThreadMessage,
  ThreadMessage,
  ThreadMessageGroup,
  ThreadMessagesWithThreadData,
  ThreadMessageWithThreadData,
  ThreadSearchResult,
  ThreadSelection,
  ThreadTab,
  ThreadType,
  toSearchString,
} from '@hb/shared'
import { User } from 'firebase/auth'
import {
  collection,
  CollectionReference,
  limit,
  orderBy,
  Query,
  query,
  where,
} from 'firebase/firestore'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import useSound from 'use-sound'
import { db, searchMyThreads } from '../../backend'
import { GROUP_THREADS_REF } from '../../collections/firestoreCollections'
import { useCachedUser } from '../../collections/hooks/cached'
import { useApp } from '../../contexts/AppContext'
import { useIsMainWindow } from '../../contexts/MainWindowContext'
import { PopUpMessageContext } from '../../contexts/PopUpMessage/PopUpMessageContext'
import { ScreenContext } from '../../contexts/ScreenContext'
import { useDocument } from '../../hooks/backend/useDocument'
import { useMe } from '../../hooks/backend/useMe'
import { useOptimisticUpdate } from '../../hooks/backend/useOptimisticUpdate'
import { useQuery } from '../../hooks/backend/useQuery'
import { useTodayDate } from '../../hooks/useTodayDate'
import newMessageSound from '../../sounds/ding.mp3'
import { useAuth } from '../../store/auth'
import { type UseMessagingWidget } from './types'

const fillEmptyThread = (
  me: User,
  threadType: ThreadType,
  threadId: string,
  threadTitle: string,
  thread: MessageThread | null,
): ThreadsListThread => {
  if (thread) {
    return {
      thread: { ...thread, title: threadTitle, type: threadType },
      threadId,
      isUnread: thread.unreadBy.includes(me.uid),
      threadType,
    }
  }
  return {
    thread: {
      type: threadType,
      title: threadTitle,
      searchString: toSearchString(threadTitle),
      accessLevel: 'patient',
      subscribers: [],
      mostRecentMessages: [],
      mostRecentMessageOn: 0,
      mostRecentMessageBy: null,
      unreadBy: [],
      readBy: [],
    },
    isUnread: false,
    threadId,
    threadType,
  }
}

const sortThreadsByMostRecentMessage = (threads: ThreadsListThread[]) =>
  threads.sort((a, b) => b.thread.mostRecentMessageOn - a.thread.mostRecentMessageOn)

export const sortMessagesByMostRecent = <T extends ThreadMessage = ThreadMessage>(
  messages: T[],
): T[] => messages.sort((a, b) => a.createdOn - b.createdOn)

export const populateThreadMessage = <
  T extends ThreadMessageWithThreadData = ThreadMessageWithThreadData,
>(
  me: User | null,
  message: T,
  previousMessage: T | undefined,
  nextMessage: T | undefined,
): PopulatedThreadMessage<T> => {
  const date = new Date(message.createdOn)
  const timeString = getTimeString(date)
  const dateString = getDateString(date, 'short')
  const prevTimeString = previousMessage ? getTimeString(new Date(previousMessage.createdOn)) : ''
  const nextTimeString = nextMessage ? getTimeString(new Date(nextMessage.createdOn)) : ''
  const prevDateString = previousMessage
    ? getDateString(new Date(previousMessage.createdOn), 'short')
    : ''
  const nextDateString = nextMessage ? getDateString(new Date(nextMessage.createdOn), 'short') : ''
  const sentBySameAsPrevious =
    previousMessage?.createdByGroup === message.createdByGroup &&
    previousMessage?.createdBy === message.createdBy
  const sentBySameAsNext =
    previousMessage?.createdByGroup === message.createdByGroup &&
    nextMessage?.createdBy === message.createdBy

  const sentByMe = !!(me && message.createdBy === me.uid)
  const sentAtSameDateAsNext = dateString === nextDateString
  const sentAtSameDateAsPrevious = dateString === prevDateString
  const sentAtSameTimeAsNext = sentAtSameDateAsNext && timeString === nextTimeString
  const sentAtSameTimeAsPrevious = sentAtSameDateAsPrevious && timeString === prevTimeString

  return {
    ...message,
    timeString,
    dateString,
    sentBySameAsPrevious,
    sentByMe,
    sentBySameAsNext,
    sentAtSameTimeAsPrevious,
    sentAtSameTimeAsNext,
    sentAtSameDateAsPrevious,
    sentAtSameDateAsNext,
  }
}

const populateThreadMessages = <
  T extends ThreadMessageWithThreadData = ThreadMessageWithThreadData,
>(
  me: User,
  messages: Array<T>,
): Array<PopulatedThreadMessage<T>> =>
  sortMessagesByMostRecent(messages).map((message, i, arr) => {
    const previousMessage = i > 0 ? arr[i - 1] : undefined
    const nextMessage = i < arr.length - 1 ? arr[i + 1] : undefined
    return populateThreadMessage(me, message, previousMessage, nextMessage)
  })

const filterThreads = (threads: ThreadsListThread[], selectedTab?: ThreadTab | null) => {
  if (!selectedTab) return []
  switch (selectedTab) {
    case 'patient':
      return threads.filter(t => t.thread.accessLevel === 'patient')
    case 'practice':
      return threads.filter(t => t.thread.accessLevel === 'practice')
    case 'admin':
      return threads.filter(t => t.thread.accessLevel === 'admin')
    default:
      return []
  }
}

export type ThreadsListThread = {
  thread: MessageThread
  isUnread: boolean
  threadType: ThreadType
  threadId: string
}

export type UseMyThreads = {
  data: ThreadsListThread[]
  numUnreadThreads: number
  selectedTabThreads: ThreadsListThread[]
  loading: boolean
  error: string | null
  deepSearchLoading: boolean
  deepSearchResults: ThreadSearchResult[] | null
  deepSearchThreads: () => void
}

// const ELAN_ID = 'sBCNDAsQGyU31Ie84WlTJopz9HS2'
export const useMyThreads = (widget: UseMessagingWidget): UseMyThreads => {
  const me = useMe()

  const { data: widgetState, searchQuery } = widget
  const myGroupThreadsQuery = useMemo(() => {
    if (!me) return null
    return query(GROUP_THREADS_REF, where('subscribers', 'array-contains', me.uid))
  }, [me])

  // const myDirectMessageThreadsQuery = useMemo(
  //   () => (me
  //     ? query(
  //       DIRECT_MESSAGE_THREADS_REF,
  //       where('subscribers', 'array-contains', me.uid),
  //     )
  //     : null),
  //   [me],
  // )

  const {
    loading: groupThreadsLoading,
    data: groupThreads,
    error: groupThreadsError,
  } = useQuery(myGroupThreadsQuery)

  // const { loading: directMessageThreadsLoading, data: directMessageThreads, error: directMessageThreadsError } = useQuery(myDirectMessageThreadsQuery)

  const soundOpts = useMemo(
    () => ({ volume: widgetState?.notificationVolume || 0.25 }),
    [widgetState],
  )
  const [play] = useSound(newMessageSound, soundOpts)
  const totalUnreadThreads = useMemo(() => {
    if (!me) return 0
    const groupUnreadMessages = groupThreads
      ? objectToArray(groupThreads).reduce(
          (acc, curr) => acc + curr.unreadBy.filter(u => u === me.uid).length,
          0,
        )
      : 0
    // const directMessageUnreadMessages = directMessageThreads
    //   ? objectToArray(directMessageThreads).reduce(
    //     (acc, curr) => acc + curr.unreadBy.filter((u) => u === me.uid).length,
    //     0,
    //   )
    //   : 0
    return groupUnreadMessages // + directMessageUnreadMessages
  }, [groupThreads, me])

  const isMainWindow = useIsMainWindow()
  const prevMessagesCount = useRef(totalUnreadThreads || 0)
  const mounted = useRef(false)
  useEffect(() => {
    if (groupThreadsLoading || !isMainWindow) {
      mounted.current = false
      return
    }
    if (mounted.current && totalUnreadThreads > prevMessagesCount.current) {
      play()
    }
    mounted.current = true
    prevMessagesCount.current = totalUnreadThreads
  }, [totalUnreadThreads, play, groupThreadsLoading, isMainWindow])

  const { processResponse } = useContext(PopUpMessageContext)

  const [deepSearchLoading, setDeepSearchLoading] = useState(false)
  const [deepSearchResults, setDeepSearchResults] = useState<null | ThreadSearchResult[]>(null)

  useEffect(() => {
    if (!searchQuery) {
      setDeepSearchResults(null)
    }
  }, [searchQuery])
  const deepSearchThreads = useCallback(async () => {
    if (!me) return
    setDeepSearchLoading(true)
    try {
      await searchMyThreads({ query: searchQuery })
        .then(res => {
          setDeepSearchResults(res.data)
        })
        .catch(err => {
          processResponse({ error: err.message || 'An error occurred' })
          console.error(err)
        })
    } finally {
      setDeepSearchLoading(false)
    }
  }, [me, searchQuery, processResponse])

  return useMemo(() => {
    if (!me) {
      return {
        data: [],
        selectedTabThreads: [],
        loading: false,
        error: null,
        deepSearchLoading: false,
        deepSearchResults: null,
        searchQuery,
        deepSearchThreads,
        numUnreadThreads: 0,
      }
    }

    const groupThreadsData = objectToArray(groupThreads || {}).map(t =>
      fillEmptyThread(me, ThreadType.GROUP, t.id, t.title || '', t),
    )
    // const directMessageThreadsData = objectToArray(
    //   directMessageThreads || {},
    // ).map((t) => fillEmptyThread(me, ThreadType.DIRECT, t.id, t.title || '', t))

    const sortedThreads = sortThreadsByMostRecentMessage([
      ...groupThreadsData,
      // ...directMessageThreadsData,
    ])
    const selectedTabThreads = searchQuery
      ? sortedThreads.filter(t =>
          t.thread.searchString.toLowerCase().includes(toSearchString(searchQuery)),
        )
      : filterThreads(sortedThreads, widgetState?.tab)
    return {
      data: sortedThreads,
      selectedTabThreads,
      deepSearchLoading,
      deepSearchResults,
      loading: groupThreadsLoading,
      searchQuery,
      deepSearchThreads,
      // || directMessageThreadsLoading,
      error: groupThreadsError, // || directMessageThreadsError,
      numUnreadThreads: totalUnreadThreads,
    }
  }, [
    groupThreadsLoading,
    // directMessageThreadsLoading,
    searchQuery,
    deepSearchLoading,
    deepSearchResults,
    totalUnreadThreads,
    deepSearchThreads,
    // directMessageThreads,
    groupThreads,
    me,
    // directMessageThreadsError,
    groupThreadsError,
    widgetState,
  ])
}

export const useAssessmentThread = (assessmentId: string) =>
  useDocument<MessageThread>(ASSESSMENT_THREADS, assessmentId)

const messagesLimit = 1 // You can adjust the limit as needed
export type UseThreadMessages = {
  data: Array<PopulatedThreadMessage>
  loading: boolean
  error: string | null
  hasMoreData: boolean
  fetchMoreData: () => void
}
const getThreadMessageGroupCollection = (threadType: ThreadType, threadId: string) =>
  collection(
    db,
    getThreadMessageGroupCollectionPath(threadType, threadId),
  ) as CollectionReference<ThreadMessageGroup>
export const useThreadMessages = (
  threadType: ThreadType,
  threadId?: string,
  fetchAll?: boolean,
): UseThreadMessages => {
  const me = useMe()

  const [numToFetch, setNumToFetch] = useState(messagesLimit)

  // keep todays messages subscribed
  const [usedThreadId, setUsedThreadId] = useState<string | null>(threadId || null)

  const collectionId = useMemo(
    () => (usedThreadId ? getThreadMessageGroupCollectionPath(threadType, usedThreadId) : null),
    [usedThreadId, threadType],
  )
  const collectionRef = useMemo(
    () =>
      collectionId && usedThreadId
        ? getThreadMessageGroupCollection(threadType, usedThreadId)
        : null,
    [collectionId, usedThreadId, threadType],
  )
  const { todayString } = useTodayDate()
  const { data: todayData } = useDocument<ThreadMessageGroup>(
    collectionId || '',
    collectionId ? todayString.replace(/\//g, '-') : null,
  )

  const [loadingOverride, setLoadingOverride] = useState(false)
  const prevThreadId = useRef<string | null>(threadId || null)
  const threadMessageTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)

  useEffect(() => {
    if (!prevThreadId.current) {
      setUsedThreadId(threadId || null)
    } else if (prevThreadId.current !== threadId) {
      if (threadMessageTimeout.current) clearTimeout(threadMessageTimeout.current)
      setUsedThreadId(null)
      setLoadingOverride(true)
      setNumToFetch(messagesLimit)
      threadMessageTimeout.current = setTimeout(() => {
        setUsedThreadId(threadId || null)
        setTimeout(() => {
          setLoadingOverride(false)
        }, 50)
      }, 100)
    }
    prevThreadId.current = threadId || null
    return () => {
      if (threadMessageTimeout.current) clearTimeout(threadMessageTimeout.current)
    }
  }, [threadId])

  const pastDataQuery = useMemo(() => {
    if (!collectionRef) return null
    const q = query(collectionRef, orderBy('createdOn', 'desc')) as Query<ThreadMessageGroup>
    return fetchAll ? q : query(q, limit(numToFetch))
  }, [collectionRef, fetchAll, numToFetch])

  const { data: pastMessages, loading, error } = useQuery(pastDataQuery)
  const fetchedMessages = useMemo<ThreadMessagesWithThreadData>(() => {
    const allMessageGroups = { ...pastMessages }
    if (todayData) allMessageGroups[todayString] = todayData
    if (!usedThreadId) return {}
    return objectToArray(allMessageGroups).reduce(
      (acc, curr) => ({
        ...acc,
        ...Object.entries(curr.messages).reduce(
          (groupAcc, [messageId, message]) => ({
            ...groupAcc,
            [messageId]: {
              ...message,
              threadId: usedThreadId,
              threadType,
              messageGroupId: curr.id,
            },
          }),
          {} as ThreadMessagesWithThreadData,
        ),
      }),
      {},
    )
  }, [pastMessages, usedThreadId, threadType, todayData, todayString])

  const hasMoreData = useMemo(() => {
    if (fetchAll || numToFetch > Object.keys(pastMessages || {}).length) {
      return false
    }
    return true
  }, [numToFetch, pastMessages, fetchAll])

  // Fetch more data function
  const fetchMoreData = useCallback(async () => {
    if (!hasMoreData || !pastDataQuery || !threadType || !usedThreadId) return
    setNumToFetch(curr => curr + 1)
  }, [pastDataQuery, hasMoreData, threadType, usedThreadId])

  const allMessages = useMemo(() => {
    if (!me) return []
    if (!threadType || !usedThreadId) return []
    return sortMessagesByMostRecent(
      populateThreadMessages(me, objectToArray({ ...fetchedMessages })),
    )
  }, [fetchedMessages, me, threadType, usedThreadId])

  return {
    data: allMessages,
    loading: loading || loadingOverride,
    error,
    hasMoreData,
    fetchMoreData,
  }
}

export const useMessagingWidget = () => {
  const authUser = useAuth(s => s.authUser)
  const { appName } = useApp()
  const uid = authUser?.uid
  const collectionPath = useMemo(() => `${MESSAGING_WIDGET}/${uid}/apps`, [uid])
  const {
    data,
    update: _update,
    ...rest
  } = useOptimisticUpdate<MessagingWidgetState>(collectionPath, uid ? appName : null)

  const [localNotificationVolume, setLocalNotificationVolume] = useState<number | null>(null)

  const [searchResult, setSearchResult] = useState<ThreadSearchResult | null>(null)

  const [creatingNewThread, setCreatingNewThread] = useState(false)

  const [threadSelection, setThreadSelection] = useState<ThreadSelection | null>(null)

  const withDefaults = useMemo<MessagingWidgetState>(
    () => ({
      tab: appName === 'app' ? 'admin' : 'practice',
      notificationVolume: data?.notificationVolume || 0.25,
      ...data,
      thread: threadSelection,
    }),
    [data, appName, threadSelection],
  )
  const { isMobile } = useContext(ScreenContext)

  const [searchQuery, setSearchQuery] = useState('')
  const updateSearchQuery = useCallback((updated: string) => {
    setSearchQuery(updated)
  }, [])

  const tabsVisible = useMemo(
    () => !searchQuery && !creatingNewThread && (!isMobile || !data?.thread),
    [searchQuery, data, isMobile, creatingNewThread],
  )

  const update = useCallback(
    async (updated: Partial<MessagingWidgetState>) => {
      if (updated.thread !== undefined) setThreadSelection(updated.thread)
      return _update(updated)
    },
    [_update],
  )

  return {
    ...rest,
    update,
    localNotificationVolume,
    clearSearchResult: () => setSearchResult(null),
    searchResult,
    setSearchResult,
    updateSearchQuery,
    tabsVisible,
    searchQuery,
    creatingNewThread,
    setCreatingNewThread,
    updateLocalNotificationVolume: setLocalNotificationVolume,
    data: withDefaults,
  }
}
export const useThreadTitle = (
  threadType: ThreadType,
  threadId: string | undefined,
  thread: MessageThread | null,
) => {
  const me = useMe()
  const directMessageUserId = useMemo(() => {
    if (!thread) return null
    if (thread.type === 'direct' || (thread?.subscribers?.length || 0) < 3)
      return thread?.subscribers?.find(m => m !== me?.uid) || null
    return null
  }, [thread, me])
  const { data: directMessageUser } = useCachedUser(directMessageUserId)
  const { data: assessmentThreadAssessment } = useDocument<Assessment>(
    ASSESSMENTS,
    threadType === ThreadType.ASSESSMENT ? threadId : null,
  )
  const assessmentThreadPatientId = assessmentThreadAssessment?.patientId
  const { data: patient } = useCachedUser(assessmentThreadPatientId || null)

  const initials = useMemo(() => {
    switch (threadType) {
      case ThreadType.DIRECT:
        return directMessageUser ? getInitials(getFullName(directMessageUser)) : ''
      case ThreadType.ASSESSMENT:
        return patient ? getInitials(getFullName(patient)) : ''
      case ThreadType.GROUP:
        if (directMessageUser) return getInitials(getFullName(directMessageUser))
        return getInitials(thread?.title || '')?.slice(0, 3) || 'G'
      default:
        return ''
    }
  }, [thread, directMessageUser, patient, threadType])
  const title = useMemo(() => {
    if (threadType === ThreadType.DIRECT)
      return directMessageUser ? getFullName(directMessageUser) : ''
    if (thread?.title) return thread?.title
    switch (threadType) {
      case ThreadType.ASSESSMENT:
        return patient ? `${getAssessmentName(assessmentThreadAssessment)}` : ''
      case ThreadType.GROUP:
        return (thread?.subscribers?.length || 0) < 3
          ? getFullName(directMessageUser)
          : 'Group Chat'
      default:
        return ''
    }
  }, [thread, directMessageUser, patient, threadType, assessmentThreadAssessment])

  return {
    title,
    directMessageUser,
    directMessageUserId,
    initials,
  }
}

export const usePopulatedMessage = (message: ThreadMessageWithThreadData) => {
  const me = useMe()

  return useMemo(() => populateThreadMessage(me, message, undefined, undefined), [me, message])
}

export const usePopulatedMessages = (messages: ThreadMessageWithThreadData[]) => {
  const me = useMe()

  return useMemo(() => (me ? populateThreadMessages(me, messages) : null), [me, messages])
}
