import { CloseIcon } from '@chakra-ui/icons'
import { Box, Button, Center, CircularProgress, Flex, HStack, Text } from '@chakra-ui/react'
import { Descendant, EditorVersion, Template, templateKeyToCollection } from '@hb/shared'

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ReactEditor } from 'slate-react'
import { DefaultModalProps } from '../Modals/DefaultModal'
import { EditorSize, SubmitText } from '../RichText'
import { EditorModalContainer } from '../RichText/EditModalContainer'
import { TemplateToolbar } from '../RichText/TemplateToolbar'
import { TextEditor } from '../RichText/TextEditor'
import { AssessmentEditorProvider } from './contexts/documentEditor'

import { useDocument } from '../../hooks/backend/useDocument'
import { useTemplateData } from './contexts/data'
import { useTemplateView } from './contexts/view'
import { EditorFaxButton, useOnFax } from './EditorFaxButton'
import { EditorSaveButton, useOnSave } from './EditorSaveButton'
import { EditorSubmitButton, useOnSubmit } from './EditorSubmitButton'
import { TemplateMenu } from './TemplateMenu'
import { TemplateViewProps } from './types'
import { getWithVariables } from './withVariables'

export const TemplateViewModal: React.FC<
  Omit<TemplateViewProps, 'editorSize'> & Omit<DefaultModalProps, 'render'>
> = ({ id, templateType, editorVersion, submitText, ...modalProps }) => (
  <EditorModalContainer
    render={(editorSize, onBack) => (
      <TemplateViewBody
        templateType={templateType}
        editorVersion={editorVersion}
        onBack={onBack}
        id={id}
        editorSize={editorSize}
        submitText={submitText}
      />
    )}
    {...modalProps}
  />
)

const TemplateViewBody: React.FC<TemplateViewProps> = ({
  id,
  editorVersion,
  editorSize,
  onBack,
  templateType,
  submitText,
}) => {
  const {
    shortcutArgs: { assessment },
  } = useTemplateData()
  const { onFax, onSave, onSubmit, initialText } = useTemplateView()
  const [manualTemplateId, setManualTemplateId] = useState<string | undefined>()
  const collection = useMemo(() => templateKeyToCollection[templateType], [templateType])
  const { data: template, loading: templateLoading } = useDocument<Template>(
    collection,
    manualTemplateId || id,
  )
  const initTextUpdatedOn = useRef(Date.now())
  const manualTemplateSelectedOn = useRef(Date.now())

  const [initText, setInitialText] = useState(initialText || template?.templateText)
  const [isRestarting, setIsRestarting] = useState(false)

  const t0 = useRef<ReturnType<typeof setTimeout> | undefined>()
  const restartWithText = useCallback((text: Descendant[]) => {
    setIsRestarting(true)
    if (t0.current) clearTimeout(t0.current)
    setInitialText(text)
    t0.current = setTimeout(() => {
      setIsRestarting(false)
    }, 250)
  }, [])
  useEffect(() => {
    if (initialText?.length) {
      initTextUpdatedOn.current = Date.now()
      restartWithText(initialText || [])
    }
  }, [restartWithText, initialText])

  const usedVersion = useMemo(
    () => (template ? template?.editorVersion || 'v1' : editorVersion),
    [template, editorVersion],
  )

  const saveManager = useOnSave(usedVersion)

  const faxManager = useOnFax()
  const submitManager = useOnSubmit(templateType, usedVersion, submitText)

  const ref = useRef<ReactEditor>(null)

  const decorators = useMemo(() => [getWithVariables(usedVersion)], [usedVersion])

  const toolbars = useMemo(() => [TemplateToolbar], [])
  return isRestarting || templateLoading ? (
    <Center w="100%" h="100%" p={4}>
      <CircularProgress color="gray.500" size={5} isIndeterminate />
    </Center>
  ) : (
    <TextEditor
      withDownload
      version={usedVersion}
      decorators={decorators}
      toolbars={toolbars}
      templateType={templateType}
      value={initText}
      ref={ref}
      {...editorSize}>
      <HStack align="center" width="100%" p={2} zIndex={4} bg="white">
        <Box minW="0" flex={1}>
          <TemplateMenu
            insuranceProviderId={assessment?.insuranceProvider?.id}
            onChange={(t: Template) => {
              manualTemplateSelectedOn.current = Date.now()
              setManualTemplateId(t.id)
              restartWithText(t.templateText || [])
            }}
            template={template}
            templateType={templateType}
          />
        </Box>
        <Flex gap={1}>
          {onFax && !submitManager.submitConfirm ? <EditorFaxButton {...faxManager} /> : null}
          {onSubmit && !faxManager.faxSending ? <EditorSubmitButton {...submitManager} /> : null}
          {onSave && !submitManager.submitConfirm ? <EditorSaveButton {...saveManager} /> : null}
          {onBack ? (
            <Button
              variant="outline"
              onClick={onBack}
              size="xs"
              bg="gray.50"
              color="gray.500"
              alignItems="center"
              gap={1}>
              <CloseIcon position="relative" top="1px" w={2} />
              <Text>Close</Text>
            </Button>
          ) : null}
        </Flex>
      </HStack>
    </TextEditor>
  )
}

export const AssessmentTemplateView: React.FC<{
  initialText: Descendant[]
  size: EditorSize
  version: EditorVersion
  submitText: SubmitText
  templateId?: string
}> = ({ initialText, size, submitText, templateId, version }) => (
  <AssessmentEditorProvider initialText={initialText}>
    <TemplateViewBody
      editorSize={size}
      editorVersion={version}
      templateType="assessments"
      submitText={submitText}
      id={templateId}
    />
  </AssessmentEditorProvider>
)
