import { StackProps, Stack } from '@chakra-ui/react'
import React from 'react'

export const InfoStack = (props: StackProps) => (
  <Stack
    spacing={8}
    fontFamily="hero-new"
    align="center"
    direction={['column', 'column', 'row']}
    {...props}
  />
)
