import { notUndefinedOrNull, PercentageField } from '@hb/shared'
import React, { forwardRef, useContext } from 'react'
import { ThemeContext } from '../../../../../contexts'
import { InputElement, InputProps } from '../../../../../types/fields'
import InputContainer from '../../Shared/InputContainer'
import { TextInputElement } from '../styles'

const PercentageInput: InputElement<PercentageField> = forwardRef<
  HTMLInputElement,
  InputProps<PercentageField>
>((props, ref) => {
  const { field, input, disabled } = props
  const { placeholder } = field
  const { value } = input
  const { theme } = useContext(ThemeContext)
  return (
    <InputContainer {...props}>
      <TextInputElement
        ref={ref}
        disabled={disabled}
        placeholder={`${placeholder} (%)`}
        {...input}
        onChange={e => {
          input.onChange({
            ...e,
            target: { ...e.target, value: e.target.value.replace(/\D/g, '') },
          })
        }}
        value={(input.value as string).replace(/\D/g, '')}
      />
      {value ? (
        <span
          style={{
            position: 'absolute',
            opacity: notUndefinedOrNull(value) ? 1 : 0,
            display: 'flex',
            alignItems: 'center',
            left: theme === 'basic' ? '0.8rem' : '1.2rem',
            pointerEvents: 'none',
            lineHeight: 1,
          }}>
          <span style={{ opacity: 0, fontSize: '14px' }}>{input.value.replace(/\D/g, '')}</span>
          <span style={{ fontSize: '14px', color: disabled ? '#777' : '#111' }}>%</span>
        </span>
      ) : null}
    </InputContainer>
  )
})

export default PercentageInput
