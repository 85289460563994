import { Button } from '@chakra-ui/react'
import { colors, FieldMapValue } from '@hb/shared'
import { ValidationErrors } from 'final-form'
import React, { useState } from 'react'
import { useFormState } from 'react-final-form'

export const SaveAsDraftButton = ({
  onSave,
}: {
  onSave: (values: FieldMapValue) => Promise<ValidationErrors>
}) => {
  const { values } = useFormState({
    subscription: { values: true, hasValidationErrors: true },
  })
  const [isLoading, setIsLoading] = useState(false)
  return (
    <Button
      // bg={colors.green.hex}
      position="absolute"
      left="40px"
      border={`1px solid ${colors.green.hex}`}
      color={colors.green.hex}
      bg="white"
      size="xs"
      onClick={async () => {
        setIsLoading(true)
        await onSave(values)
        setIsLoading(false)
      }}
      isLoading={isLoading}>
      SAVE AS DRAFT
    </Button>
  )
}
