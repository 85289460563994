import { CheckIcon, EditIcon, WarningIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  Flex,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import {
  colors,
  getDateString,
  getFullName,
  InvoicePaymentInstallment,
  objectToArray,
  PracticeInvoice,
  sortByDateKey,
  WithId,
} from '@hb/shared'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { confirmPaymentInstallment, reportPaymentInstallment } from '../../backend'
import { useCachedUser } from '../../collections'
import { PopUpMessageContext, ScreenContext, useApp } from '../../contexts'
import { useAuth } from '../../store'
import { ActionLog } from '../ActionLog'
import { ActionButton, DeleteButton, SolidActionButton } from '../Buttons'
import { CollapseHorizontal } from '../shared'
import { RecordPaymentButton, RecordPaymentModal } from './RecordInvoicePayment'

const InvoicePendingConfirmationOverlay = () => (
  <Box
    position="absolute"
    top={0}
    left={0}
    height="100%"
    width="100%"
    opacity={0.3}
    // bg="#00000022"
    backgroundImage={'url(/images/hazard_overlay.png)'}
    // tile bg image
    // backgroundSize="cover"
    backgroundSize="70px 30px"
    backgroundRepeat="repeat"
    backgroundPosition="center"
  />
)

const PendingConfirmationAdminView = ({
  installmentId,
  onEditClick,
  invoice,
}: {
  // installment: InvoicePaymentInstallment & { percentPaid: number }
  installmentId: string
  onEditClick: () => void
  invoice: WithId<PracticeInvoice>
}) => {
  const { processResponse } = useContext(PopUpMessageContext)
  const [loadingAction, setLoadingAction] = useState<'delete' | 'edit' | 'confirm' | null>(null)

  const onConfirm = useCallback(async () => {
    setLoadingAction('confirm')
    try {
      await confirmPaymentInstallment({
        invoiceId: invoice.id,
        installmentId,
      })
    } catch (err: any) {
      processResponse({ error: err?.message || 'Error deleting payment' })
    } finally {
      setLoadingAction(null)
    }
  }, [installmentId, invoice, processResponse])

  return (
    <Flex pb={2} px={2} gap={2}>
      <ActionButton
        isLoading={loadingAction === 'edit'}
        onClick={onEditClick}
        pointerEvents={loadingAction ? 'none' : 'auto'}
        opacity={loadingAction && loadingAction !== 'edit' ? 0.5 : 1}
        gap={1}
        size="sm">
        <EditIcon />
        <Text>Edit</Text>
      </ActionButton>
      <SolidActionButton
        isLoading={loadingAction === 'confirm'}
        pointerEvents={loadingAction ? 'none' : 'auto'}
        opacity={loadingAction && loadingAction !== 'confirm' ? 0.5 : 1}
        onClick={onConfirm}
        gap={1}
        size="sm">
        <CheckIcon filter="drop-shadow(1px 1px 3px #00000077)" />
        <Text>Confirm</Text>
      </SolidActionButton>
    </Flex>
  )
}

const DeletePaymentInstallment = ({
  invoice,
  installment,
  installmentId,
}: {
  invoice: WithId<PracticeInvoice>
  installment: InvoicePaymentInstallment
  installmentId: string
}) => {
  const { appName } = useApp()
  const { processResponse } = useContext(PopUpMessageContext)
  const shouldConfirmDelete = useMemo(
    () => invoice.paidOn && (invoice.paidAmount || 0) - installment.amount < invoice.amount,
    [invoice, installment],
  )
  const onDelete = useCallback(async () => {
    try {
      await reportPaymentInstallment({
        appName,
        installment: null,
        invoiceId: invoice.id,
        installmentId,
      })
    } catch (err: any) {
      processResponse({ error: err?.message || 'Error deleting payment' })
    }
  }, [installmentId, invoice, appName, processResponse])
  return (
    <Flex w="100%">
      <Box ml="auto">
        <DeleteButton
          noConfirm={!shouldConfirmDelete}
          itemName="Payment"
          alertBody="Deleting this payment will mark the invoice as unpaid. Continue?"
          onDelete={onDelete}
          size="xs"
          text="Delete installment"
        />
      </Box>
    </Flex>
  )
}

const PendingConfirmationView = (props: {
  installment: InvoicePaymentInstallment & { percentPaid: number }
  installmentId: string
  invoice: WithId<PracticeInvoice>
  onEditClick: () => void
}) => {
  const { appName } = useApp()
  return (
    <Flex
      position="relative"
      borderRadius={6}
      overflow="hidden"
      border="1px solid #cdcdcd"
      align="center"
      bg="white"
      flexFlow="column"
      gap={0}>
      <Flex bg="green.400" borderBottom="1px solid #cdcdcd" position="relative" w="100%" h="20px">
        <InvoicePendingConfirmationOverlay />
      </Flex>
      <Flex p={1} gap={2} align="center">
        <WarningIcon w={4} color="gray.400" />
        <Text color="gray.500" fontSize="sm" fontFamily="Open Sans">
          Pending admin confirmation
        </Text>
      </Flex>
      {appName === 'app' ? <PendingConfirmationAdminView {...props} /> : null}
    </Flex>
  )
}

const PaymentInstallmentPopoverContent = ({
  installment,
  installmentId,
  invoice,
  onEditClick,
}: {
  installment: InvoicePaymentInstallment & { percentPaid: number }
  installmentId: string
  invoice: WithId<PracticeInvoice>
  onEditClick: () => void
}) => {
  const role = useAuth(s => s.role)
  const { appName } = useApp()
  const {
    amount,
    paidOn,
    type,
    createdBy,
    createdOn,
    pendingAdminConfirmation,
    updatedBy,
    updatedOn,
    createdByGroup,
    updatedByGroup,
  } = installment
  return (
    <Flex flexFlow="column" p={3} borderRadius={6}>
      <Text lineHeight={1} color="gray.600" fontFamily="Hero-New">
        <span
          style={{
            color: colors.green.hex,
            fontWeight: 500,
          }}>{`$${amount.toFixed(2)}`}</span>
      </Text>
      <Text>
        Paid via {type} on {getDateString(paidOn)}
      </Text>
      {pendingAdminConfirmation ? (
        <PendingConfirmationView
          installmentId={installmentId}
          invoice={invoice}
          installment={installment}
          onEditClick={onEditClick}
        />
      ) : null}
      <Flex pt={1} flexFlow="column" gap={1}>
        <ActionLog action="Recorded payment" by={createdBy} on={createdOn} group={createdByGroup} />
        {createdBy !== updatedBy && createdOn !== updatedOn ? (
          <ActionLog action="Updated" by={updatedBy} on={updatedOn} group={updatedByGroup} />
        ) : null}
      </Flex>
      {appName === 'app' && role === 'super-admin' ? (
        <DeletePaymentInstallment
          installment={installment}
          installmentId={installmentId}
          invoice={invoice}
        />
      ) : null}
    </Flex>
  )
}

const PaymentInstallmentPreview = ({
  installment,
  isOpen,
}: {
  installment: InvoicePaymentInstallment & { percentPaid: number }
  isOpen: boolean
}) => {
  const { amount: paymentAmount, percentPaid, pendingAdminConfirmation } = installment
  const { isMobile } = useContext(ScreenContext)
  const fontSize = useMemo(() => {
    if (installment.percentPaid > 0.3) return isMobile ? 'mm' : 'md'
    if (installment.percentPaid > 0.1) return isMobile ? 'xs' : 'sm'
    return 'xs'
  }, [installment, isMobile])
  const isSmall = useMemo(() => {
    if (percentPaid > 0.3) return isMobile
    if (percentPaid > 0.1) return isMobile
    return true
  }, [percentPaid, isMobile])
  return (
    <Center
      bg={isOpen ? 'green.400' : 'green.300'}
      h="100%"
      borderRadius={4}
      fontWeight={600}
      color="white"
      position="relative"
      w="100%"
      maxW="100%"
      overflow="hidden"
      fontFamily="Open Sans"
      px={2}
      cursor="pointer"
      textShadow="1px 1px 3px #000000cc"
      transition="all 300ms">
      {pendingAdminConfirmation ? <InvoicePendingConfirmationOverlay /> : null}
      <Flex fontSize={fontSize} zIndex={1} align="center" gap={2}>
        <span style={{ lineHeight: 1 }}>${paymentAmount.toFixed(isSmall ? 0 : 2)}</span>
      </Flex>
    </Center>
  )
}

const PaymentInstallmentView = ({
  installment,
  installmentId,
  invoice,
}: {
  installment: InvoicePaymentInstallment & { percentPaid: number }
  installmentId: string
  invoice: WithId<PracticeInvoice>
}) => {
  const [editing, setEditing] = useState(false)

  return (
    <>
      <Popover closeOnBlur={false} isLazy trigger="hover" placement="top">
        {({ isOpen }) => (
          <>
            <PopoverTrigger>
              <Box minW="0" overflow="hidden" flex={installment.percentPaid}>
                <PaymentInstallmentPreview isOpen={isOpen} installment={installment} />
              </Box>
            </PopoverTrigger>
            <PopoverContent bg="gray.50" borderRadius={6} width="400px" maxW="100vw">
              <Box>
                <PaymentInstallmentPopoverContent
                  onEditClick={() => setEditing(true)}
                  installment={installment}
                  invoice={invoice}
                  installmentId={installmentId}
                />
              </Box>
              <PopoverArrow bg="gray.50" />
            </PopoverContent>
          </>
        )}
      </Popover>
      {editing ? (
        <RecordPaymentModal
          isOpen
          onClose={() => setEditing(false)}
          invoice={invoice}
          installmentId={installmentId}
        />
      ) : null}
    </>
  )
}

const RemainingBalanceView = ({ amount, paidAmount }: { amount: number; paidAmount: number }) => {
  const percentNotPaid = useMemo(
    () => (paidAmount && amount ? 1 - paidAmount / amount : 1),
    [paidAmount, amount],
  )
  const { isMobile } = useContext(ScreenContext)
  const fontSize = useMemo(() => {
    if (percentNotPaid > 0.3) return isMobile ? 'mm' : 'md'
    if (percentNotPaid > 0.1) return isMobile ? 'xs' : 'sm'
    return 'xs'
  }, [percentNotPaid, isMobile])
  const remainingAmount = amount - (paidAmount || 0)
  return (
    <Tooltip
      placement="top"
      hasArrow
      bg="#999999"
      textShadow="1px 1px 3px #00000077"
      label={`$${remainingAmount.toFixed(2)} remaining`}>
      <Center
        fontWeight={600}
        color="#7a7a7a"
        fontSize={fontSize}
        borderRadius={4}
        minW="0"
        h="100%"
        flex={percentNotPaid}>
        ${remainingAmount.toFixed(2)}
      </Center>
    </Tooltip>
  )
}

const FallbackPaidInFullView = ({
  markedPaidBy,
  markedPaidOn,
}: {
  markedPaidBy: string | null
  markedPaidOn: string | null
}) => {
  const { data } = useCachedUser(markedPaidBy)
  const fullName = useMemo(() => getFullName(data), [data])
  return (
    <Center
      bg="green.400"
      h="100%"
      borderRadius={4}
      fontWeight={600}
      color="white"
      position="relative"
      flex={1}
      fontFamily="Open Sans"
      cursor="pointer"
      textShadow="1px 1px 3px #000000cc">
      <Flex zIndex={1} align="center" gap={2}>
        <span style={{ lineHeight: 1, fontSize: '0.9rem' }}>
          Marked paid by {fullName}
          {markedPaidOn ? ` on ${getDateString(markedPaidOn, 'short')}` : ''}
        </span>
      </Flex>
    </Center>
  )
}

export const InvoicePaymentProgress = ({ invoice }: { invoice: WithId<PracticeInvoice> }) => {
  const { amount, installments, paidOn: invoicePaidOn, markedPaidBy } = invoice || {}

  const paidAmount = useMemo(() => {
    if (invoicePaidOn && !installments) return amount
    if (!installments) return 0
    return objectToArray(installments).reduce(
      (acc, installment) => acc + (installment.amount || 0),
      0,
    )
  }, [installments, amount, invoicePaidOn])

  const { paidInFull, paidPercent } = useMemo(() => {
    if (invoicePaidOn) return { paidInFull: true, paidPercent: 1 }
    if (!amount) return { paidInFull: false, paidPercent: 0 }
    return {
      paidInFull: paidAmount >= amount,
      paidPercent: paidAmount / amount,
    }
  }, [amount, paidAmount, invoicePaidOn])

  const installmentsWithPercents = useMemo(() => {
    if (!installments) return []
    const installmentsArray = objectToArray(sortByDateKey(installments))
    return installmentsArray.map(installment => ({
      ...installment,
      percentPaid: installment.amount / amount,
    }))
  }, [installments, amount])

  return (
    <Flex flexFlow="column" p={2} w="100%">
      <Center gap={2}>
        <Text
          // fontSize="sm"
          fontFamily="Open Sans"
          color="gray.500"
          lineHeight={1}
          py={1}>{`$${(paidAmount || 0).toFixed(2)} of $${amount.toFixed(2)} paid`}</Text>
        <Text
          fontSize="xs"
          lineHeight={1}
          bg="green.400"
          color="white"
          p="0.15rem 0.35rem"
          fontWeight={500}
          fontFamily="Hero-New"
          textShadow="1px 1px 3px #00000077"
          borderRadius="15px">
          {(paidPercent * 100).toFixed(0)}%
        </Text>
      </Center>
      <Flex w="100%" height="auto" align="center">
        <Flex
          boxShadow="1px 1px 5px #00000088"
          flex={1}
          minW="0"
          p="0.2rem"
          borderRadius={4}
          bg="gray.50">
          <Flex
            bg="#ccc"
            height="28px"
            borderRadius={4}
            p="3px"
            w="100%"
            gap="0.15rem"
            position="relative">
            {installmentsWithPercents.map(installment => (
              <PaymentInstallmentView
                key={installment.id}
                installment={installment}
                installmentId={installment.id}
                invoice={invoice}
              />
            ))}
            {!paidInFull ? <RemainingBalanceView amount={amount} paidAmount={paidAmount} /> : null}
            {paidInFull && installmentsWithPercents.length === 0 ? (
              <FallbackPaidInFullView markedPaidOn={invoicePaidOn} markedPaidBy={markedPaidBy} />
            ) : null}
          </Flex>
        </Flex>
        <CollapseHorizontal height="40px" in={!invoicePaidOn} width={40}>
          <RecordPaymentButton invoice={invoice} />
        </CollapseHorizontal>
      </Flex>
    </Flex>
  )
}
