import { AssessmentInvoiceSnippet, Practice, PracticeAccess, PracticeUserAccess } from '../../types'
import {
  formatDollarValue,
  formatPaymentType,
  getDateString,
  separatePayments,
  toSearchString,
} from '../../utils'
import { invoiceStatuses, nyTimezoneOffset } from '../constants'
import {
  ChargedPaymentReceived,
  Invoice,
  InvoiceAssessmentPaymentsSnippet,
  PracticeInvoice,
  PracticeInvoiceOmission,
} from '../types'

export const getInvoiceMonthString = (date: Date) => {
  const month = date.getUTCMonth() + 1
  const year = date.getUTCFullYear()
  return `${month}-${year}`
}

// const getCurrentInvoiceMonthString = () => {
//   const currentTimeNY = new Date(Date.now() - nyTimezoneOffset)
//   return getInvoiceMonthString(currentTimeNY)
// }

export const getInvoiceId = (practiceId: string, date: Date, numAttempts: number) =>
  `${practiceId}-${getInvoiceMonthString(date)}${numAttempts ? `-${numAttempts}` : ''}`

export const getCurrentMonthAndYear = () => {
  const currentTimeNY = new Date(Date.now() - nyTimezoneOffset)
  return {
    month: currentTimeNY.getUTCMonth(),
    year: currentTimeNY.getUTCFullYear(),
  }
}

export const parseDateFromInvoiceId = (invoiceId: string) => {
  const [, monthStr, yearStr] = invoiceId.split('-')
  const month = parseInt(monthStr, 10) - 1
  if (Number.isNaN(month)) throw new Error(`Invalid month ${monthStr}`)
  const year = parseInt(yearStr, 10)
  if (Number.isNaN(year)) throw new Error(`Invalid year ${yearStr}`)
  return { month, year }
}

// 2 months ahead
export const getInvoiceDueDate = ({ year, month }: { year: number; month: number }) => {
  const date = month > 10 ? new Date(year + 1, month - 11, 25) : new Date(year, month + 1, 25)

  return date.toISOString()
}
// 1 month ahead
export const getInvoiceScheduleDate = ({ year, month }: { year: number; month: number }) => {
  const date = month > 10 ? new Date(year + 1, month - 11, 1) : new Date(year, month + 1, 1)

  return date.toISOString()
}

export const getAdjustedDepositsTotal = (assessment: InvoiceAssessmentPaymentsSnippet) => {
  const { deposits } = separatePayments(assessment.chargedPayments)
  const totalCharged = Object.values(deposits).reduce(
    (total, deposit) => total + deposit.invoicedAmount,
    0,
  )
  return Math.max(0, totalCharged)
}

export const getInvoiceAssessmentDepositsString = (
  assessment: InvoiceAssessmentPaymentsSnippet,
  assessmentId: string,
  omissions: Record<string, PracticeInvoiceOmission>,
): string | null => {
  const { deposits } = separatePayments(assessment.chargedPayments, assessmentId, omissions)
  const totalDepositsAmount = getAdjustedDepositsTotal(assessment)
  const datesString = Object.values(deposits)
    .reduce((dates, deposit) => {
      const dateString = getDateString(deposit.date, 'short')
      if (!dates.includes(dateString)) {
        dates.push(dateString)
      }
      return dates
    }, [] as string[])
    .map(date => getDateString(date, 'short'))
    .join(', ')

  return totalDepositsAmount
    ? `${formatDollarValue(totalDepositsAmount)} in deposits paid by patient - ${datesString}`
    : null
}

export const getInvoiceAssessmentPaymentString = (payment: ChargedPaymentReceived) =>
  `${formatDollarValue(payment.invoicedAmount)} ${formatPaymentType(
    payment.type,
  )} paid by ${payment.paidBy} -  ${getDateString(payment.date, 'short')}`

export const getInvoiceSentFromStatus = (status: Invoice['status']) =>
  status === 'sent' || status === 'paid'

export const getInvoiceSent = (invoice: Invoice | AssessmentInvoiceSnippet) =>
  getInvoiceSentFromStatus(
    (invoice as Invoice).status || (invoice as AssessmentInvoiceSnippet).invoiceStatus,
  )

export const getInvoiceSendToUser = (
  practiceAccess: PracticeAccess | undefined,
): PracticeUserAccess | null => {
  const sendToId =
    practiceAccess?.sendInvoicesTo ||
    Object.entries(practiceAccess?.users || {})
      .sort(([, { addedOn: addedOnA }], [, { addedOn: addedOnB }]) => addedOnA - addedOnB)
      .filter(([, userAccess]) => userAccess.role === 'admin')?.[0]?.[0]
  if (!sendToId) return null
  return practiceAccess?.users?.[sendToId] || null
}

export const invoiceStatusSort = (
  a: AssessmentInvoiceSnippet | PracticeInvoice,
  b: AssessmentInvoiceSnippet | PracticeInvoice,
) => {
  const aAsSnippet = a as AssessmentInvoiceSnippet
  const aAsInvoice = a as PracticeInvoice
  const bAsSnippet = b as AssessmentInvoiceSnippet
  const bAsInvoice = b as PracticeInvoice
  const aStatus = aAsSnippet.invoiceStatus || aAsInvoice.status
  const bStatus = bAsSnippet.invoiceStatus || bAsInvoice.status

  return invoiceStatuses.indexOf(aStatus) - invoiceStatuses.indexOf(bStatus)
}

const getInvoiceAssessmentSnippetSearchString = (assessment: InvoiceAssessmentPaymentsSnippet) => {
  const { assessmentName, fname, lname, nickname } = assessment
  return [assessmentName, fname, lname, nickname]
    .map(m => m || '')
    .filter(s => !!s)
    .map(toSearchString)
    .join('|')
}
export const getInvoiceSearchString = (
  invoice: Omit<PracticeInvoice, 'searchString'>,
  practice: Practice | null,
) => {
  const { practiceName, assessments } = invoice
  const { fname, lname } = practice || {}
  const assessmentSearchString = Object.values(assessments)
    .map(getInvoiceAssessmentSnippetSearchString)
    .join('|')

  const invoiceStringified = [fname, lname, practiceName, assessmentSearchString]
    .filter(s => !!s)
    .map(s => toSearchString(s || ''))
    .join('|')
  return [assessmentSearchString, invoiceStringified].join('|')
}

export const getInvoiceStatusName = (status: PracticeInvoice['status']) => {
  switch (status) {
    case 'draft':
      return 'Draft'
    case 'paid':
      return 'Paid'
    case 'pending-approval':
      return 'Pending Approval'
    case 'scheduled':
      return 'Scheduled'
    case 'sent':
      return 'Sent'
    default:
      return 'Unknown'
  }
}
