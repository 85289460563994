import { AnyObject, WithId } from '@hb/shared'
import { onSnapshot, Query } from 'firebase/firestore'
import { useCallback, useContext, useEffect, useState } from 'react'
import { PopUpMessageContext } from '../../contexts/PopUpMessage/PopUpMessageContext'

export const useQuery = <T>(
  ref: Query<T> | null,
  onNewData?: (newData: Record<string, T> | null) => void,
) => {
  const { showError } = useContext(PopUpMessageContext)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [data, setData] = useState<Record<string, T> | null>(null)
  const onError = useCallback(
    (message: string) => {
      setError(message)
      showError(message)
    },
    [showError],
  )

  useEffect(() => {
    if (!ref) {
      setData(null)
      return () => {}
    }
    setLoading(true)
    return onSnapshot(
      ref,
      res => {
        setLoading(false)
        if (res.empty) {
          setData(null)
        } else {
          const updated = res.docs.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.id]: curr.data() as T,
            }),
            {} as Record<string, T>,
          )
          if (onNewData) onNewData(updated)
          setData(updated)
        }
        setError(null)
      },
      err => {
        setLoading(false)
        console.error(err)
        console.error(ref)
        switch (err.code) {
          case 'permission-denied':
            onError('Insufficient permissions')
            break
          case 'unauthenticated':
            onError('Not authenticated')
            break
          default:
            onError('Error getting firestore document')
            break
        }
      },
    )
  }, [ref, onError, onNewData])

  return {
    data,
    loading,
    error,
  }
}

export const useListQuery = <T extends AnyObject>(ref: Query<T> | null) => {
  const { showError } = useContext(PopUpMessageContext)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [data, setData] = useState<Array<WithId<T>> | null>(null)
  const onError = useCallback(
    (message: string) => {
      setError(message)
      showError(message)
    },
    [showError],
  )

  useEffect(() => {
    if (!ref) {
      setData(null)
      return () => {}
    }
    return onSnapshot(
      ref,
      res => {
        setLoading(false)
        if (res.empty) {
          setData(null)
        } else {
          setData(res.docs.map(doc => ({ ...doc.data(), id: doc.id })))
        }
      },
      err => {
        console.error(err)
        setLoading(false)
        switch (err.code) {
          case 'permission-denied':
            onError('Insufficient permissions')
            break
          case 'unauthenticated':
            onError('Not authenticated')
            break
          default:
            onError('Error getting firestore document')
            break
        }
      },
    )
  }, [ref, onError])

  return {
    data,
    loading,
    error,
  }
}
