/*
Delivery    Global Maternity Care    59400    Z34.80    O80    Z39.2    Z39.1    Z3A.40
Delivery    Vaginal Delivery + PP Care    59410    O80    Z39.2    Z39.1        Z3A.40
Delivery    Vaginal Delivery    59409    O80                Z3A.40
Delivery    Cesarean Global Maternity Care    59510    Z34.80    O82    Z39.2    Z39.1    Z3A.40
Delivery    Cesarean Delivery    59514    O82                Z3A.40

Antepartum    Prenatal Visits, 4-6    59425    Z34.80    Z33.1
Antepartum    Prenatal Visits, 7 or more    59426    Z34.80    Z33.1
Antepartum    New Patient Home Visit, 1hr    99344    Z34.80    Z33.1
Antepartum    Established Patient Home 40+ min    99349    Z34.80    Z33.1
Antepartum    New Patient Office Visit, 1 hr    99205    Z34.80    Z33.1
Antepartum    New Patient Office Visit, 45-60 min    99204    Z34.80    Z33.1
Antepartum    Established Patient Office Visit 40-54 min    99215    Z34.80    Z33.1
Antepartum    Consultation / 60 min    99404    Z34.80    Z33.1
Fetal nonstress test        59025        Z33.1

Postpartum    Postpartum Visits    59430    Z39.2    Z39.1
Postpartum    Established Patient Home Visit 40+min    99349    Z39.2    Z39.1
Postpartum    Established Patient Office Visit 40-54min    99215    Z39.2    Z39.1

Fetal non-stress tes
Labor    Home Labor, 1st hour    99350    Z34.80    Z33.1
Labor    Home Labor, 2nd hour    99291    Z34.80    Z33.1
Labor    Home Labor, 30 minutes    99292    Z34.80    Z33.1    O63.9
Labor    Home Labor, 15 minutes    99417    Z34.80    Z33.1    O63.9
Labor    Hospital Admission (1st hr of Labor)    99223    Z34.80    Z33.1
Labor    Hospital Labor, 15 minutes    99418    Z34.80    Z33.1    O63.9
Labor    Discharge, Final exam in Hospital    99217    Z39.2

Newborn
Newborn    Initial Newborn Care    99461    Z38.1    Z00.110    Z37.0
Newborn    New Patient Home Visit, 1hr    99344    Z38.1    Z00.110
Newborn    New Patient Home Visit, 30+ minutes    99342        Z00.110 OR Z00.111
Newborn    Established Patient Home Visit    99349    Z38.1    Z00.110    Z00.111
Newborn    Established patient / newborn    99391        Z00.110 or Z00.111
Newborn    Birth Assistant    99464    Z00.110
Newborn    Newborn Resuscitation    99465    P92.6

Lactation    Lactation Class / Breastfeeding support    S9443    Z39.1

GYN    New Patient GYN Visit, 18-39 yrs    99385    Z01.419    Z30.0
GYN    Established Patient GYN Visit, 18-39 yrs    99395    Z01.419
GYN    New Patient GYN Visit, 40-64 yrs    99386    Z01.419    Z30.0
GYN    Established Patient GYN Visit, 40-64 yrs    99396    Z01.419
GYN    IUD Placement    58300    Z30.430
GYN    Annual GYN Exam, Established Patient    S0612    Z01.419
GYN    Pap Smear    Q0091    Z11.51

*/

import { ICD10 } from '../../types/providers'

export const o36_0131: ICD10 = {
  code: 'O36.0131',
  description: 'Administered upon delivery of newborn',
}

export const o36_0191: ICD10 = {
  code: 'O36.0191',
  description: '(administered at 28 weeks)',
}

export const o63_9: ICD10 = {
  code: 'O63.9',
  description: 'Unspecified complication of labor and delivery.',
}

export const o80: ICD10 = {
  code: 'O80',
  description: 'Encounter for full-term uncomplicated delivery.',
}

export const o82: ICD10 = {
  code: 'O82',
  description: 'Encounter for cesarean delivery without indication.',
}

export const p92_6: ICD10 = {
  code: 'P92.6',
  description: 'Failure to thrive in newborn.',
}

export const z00_110: ICD10 = {
  code: 'Z00.110',
  description: 'Health examination for newborn under 8 days old',
}

export const z00_111: ICD10 = {
  code: 'Z00.111',
  description: 'Health examination for newborn 8 to 28 days old',
}

export const z01_419: ICD10 = {
  code: 'Z01.419',
  description:
    'Encounter for gynecological examination (general) (routine) without abnormal findings.',
}

export const z11_51: ICD10 = {
  code: 'Z11.51',
  description: 'Encounter for screening for human papillomavirus (HPV).',
}

export const z30_0: ICD10 = {
  code: 'Z30.0',
  description: 'Encounter for general counseling and advice on contraception.',
}

export const z30_430: ICD10 = {
  code: 'Z30.430',
  description: 'Encounter for insertion of intrauterine contraceptive device.',
}

export const z33_1: ICD10 = {
  code: 'Z33.1',
  description: 'Pregnant state, incidental.',
}

export const z34_80: ICD10 = {
  code: 'Z34.80',
  description:
    'Encounter for supervision of other normal pregnancy, unspecified trimester(80), 1st trimester (81), 2nd trimester (82), 3rd trimester (83).',
}

export const z37_0: ICD10 = {
  code: 'Z37.0',
  description: 'Single liveborn infant, born in hospital.',
}

export const z37_2: ICD10 = {
  code: 'Z37.2',
  description: 'Twin birth, mate liveborn, born in hospital.',
}

export const z38_1: ICD10 = {
  code: 'Z38.1',
  description: 'Single liveborn infant, born outside hospital.',
}

export const z38_30: ICD10 = {
  code: 'Z38.30',
  description: 'Twin liveborn infant, delivered vaginally.',
}

export const z39_2: ICD10 = {
  code: 'Z39.2',
  description: 'Encounter for routine postpartum follow-up.',
}

export const z39_1: ICD10 = {
  code: 'Z39.1',
  description:
    'Encounter for care and examination of lactating mother, encounter for supervision of lactation.',
}

export const z3a_40: ICD10 = {
  code: 'Z3A.40',
  description:
    '40 weeks gestation of pregnancy. "Z3A." -then add the number of weeks the patient is at the time of delivery.',
}

export const getBcbsIcd10 = (icd: ICD10): ICD10 => ({
  ...icd,
  prefix: 'BCBS',
})
