import { INVOICES, INVOICES_DELETED } from '@hb/shared'
import { collection, getDocs, limit, query, where } from 'firebase/firestore'
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { db } from '../../backend/db'
import { useApp } from '../../contexts/AppContext'
import { PopUpMessageContext } from '../../contexts/PopUpMessage/PopUpMessageContext'
import { usePracticeAccess } from '../../contexts/PracticeAccess/PracticeAccessProvider'
import { UserProfileTabName } from '../Users/Profile/types'

type InvoiceTabName =
  | 'pending-approval'
  | 'current'
  | 'sent'
  | 'paid'
  | 'settings'
  | 'archived'
  | 'deleted'
  | 'outstanding'
const invoiceTabNames: Array<InvoiceTabName> = [
  'pending-approval',
  'current',
  'sent',
  'paid',
  'settings',
  'archived',
  'deleted',
  'outstanding',
]
export type InvoicesViewContextValue = {
  selectedPracticeId: string | null
  selectPractice: (id: string | null) => void
  selectedInvoiceId: string | null
  selectInvoice: (id: string | null) => void
  tabIndex: number
  invoicesTab?: InvoiceTabName
  onTabSelect: (index: number) => void
  selectedAssessmentId: string | null
  goToInvoiceByNumber: (invoiceNumber: string) => Promise<void>
  selectAssessment: (invoiceId: string, assessmentId: string, tab?: string) => void
  deselectAssessment: () => void
  assessmentTab?: UserProfileTabName
  selectAssessmentTab: (tab: UserProfileTabName) => void
}

const InvoicesViewContext = createContext<InvoicesViewContextValue>({
  selectedPracticeId: null,
  selectPractice: () => {},
  selectedInvoiceId: null,
  selectInvoice: () => {},
  goToInvoiceByNumber: async () => {},
  deselectAssessment: () => {},
  tabIndex: 0,
  onTabSelect: () => {},
  selectedAssessmentId: null,
  selectAssessment: () => {},
  selectAssessmentTab: () => {},
})

export const useInvoicesView = () => useContext<InvoicesViewContextValue>(InvoicesViewContext)

const useInvoicesViewData = (basePath: string) => {
  const { appName } = useApp()
  const { selectedPracticeId: appPracticeId } = usePracticeAccess()
  const [manualPracticeId, setSelectedPracticeId] = useState<string | null>(null)

  const selectedPracticeId = useMemo(
    () => (appName === 'app' ? manualPracticeId : appPracticeId),
    [appName, appPracticeId, manualPracticeId],
  )

  const {
    invoiceId: selectedInvoiceId = null,
    assessmentId: selectedAssessmentId = null,
    assessmentTab,
  } = useParams<{
    invoiceId?: string
    assessmentId?: string
    assessmentTab?: UserProfileTabName
  }>()
  const defaultTab = useMemo(
    () => (appName === 'app' ? 'pending-approval' : 'outstanding'),
    [appName],
  )
  const { pathname } = useLocation()
  const invoicesTab = useMemo<InvoiceTabName>(() => {
    const parsed = pathname.split('/')[3] as InvoiceTabName | undefined
    const existsInTabList = parsed && invoiceTabNames.includes(parsed)
    return existsInTabList ? parsed : defaultTab
  }, [pathname, defaultTab])

  const tabIndex = useMemo(() => {
    switch (appName) {
      case 'app':
        switch (invoicesTab) {
          case 'pending-approval':
            return 0
          case 'current':
            return 1
          case 'sent':
            return 2
          case 'paid':
            return 3
          case 'settings':
            return 4
          case 'archived':
            return 5
          case 'deleted':
            return 6
          default:
            return 0
        }
      case 'providers-app':
        switch (invoicesTab) {
          case 'outstanding':
            return 0
          case 'paid':
            return 1
          default:
            return 0
        }
      default:
        return 0
    }
  }, [invoicesTab, appName])
  const history = useHistory()
  const onTabSelect = useCallback(
    (index: number) => {
      switch (index) {
        case 0:
          history.push(`${basePath}/${appName === 'app' ? 'pending-approval' : 'outstanding'}`)
          break
        case 1:
          history.push(`${basePath}/${appName === 'app' ? 'current' : 'paid'}`)
          break
        case 2:
          history.push(`${basePath}/sent`)
          break
        case 3:
          history.push(`${basePath}/paid`)
          break
        case 4:
          history.push(`${basePath}/settings`)
          break
        case 5:
          history.push(`${basePath}/archived`)
          break
        case 6:
          history.push(`${basePath}/deleted`)
          break
        default:
          history.push(basePath)
      }
    },
    [basePath, history, appName],
  )

  const selectAssessmentTab = useCallback(
    (tab: UserProfileTabName) => {
      if (!selectedInvoiceId || !selectedAssessmentId) return
      history.push(`${basePath}/${selectedInvoiceId}/assessment/${selectedAssessmentId}/${tab}`)
    },
    [selectedAssessmentId, selectedInvoiceId, basePath, history],
  )

  const { processResponse } = useContext(PopUpMessageContext)
  const goToInvoiceByNumber = useCallback(
    async (invoiceNumber: string) => {
      let asInt = parseInt(invoiceNumber, 10)
      if (Number.isNaN(asInt)) {
        processResponse({ error: 'Invalid invoice number' })
        return
      }

      if (asInt < 1000) {
        asInt += 1000
      }

      const invoiceDoc = await getDocs(
        query(collection(db, INVOICES), where('invoiceNumber', '==', asInt), limit(1)),
      )
      if (invoiceDoc.empty) {
        const deletedInvoiceDoc = await getDocs(
          query(collection(db, INVOICES_DELETED), where('invoiceNumber', '==', asInt), limit(1)),
        )
        if (deletedInvoiceDoc.empty) {
          processResponse({ error: 'Invoice not found' })
          return
        }
        const invoiceId = deletedInvoiceDoc.docs[0].id
        history.push(`${basePath}/deleted/${invoiceId}`)
        return
      }
      const invoiceId = invoiceDoc.docs[0].id
      const tab = !invoicesTab || invoicesTab === 'deleted' ? 'pending-approval' : invoicesTab
      history.push(`${basePath}/${tab}/${invoiceId}`)
    },
    [processResponse, basePath, history, invoicesTab],
  )

  return useMemo(
    () => ({
      selectedPracticeId,
      selectPractice: (id: string | null) => setSelectedPracticeId(id),
      selectedInvoiceId,
      assessmentTab,
      invoicesTab,
      goToInvoiceByNumber,
      selectInvoice: (id: string | null) => {
        if (!id) history.push(`${basePath}/${invoicesTab || defaultTab}`)
        else history.push(`${basePath}/${invoicesTab || defaultTab}/${id}`)
      },
      selectedAssessmentId,
      tabIndex,
      deselectAssessment: () => {
        if (selectedInvoiceId) {
          history.push(`${basePath}/${invoicesTab || defaultTab}/${selectedInvoiceId}`)
        } else history.push(basePath)
      },
      selectAssessment: (invoiceId: string, assessmentId: string, tab?: string) =>
        history.push(
          `${basePath}/${invoicesTab || defaultTab}/${invoiceId}/assessment/${assessmentId}${tab ? `/${tab}` : ''}`,
        ),
      selectAssessmentTab,
      onTabSelect,
    }),
    [
      basePath,
      defaultTab,
      history,
      selectedInvoiceId,
      goToInvoiceByNumber,
      selectedPracticeId,
      onTabSelect,
      invoicesTab,
      assessmentTab,
      selectedAssessmentId,
      selectAssessmentTab,
      tabIndex,
    ],
  )
}

export const InvoicesViewProvider = ({ children }: PropsWithChildren) => {
  const data = useInvoicesViewData('/super-admin/invoices')
  return <InvoicesViewContext.Provider value={data}>{children}</InvoicesViewContext.Provider>
}
