import { HStack, StackDivider, VStack } from '@chakra-ui/react'
import {
  emailField,
  faxField,
  midwivesCollection,
  PracticeWithAdmin,
  providerPhoneField,
  WithId,
} from '@hb/shared'
import React from 'react'
import { useUpdateCollection } from '../../hooks/useAppCollections'
import { EditableRow } from '../forms/Input'

export const ProviderContact = ({ midwife }: { midwife?: WithId<PracticeWithAdmin> }) => {
  const { updateItem } = useUpdateCollection(midwivesCollection)
  const [editedLabel, setEditedLabel] = React.useState('')
  return (
    <HStack divider={<StackDivider />} px={1} bg="white" shadow="md" borderRadius="full" w="100%">
      {!editedLabel || editedLabel === 'phone' ? (
        <VStack flex={1}>
          <EditableRow
            openCallback={() => {
              setEditedLabel('phone')
            }}
            closeCallback={() => {
              setEditedLabel('')
            }}
            label="Phone"
            onSubmit={v => updateItem(midwife?.id || '', 'phone', v)}
            field={providerPhoneField}
            value={midwife?.phone}
          />
        </VStack>
      ) : null}
      {!editedLabel || editedLabel === 'fax' ? (
        <VStack flex={1}>
          <EditableRow
            openCallback={() => {
              setEditedLabel('fax')
            }}
            closeCallback={() => {
              setEditedLabel('')
            }}
            label="Fax"
            onSubmit={v => updateItem(midwife?.id || '', 'fax', v)}
            field={faxField}
            value={midwife?.fax}
          />
        </VStack>
      ) : null}
      {!editedLabel || editedLabel === 'email' ? (
        <VStack flex={1}>
          <EditableRow
            openCallback={() => {
              setEditedLabel('email')
            }}
            closeCallback={() => {
              setEditedLabel('')
            }}
            label="Email"
            onSubmit={v => updateItem(midwife?.id || '', 'email.main', v)}
            field={emailField}
            value={midwife?.email?.main}
          />
        </VStack>
      ) : null}
    </HStack>
  )
}
