import { Field } from '@hb/shared'
import { useMemo } from 'react'
import { useCollections } from '../store/collections'
import { formatValue } from '../utils/data'

export const useFormattedValue = (field?: Field, value?: any, textOnly?: boolean) => {
  const collections = useCollections()

  return useMemo(
    () =>
      formatValue({
        field,
        value,
        collections,
        textOnly,
      }),
    [field, value, collections, textOnly],
  )
}
