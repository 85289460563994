import { PracticeVisit } from '../types/appointments'

export const visitTypeNames: Record<PracticeVisit['type'], string> = {
  encounter: 'Encounter',
  consultation: 'Consultation',
  antepartum: 'Antepartum',
  'confirmation-of-pregnancy': 'Confirmation of Pregnancy',
  'problem-visit': 'Problem Visit',
  postpartum: 'Postpartum',
  newborn: 'Newborn',
  'labor-and-delivery': 'Labor and Delivery',
}
