import { DownloadIcon } from '@chakra-ui/icons'
import {
  Center,
  Flex,
  HStack,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { colors, getDateString, PracticeInvoice, WithId } from '@hb/shared'

import React, { useCallback, useContext, useMemo, useState } from 'react'
import { createInvoicePreview, downloadFromStorage } from '../../backend'
import { PopUpMessageContext, ScreenContext } from '../../contexts'
import { InvoiceAssessmentsBreakdown } from './AdminBreakdown/InvoiceAssessmentsBreakdown'
import { InvoiceEmailNotificationsView } from './AdminBreakdown/InvoiceEmailNotificationsView'
import { InvoiceSummary } from './AdminBreakdown/InvoiceSummary'
import { InvoicePaymentProgress } from './InvoicePaymentProgress'
import { InvoicePDFPreview } from './Preview/InvoicePDFPreview'

const generateAndDownloadPdf = async (invoice: WithId<PracticeInvoice>) => {
  const storagePath = await createInvoicePreview({ invoiceId: invoice.id })
  await downloadFromStorage(storagePath.data)
}

export const InvoiceView = ({
  invoice,
  onClose,
}: {
  invoice: WithId<PracticeInvoice>
  onClose: () => void
}) => {
  const [generatingPdf, setGeneratingPdf] = useState(false)

  const { processResponse } = useContext(PopUpMessageContext)

  const generatePdf = useCallback(async () => {
    setGeneratingPdf(true)
    try {
      await generateAndDownloadPdf(invoice)
    } catch (e: any) {
      console.error(e?.message)
      processResponse({ error: e?.message })
    } finally {
      setGeneratingPdf(false)
    }
  }, [invoice, processResponse])
  return (
    <VStack userSelect="none" w="100%" align="flex-start" spacing={0}>
      <InvoiceSummary invoice={invoice} />
      <Flex w="100%" border="1px solid #cdcdcd" bg="gray.50">
        <InvoicePaymentProgress invoice={invoice} />
      </Flex>
      <VStack p={2} align="flex-start" borderBottomRadius={4} bg="gray.50" spacing={0} w="100%">
        <Tabs border="1px solid #cdcdcd" borderRadius={4} variant="solid-rounded" w="100%">
          <TabList
            bg="gray.100"
            display="flex"
            alignItems="center"
            borderBottom="1px solid #cdcdcd"
            px={2}
            height="40px">
            <HStack py={1}>
              <Tab height="26px" _selected={{ background: colors.green.hex, color: 'white' }}>
                Breakdown
              </Tab>
              <Tab height="26px" _selected={{ background: colors.green.hex, color: 'white' }}>
                Invoice
              </Tab>
            </HStack>
            <IconButton
              ml="auto"
              size="sm"
              // bg='blackAlpha.200'
              variant="ghost"
              overflow="hidden"
              _hover={{ bg: 'blackAlpha.200' }}
              isLoading={generatingPdf}
              borderRadius="full"
              aria-label="generate pdf"
              onClick={generatePdf}
              icon={<DownloadIcon color="gray.500" />}
            />
          </TabList>
          <TabPanels>
            <TabPanel p={0}>
              <InvoiceAssessmentsBreakdown onClose={onClose} invoice={invoice} />
            </TabPanel>
            <TabPanel p={0}>
              <InvoicePDFPreview invoice={invoice} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </VStack>
  )
}

export const PracticeInvoiceView = ({ invoice }: { invoice: WithId<PracticeInvoice> }) => {
  const [generatingPdf, setGeneratingPdf] = useState(false)
  const { isMobile } = useContext(ScreenContext)
  const { processResponse } = useContext(PopUpMessageContext)

  const generatePdf = useCallback(async () => {
    setGeneratingPdf(true)
    try {
      await generateAndDownloadPdf(invoice)
    } catch (e: any) {
      console.error(e?.message)
      processResponse({ error: e?.message })
    } finally {
      setGeneratingPdf(false)
    }
  }, [invoice, processResponse])
  const title = useMemo(() => {
    if (invoice.paidOn)
      return `Invoice #${invoice.invoiceNumber} - Paid on ${getDateString(invoice.paidOn)}`
    return `Invoice #${invoice.invoiceNumber} - Due ${getDateString(invoice.dueDate)}`
  }, [invoice])
  return (
    <VStack bg="gray.100" userSelect="none" w="100%" align="flex-start" spacing={0}>
      <HStack
        pr={12}
        borderBottom="1px solid #cdcdcd"
        w="100%"
        pl={4}
        py={1}
        bg="gray.50"
        align="center"
        h="42px">
        <Text
          position="relative"
          top="1px"
          lineHeight={1}
          color="gray.600"
          fontSize={isMobile ? 'sm' : 'md'}
          isTruncated
          fontFamily="Hero-New">
          {title}
        </Text>
      </HStack>
      <InvoicePaymentProgress invoice={invoice} />
      <VStack
        px={2}
        pb={2}
        align="flex-start"
        borderBottomRadius={4}
        spacing={0}
        pos="relative"
        w="100%">
        {/* <InvoiceSummary invoice={invoice} /> */}
        <InvoicePDFPreview invoice={invoice} />
        <Center position="absolute" top={4} w="100%">
          <Tooltip label="Download PDF" hasArrow bg="gray.50" color="gray.600" placement="top">
            <IconButton
              // bg='blackAlpha.200'
              size="sm"
              variant="ghost"
              overflow="hidden"
              _hover={{ bg: 'blackAlpha.200' }}
              bg="blackAlpha.100"
              boxShadow="1px 1px 4px #00000066"
              isLoading={generatingPdf}
              borderRadius="full"
              aria-label="generate pdf"
              onClick={generatePdf}
              icon={<DownloadIcon color="gray.500" />}
            />
          </Tooltip>
        </Center>
        <InvoiceEmailNotificationsView invoice={invoice} />
      </VStack>
    </VStack>
  )
}
