import {
  Box,
  Center,
  CircularProgress,
  Collapse,
  Flex,
  HStack,
  Progress,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  colors,
  MessageThread,
  messageThreadCollections,
  PopulatedThreadMessage,
  ThreadType,
  UserGroup,
  WithId,
} from '@hb/shared'
import { collection, doc } from 'firebase/firestore'
import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { db } from '../../../backend/db'
import { PopUpMessageContext, ScreenContext, useApp } from '../../../contexts'
import { useDocument } from '../../../hooks/backend/useDocument'
import { useMe } from '../../../hooks/backend/useMe'
import { useUpdateDoc } from '../../../hooks/backend/useUpdateDoc'
import { useResizeObserver } from '../../../hooks/useResizeObserver'

import { ProfilePicturePopover } from '../../Admins/ProfilePicturePopover'
import { PulsingCircle } from '../../Animation/PulsingCirlce'

import { Editable } from '../../forms'
import { getBadgeColor } from '../../Users/UserBadge'
import { THREAD_HEADER_HEIGHT, THREAD_LIST_HEIGHT } from '../constants'
import { useThreadsList } from '../contexts'
import { groupThreadTitleField } from '../fields'
import { useThreadMessages, useThreadTitle } from '../hooks'
import { threadAccessIcons } from '../icons'
import { ThreadMembersPopover } from '../ThreadMembers/ThreadMembersPopover'
import { reportThreadRead } from '../utils'
import { AddMembersView } from './AddMembersView'
import { ThreadViewContext } from './contexts'
import { RecentMessageView } from './RecentMessageView'
import { SendMessage } from './SendMessage'
import './styles.css'
import { ThreadBackButton } from './ThreadBackButton'
import { ThreadMessagesList } from './ThreadMessageList'
import { ThreadMuteButton } from './ThreadMuteButton'
import { ThreadSearch, ThreadSearchButton, ThreadSearchProvider } from './ThreadSearchPopover'
import { ThreadUnreadButton } from './ThreadUnreadButton'

// const ThreadUnreadIndicator = () => (
//   <Box
//     position="absolute"
//     top="-1px"
//     right="-1px"
//     borderRadius="full"
//     bg="white"
//     boxShadow={'0 0 5px rgba(0,0,0,0.5)'}
//     w="13px"
//     h="13px"
//   />
// )
const ThreadTitleBadge = ({
  initials,
  accessLevel,
  title,
}: {
  accessLevel: UserGroup
  initials: string
  title: string
}) => {
  const { appName } = useApp()
  return (
    <Tooltip placement="top" hasArrow label={title}>
      <Center
        fontSize="sm"
        fontWeight={600}
        color="white"
        bg={getBadgeColor(appName, accessLevel)}
        position="relative"
        borderRadius="full"
        boxShadow="1px 1px 4px rgba(0,0,0,0.3)"
        width={10}
        whiteSpace="nowrap"
        height={10}>
        <Text lineHeight={1} textShadow="1px 1px 3px #00000099" fontFamily="Comfortaa">
          {initials}
        </Text>
        {/* {unread ? <ThreadUnreadIndicator /> : null} */}
      </Center>
    </Tooltip>
  )
}

const DirectMessageThreadBadge = ({ userId, size = 10 }: { userId: string; size?: number }) => (
  <Box position="relative">
    <ProfilePicturePopover userId={userId} size={size} noPopover />
  </Box>
)

const useScrollToThreadResult = (
  selectMessage: (messageId: string, fetchAll: boolean) => void,
  threadLoading: boolean,
) => {
  const {
    widget: { searchResult, clearSearchResult },
  } = useThreadsList()
  useLayoutEffect(() => {
    if (searchResult && !threadLoading) {
      selectMessage(searchResult.messageId, true)
      clearSearchResult()
    }
  }, [searchResult, clearSearchResult, threadLoading, selectMessage])
}

const SCROLL_RETRY_DELAY = 300
const SCROLL_RETRY_NUM_TRIES = 5

const retry = (
  fn: () => void,
  delay: number,
  tries: number = 0,
): null | ReturnType<typeof setTimeout> => {
  if (tries > SCROLL_RETRY_NUM_TRIES) return null
  return setTimeout(() => {
    try {
      fn()
    } catch (_e) {
      retry(fn, delay, tries + 1)
    }
  }, delay)
}

const ThreadTitleView = ({
  thread,
  threadType,
  updateIsEditing,
  threadId,
}: {
  thread: MessageThread | null
  updateIsEditing: (updated: boolean) => void
  threadType: ThreadType
  threadId: string | undefined
}) => {
  const { title } = useThreadTitle(threadType, threadId, thread)
  const update = useUpdateDoc('thread title')
  const docRef = useMemo(
    () =>
      threadType && threadId
        ? doc(collection(db, messageThreadCollections[threadType]), threadId)
        : null,
    [threadType, threadId],
  )
  return (
    <Box minW="0" flex={1}>
      {threadType === 'assessment' ? (
        <Text
          color="white"
          fontFamily="Open Sans"
          fontSize="lg"
          fontWeight={500}
          px={1}
          isTruncated
          textShadow="1px 1px 3px #00000099">
          {title}
        </Text>
      ) : (
        <Editable
          previewIsButton
          value={title}
          style={{
            color: 'white',
            fontFamily: 'Open Sans',
            fontWeight: 600,
          }}
          dataCellProps={{
            color: 'white',
            fontFamily: 'Open Sans',
            fontSize: 'lg',
            fontWeight: 500,
            px: 1,
            textShadow: '1px 1px 3px #00000099',
          }}
          field={groupThreadTitleField}
          openCallback={() => updateIsEditing(true)}
          closeCallback={() => updateIsEditing(false)}
          onSubmit={newTitle =>
            update(docRef, 'title', newTitle).then(res => {
              if (res.error) {
                console.error(res.error)
              } else {
                updateIsEditing(false)
              }
              return res
            })
          }
        />
      )}
    </Box>
  )
}

export const ThreadView = ({
  onBack,
  threadId,
  width,
  threadType,
  fromSearch,
  assessmentThreadUnread,
  isOpen,
}: {
  onBack: () => void
  threadId?: string
  width: number
  threadType: ThreadType
  fromSearch?: boolean
  isOpen: boolean
  assessmentThreadUnread?: boolean
}) => {
  const { appName } = useApp()

  const threadCollection = messageThreadCollections[threadType]
  const [messageListHeight, setMessageListHeight] = useState(THREAD_LIST_HEIGHT)

  const [fetchAllMessages, setFetchAllMessages] = useState(!!fromSearch)
  const {
    data: thread,
    loading: threadLoading,
    ref: threadRef,
  } = useDocument<MessageThread>(threadCollection, threadId)

  const messages = useThreadMessages(threadType, threadId, !!fetchAllMessages)
  const { loading: messagesLoading, data: sortedMessages } = messages
  const [manuallyMarkedUnread, setManuallyMarkedUnread] = useState(false)

  useEffect(() => {
    if (!threadId || !threadType) setManuallyMarkedUnread(false)
  }, [threadId, threadType, fromSearch])
  const messageListRef = useRef<HTMLDivElement>(null)

  const [searchOpen, setSearchOpen] = useState(false)
  const handleMessageSelect = useCallback(
    (messageId: string, fetchAll?: boolean) => {
      if (fetchAll && !sortedMessages.find(p => `${p.createdOn}` === messageId))
        setFetchAllMessages(true)
      const selectMessage = (el: HTMLElement) => {
        const messageEl = el.querySelector(`#message-${messageId}`)
        if (!messageEl) throw new Error('No message element')
        messageEl.scrollIntoView({ behavior: 'smooth' })
      }
      const findAndScroll = () => {
        if (!messageListRef.current) throw new Error('No message list ref')
        selectMessage(messageListRef.current)
      }
      let retryTimeout: null | ReturnType<typeof setTimeout> = null
      try {
        findAndScroll()
        return () => {}
      } catch (_e) {
        retryTimeout = retry(findAndScroll, SCROLL_RETRY_DELAY)
      }
      return () => {
        if (retryTimeout) clearTimeout(retryTimeout)
      }
    },
    [sortedMessages],
  )

  const searchInputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (searchOpen) {
      searchInputRef.current?.focus()
    }
  }, [searchOpen])

  useScrollToThreadResult(handleMessageSelect, threadLoading)

  const { accessLevel } = thread || {}

  const [addingMembers, setAddingMembers] = useState(false)

  const mounted = useRef(false)

  const containerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (isOpen && containerRef.current) {
      // check if box is off screen
      const rect = containerRef.current.getBoundingClientRect()
      if (rect.bottom > window.innerHeight) {
        setMessageListHeight(window.innerHeight - rect.top - 100)
      } else {
        setMessageListHeight(THREAD_LIST_HEIGHT)
      }
    } else {
      mounted.current = false
    }
  }, [isOpen])

  useEffect(() => {
    // check if at bottom of scroll and scroll to bottom
    if (!sortedMessages.length) return
    if (messageListRef.current) {
      const { scrollHeight, scrollTop, clientHeight } = messageListRef.current
      if (!mounted.current || scrollHeight - scrollTop < clientHeight + 100) {
        messageListRef.current.scrollTo({
          behavior: 'smooth',
          top: scrollHeight,
        })
        mounted.current = true
      }
    }
  }, [sortedMessages])

  const [editingTitle, setEditingTitle] = useState(false)

  let body: ReactElement | null = null
  if (threadLoading || (messagesLoading && !sortedMessages.length)) {
    body = (
      <Center w="100%" py={3}>
        <HStack spacing={3}>
          <CircularProgress size={5} isIndeterminate color={colors.green.hex} />
          <Text color="gray.500">Loading messages...</Text>
        </HStack>
      </Center>
    )
  } else if (!sortedMessages?.length) {
    body = (
      <Center w="100%">
        <Text px={3} color="gray.500" py={1}>
          No messages yet
        </Text>
      </Center>
    )
  } else {
    body = (
      <ThreadMessagesList
        data={messages}
        messagesLoading={messagesLoading}
        assessmentThreadUnread={assessmentThreadUnread}
        thread={thread}
        ref={messageListRef}
        threadId={threadId}
        manuallyMarkedUnread={manuallyMarkedUnread}
      />
    )
  }

  const { isMobile } = useContext(ScreenContext)
  const [replyingTo, setReplyingTo] = useState<PopulatedThreadMessage | null>(null)

  const [editingMessage, setEditingMessage] = useState<PopulatedThreadMessage | null>(null)

  const bodyRef = useRef<HTMLDivElement>(null)
  const { height: bodyHeight } = useResizeObserver(bodyRef, 'content')

  const contextData = useMemo(
    () => ({
      threadId: threadId || '',
      threadType,
      thread,
      handleMessageSelect,
      messageListHeight,
      bodyHeight,
      isOpen,
      onBack,
      threadRef,
      replyingTo,
      setReplyingTo: (message: PopulatedThreadMessage | null) => {
        if (message) {
          setEditingMessage(null)
          setReplyingTo(message)
        } else {
          setReplyingTo(null)
        }
      },
      editingMessage,
      setEditingMessage: (message: PopulatedThreadMessage | null) => {
        if (message) {
          setReplyingTo(null)
          setEditingMessage(message)
        } else {
          setEditingMessage(null)
        }
      },
      addingMembers,
      setAddingMembers,
    }),
    [
      threadId,
      threadType,
      handleMessageSelect,
      editingMessage,
      threadRef,
      bodyHeight,
      thread,
      replyingTo,
      addingMembers,
      setAddingMembers,
      messageListHeight,
      isOpen,
      onBack,
    ],
  )
  return (
    <ThreadViewContext.Provider value={contextData}>
      <VStack
        borderRadius={6}
        boxShadow="0 0 4px rgba(0,0,0,0.5)"
        // bg="gray.50"
        ref={containerRef}
        height={messageListHeight}
        w={width}
        position="relative"
        bg="gray.100"
        spacing={0}>
        <HStack
          borderTopRadius={6}
          // bg="linear-gradient(0deg, rgba(116,116,116,1) 0%, rgba(194,194,194,1) 100%)"
          bg={getBadgeColor(appName, accessLevel)}
          spacing={1}
          borderBottom="1px solid #ababab"
          w="100%"
          px={3}
          height={`${THREAD_HEADER_HEIGHT}px`}
          align="center">
          {accessLevel && threadType === ThreadType.GROUP ? (
            <Tooltip
              placement="top"
              bg="whitesmoke"
              color="gray.600"
              hasArrow
              label={`${accessLevel?.toUpperCase()} CHAT`}>
              <Box
                mr="0.2rem"
                filter="grayscale(100%) brightness(2) drop-shadow(1px 1px 2px #00000088)"
                pl="0.3rem"
                cursor="default">
                {threadAccessIcons[accessLevel] || ''}
              </Box>
            </Tooltip>
          ) : null}
          <ThreadTitleView
            thread={thread}
            updateIsEditing={setEditingTitle}
            threadType={threadType}
            threadId={threadId}
          />
          {editingTitle ? null : (
            <HStack spacing={1}>
              {threadType !== ThreadType.ASSESSMENT ? (
                <ThreadMembersPopover
                  threadId={threadId}
                  threadType={threadType}
                  isAdding={addingMembers}
                  onAddClick={() => setAddingMembers(true)}
                  thread={thread}
                />
              ) : null}
            </HStack>
          )}
        </HStack>
        <Box bg="gray.100" w="100%" minH="0" overflow="hidden" ref={bodyRef} flex={1}>
          <Collapse style={{ width: '100%' }} in={!addingMembers}>
            <Box w="100%" height={`${bodyHeight}px`}>
              {body}
            </Box>
          </Collapse>
          <Collapse style={{ width: '100%' }} in={addingMembers}>
            <AddMembersView
              height={bodyHeight}
              onClose={() => setAddingMembers(false)}
              thread={thread}
              isOpen={addingMembers}
              threadType={threadType}
              threadId={threadId}
            />
          </Collapse>
        </Box>
        <SendMessage threadId={threadId} threadType={threadType} />
        <ThreadSearchProvider
          onClose={() => setSearchOpen(false)}
          isOpen={searchOpen}
          onOpen={() => setSearchOpen(true)}>
          <Flex
            gap={2}
            flexFlow={isMobile ? 'row' : 'column'}
            position="absolute"
            bottom={isMobile ? '-50px' : 'unset'}
            top={isMobile ? 'unset' : 4}
            right={isMobile ? 2 : '-50px'}>
            <ThreadBackButton />
            <ThreadSearchButton />
            <ThreadUnreadButton
              manuallyMarkedUnread={manuallyMarkedUnread}
              setManuallyMarkedUnread={setManuallyMarkedUnread}
            />
            <ThreadMuteButton />
          </Flex>
          <ThreadSearch ref={searchInputRef} />
        </ThreadSearchProvider>
      </VStack>
    </ThreadViewContext.Provider>
  )
}

export const ThreadPreview = ({
  thread,
  isChild,
  onClick,
}: {
  thread: WithId<MessageThread>
  isChild?: boolean
  onClick?: () => void
}) => {
  const mostRecentMessage = thread.mostRecentMessages?.[0]
  const me = useMe()
  const unreadByMe = useMemo(() => thread.unreadBy.includes(me?.uid || ''), [thread, me])
  const {
    widget: { data: widgetState },
  } = useThreadsList()
  const { thread: selectedThread } = widgetState || {}
  const { title, initials, directMessageUserId } = useThreadTitle(thread.type, thread.id, thread)
  const { showMessage } = useContext(PopUpMessageContext)
  const { appName } = useApp()
  const [reportReadLoading, setReportReadLoading] = useState(false)
  const handleClick = useCallback(async () => {
    if (!onClick) return
    if (!selectedThread || selectedThread.id !== thread.id || selectedThread.type !== thread.type) {
      onClick()
    } else if (unreadByMe) {
      setReportReadLoading(true)
      try {
        await reportThreadRead(appName, thread.type, thread.id)
      } catch (e: any) {
        showMessage({
          text: 'Failed to mark thread as read',
          type: 'error',
          subText: e.message,
        })
      }
      setReportReadLoading(false)
    }
  }, [selectedThread, unreadByMe, onClick, thread, appName, showMessage])

  return (
    <Flex
      onClick={handleClick}
      w="100%"
      cursor={onClick ? 'pointer' : 'default'}
      className={unreadByMe ? 'thread-preview-unread' : 'thread-preview'}
      bg="white"
      boxShadow={isChild ? 'none' : '0 0 4px rgba(0,0,0,0.2)'}
      fontFamily="Hero-New"
      fontWeight={500}
      borderRadius={0}
      position="relative"
      height="auto"
      align="center"
      display="flex"
      px={2}
      py={1}
      flexFlow="row"
      gap={2}>
      {directMessageUserId ? (
        <DirectMessageThreadBadge userId={directMessageUserId} />
      ) : (
        <ThreadTitleBadge accessLevel={thread.accessLevel} title={title} initials={initials} />
      )}
      <Flex py={1} minH="30px" minW="0" flex={1} align="flex-start" flexDir="column">
        <HStack w="100%" spacing={1}>
          <Text maxW="100%" isTruncated color="gray.600">
            {title}
          </Text>
          {unreadByMe ? (
            <Box position="relative" h={4} w={8} ml="auto">
              <PulsingCircle style={{ height: '13px', width: '13px', right: '2px', top: '4px' }} />
            </Box>
          ) : null}
        </HStack>
        {mostRecentMessage ? (
          <RecentMessageView message={mostRecentMessage} />
        ) : (
          <Text lineHeight={1} pb={1} fontSize="sm" color="gray.500" fontStyle="italic">
            No messages yet
          </Text>
        )}
      </Flex>
      {reportReadLoading ? (
        <Progress
          colorScheme="green"
          w="100%"
          h="5px"
          position="absolute"
          left={0}
          bottom={0}
          isIndeterminate
        />
      ) : null}
    </Flex>
  )
}
