import { WarningTwoIcon } from '@chakra-ui/icons'
import {
  Box,
  BoxProps,
  forwardRef,
  IconProps,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react'
import { RedFlagInfo } from '@hb/shared'
import React from 'react'
import { GridItemPreviewContent } from './GridItemPreviewContent'

export const RedFlagIcon = forwardRef<IconProps, 'svg'>((props, ref) => (
  <WarningTwoIcon ref={ref} cursor="pointer" width={5} color="yellow.500" height={5} {...props} />
))

export const ProfileRedFlagPopover = ({
  redFlags,
  reverseName,
  boxProps,
  ...iconProps
}: {
  redFlags?: Record<string, RedFlagInfo>
  reverseName?: string
  boxProps?: BoxProps
} & IconProps) => (
  <Popover isLazy placement="bottom" strategy="fixed" trigger="hover">
    <PopoverTrigger>
      <Box bg="whiteAlpha.800" borderRadius={4} px={1} pb={1} {...boxProps}>
        <RedFlagIcon color={redFlags ? 'yellow.500' : 'gray.400'} {...iconProps} />
      </Box>
    </PopoverTrigger>
    <Portal>
      <PopoverContent>
        <PopoverArrow />
        {redFlags ? (
          <PopoverBody border="none">
            {reverseName ? <Text fontSize="lg">{reverseName}</Text> : null}
            <Text fontSize="sm" color="yellow.600" fontFamily="Open Sans" fontWeight={600}>
              {redFlags ? 'Red flagged' : 'Not red flagged'}
            </Text>
            <Stack spacing={0} divider={<StackDivider />}>
              {Object.keys(redFlags).map(key => (
                <Box key={key}>
                  {redFlags[key].header ? (
                    <Text fontWeight={600} color="blackAlpha.700">
                      {redFlags[key].header}
                    </Text>
                  ) : null}

                  <Text color="gray.600" fontFamily="Open Sans" fontSize="sm">
                    {redFlags[key].reason}
                  </Text>
                </Box>
              ))}
            </Stack>
          </PopoverBody>
        ) : null}
      </PopoverContent>
    </Portal>
  </Popover>
)

export const RedFlagPopover = ({
  redFlags,
  isOpen,
  onClose,
  reverseName,
  ...iconProps
}: {
  redFlags?: Record<string, RedFlagInfo>
  isOpen?: boolean
  onClose?: () => void
  reverseName?: string
} & IconProps) => (
  <Popover
    isOpen={isOpen}
    isLazy
    strategy="fixed"
    onClose={onClose}
    placement="right-start"
    trigger="click">
    <PopoverTrigger>
      <RedFlagIcon color={redFlags ? 'yellow.500' : 'gray.400'} {...iconProps} />
    </PopoverTrigger>
    <GridItemPreviewContent previewId="redFlags" onClick={e => e.stopPropagation()} py={2}>
      <PopoverArrow />
      <PopoverCloseButton />
      <PopoverHeader fontWeight={500} color={redFlags ? 'yellow.500' : 'gray.400'} border="none">
        {reverseName ? (
          <Text fontSize="lg" pb={3}>
            {reverseName}
          </Text>
        ) : null}
        <Text>{redFlags ? 'Red flagged' : 'Not red flagged'}</Text>
      </PopoverHeader>
      {redFlags ? (
        <PopoverBody border="none">
          <Stack divider={<StackDivider />}>
            {Object.keys(redFlags).map(key => (
              <VStack spacing={0} key={key}>
                {redFlags[key].header ? (
                  <Text mb={2} fontWeight={600} color="blackAlpha.700">
                    {redFlags[key].header}
                  </Text>
                ) : null}

                <Text lineHeight={1.2}>{redFlags[key].reason}</Text>
              </VStack>
            ))}
          </Stack>
        </PopoverBody>
      ) : null}
    </GridItemPreviewContent>
  </Popover>
)
