import { Kbd, Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/react'
import React, { ReactNode, useMemo } from 'react'

const keyIcons: Record<string, ReactNode> = {
  Command: '⌘',
  Alt: '⌥',
  // Shift: '⇧',
  // Enter: <SvgIcon opacity={0.5} width='16px' src={enterIcon} />,
}

const ShortcutBody = ({ shortcut }: { shortcut: string }) => {
  const parts = useMemo(() => shortcut.split('+'), [shortcut])

  const withPlus = parts.join(' + ').split(' ')
  return (
    <Kbd style={{ display: 'flex', gap: 2 }} color="gray.500">
      {withPlus.map((part, i) => (
        <span key={i}>{keyIcons[part] ?? part}</span>
      ))}
    </Kbd>
  )
}

export const KeyboardShortcutTooltip = ({
  shortcut,
  children,
}: {
  shortcut: string
  children: React.ReactNode
}) => (
  <Popover trigger="hover">
    <PopoverTrigger>{children}</PopoverTrigger>
    <PopoverContent bg="transparent" border="none" boxShadow="none" w="auto">
      <ShortcutBody shortcut={shortcut} />
    </PopoverContent>
  </Popover>
)
