import { AddIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  HStack,
  Popover,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { ASSESSMENTS, colors, FieldMapValue, OnUploadProgress } from '@hb/shared'

import { FORM_ERROR } from 'final-form'
import React, { useCallback, useContext, useMemo } from 'react'
import { processFieldMapData } from '../../../../backend'
import { PopUpMessageContext, ScreenContext, useApp, UserContext } from '../../../../contexts'
import { SimpleForm } from '../../../forms'
import { getReceivedPatientPaymentFields } from './fields'
import { submitReceivedPayment } from './utils'

export const AddPaymentPopover = () => {
  const { processResponse } = useContext(PopUpMessageContext)
  const { assessmentId } = useContext(UserContext)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const newPaymentId = useMemo(() => (isOpen ? `${Date.now()}` : null), [isOpen])

  const { isMobile } = useContext(ScreenContext)
  const { appName } = useApp()
  const field = useMemo(
    () => (newPaymentId ? getReceivedPatientPaymentFields(appName, newPaymentId) : null),
    [appName, newPaymentId],
  )
  const handleSubmit = useCallback(
    async (data: FieldMapValue, onUploadProgress: OnUploadProgress) => {
      if (!assessmentId) {
        return { [FORM_ERROR]: 'No assessment selected' }
      }
      if (!newPaymentId) {
        return { [FORM_ERROR]: 'No payment ID' }
      }
      if (!field) return { [FORM_ERROR]: 'No field' }
      const processed = await processFieldMapData(
        `${ASSESSMENTS}/${assessmentId}/payments/${newPaymentId}`,
        field,
        data,
        undefined,
        onUploadProgress,
      )
      const res = await submitReceivedPayment(
        appName,
        assessmentId,
        newPaymentId,
        processed,
        !!processed.sendMessageToHb,
        processed.messageToHb || '',
      )
      processResponse(res)
      if (res.success) {
        onClose()
      }
      return res
    },
    [processResponse, assessmentId, onClose, newPaymentId, field, appName],
  )
  return (
    <Popover
      closeOnBlur={false}
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      isLazy
      strategy="fixed">
      <PopoverTrigger>
        <Box>
          <Tooltip hasArrow placement="bottom" label="Add New Payment">
            <Button
              size={isMobile ? 'xs' : 'sm'}
              fontWeight={300}
              fontFamily="hero-new"
              bg={colors.green.hex}
              color="gray.800"
              _hover={{ bg: 'green.300' }}>
              <HStack spacing={0}>
                <AddIcon width={3} color="gray.50" />
                <Text
                  height="14px"
                  fontFamily="Comfortaa"
                  px={1}
                  fontSize={isMobile ? 'xs' : 'sm'}
                  fontWeight={600}
                  color="gray.50">
                  PAYMENT
                </Text>
              </HStack>
            </Button>
          </Tooltip>
        </Box>
      </PopoverTrigger>
      <PopoverContent maxH="500px" overflowY="auto" w="400px" maxW="100vw">
        <PopoverCloseButton top={2} color="gray.100" bg="blackAlpha.300" borderRadius="full" />
        <PopoverHeader
          color="white"
          textShadow="1px 1px 3px #00000077"
          borderTopRadius={6}
          fontFamily="Comfortaa"
          fontWeight={600}
          bg={colors.green.hex}>
          Add Payment
        </PopoverHeader>
        {field ? <SimpleForm theme="detailed" field={field} onSubmit={handleSubmit} /> : null}
      </PopoverContent>
    </Popover>
  )
}
