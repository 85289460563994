// TEMPLATES

import { CloseIcon, DownloadIcon } from '@chakra-ui/icons'
import { Box, Flex, IconButton, Text, Tooltip } from '@chakra-ui/react'
import {
  A4_DIMS_PIXELS,
  A4_MARGINS_PIXELS,
  defaultInitialValue,
  isConsentForm,
  TemplateFieldElement,
  colors,
  FieldTypes,
  getDateTimeString,
  getFullName,
} from '@hb/shared'
import React, {
  forwardRef,
  KeyboardEvent,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useField } from 'react-final-form'
import { createEditor, Path } from 'slate'
import { withHistory } from 'slate-history'
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  withReact,
} from 'slate-react'
import { downloadFromStorage } from '../../backend'
import { PopUpMessageContext } from '../../contexts'
import { useNonEditable } from '../Templates/useNonEditable'
import { pipe } from '../utils'
import { TextEditorContext, TextEditorFormContext } from './context'
import { DefaultRenderLeaf } from './DefaultRenderLeaf'
import { EditorToolbar } from './EditorToolbar'
import { useElementStyle } from './hooks'
import { downloadFromEditor } from './TemplateToolbar/utils'
import { TextEditorFormProvider } from './TextEditorFormProvider'
import { ReadOnlyEditorProps, TextEditorProps } from './types'

import { useCachedUser } from '../../collections/hooks/cached'
import { useMe } from '../../hooks/backend/useMe'
import { CustomEditor, CustomRenderElementProps } from '../../types/editor'
import { Container } from '../Container'
import { FormElement } from '../forms/Input'
import marginGuidelinesSvg from './assets/marginGuidelines.svg'
import marginGuidelinesTiledSvg from './assets/marginGuidelinesTiled.svg'
import { ImageElement } from './Elements/ImageElement'
import { LinkElement } from './Elements/LinkElement'
import { DefaultElement } from './Elements/SpanTemplateElement'
import { VariableElement } from './Elements/VariableElement'

const pathsEqual = (path1: Path, path2: Path) => {
  if (path1.length !== path2.length) return false
  for (let i = 0; i < path1.length; i += 1) {
    if (path1[i] !== path2[i]) return false
  }
  return true
}

export const TemplateFieldElementView = ({
  children,
  element,
  attributes,
  version,
  editor,
  mode,
}: RenderElementProps & CustomRenderElementProps) => {
  const node = element as TemplateFieldElement
  const { document, signerLoading, toBeSignedByUser } = useContext(TextEditorContext)
  const { field, readOnly } = useContext(TextEditorFormContext)
  const style = useElementStyle(mode, node, version)

  const path = ReactEditor.findPath(editor, element)
  const fullWidth = useMemo(() => node.field.type === FieldTypes.SIGNATURE, [node])

  const id = useMemo(
    () =>
      Object.values(field?.children || {}).find(f => pathsEqual(f.path, path))?.id || '__NONE__',
    [field, path],
  )

  const {
    meta: { error },
  } = useField(id)
  const { onClick, isSelected, onDelete } = useNonEditable(editor, node, mode)
  const me = useMe()
  const toBeSignedByMe =
    document &&
    isConsentForm(document) &&
    !document.signedBy &&
    me &&
    me.uid === document.toBeSignedByUser

  const tooltip = useMemo(() => {
    if (me && document && isConsentForm(document) && document.toBeSignedByUser !== me.uid) {
      if (signerLoading && !toBeSignedByUser) return 'Loading signer data...'
      if (document.signedOn && toBeSignedByUser)
        return `Signed by ${getFullName(toBeSignedByUser)} on ${getDateTimeString(document.signedOn, 'short')}`
      return `To be entered by ${toBeSignedByUser ? getFullName(toBeSignedByUser) : 'ERROR: User not found'}`
    }
    return ''
  }, [document, me, signerLoading, toBeSignedByUser])
  let borderColor = '#dedede'
  if (readOnly && error) borderColor = '#ff5555'
  else if (isSelected && mode === 'Edit') borderColor = colors.blue.hex
  return (
    <div
      {...attributes}
      contentEditable={false}
      onClick={onClick}
      style={{
        position: 'relative',
        cursor: readOnly ? 'default' : 'pointer',
        borderColor,
        alignItems: 'center',
        userSelect: 'none',
        zIndex: 2,
        ...style,
      }}>
      <Tooltip hasArrow placement="top" label={tooltip}>
        <div
          style={{
            pointerEvents: toBeSignedByMe ? 'auto' : 'none',
            width: fullWidth ? '100%' : 'auto',
          }}>
          <FormElement
            field={node.field}
            readOnly={readOnly}
            name={id}
            style={{
              border: `1px solid ${isSelected && mode === 'Edit' ? colors.blue.hex : '#dedede'}`,
              borderRadius: '6px',
            }}
            // name={node.id}
          />
        </div>
      </Tooltip>
      {isSelected && mode === 'Edit' ? (
        <IconButton
          variant="ghost"
          borderRadius="full"
          position="absolute"
          right="-3px"
          top="-6px"
          minW="0"
          ml={1}
          minH="0"
          w="16px"
          title="Remove"
          h="16px"
          color="white"
          bg="red.400"
          onClick={e => {
            e.stopPropagation()
            onDelete()
          }}
          aria-label="Remove variable"
          icon={<CloseIcon width={2} />}
        />
      ) : null}
      <span
        style={{
          display: 'none',
        }}>
        {children}
      </span>
    </div>
  )
}

export const DefaultRenderElement = (props: CustomRenderElementProps) => {
  switch (props.element.type) {
    case 'variable':
      return <VariableElement {...props} />
    case 'field':
      return <TemplateFieldElementView {...props} />
    case 'image':
      return <ImageElement node={props.element} {...props} />
    case 'link':
      return <LinkElement {...props} />
    default:
      return <DefaultElement {...props} />
  }
}

export const ReadOnlyEditor: React.FC<ReadOnlyEditorProps> = props => (
  <TextEditor readOnly {...props} />
)

export const TextEditor = forwardRef<ReactEditor, PropsWithChildren<TextEditorProps>>(
  (
    {
      value,
      decorators,
      onChange,
      withDownload,
      document,
      onFormSubmit,
      toolbars,
      version,
      children,
      templateType,
      baseZoom,
      style,
      readOnly,
      height,
      width,
      smallMargins,
    }: TextEditorProps,
    ref,
  ) => {
    const { processResponse } = useContext(PopUpMessageContext)
    const [editor] = useState<CustomEditor>(
      decorators
        ? pipe(...decorators)(withReact(withHistory(createEditor())))
        : withReact(withHistory(createEditor())),
    )

    const [mode, setMode] = useState<'View' | 'Edit'>(readOnly ? 'View' : 'Edit')
    const [downloadLoading, setDownloadLoading] = useState(false)

    useImperativeHandle(ref, () => editor)

    const onDownload = useCallback(async () => {
      if (!withDownload) return
      if (document && isConsentForm(document) && !document.signedStoragePath) {
        processResponse({ error: 'Consent form not yet signed' })
        return
      }
      setDownloadLoading(true)
      const res =
        document && isConsentForm(document)
          ? await downloadFromStorage(document.signedStoragePath || '')
          : await downloadFromEditor(editor)
      processResponse(res)
      setDownloadLoading(false)
    }, [editor, processResponse, withDownload, document])

    const handleRenderElement = useCallback(
      (props: RenderElementProps) =>
        DefaultRenderElement({
          ...props,
          version,
          mode,
          readOnly: !!readOnly,
          editor,
        }),
      [version, mode, readOnly, editor],
    )

    const handleRenderLeaf = useCallback(
      (props: RenderLeafProps) =>
        DefaultRenderLeaf({
          ...props,
          mode,
          editor,
          version,
        }),
      [mode, editor, version],
    )

    const handleKeyDown = useCallback(
      (event: KeyboardEvent) => {
        if (event.key === 'Tab') {
          event.preventDefault()
          editor.insertText('\u2003'.toString())
        }
      },
      [editor],
    )

    const toBeSignedByUserId = useMemo(
      () => (document && isConsentForm(document) ? document.toBeSignedByUser : null),
      [document],
    )
    const { data: toBeSignedByUser, loading: signerLoading } = useCachedUser(toBeSignedByUserId)

    const editorDomElement = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
      const domElement = ReactEditor.toDOMNode(editor, editor) as HTMLDivElement

      const onResize = () => {
        const elementHeight = domElement.offsetHeight

        if (mode === 'Edit') {
          domElement.style.setProperty(
            'background',
            elementHeight > A4_DIMS_PIXELS[1]
              ? `url(${marginGuidelinesTiledSvg})`
              : `url(${marginGuidelinesSvg})`,
          )
        } else {
          domElement.style.setProperty('background', 'none')
        }
      }
      onResize()
      new ResizeObserver(onResize).observe(domElement)
      editorDomElement.current = domElement
    }, [editor, baseZoom, mode])

    const contextData = useMemo(
      () => ({
        mode,
        document,
        toBeSignedByUser,
        editorDomElement,
        signerLoading,
        width,
        height,
        baseZoom,
      }),
      [document, mode, toBeSignedByUser, signerLoading, width, height, baseZoom],
    )

    const downloadText = useMemo(
      () =>
        document && isConsentForm(document)
          ? 'Downloading signed consent form...'
          : 'Generating PDF...',
      [document],
    )

    const editorPadding = useMemo(() => {
      if (smallMargins) {
        return `${A4_MARGINS_PIXELS[1] / 2}px ${A4_MARGINS_PIXELS[0] / 2}px`
      }
      return `${A4_MARGINS_PIXELS[1]}px ${A4_MARGINS_PIXELS[0]}px`
    }, [smallMargins])

    return (
      <Slate
        initialValue={value?.length ? value : defaultInitialValue}
        onChange={onChange || (() => {})}
        editor={editor}
        // value={value || initialValue}
      >
        <TextEditorFormProvider
          readOnly={readOnly}
          onSubmit={onFormSubmit}
          document={document}
          height={height}
          style={style}>
          <TextEditorContext.Provider value={contextData}>
            <Container
              contentEditable={false}
              style={{
                position: 'sticky',
                height: 'auto',
                background: 'whitesmoke',
                zIndex: 2,
                top: 0,
              }}>
              {withDownload ? (
                <Flex
                  pos="absolute"
                  alignItems="center"
                  bottom={readOnly ? '-40px' : '-32px'}
                  left={readOnly ? '8px' : '5px'}>
                  <Tooltip
                    hasArrow
                    placement="right"
                    bg="gray.600"
                    label={downloadLoading ? '' : 'Download'}>
                    <IconButton
                      size={readOnly ? 'sm' : 'xs'}
                      color="white"
                      isLoading={downloadLoading}
                      aria-label="download"
                      bg="blackAlpha.400"
                      _hover={{ bg: 'blackAlpha.600' }}
                      onClick={onDownload}
                      icon={<DownloadIcon />}
                    />
                  </Tooltip>
                  <Text
                    opacity={downloadLoading ? 1 : 0}
                    transition="opacity 500ms"
                    pointerEvents="none"
                    color="gray.400"
                    position="absolute"
                    right="120%"
                    whiteSpace="nowrap"
                    fontSize="xs">
                    {downloadText}
                  </Text>
                </Flex>
              ) : null}
              {!readOnly ? (
                <>
                  {children}
                  <EditorToolbar setMode={setMode} mode={mode} />
                  {toolbars
                    ? toolbars.map((T, index) => (
                        <T
                          document={document}
                          templateType={templateType}
                          version={version}
                          key={index}
                          mode={mode}
                        />
                      ))
                    : null}
                </>
              ) : null}
            </Container>
            <Box
              style={{
                boxSizing: 'border-box',
                justifyContent: 'flex-start',
                background: 'white',
                position: 'relative',
                width,
                height: '100%',
              }}>
              <Editable
                readOnly={readOnly || mode === 'View'}
                spellCheck={mode === 'Edit'}
                renderLeaf={handleRenderLeaf}
                renderElement={handleRenderElement}
                onKeyDown={handleKeyDown}
                style={{
                  width: A4_DIMS_PIXELS[0],
                  position: 'absolute',
                  minHeight: A4_DIMS_PIXELS[1],
                  padding: editorPadding,
                  boxSizing: 'border-box',
                  background: 'white',
                  left: 0,
                  top: 0,
                  transformOrigin: '0 0',
                  border: 'none',
                  transform: `scale(${baseZoom || 1})`,
                  transformBox: 'view-box',
                  // backgroundImage,
                  backgroundRepeat: 'repeat-y',
                  outline: 'none',
                }}
              />
              {/* {
                // guidelines for edit mode
                mode === 'Edit' ? (
                  <EditorMarginGuidelines/>
                ) : null
              } */}
            </Box>
          </TextEditorContext.Provider>
        </TextEditorFormProvider>
      </Slate>
    )
  },
)
