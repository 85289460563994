import { CircularProgress, Flex, HStack, Image, Text, VStack } from '@chakra-ui/react'
import {
  Assessment,
  AssessmentDocument,
  colors,
  getCorrectedValue,
  getDateString,
  objectToArray,
  WithId,
} from '@hb/shared'
import React, { useMemo } from 'react'
import { usePatientDocuments } from '../../../../hooks/backend/documents/usePatientDocuments'
import { useUserAssessments } from '../../../../hooks/backend/user/useUserAssessments'
import { DocumentsList } from './DocumentsList'

const separateDocumentsByAssessment = (documents: WithId<AssessmentDocument>[]) =>
  documents.reduce(
    (acc, doc) => {
      const { assessmentId } = doc
      if (!acc[assessmentId]) {
        acc[assessmentId] = []
      }
      acc[assessmentId].push(doc)
      return acc
    },
    {} as Record<string, WithId<AssessmentDocument>[]>,
  )

// .reduce(
//   (acc, [assessmentId, docs]) => ({
//     ...acc,
//     [assessmentId]: separateDocumentsByType(docs),
//   }),
//     {} as Record<
//       string,
//       Record<AssessmentDocumentType, WithId<AssessmentDocument>[]>
//     >,
// )

const PatientAssessmentDocuments = ({
  assessment,
  documents,
}: {
  assessment: Assessment
  documents?: WithId<AssessmentDocument>[]
}) => {
  // const typeKeys = useMemo(() => Object.keys(documents || {}), [documents])
  const name = useMemo(() => {
    const edd = getCorrectedValue(assessment, 'delivery.edd')
    const eddStr = edd ? `EDD: ${getDateString(edd, 'short')}` : 'EDD: Awaiting...'
    return assessment.name ? assessment.name : eddStr
  }, [assessment])
  return (
    <VStack
      // sx={{ ':nth-of-type(4n - 3)': { background: 'rgb(245,245,245)' } }}
      px={2}
      bg="white"
      borderRadius={6}
      shadow="md"
      pt={1}
      pb={2}
      align="flex-start"
      w="100%"
      spacing={0}>
      <HStack justify="space-between" w="100%">
        <HStack py={1} spacing={1}>
          <Image src="/images/assessment.svg" height="20px" opacity={0.6} />
          <Text
            fontSize="md"
            position="relative"
            top="1px"
            fontFamily="Comfortaa"
            fontWeight={600}
            color="gray.600">
            {name}
          </Text>
        </HStack>
      </HStack>
      <DocumentsList
        // key={type}
        // collection={typeToCollection[type as AssessmentDocumentType]}
        documents={documents}
      />
    </VStack>
  )
}

export const PatientDocuments = ({
  patientId,
  adminView,
}: {
  patientId: string
  adminView?: boolean
}) => {
  const { data, loading } = usePatientDocuments(patientId, adminView)
  const { data: assessments, loading: loadingAssessments } = useUserAssessments(patientId)
  const dataArr = useMemo(() => objectToArray(data || {}), [data])

  const documentsByAssessment = useMemo(() => separateDocumentsByAssessment(dataArr), [dataArr])

  const assessmentKeys = useMemo(() => Object.keys(assessments || {}), [assessments])

  return (
    <Flex w="100%" borderRadius={6}>
      <VStack spacing={3} w="100%">
        <HStack boxShadow="md" bg={colors.green.hex} borderRadius={6} py={2} px={3} w="100%">
          <Text
            fontFamily="Comfortaa"
            fontWeight={600}
            fontSize="lg"
            color="white"
            textShadow="1px 1px 2px #00000077">
            {adminView ? 'Patient Documents' : 'My Documents'}
          </Text>
        </HStack>
        {!loading && !loadingAssessments ? (
          <VStack w="100%" spacing={3}>
            {assessmentKeys.length ? (
              assessmentKeys.map(assessmentId =>
                assessments?.[assessmentId] ? (
                  <PatientAssessmentDocuments
                    key={assessmentId}
                    assessment={assessments?.[assessmentId]}
                    documents={documentsByAssessment[assessmentId]}
                  />
                ) : null,
              )
            ) : (
              <Text w="100%" p={2} bg="whiteAlpha.800" borderRadius={4} color="gray.600">
                {loading ? 'Loading...' : 'No assessments yet'}
              </Text>
            )}
          </VStack>
        ) : (
          <HStack bg="white" p={4} borderRadius={6} boxShadow="md" w="100%">
            <CircularProgress color={colors.green.hex} isIndeterminate size={5} />
            <Text fontSize="md" fontFamily="Open Sans" color="gray.600">
              {adminView ? 'Loading patient documents...' : 'Loading your documents...'}
            </Text>
          </HStack>
        )}
      </VStack>
    </Flex>
  )
}
