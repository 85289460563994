import { Box, Center, Divider, HStack, Text, VStack } from '@chakra-ui/react'
import { colors } from '@hb/shared'
import { deleteField } from 'firebase/firestore'
import React, { useCallback, useContext } from 'react'
import { InlineWidget } from 'react-calendly'
import { PopUpMessageContext } from '../../../contexts/PopUpMessage/PopUpMessageContext'
import { useUpdateDoc } from '../../../hooks/backend/useUpdateDoc'
import { Editable } from '../../forms/Input'
import { AdminProfileContext } from '../context'
import { AdminSortCalendlyLinks } from './AdminSortCalendlyLinks'
import { calendlyDescriptionField, calendlyLinkUrlField } from './fields'
import { checkIfValidCalendlyLink } from './utils'

export const AdminCalendlyLink = () => {
  const { adminUserRef, user } = useContext(AdminProfileContext)
  const { processResponse } = useContext(PopUpMessageContext)
  const update = useUpdateDoc('admin profile')
  const handleUpdate = useCallback(
    async (key: 'calendlyLink' | 'calendlyDescription', v: string) => {
      if (v) {
        let submitted = v
        if (key === 'calendlyLink') {
          const urlExists = await checkIfValidCalendlyLink(v)
          if (!urlExists) {
            const err = 'Invalid Calendly URL'
            processResponse({ error: err })
            return { error: err }
          }
          submitted = v.includes('https://') ? v : `https://${v}`
        }
        return update(adminUserRef, key, submitted)
      }
      return update(adminUserRef, key, deleteField())
    },
    [adminUserRef, update, processResponse],
  )
  return (
    <VStack h="100%" w="100%">
      <HStack h="100%" w="100%" align="flex-start" p={2} bg={colors.pink.hex}>
        <VStack flex={1} borderRadius={4} bg="white" shadow="md" spacing={0}>
          <VStack w="100%" bg="gray.50" borderBottom="1px solid #cdcdcd" p={2} spacing={0}>
            <Text fontSize="lg" fontWeight="bold" fontFamily="Comfortaa">
              Calendly Link
            </Text>
            <Text lineHeight={1}>
              This link will be used for appointments for assessments sent by {user?.fname}
            </Text>
          </VStack>
          <HStack w="100%" p={1}>
            <Editable
              style={{ width: '100%' }}
              onSubmit={v => handleUpdate('calendlyLink', v)}
              field={calendlyLinkUrlField}
              value={user?.calendlyLink}
            />
          </HStack>
          <Divider />
          <HStack w="100%" p={1}>
            <Editable
              style={{ width: '100%' }}
              onSubmit={v => handleUpdate('calendlyDescription', v)}
              field={calendlyDescriptionField}
              value={user?.calendlyDescription}
            />
          </HStack>
          <Divider />
          {user?.calendlyLink && !user.calendlyDescription ? (
            <Box px={2} w="100%" bg="gray.100" py={1} color="red.600">
              <Text color="red.600">Calendly description is required for drop-down option</Text>
            </Box>
          ) : null}
        </VStack>
        <VStack
          justify="center"
          bg="whiteAlpha.500"
          boxShadow="inset 0 0 12px #00000066"
          spacing={0}
          flex={1}>
          {user?.calendlyLink ? (
            <VStack justify="center" h="100%" pt={4}>
              <Text
                borderRadius="full"
                shadow="md"
                px={4}
                py={1}
                bg="gray.50"
                fontFamily="Comfortaa"
                fontSize="sm"
                color="gray.500">
                WIDGET PREVIEW
              </Text>
              <InlineWidget url={user.calendlyLink} />
            </VStack>
          ) : (
            <Center
              minH="100px"
              w="100%"
              border="1px dashed #888"
              bg="whiteAlpha.700"
              h="100%"
              p={1}>
              <Text color="gray.500" p={2}>
                Calendly widget will show here once a link is provided
              </Text>
            </Center>
          )}
        </VStack>
        <Box flex={1}>
          <AdminSortCalendlyLinks />
        </Box>
      </HStack>
    </VStack>
  )
}
