import { HStack, Text, VStack } from '@chakra-ui/react'
import {
  AdminAdminData,
  adminsCollection,
  getFullName,
  objectToArray,
  UserRoleItem,
  WithId,
} from '@hb/shared'

import { doc, onSnapshot } from 'firebase/firestore'
import React, { useEffect, useMemo, useState } from 'react'
import { USERS_ADMIN_REF } from '../../../collections/firestoreCollections'
import { getCollectionQuery } from '../../../collections/utils/read'
import { useQuery } from '../../../hooks/backend/useQuery'
import { CollectionListEdit } from '../../forms/FinalForm/ListEdit'
import { calendlyLinkField } from './fields'

const CalendlyLinkPreview = ({ value }: { value: AdminAdminData }) => {
  const { calendlyLink, calendlyDescription } = value || {}

  return (
    <VStack w="100%" px={3} fontFamily="Hero-New" align="flex-start">
      <HStack>
        <Text fontSize="sm" color="gray.500" fontWeight={600}>
          Link
        </Text>
        <Text fontSize="sm">{calendlyLink}</Text>
      </HStack>
      <Text
        p={2}
        fontSize="sm"
        fontFamily="Open Sans"
        w="100%"
        bg="blackAlpha.100"
        borderRadius={4}>
        {calendlyDescription}
      </Text>
    </VStack>
  )
}

const adminsQuery = getCollectionQuery(adminsCollection)
export const AdminSortCalendlyLinks = () => {
  const { data: admins } = useQuery(adminsQuery)

  // const [loading, setLoading] = useState(false)
  const [publicItems, setPublicItems] = useState<Record<string, WithId<AdminAdminData>>>({})

  useEffect(() => {
    if (admins) {
      // setLoading(true)
      const unsubscribers = objectToArray(admins).map(admin => {
        const docRef = doc(USERS_ADMIN_REF, admin.id)
        return onSnapshot(docRef, {
          next: updated => {
            // setLoading(false)
            if (updated.exists()) {
              setPublicItems(prev => ({
                ...prev,
                [admin.id]: { ...admin, ...updated.data() },
              }))
            } else {
              setPublicItems(({ [admin.id]: _, ...prev }) => prev)
            }
          },
        })
      })
      return () => {
        unsubscribers.forEach(unsub => unsub())
      }
    }
    return () => {}
  }, [admins])

  const adminsWithCalendly = useMemo(
    () =>
      objectToArray(admins || {})
        .map(roleDoc => ({ ...roleDoc, ...publicItems[roleDoc.id] }))
        .filter(admin => admin.calendlyDescription && admin.calendlyLink),
    [admins, publicItems],
  )
  return (
    <VStack>
      <CollectionListEdit<WithId<AdminAdminData & UserRoleItem>>
        value={adminsWithCalendly}
        ItemPreview={CalendlyLinkPreview}
        collectionRef={USERS_ADMIN_REF as any}
        itemField={calendlyLinkField}
        itemName="Calendly Link"
        orderProp="calendlyRank"
        listName="Calendly Links"
        getItemTitle={item => getFullName(item as UserRoleItem)}
      />
    </VStack>
  )
}
