import { Image, Text } from '@chakra-ui/react'
import { colors, ThreadType, UserGroup } from '@hb/shared'
import React, { ReactElement } from 'react'
import assessmentIcon from '../../icons/assessment_green.svg'
import groupsIcon from '../../icons/groups_green.svg'
import groupIcon from '../../icons/group_green.svg'

export const threadAccessIcons: Record<UserGroup, ReactElement> = {
  admin: (
    <Text
      // transition="all 300ms"
      color={colors.green.hex}
      fontSize="md"
      fontFamily="Open Sans"
      fontWeight={700}
      px="0.1rem"
      // bg='blackAlpha.400'
      borderRadius={4}
      // textShadow="1px 1px 3px #00000099"
      lineHeight={1}>
      HB
    </Text>
  ),
  practice: <Image src={groupsIcon} h="22px" />,
  patient: <Image src={groupIcon} h="22px" />,
}

export const threadIcons: Record<ThreadType, ReactElement> = {
  [ThreadType.DIRECT]: <Image src={groupIcon} h="20px" />,
  [ThreadType.GROUP]: <Image src={groupsIcon} h="20px" />,
  [ThreadType.ASSESSMENT]: <Image src={assessmentIcon} h="20px" />,
}
