import { InfoIcon } from '@chakra-ui/icons'
import {
  Button,
  Center,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from '@chakra-ui/react'
import { notesField } from '@hb/shared'
import React from 'react'
import { Editable } from './forms/Input'

export const NotesPopover = ({
  notes,
  noText,
  label = 'Notes',
}: {
  notes?: string
  noText?: boolean
  label?: string
}) => (
  <Popover strategy="fixed" placement="top" trigger="hover" isLazy>
    <PopoverTrigger>
      <HStack px={noText ? 0 : 1} spacing={1}>
        <IconButton
          size="xs"
          variant="unstyled"
          aria-label="Notes"
          opacity={0.7}
          _hover={{ opacity: 1 }}
          color="gray.500"
          icon={
            <Center>
              <InfoIcon w={4} h={4} />
            </Center>
          }
        />
        {noText ? null : (
          <Text
            whiteSpace="nowrap"
            isTruncated
            fontFamily="Open Sans"
            fontSize="sm"
            color={notes ? 'gray.600' : 'gray.400'}>
            {notes || 'No notes'}
          </Text>
        )}
      </HStack>
    </PopoverTrigger>
    <PopoverContent maxW="400px" minW={0} w="auto">
      <PopoverArrow />
      <VStack minW="60px" py={2} px={3} align="flex-start" spacing={0}>
        <Text lineHeight={1} fontSize="sm" fontWeight={600} color="gray.500">
          {label}
        </Text>
        {notes ? (
          <Text fontWeight={400} whiteSpace="pre-wrap" fontSize="md" color="gray.500">
            {notes || `No ${label.toLowerCase()}`}
          </Text>
        ) : null}
      </VStack>
    </PopoverContent>
  </Popover>
)

export const EditableNotesPopover = ({
  notes,
  onSubmit,
  withIcon,
}: {
  notes?: string
  onSubmit: (v: string | undefined) => Promise<void>
  withIcon?: boolean
}) => (
  <Popover matchWidth strategy="fixed" isLazy>
    {({ onClose }) => (
      <>
        <PopoverTrigger>
          {withIcon ? (
            <IconButton
              size="xs"
              border="1px solid #777"
              variant="unstyled"
              aria-label="Notes"
              opacity={0.7}
              bg="gray.50"
              _hover={{ opacity: 1 }}
              color="gray.500"
              icon={<InfoIcon w={4} h={4} />}
            />
          ) : (
            <Button
              w="100%"
              variant="ghost"
              flexFlow="row"
              size="xs"
              border="1px solid #ababab"
              bg="gray.50"
              justifyContent="flex-start"
              gap={1}>
              <InfoIcon color="gray.600" w={3} h={3} />
              <Text
                textAlign="left"
                fontSize="sm"
                fontWeight={500}
                fontFamily="Hero-New"
                color={notes ? 'gray.600' : 'gray.400'}
                isTruncated
                lineHeight={1}
                flex={1}
                minW="0">
                {notes ? notes.split('\n')[0] : 'No notes'}
              </Text>
            </Button>
          )}
        </PopoverTrigger>
        <PopoverContent width="auto" minW="300px">
          <PopoverArrow />
          <PopoverBody minW="300px" p={0}>
            <Editable
              closeCallback={onClose}
              onSubmit={v =>
                onSubmit(v)
                  .then(() => {
                    onClose()
                    return { success: 'Notes updated' }
                  })
                  .catch((e: any) => ({
                    error: e.message || 'Error updating notes',
                  }))
              }
              style={{ width: '100%' }}
              field={notesField}
              dataCellProps={{
                style: { padding: '0.4rem 0.6rem', fontSize: '0.9rem' },
              }}
              value={notes}
            />
          </PopoverBody>
        </PopoverContent>
      </>
    )}
  </Popover>
)
