import { Button, Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { colors, defaultStageValidate, Field, FieldMap, FieldTypes } from '@hb/shared'
import { FORM_ERROR } from 'final-form'

import React, { useContext, useState } from 'react'

import { PopUpMessageContext } from '../../contexts'
import { ThemeContext } from '../../contexts/ThemeContext'
import { useMultiFactorOpen } from '../../hooks'
import {
  registerWithEmailPassword,
  signInWithEmailPassword,
  signInWithGoogle,
} from '../../store/auth'
import { ActionButton } from '../Buttons/ActionButton'
import { SimpleForm } from '../forms/FinalForm/SimpleForm'
import { ForgotPasswordModal } from './ForgotPasswordModal'

const passwordField: Field = {
  type: FieldTypes.TEXT,
  placeholder: 'Password',
  protected: true,
  autocomplete: 'current-password',
}

const emailField: Field = {
  type: FieldTypes.EMAIL,
  placeholder: 'Email',
  autocomplete: 'email',
}

const newPasswordField = { ...passwordField, autocomplete: 'new-password' }

const confirmPasswordField: Field = {
  type: FieldTypes.TEXT,
  placeholder: 'Confirm password',
  protected: true,
  autocomplete: 'new-password',
}
const emailPasswordField: FieldMap = {
  name: 'Sign In',
  children: {
    email: emailField,
    password: passwordField,
  },
}

const newAccountField: FieldMap = {
  name: 'Register',
  validate: v => {
    if (!v) return { email: 'Required' }
    if (!v.newpass || !v.newpass.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/g)) {
      return {
        newpass:
          'Password should be at least 8 characters and include an uppercase letter and a number',
      }
    }
    if (v.newpass !== v.confirmPass) {
      return { confirmPass: 'Passwords do not match' }
    }
    const baseErrors = defaultStageValidate(newAccountField)(v)
    if (baseErrors) return baseErrors
    return undefined
  },
  children: {
    email: emailField,
    newpass: newPasswordField,
    confirmPass: confirmPasswordField,
  },
}
export const SignInContent = ({ onClose }: { onClose?: () => void }) => {
  const [showForgotPassword, setShowForgotPassword] = useState(false)

  const [fetchingGoogleSignIn, setFetchingGoogleSignIn] = useState(false)
  const { processResponse } = useContext(PopUpMessageContext)
  const multiFactorOpen = useMultiFactorOpen()

  return (
    <ThemeContext.Provider value={{ theme: 'detailed' }}>
      <Tabs isLazy w="100%">
        <TabList>
          <Tab py={4}>Sign In</Tab>
          <Tab py={4}>Register</Tab>
        </TabList>
        <TabPanels p={0}>
          <TabPanel p={2}>
            <SimpleForm
              hasResetButton={false}
              submitOnEnter={!multiFactorOpen}
              submitText="Log in"
              theme="detailed"
              boxProps={{ shadow: 'none', p: 0 }}
              onSubmit={(v: any) =>
                signInWithEmailPassword(v)
                  .then(() => {
                    if (onClose) onClose()
                    return undefined
                  })
                  .catch((err: any) => ({ [FORM_ERROR]: err.message }))
              }
              field={emailPasswordField}>
              <Button
                onClick={() => setShowForgotPassword(true)}
                // px={3}
                style={{ padding: '4px 10px' }}
                ml="auto"
                fontWeight={400}
                size="sm"
                variant="link">
                Forgot your password?
              </Button>
            </SimpleForm>
          </TabPanel>
          <TabPanel p={2}>
            <SimpleForm<{ email: string; newpass: string }>
              submitText="Register"
              theme="detailed"
              submitOnEnter={!multiFactorOpen}
              hasResetButton={false}
              boxProps={{ shadow: 'none', p: 0 }}
              onSubmit={({ email, newpass }) =>
                registerWithEmailPassword!({
                  email,
                  password: newpass,
                })
                  .then(res =>
                    signInWithEmailPassword!({ email, password: newpass }).then(() => {
                      if (onClose) onClose()
                      return res
                    }),
                  )
                  .catch((err: any) => {
                    processResponse({
                      error: err?.message || 'Error registering',
                    })
                    return { [FORM_ERROR]: err.message }
                  })
              }
              field={newAccountField}
            />
          </TabPanel>
        </TabPanels>
        <Flex mt={2} pt={1} pb={3} px={4}>
          <ActionButton
            colorScheme="gray"
            isLoading={fetchingGoogleSignIn}
            onClick={async () => {
              setFetchingGoogleSignIn(true)
              try {
                await signInWithGoogle()
              } catch (err: any) {
                processResponse({ error: err?.message || 'Error signing in' })
              }
              if (onClose) onClose()
              setFetchingGoogleSignIn(false)
            }}
            overflow="hidden"
            bg="white"
            _hover={{ bg: 'white', border: `1px solid ${colors.green.hex}` }}
            py={1}
            width="100%"
            height="auto">
            <img
              // width='160px'
              alt="google-sign-in"
              src="/images/google-sign-in.png"
            />
          </ActionButton>
        </Flex>
        <ForgotPasswordModal
          isOpen={showForgotPassword}
          onClose={() => setShowForgotPassword(false)}
        />
      </Tabs>
    </ThemeContext.Provider>
  )
}
