import { HStack } from '@chakra-ui/react'
import { Fax, WithId } from '@hb/shared'

import { doc, setDoc } from 'firebase/firestore'
import React from 'react'
import { FAX_RECEIVED_REF, FAX_SENT_REF } from '../../collections/firestoreCollections'

import { EditableNotesPopover } from '../NotesPopover'
import { AssignToPatient } from './AssignToPatient'

export const FaxModalHeader = ({ fax }: { fax: WithId<Fax> }) => (
  <HStack maxW="50%" flex={1} p={2} pr={10}>
    <EditableNotesPopover
      onSubmit={v =>
        setDoc(
          doc(fax.type === 'received' ? FAX_RECEIVED_REF : (FAX_SENT_REF as any), fax.id),
          { notes: v },
          { merge: true },
        )
      }
      notes={fax.notes}
    />
    <AssignToPatient fax={fax} />
  </HStack>
)
