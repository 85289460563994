import { Box, Text } from '@chakra-ui/react'
import { FAQList, InfoText, InlineLink } from '@hb/shared-frontend'
import React from 'react'
import { PatientsPageLink } from './PatientsPageLink'

export const insurancePlansFaq: FAQList = {
  name: 'Insurance plans that cover homebirth / midwives',
  description: (
    <InfoText>
      There are many insurance plans out there, each tailored to specific employers or unions, each
      with their own contract on coverage for services. This makes it impossible to simply
      ‘recommend’ one insurer over another. Things also change from year to year, so keep in mind
      that while the insurance companies listed below have historically provided coverage, this does
      not mean obtaining coverage is always simple, nor straight-forward! Insurers listed below are
      for informational purposes only, subject to change (completely beyond our control)!
    </InfoText>
  ),
  items: [
    {
      title: 'Open Enrollment and browsing future coverage options',
      body: (
        <>
          <InfoText>
            In our history dealing with insurance companies, those plans listed below have generally
            covered homebirth and/or midwives delivering in a facility setting, but ultimately all
            depends on plan details. If you are looking to choose a plan during open enrollment, we
            hope this can help guide you to finding something that might work for future coverage.
          </InfoText>
          <InfoText>
            *If you are looking at plan options through an employer: be aware that any future plan
            your employer offers will often won’t divulge critical details to assess coverage. See
            tips below under each insurer for this scenario to help you decide whether or not you
            should switch. For NY Marketplace plans see below for the best options.
          </InfoText>
        </>
      ),
    },
    {
      title: '*OXFORD',
      body: (
        <>
          <InfoText>
            Oxford plans have historically provided adequate coverage for midwives/homebirth, even
            if the plan has no out of network benefits. You can ask HR or your broker (if you are
            looking to switch to this insurer) if the Oxford plan you are exploring follows NY state
            insurance law or - if you are currently covered, you can verify this information with
            member services. If your plan does follow state mandates, coverage should apply. On rare
            occasions, out of network coverage may apply if your plan has out of network benefits.
            Coverage can vary under these circumstances. If you are currently covered by Oxford it
            is best to request an assessment from our team by going to the <PatientsPageLink />.
          </InfoText>
        </>
      ),
    },
    {
      title: 'UNITED',
      body: (
        <>
          <InfoText>
            United plans historically allow for coverage with some exceptions. More research would
            be required to find out what kind of coverage is available. United marketplace plans
            (United Compass plan, for instance) have historically waived coverage for
            homebirth/midwives even though these plans have no out of network coverage. Many
            employer plans also allow for this type of coverage.
          </InfoText>
          <InfoText>
            Keep in mind that any insurance plan needs comprehensive research before Hamilton
            Billing can confirm that specific plan will or will not provide adequate coverage. If
            you are looking at a future plan, only HR can help answer specific questions on that
            future plan in order for our team to assess.If you are currently covered by United it is
            best to request an assessment from our team by going to the <PatientsPageLink />.
          </InfoText>
        </>
      ),
    },
    {
      title: 'NYSHIP',
      body: (
        <>
          <InfoText>
            NYSHIP plans reimburse at the out of network benefit level, historically applying FAIR
            health rates, resulting in adequate coverage for care for midwives and homebirth in
            general. This is true as long as your plan’s annual out of network deductible does not
            exceed $1250 and your coinsurance does not exceed 20%. United Healthcare (the insurer)
            manages coverage for this type of plan, covering homebirth and midwives. *These plans
            are only available to New York State employees under NYSHIP.
          </InfoText>
        </>
      ),
    },
    {
      title: 'CIGNA',
      body: (
        <>
          <InfoText>
            With Cigna plans, out of network benefits typically apply, which also means coverage
            varies widely (depending on plan details). As a general rule, if your CIGNA plan
            determines reimbursement for out of network providers based on FAIR health rates, OR on
            300% or higher of the medicare fee schedule, coverage can often be good. The lower the
            annual out of network deductible and coinsurance, the higher the plan will reimburse
            towards services. If your plan allows for negotiations during the claims process, you
            may also have a better chance of receiving adequate coverage. HR can often answer the
            above question and provide a PDF document indicating overall coverage with plan options.
          </InfoText>
        </>
      ),
    },
    {
      title: 'AETNA',
      body: (
        <InfoText>
          Fully insured AETNA plans are the most reliable on coverage for homebirth/midwives as
          opposed to self-funded plans, for which coverage varies widely and in some cases can be
          minimal. AETNA plans require research to assess coverage and are not 'all good or all
          bad'.
        </InfoText>
      ),
    },
    {
      title: 'MERITAIN',
      subtitle: '(utilizing the AETNA or CIGNA network)',
      body: (
        <InfoText>
          Meritain plans historically allow coverage for homebirth in general, often covering
          adequately, but not all plans do - additional research is required. For instance: If your
          plan has large deductibles (over $3000 for out of network coverage) and/or the plan
          determines reimbursement for out of network providers based on a percentage lower than
          300% of the Medicare fee schedule (as opposed to reasonable and customary rates), coverage
          can be inadequate. Meritain plans are typically only available through an employer, group
          or union. Look for plans that use the ‘reasonable and customary’ or ‘FAIR Health rates’
          method to come up with reimbursement, and/or plans with out of network deductibles that
          are lower, in general.
        </InfoText>
      ),
    },
    {
      title: 'EMPIRE BCBS',
      body: (
        <InfoText>
          Keep in mind that any insurance plan needs comprehensive research before Hamilton Billing
          can confirm that specific plan will or will not provide adequate coverage. If you are
          looking at a future plan, only HR can help answer specific questions on that future plan
          in order for our team to assess.If you are currently covered by United it is best to
          request an assessment from our team by going to the <PatientsPageLink />.
        </InfoText>
      ),
    },
    {
      title: 'BCBS of CALIFORNIA',
      subtitle: 'or other out of state BCBS plans',
      body: (
        <InfoText>
          Some employer plans based out of state are very poor on coverage - leaving large amounts
          to pay out of pocket for patients. On rare occasions, some Anthem/BCBS plans will provide
          better coverage (for instance BCBS of Illinois), but these are more rare and require a lot
          of follow up from you as the member. Some Google plans (which are based out of California)
          provide decent coverage and waive exceptions for homebirth. Deductibles and coinsurance
          almost always apply, meaning you will have amounts you will be required to pay out of
          pocket in addition to coverage from your plan.
        </InfoText>
      ),
    },
    {
      title: 'FEP BLUE BCBS or UHC PLANS',
      body: (
        <InfoText>
          STANDARD FEP BLUE plans are the only plans that provide coverage - BASIC plans do not
          preauthorize and won't cover anything as there are no out of network benefits. The lower
          the deductible, the better on reimbursement from the plan.
        </InfoText>
      ),
    },
    {
      title: 'GHI',
      body: (
        <InfoText>
          These plans have out of network benefits (which would apply for care), although coverage
          is generally not adequate for homebirth/midwives, leaving a large out of pocket expense
          for patients. If you are further along in pregnancy your GHI plan may cover even less,
          leaving most of the fee out of pocket for you. Please see our <PatientsPageLink /> for an
          assessment so we can help suggest alternatives on coverage if they are available to you!
        </InfoText>
      ),
    },
    {
      title: 'EMBLEM / HIP',
      body: (
        <InfoText>
          Historically these plans are difficult to work with, however - they do preauthorize
          coverage for homebirth, often resulting in either some coverage or adequate coverage, for
          which follow up by you as the member with this insurer will be required. Please see our
          Patients page for an assessment on how coverage from this plan works.
        </InfoText>
      ),
    },
  ],
}

export const marketplacePlansFaq: FAQList = {
  name: 'Marketplace Plans / New York State of Health',
  description: (
    <Box>
      <Text mt={2}>
        <InlineLink onClick={() => window.open('https://nystateofhealth.ny.gov', '_blank')}>
          Visit the NYSOH website here
        </InlineLink>{' '}
        or contact them at (855) 355-5777
      </Text>
    </Box>
  ),
  items: [
    {
      title: 'OSCAR',
      body: (
        <InfoText>
          Plans historically waive coverage for homebirth/midwives, even though there are no out of
          network benefits. Only lower deductible plans (under $2000) cover substantially for care,
          so be sure to research this when selecting a plan - some OSCAR plans have very high
          deductibles, which must be met before coverage kicks in. We have also witnessed
          complications with authorizations on coverage when patients are over the age of 33, so
          please aware this may come up.
        </InfoText>
      ),
    },
    {
      title: 'FIDELIS',
      body: (
        <InfoText>
          Fidelis plans historically waive coverage for homebirth/midwives, even though plans have
          no out of network benefits. Coverage is also typically adequate from these plans for full
          maternity care when authorizations are passed, but please keep in mind that you have to
          fulfill the plan deductible and any percentage coinsurance the plan imposes (be sure to
          research your options to keep out of pocket costs lower for yourself as the member). For
          instance, a lower deductible and coinsurance means lower costs for care! Coverage can be
          lower if you sign on for care with the midwife later in pregnancy, although even under
          these circumstances this plan on average covers beyond deductibles and coinsurance that
          apply.
        </InfoText>
      ),
    },
    {
      title: 'UNITED COMPASS PLAN',
      body: (
        <InfoText>
          Historically these plans will waive coverage for midwives/homebirth, even though there are
          technically no out of network benefits available. Coverage is also generally adequate for
          full maternity care once a pre-authorization is approved. This plan requires involvement
          by you as the member to obtain an authorization which can take some homework/effort
          (providing fair warning)! Our team provides guidance and support but be aware you will be
          required to take action regarding coverage. If you sign on for care later in pregnancy
          with the midwife (3rd trimester), coverage may be reduced.
        </InfoText>
      ),
    },
    {
      title: 'EMPIRE BLUE',
      body: (
        <InfoText>
          Historically BCBS marketplace plans (other than Excellus BCBS) will waive coverage for
          midwives/homebirth, even though there is technically no out of network coverage available.
          Only lower deductible plans (under $2000) cover substantially for care, so be sure to
          research annual deductible and coinsurance information when selecting a plan. If annual
          coinsurance (a percentage) is 50/50 or 60/40, coverage will likely be inadequate for any
          services you seek. This insurer often requires follow up from you as the member.
        </InfoText>
      ),
    },
    {
      title: 'HEALTH FIRST LEAF PLANS',
      body: (
        <InfoText>
          These plans historically waive coverage for homebirth/midwives, even though plans have no
          out of network benefits. Coverage is also typically adequate from these plans for full
          maternity care as long as you are not beyond 15 weeks into pregnancy when authorizations
          are initiated by your provider. Please keep in mind that you have to fulfill any plan
          deductible and percentage coinsurance if applicable (be sure to research your options to
          keep out of pocket costs lower for yourself as the member).
        </InfoText>
      ),
    },
  ],
}

export const medicaidPlansFaq: FAQList = {
  name: 'Medicaid Managed Plans',
  items: [
    {
      title: 'HEALTH FIRST MEDICAID',
      body: (
        <InfoText>
          Most homebirth midwives will accept this plan if you are early in pregnancy. Otherwise,
          reimbursement from the plan may not be adequate and coverage may not be accepted by the
          provider. Please get in touch with us through the <PatientsPageLink /> for an assessment
          so we can advise.
        </InfoText>
      ),
    },
    {
      title: 'FIDELIS MEDICAID',
      body: (
        <InfoText>
          Historically Fidelis Medicaid preauthorizes coverage for homebirth. The earlier you are in
          pregnancy, the more likely the chances are the midwife can take your plan coverage. Please
          see our <PatientsPageLink /> for an assessment so we can advise.
        </InfoText>
      ),
    },
    {
      title: 'UHC Community Plan (Medicaid)',
      body: (
        <>
          <InfoText>
            Most midwives can not take this insurance as coverage for homebirth. To find out more
            what your alternative options may be, please go to our <PatientsPageLink /> for a free
            assessment (so we can help).
          </InfoText>
        </>
      ),
    },
  ],
}

export const commercialInsurersFaq: FAQList = {
  name: 'Commercial Insurers',
  items: [
    {
      title: 'EMPIRE BCBS',
      body: (
        <>
          <InfoText>
            When 'home plan' is based in New York State (and not out of state) most plans can be
            preauthorized for better coverage - but also, your biller needs to know how to go after
            BCBS during claims if they undercut on reimbursement. Union plans have restrictions on
            coverage, so it is completely mixed bag on reimbursement.
          </InfoText>
          <InfoText fontStyle="italic">
            Most Empire BCBS plans are through an employer or union/institution.
          </InfoText>
        </>
      ),
    },
    {
      title: "SAG OR DIRECTOR'S GUILD BCBS",
      body: (
        <InfoText>
          Generally easy to work with and decent on coverage but deductibles and coinsurance factor
          in and an assessment through your biller will help confirm out of pocket costs for the
          patient. This is a California based BCBS plan - one of the few CA BCBS plans that cover
          homebirth by a fair amount. The lower the deductible the better the outcome on coverage
        </InfoText>
      ),
    },
    {
      title: 'FEP BLUE BCBS PLANS',
      body: (
        <InfoText>
          Standard plans are the only plans that cover homebirth - BASIC plans do not preauthorize
          and won't cover anything as there are no out of network benefits. The lower the
          deductible, the better on reimbursement from the plan.
        </InfoText>
      ),
    },
    {
      title: 'GOOGLE BCBS',
      body: (
        <InfoText>
          This plan preauthorizes for homebirth, but coverage can sometimes be low. Patients often
          have to supplement on these plans as coverage lands between $4000 and $6500 on
          reimbursement as a general rule due to coinsurance, deductibles and allowed charges. An
          assessment by your biller will be required to estimate out of pocket costs.
        </InfoText>
      ),
    },
    {
      title: 'GHI',
      body: (
        <InfoText>
          covers a flat $4450 for most midwives, $5k sometimes (only if patient is initiating
          prenatal care with you as the midwife and is not switching from another practice). Pays
          practically NOTHING if patient transfers to the hospital out of midwife's care - also pays
          approximately $2500 or less if another provider has billed for ANY prenatal care prior to
          switching over to a homebirth midwife - be careful with this one.
        </InfoText>
      ),
    },
  ],
}

export const badPlansFaq: FAQList = {
  name: "Bad plans that don't cover / Steer clear",
  items: [
    {
      title: 'FIDELIS',
      body: (
        <InfoText>
          Covers $3k max if no deductible involved (Medicaid, for instance) - will not negotiate
          above this rate for services (as a general rule) either on Medicaid managed plan or
          through NYSOH.
        </InfoText>
      ),
    },
    {
      title: 'EMBLEM / HIP',
      subtitle:
        '(unless associated with Healthcare Partners - then there is potential for negotiation)',
      body: (
        <InfoText>
          HIP does not negotiate above $2700 for all-inclusive care and homebirth as a general rule.
        </InfoText>
      ),
    },
    {
      title: 'AFFINITY (Medicaid)',
      body: (
        <InfoText>
          Plan does not negotiate above $3000 for all-inclusive care as a general rule. Can not
          balance bill patient as this is Medicaid
        </InfoText>
      ),
    },
    {
      title: 'BCBS HEALTH PLUS (Medicaid)',
      body: (
        <InfoText>
          Plan does not negotiate above $3000 for all-inclusive care as a general rule. Can not
          balance bill patient as this is Medicaid
        </InfoText>
      ),
    },
    {
      title: 'BCBS of CALIFORNIA',
      body: (
        <InfoText>
          Mostly employer plans based in CA are very poor on coverage - less than $3000 total). Some
          Google plans are ok SAG and Directors Guild are an exception to this rule (see above).
        </InfoText>
      ),
    },
    {
      title: '1199',
      body: <InfoText>Covers less than $1000 in most cases overall</InfoText>,
    },
    {
      title: 'TRICARE',
      body: (
        <InfoText>
          Covers approximately $1500 - $3000 on average. There may be a different type of TRICARE
          plan that covers more but we have never witnessed this.
        </InfoText>
      ),
    },
    {
      title: 'BCBS EXCELLUS PLANS',
      body: (
        <InfoText>
          Will not cover homebirth if the midwife has no malpractice insurance policy specifically
          for homebirth. They will ask you to present them with this information or they won't
          preauthorize.
        </InfoText>
      ),
    },
  ],
}
