import { AppName } from '../types'

export const getAppDisplayName = (appName: AppName) => {
  switch (appName) {
    case 'app':
      return 'Hamilton Billing Site'
    case 'providers-app':
      return 'HB Providers Portal'
    default:
      return 'Error: No App Name Provided'
  }
}
