import { Button, ButtonProps, forwardRef } from '@chakra-ui/react'

import React from 'react'

export const BigGreenButton = forwardRef<ButtonProps, 'button'>((props: ButtonProps, ref) => (
  <Button
    ref={ref}
    mb={12}
    mx="auto"
    height="auto"
    py={[2, 4]}
    px={[3, 5]}
    borderRadius={32}
    boxShadow="md"
    fontWeight={500}
    fontSize={['lg', 'x-large']}
    background="#43AD9E"
    _hover={{
      background: '#65CFBF',
    }}
    color="white"
    fontFamily="hero-new"
    {...props}
  />
))
