import { Divider, HStack, Radio, RadioGroup, Text, VStack } from '@chakra-ui/react'
import {
  colors,
  isActiveField,
  midwiferyNameField,
  midwivesCollection,
  PracticeWithAdmin,
  specialtyField,
  WithId,
} from '@hb/shared'
import React, { useContext } from 'react'
import { PracticeContext } from '../../contexts'
import { ThemeContext } from '../../contexts/ThemeContext'
import { useUpdateCollection } from '../../hooks/useAppCollections'
import { useAuth } from '../../store/auth'
import { Editable, EditableRow } from '../forms/Input'
import { DataLabel } from '../forms/Input/DataLabel'
import { PracticeAccessView } from './PracticeAccess/PracticeAccessView'
import { ProviderBillingInfo } from './ProviderBillingInfo'
import { ProviderContact } from './ProviderContact'
import { ProviderOtherInfo } from './ProviderOtherInfo'

const IsGroupField = ({ midwife }: { midwife?: WithId<PracticeWithAdmin> }) => {
  const { updateItem } = useUpdateCollection(midwivesCollection)
  return (
    <HStack
      shadow="md"
      borderRadius="full"
      bg={`${colors.pink.hex}`}
      py="0.2rem"
      px={2}
      justify="space-between"
      w="100%">
      <RadioGroup
        colorScheme="green"
        onChange={v => updateItem(midwife?.id || '', 'isGroup', v === 'group')}
        value={midwife?.isGroup ? 'group' : 'solo'}>
        <HStack>
          <Radio bg="white" value="group">
            <Text color="gray.700" fontSize="sm" fontWeight={600}>
              Group
            </Text>
          </Radio>
          <Radio bg="white" value="solo">
            <Text whiteSpace="nowrap" color="gray.700" fontSize="sm" fontWeight={600}>
              Solo Provider
            </Text>
          </Radio>
        </HStack>
      </RadioGroup>
      <HStack ml="auto">
        <Editable
          style={{ flex: 1 }}
          field={isActiveField}
          value={!midwife?.isInactive}
          onSubmit={v => updateItem(midwife?.id || '', 'isInactive', !v)}
        />
      </HStack>
    </HStack>
  )
}

export const PracticeDataView = () => {
  const { updateItem } = useUpdateCollection(midwivesCollection)
  const role = useAuth(s => s.role)
  const { practice } = useContext(PracticeContext)
  return (
    <ThemeContext.Provider value={{ theme: 'detailed' }}>
      <VStack p={2} bg="gray.100" align="flex-start" spacing={2} w="100%">
        <VStack
          borderRadius={4}
          bg="white"
          w="100%"
          spacing={0}
          overflow="hidden"
          align="flex-start">
          <HStack w="100%" justify="space-between">
            <HStack bg={`${colors.pink.hex}aa`} spacing={0} flex={1} px={2}>
              <DataLabel fontSize="md">Practice</DataLabel>
              <Editable
                dataCellProps={{ fontSize: 'md' }}
                onSubmit={val => updateItem(practice?.id || '', 'name', val)}
                value={practice?.name}
                field={midwiferyNameField}
                style={{ flex: 1 }}
              />
            </HStack>
          </HStack>
          <Divider />
          <EditableRow
            label="Specialty"
            onSubmit={val => updateItem(practice?.id || '', 'specialty', val)}
            value={practice?.specialty}
            field={specialtyField}
          />
          <Divider />
        </VStack>
        <IsGroupField midwife={practice} />
        <ProviderContact midwife={practice} />
        <ProviderBillingInfo midwife={practice} />
        <ProviderOtherInfo midwife={practice} />
        {role === 'super-admin' && practice ? (
          <PracticeAccessView practiceId={practice.id} />
        ) : null}
      </VStack>
    </ThemeContext.Provider>
  )
}
