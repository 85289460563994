import React from 'react'
import { useAppModals } from '../../store/modals'
import { PushNotificationsSettings } from '../PushNotifications/Settings'
import { DefaultModal } from './DefaultModal'

export const PushNotificationsSettingsModal = ({ id = 'root' }: { id?: string }) => {
  const { openId, close } = useAppModals(s => s.pushNotificationsSettings)
  return openId === id ? (
    <DefaultModal
      overlayHeader
      isOpen
      onClose={close}
      closeDisabled
      render={() => <PushNotificationsSettings onClose={close} />}
    />
  ) : null
}
