import { CircularProgress, Flex, HStack, Text } from '@chakra-ui/react'
import { getDateTimeString, ThreadMessage } from '@hb/shared'
import React from 'react'
import { useCachedUser } from '../../../collections/hooks/cached'

export const RecentMessageView = ({
  message,
  vertical,
}: {
  message: ThreadMessage
  vertical?: boolean
}) => {
  const { data: user, loading } = useCachedUser(message.createdBy)
  return (
    <Flex flexFlow={vertical ? 'column' : 'row'} gap={vertical ? 0 : 1} w="100%">
      <HStack
        px={0}
        pt={0}
        w={vertical ? '100%' : 'auto'}
        flex={vertical ? 'unset' : 1}
        minW="0"
        spacing={0.5}>
        {loading && !user ? (
          <CircularProgress size="20px" isIndeterminate />
        ) : (
          <Text fontSize="sm" fontWeight={600} opacity={0.8}>
            {user?.fname || ''} {user?.lname?.[0] || ''}
          </Text>
        )}
        <Text
          textAlign="left"
          fontSize="sm"
          lineHeight={1.2}
          pl={1}
          color="gray.500"
          flex={1}
          minW="0"
          isTruncated
          fontFamily="Open Sans">
          {message.text}
        </Text>
      </HStack>
      <Text
        textAlign="left"
        fontSize="xs"
        ml="auto"
        letterSpacing="0"
        color="gray.500"
        fontFamily="Hero-New">
        {getDateTimeString(message.createdOn, 'short', true)}
      </Text>
    </Flex>
  )
}
