import { useMemo } from 'react'
import { useAuth } from '../../../store/auth'
import { usePatientConsentForms } from './usePatientConsentForms'

export const useUnsignedConsentForms = () => {
  const { user } = useAuth()
  const { data } = usePatientConsentForms(user?.id)
  return useMemo(
    () =>
      Object.entries(data || {})
        .filter(([, doc]) => !doc.signedOn)
        .map(([id, doc]) => ({
          id,
          ...doc,
        })),
    [data],
  )
}
