import { Badge, BadgeProps, CircularProgress, Tooltip, TooltipProps } from '@chakra-ui/react'
import { AppName, colors, getNameAndInitials, UserGroup, userGroupLabels } from '@hb/shared'
import React, { useMemo } from 'react'
import { useCachedUser } from '../../collections/hooks/cached'
import { useApp } from '../../contexts/AppContext'

export const getBadgeColor = (appName: AppName, group?: UserGroup) => {
  switch (group) {
    case 'admin':
      return appName === 'app' ? colors.green.hex : colors.pink.hex
    case 'practice':
      return appName === 'app' ? colors.pink.hex : colors.green.hex
    case 'patient':
    default:
      return '#dbcca0'
  }
}

export type UserBadgeProps = {
  userId: string
  tooltipProps?: Omit<TooltipProps, 'children'>
  inverted?: boolean
  userGroup?: UserGroup
  noTooltip?: boolean
  circular?: boolean
} & BadgeProps

export const UserBadge = ({
  userId,
  tooltipProps,
  inverted,
  noTooltip,
  userGroup: role,
  circular,
  ...props
}: UserBadgeProps) => {
  const { data: user, loading } = useCachedUser(userId)
  const { appName } = useApp()
  // const role = useMemo(() => {
  //   return userGroup || (user ? getUserGroup(user) : 'patient')
  // }, [user, userGroup])
  const groupLabel = useMemo(() => userGroupLabels[role || 'patient'], [role])
  const { name, initials } = useMemo(() => getNameAndInitials(appName, user), [user, appName])
  const color = useMemo(() => getBadgeColor(appName, role), [appName, role])
  const { isPink, textColor } = useMemo(() => {
    const lightColor = color !== colors.green.hex
    let textColor = 'white'
    if (inverted) textColor = color
    if (lightColor) textColor = '#1b1b1b'
    return { isPink: lightColor, textColor }
  }, [color, inverted])

  const body = (
    <Badge
      cursor="pointer"
      opacity={0.85}
      alignItems="center"
      justifyContent="center"
      filter={inverted ? 'brightness(0.6) saturate(1.5)' : 'none'}
      boxShadow={inverted ? 'none' : '1px 1px 3px rgba(0,0,0,0.4)'}
      textShadow={inverted || isPink ? 'none' : '1px 1px 2px #00000066'}
      display="flex"
      fontWeight={500}
      width={circular ? '26px' : 'auto'}
      height={circular ? '26px' : 'auto'}
      borderRadius={circular ? '100%' : 0}
      fontFamily="Hero-New"
      color={textColor}
      letterSpacing="wider"
      px={inverted ? 0 : 1}
      bg={inverted ? 'transparent' : color}
      {...props}>
      {loading ? <CircularProgress isIndeterminate size={4} /> : initials}
    </Badge>
  )
  if (!initials) return null
  return noTooltip ? (
    body
  ) : (
    <Tooltip
      textShadow="1px 1px 3px black"
      placement="top"
      bg={color}
      color="white"
      hasArrow
      label={`${name} | ${groupLabel}`}
      {...tooltipProps}>
      {body}
    </Tooltip>
  )
}
