import { Text, TextProps } from '@chakra-ui/react'
import React from 'react'

export const DataLabel = (props: TextProps) => (
  <Text
    fontSize="sm"
    fontFamily="Open Sans"
    color="gray.600"
    whiteSpace="nowrap"
    fontWeight={600}
    {...props}
  />
)
