import { Icon, IconProps } from '@chakra-ui/react'
import React from 'react'

type BaseIconProps = IconProps & { selected: boolean }
type BaseIconFC = React.FC<BaseIconProps>
const BaseIcon: BaseIconFC = ({ selected, ...props }) => (
  <Icon
    viewBox="0 0 122.88 85.36"
    cursor="pointer"
    opacity={selected ? 0.75 : 0.325}
    _hover={{ opacity: 0.75 }}
    {...props}
  />
)

export const JustifyLeft: BaseIconFC = p => (
  <BaseIcon {...p}>
    <g>
      <path d="M6.12,12.23C2.74,12.23,0,9.49,0,6.12C0,2.74,2.74,0,6.12,0h110.65c3.38,0,6.12,2.74,6.12,6.12c0,3.38-2.74,6.12-6.12,6.12 H6.12L6.12,12.23z M6.12,36.61C2.74,36.61,0,33.87,0,30.49c0-3.38,2.74-6.12,6.12-6.12H76.5c3.38,0,6.12,2.74,6.12,6.12 c0,3.38-2.74,6.12-6.12,6.12H6.12L6.12,36.61z M6.12,60.99C2.74,60.99,0,58.25,0,54.87c0-3.38,2.74-6.12,6.12-6.12h110.65 c3.38,0,6.12,2.74,6.12,6.12c0,3.38-2.74,6.12-6.12,6.12H6.12L6.12,60.99z M6.12,85.36C2.74,85.36,0,82.63,0,79.25 c0-3.38,2.74-6.12,6.12-6.12H76.5c3.38,0,6.12,2.74,6.12,6.12c0,3.38-2.74,6.12-6.12,6.12H6.12L6.12,85.36z" />
    </g>
  </BaseIcon>
)

export const JustifyCenter: BaseIconFC = p => (
  <BaseIcon {...p}>
    <g>
      <path d="M6.12,12.23C2.74,12.23,0,9.49,0,6.12C0,2.74,2.74,0,6.12,0h110.65c3.38,0,6.12,2.74,6.12,6.12c0,3.38-2.74,6.12-6.12,6.12 H6.12L6.12,12.23z M26.25,85.36c-3.38,0-6.12-2.74-6.12-6.12c0-3.38,2.74-6.12,6.12-6.12h70.38c3.38,0,6.12,2.74,6.12,6.12 c0,3.38-2.74,6.12-6.12,6.12H26.25L26.25,85.36z M6.12,60.99C2.74,60.99,0,58.25,0,54.87c0-3.38,2.74-6.12,6.12-6.12h110.65 c3.38,0,6.12,2.74,6.12,6.12c0,3.38-2.74,6.12-6.12,6.12H6.12L6.12,60.99z M26.25,36.61c-3.38,0-6.12-2.74-6.12-6.12 c0-3.38,2.74-6.12,6.12-6.12h70.38c3.38,0,6.12,2.74,6.12,6.12c0,3.38-2.74,6.12-6.12,6.12H26.25L26.25,36.61z" />
    </g>
  </BaseIcon>
)

export const JustifyRight: BaseIconFC = p => (
  <BaseIcon {...p}>
    <g>
      <path d="M6.12,12.23C2.74,12.23,0,9.49,0,6.12C0,2.74,2.74,0,6.12,0h110.65c3.38,0,6.12,2.74,6.12,6.12c0,3.38-2.74,6.12-6.12,6.12 H6.12L6.12,12.23z M46.38,85.36c-3.38,0-6.12-2.74-6.12-6.12c0-3.38,2.74-6.12,6.12-6.12h70.38c3.38,0,6.12,2.74,6.12,6.12 c0,3.38-2.74,6.12-6.12,6.12H46.38L46.38,85.36z M6.12,60.99C2.74,60.99,0,58.25,0,54.87c0-3.38,2.74-6.12,6.12-6.12h110.65 c3.38,0,6.12,2.74,6.12,6.12c0,3.38-2.74,6.12-6.12,6.12H6.12L6.12,60.99z M46.38,36.61c-3.38,0-6.12-2.74-6.12-6.12 c0-3.38,2.74-6.12,6.12-6.12h70.38c3.38,0,6.12,2.74,6.12,6.12c0,3.38-2.74,6.12-6.12,6.12H46.38L46.38,36.61z" />
    </g>
  </BaseIcon>
)

// const MarginGuideLines = () => (
//     <svg viewBox="0 0 840 1188" width="100%" height='100%' xmlns="http://www.w3.org/2000/svg">
//   {/* <!-- Top margin --> */}
//   <line x1="63" y1="63" x2="777" y2="63" stroke="black" stroke-dasharray="5,5" />
//   {/* <!-- Bottom margin --> */}
//   <line x1="63" y1="1125" x2="777" y2="1125" stroke="black" stroke-dasharray="5,5" />
//   {/* <!-- Left margin --> */}
//   <line x1="63" y1="63" x2="63" y2="1125" stroke="black" stroke-dasharray="5,5" />
//   {/* <!-- Right margin --> */}
//   <line x1="777" y1="63" x2="777" y2="1125" stroke="black" stroke-dasharray="5,5" />
// </svg>
// )
