import { Center, HStack, Text } from '@chakra-ui/react'
import { colors } from '@hb/shared'
import React from 'react'
import { useCookies } from 'react-cookie'
import { ActionButton } from '../Buttons/ActionButton'

export const CookieBanner: React.FC = () => {
  const [{ cookieConsent }, setCookie] = useCookies(['cookieConsent'])
  const showBanner = !cookieConsent

  const handleAccept = () => {
    setCookie('cookieConsent', 'true', { path: '/' })
  }

  if (!showBanner) {
    return null
  }

  return (
    <Center position="fixed" zIndex={2} bottom={4} width="100%" px={4}>
      <HStack
        p={2}
        bg="#f7f7f7"
        justifyContent="center"
        borderRadius={6}
        shadow="md"
        flexFlow="row wrap"
        spacing={0}
        alignItems="center"
        textAlign="center">
        <Text fontSize="sm" px={2} py={1} fontFamily="Comfortaa">
          This site uses cookies for authentication and analytics.
        </Text>
        <ActionButton bg={colors.green.hex} color="white" size="xs" onClick={handleAccept}>
          Accept & Close
        </ActionButton>
      </HStack>
    </Center>
  )
}
