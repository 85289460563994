import { Checkbox, HStack, Text, VStack } from '@chakra-ui/react'
import { MultipleSelectField } from '@hb/shared'
import React, { forwardRef, useCallback, useImperativeHandle, useMemo } from 'react'
import { InputElement, InputProps } from '../../../../types/fields'
import InputContainer from '../Shared/InputContainer'

export const MultipleSelectInput: InputElement<MultipleSelectField> = forwardRef<
  {
    focus: () => void
  },
  InputProps<MultipleSelectField>
>((props, ref) => {
  const { field, input } = props
  const { options, renderOption } = field || {}
  const { value, onChange } = input || {}
  const valueAsArray = useMemo(
    () => (typeof value === 'string' ? value.split(', ') : value),
    [value],
  )
  useImperativeHandle(ref, () => ({
    focus: () => {},
  }))
  const selectedIds = useMemo(
    () => options.filter(o => valueAsArray.includes(o.text)).map(o => o.id),
    [options, valueAsArray],
  )
  const handleChange = useCallback(
    (id: string, event: React.ChangeEvent<HTMLInputElement>) => {
      const updated = event.target.checked
        ? Array.from(new Set([...selectedIds, id]))
        : selectedIds.filter((v: string) => v !== id)

      onChange({ target: { value: updated.length ? updated : undefined } })
    },
    [selectedIds, onChange],
  )
  return (
    <InputContainer {...props}>
      <VStack spacing={0}>
        {options.map(o => (
          <HStack key={o.id} w="100%">
            <Checkbox
              colorScheme="green"
              onChange={e => handleChange(o.id, e)}
              isChecked={selectedIds.includes(o.id)}>
              {renderOption ? (
                renderOption(o)
              ) : (
                <Text
                  fontSize="sm"
                  color="gray.600"
                  opacity={selectedIds.includes(o.id) ? 1 : 0.7}
                  fontFamily="Hero-New">
                  {o.text}
                </Text>
              )}
            </Checkbox>
          </HStack>
        ))}
      </VStack>
    </InputContainer>
  )
})
