import { AddIcon, AttachmentIcon, EditIcon, TimeIcon } from '@chakra-ui/icons'
import {
  Box,
  BoxProps,
  Button,
  Flex,
  FlexProps,
  Grid,
  GridItem,
  IconButton,
  Image,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import {
  arrayToObject,
  AssessmentInvoiceSnippet,
  capitalizeFirstLetter,
  colors,
  Field,
  FieldTypes,
  FileDBValue,
  formatDollarValue,
  formatDropdownValue,
  formatPaymentType,
  getBalanceTowardsFee,
  getBalanceTowardsPRM,
  getDateString,
  getFee,
  invoiceStatusSort,
  objectToArray,
  PaymentReceived,
  PopulatedAssessmentInterface,
  UpdateCallback,
  WithId,
} from '@hb/shared'

import { deleteField, updateDoc } from 'firebase/firestore'
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import {
  addReceivedPayment,
  deleteReceivedPayment,
  fixAssessmentInvoiceSnippets,
  manuallyAddPaymentToInvoice,
  removeManualPaymentFromInvoice,
} from '../../../../../backend/functions'
import { useApp } from '../../../../../contexts'
import { PopUpMessageContext } from '../../../../../contexts/PopUpMessage/PopUpMessageContext'
import { ScreenContext } from '../../../../../contexts/ScreenContext'
import { UserContext } from '../../../../../contexts/UserContext'
import { useAssessmentInvoiceSnippets } from '../../../../../hooks/backend/invoicing'
import { useFile } from '../../../../../hooks/backend/storage/downloadFile'
import { useFilePath } from '../../../../../hooks/backend/storage/useFilePath'
import imageIcon from '../../../../../icons/image.svg'
import { useAuth } from '../../../../../store/auth'
import { ActionLog } from '../../../../ActionLog'
import { ActionButton } from '../../../../Buttons'
import { DeleteButton } from '../../../../Buttons/DeleteButton'
import { getRowBackground } from '../../../../DataView'
import { Editable } from '../../../../forms/Input'
import { DataLabel } from '../../../../forms/Input/DataLabel'
import { InvoicePaymentPopover } from '../../../../Invoices/InvoicePopover'
import { FileViewModal } from '../../../../Modals/FileViewModal'
import { NotesPopover } from '../../../../NotesPopover'
import { UserBadge } from '../../../UserBadge'
import { paidByOptions, paymentSourceOptions } from '../fields'
import { usePaymentsContext } from '../PaymentsProvider'
import { ReceivedPatientPaymentModal } from './ReceivedPatientPaymentModal'

const PaymentReceivedItem = (props: BoxProps) => <Box fontSize="sm" p={1} {...props} />

const PaymentReceivedHeaderItem = (props: FlexProps) => (
  <PaymentReceivedItem
    borderTop="1px solid #cdcdcd"
    borderBottom="1px solid #cdcdcd"
    display="flex"
    whiteSpace="nowrap"
    color="blackAlpha.600"
    fontWeight={600}
    {...props}
  />
)

export const ViewFileButton = ({ value, text }: { value: FileDBValue; text: string }) => {
  const [isOpen, setIsOpen] = useState(false)

  const { downloadPath: eobPath } = useFilePath(value)
  const { url: eobUrl } = useFile({
    path: eobPath,
    noFetch: true,
  })

  const icon = useMemo(() => {
    if (value.type.startsWith('image')) {
      return <Image opacity={0.8} src={imageIcon} h="16px" />
    }
    return <AttachmentIcon opacity={0.8} h="16px" />
  }, [value])

  return (
    <>
      <Button
        isLoading={!eobUrl}
        onClick={() => setIsOpen(true)}
        variant="outline"
        bg="#f4f4f4"
        color="#4b4b4b"
        gap={1}
        size="xs"
        fontSize="sm">
        {icon}
        {text}
      </Button>
      {eobUrl ? (
        <FileViewModal
          isOpen={isOpen}
          header={text}
          type={value.type}
          onClose={() => setIsOpen(false)}
          url={eobUrl}
        />
      ) : null}
    </>
  )
}

const PaymentReceivedInvoicesView = ({
  payment,
  invoices,
  assessmentId,
}: {
  payment: WithId<PaymentReceived>
  invoices: Record<string, AssessmentInvoiceSnippet>
  assessmentId?: string
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { processResponse } = useContext(PopUpMessageContext)
  const handleAddToInvoice = useCallback(async () => {
    if (!assessmentId) return
    if (!payment.id) return
    setIsLoading(true)
    try {
      await manuallyAddPaymentToInvoice({ assessmentId, paymentId: payment.id })
    } catch (err: any) {
      console.error(err)
      processResponse({
        error: err?.message || 'Error adding payment to invoice',
      })
    }
    setIsLoading(false)
  }, [payment, assessmentId, processResponse])
  const [removeLoading, setRemoveLoading] = useState(false)
  const removeFromInvoice = useCallback(
    async (invoiceId: string) => {
      if (!assessmentId) return
      setRemoveLoading(true)
      try {
        await removeManualPaymentFromInvoice({
          assessmentId,
          paymentId: payment.id,
          invoiceId,
        })
      } catch (err: any) {
        processResponse({
          error: err?.message || 'Error removing payment from invoice',
        })
      }
      setRemoveLoading(false)
    },
    [assessmentId, payment, processResponse],
  )
  const hasInvoices = Object.keys(invoices).length > 0
  return hasInvoices ? (
    <InvoicePaymentPopover
      onRemove={removeFromInvoice}
      removeLoading={removeLoading}
      paymentId={payment.id}
      assessmentId={assessmentId}
      invoiceSnippets={invoices}
    />
  ) : (
    <Tooltip placement="top" hasArrow label="Add to Current Invoice">
      <Button
        isLoading={isLoading}
        onClick={handleAddToInvoice}
        border="1px solid #777"
        fontFamily="Comfortaa"
        fontWeight={800}
        bg="white"
        color="#777"
        w="100%"
        size="xs">
        <TimeIcon mr={1} width={4} height={4} color="#777" />
        <Text position="relative" fontSize="xs" top="1px">
          SCHEDULE
        </Text>
      </Button>
    </Tooltip>
  )
}

const paymentDescriptionWidth = 250
const paymentAmountWidth = 80
const paymentSourceWidth = 80
const paymentsFilesWidth = 100
const notesWidth = 150
const paymentInvoicesWidth = 140
const paymentDeleteWidth = 60
const paymentRowWidth =
  paymentDescriptionWidth +
  paymentAmountWidth +
  paymentSourceWidth +
  paymentsFilesWidth +
  notesWidth +
  paymentDeleteWidth

const PaymentReceivedView = ({
  payment,
  index,
  small,
  onEditClick,
  widths,
  invoiceSnippets,
  onDelete,
}: {
  payment: WithId<PaymentReceived>
  invoiceSnippets?: Record<string, AssessmentInvoiceSnippet>
  index: number
  small?: boolean
  id: string
  widths: Array<number>
  onEditClick: () => void
  onDelete: () => Promise<any>
}) => {
  const bg = useMemo(() => getRowBackground(index), [index])
  const { assessmentId } = useContext(UserContext)
  const { notes, eobFile, checkFile, createdBy, updatedBy, updatedByGroup, createdByGroup } =
    payment
  const { role } = useAuth()
  const { paidBy, source, date, amount, type } = useMemo(
    () => ({
      paidBy: formatDropdownValue(payment.paidBy, paidByOptions),
      source: formatDropdownValue(payment.source, paymentSourceOptions),
      type: capitalizeFirstLetter(formatPaymentType(payment.type)),
      amount: formatDollarValue(payment.amount),
      date: getDateString(payment.date, 'short'),
    }),
    [payment],
  )

  const description = useMemo(() => `${date} - ${type} paid by ${paidBy}`, [type, paidBy, date])
  return (
    <Flex bg={bg} align="center" w="100%">
      <PaymentReceivedItem w={`${widths[0]}px`}>
        <Flex gap={2} pl={1} w="100%">
          <Box>
            <UserBadge
              userGroup={updatedByGroup || createdByGroup}
              userId={updatedBy || createdBy || ''}
            />
          </Box>
          {small ? (
            <NotesPopover label="Description" noText notes={description} />
          ) : (
            <Tooltip placement="top" hasArrow label={description}>
              <Text flex={1} minW="0" isTruncated>
                {description}
              </Text>
            </Tooltip>
          )}
        </Flex>
      </PaymentReceivedItem>
      <PaymentReceivedItem w={`${widths[1]}px`}>{amount}</PaymentReceivedItem>
      <PaymentReceivedItem w={`${widths[2]}px`}>
        <Text w="100%" isTruncated>
          {source}
        </Text>
      </PaymentReceivedItem>
      <PaymentReceivedItem w={`${widths[3]}px`}>
        <Stack w="100%" spacing={1}>
          {eobFile ? <ViewFileButton text="EOB" value={eobFile} /> : null}
          {checkFile ? <ViewFileButton text="CHECK" value={checkFile} /> : null}
          {!checkFile && !eobFile ? <Text color="gray.500">None</Text> : null}
        </Stack>
      </PaymentReceivedItem>
      <Flex
        position="relative"
        fontSize="sm"
        py={1}
        w={`${widths[4]}px`}
        px={3}
        align="center"
        overflow="hidden">
        {notes ? (
          <Tooltip bg="#fafafa" color="#111" placement="top" label={notes}>
            <Text isTruncated>{notes}</Text>
          </Tooltip>
        ) : (
          <Text color="gray.500">None</Text>
        )}
      </Flex>
      {role === 'super-admin' ? (
        <PaymentReceivedItem width={`${widths[5]}px`}>
          <PaymentReceivedInvoicesView
            assessmentId={assessmentId}
            payment={payment}
            invoices={invoiceSnippets || {}}
          />
        </PaymentReceivedItem>
      ) : null}
      <PaymentReceivedItem w={`${widths[6]}px`} display="flex">
        <IconButton
          onClick={onEditClick}
          aria-label="edit"
          variant="ghost"
          size="xs"
          icon={<EditIcon color="blackAlpha.700" />}
        />
        <DeleteButton itemName="Received payment" onDelete={onDelete} />
      </PaymentReceivedItem>
    </Flex>
  )
}

const MobilePaymentReceivedView = ({
  payment,
  index,
  onEditClick,
  invoiceSnippets,
  onDelete,
}: {
  payment: WithId<PaymentReceived>
  invoiceSnippets?: Record<string, AssessmentInvoiceSnippet>
  index: number
  small?: boolean
  id: string
  onEditClick: () => void
  onDelete: () => Promise<any>
}) => {
  const bg = useMemo(() => getRowBackground(index), [index])
  const { assessmentId } = useContext(UserContext)
  const {
    notes,
    eobFile,
    checkFile,
    createdBy,
    updatedBy,
    updatedOn,
    updatedByGroup,
    createdByGroup,
  } = payment
  const { role } = useAuth()
  const { paidBy, source, date, amount, type } = useMemo(
    () => ({
      paidBy: formatDropdownValue(payment.paidBy, paidByOptions),
      source: formatDropdownValue(payment.source, paymentSourceOptions),
      type: capitalizeFirstLetter(formatPaymentType(payment.type)),
      amount: formatDollarValue(payment.amount),
      date: getDateString(payment.date, 'short'),
    }),
    [payment],
  )

  const description = useMemo(() => `${date} - ${type} paid by ${paidBy}`, [type, paidBy, date])

  return (
    <Flex borderRadius={6} boxShadow="1px 1px 4px #00000077" flexFlow="column" w="100%" bg={bg}>
      <Flex
        borderTopRadius={6}
        color="white"
        bg={colors.green.hex}
        w="100%"
        py={1}
        px={2}
        borderBottom="1px solid #cdcdcd">
        <Text
          textShadow="1px 1px 3px #00000077"
          fontSize="sm"
          fontWeight={600}
          color="white"
          isTruncated>
          {description}
        </Text>
      </Flex>
      {updatedBy || createdBy ? (
        <Flex px={3} pt={2}>
          <ActionLog
            action="Updated"
            group={updatedByGroup || createdByGroup}
            on={updatedOn}
            by={updatedBy || createdBy || ''}
          />
        </Flex>
      ) : null}
      <Flex py={1} px={3} flexFlow="column">
        <Flex gap={1} align="center">
          <DataLabel>Amount</DataLabel>
          <Text fontSize="sm">{amount}</Text>
        </Flex>
        <Flex gap={1} align="center">
          <DataLabel>Source</DataLabel>
          <Text fontSize="sm">{source}</Text>
        </Flex>
        <Stack spacing={1}>
          {eobFile ? <ViewFileButton text="EOB" value={eobFile} /> : null}
          {checkFile ? <ViewFileButton text="Check" value={checkFile} /> : null}
          {!checkFile && !eobFile ? <Text color="gray.500">None</Text> : null}
        </Stack>
        <Flex py={1} flexFlow="column">
          <DataLabel>Notes</DataLabel>
          <Text fontSize="sm" color="gray.500">
            {notes || 'None'}
          </Text>
        </Flex>
        {role === 'super-admin' ? (
          <PaymentReceivedInvoicesView
            assessmentId={assessmentId}
            payment={payment}
            invoices={invoiceSnippets || {}}
          />
        ) : null}
        <Flex align="center" w={paymentDeleteWidth} justify="space-between">
          <DeleteButton itemName="Received payment" onDelete={onDelete} />
          <IconButton
            onClick={onEditClick}
            aria-label="edit"
            variant="ghost"
            size="xs"
            icon={<EditIcon color="blackAlpha.700" />}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

const PaymentsReceivedHeader = ({ small, widths }: { small?: boolean; widths: Array<number> }) => {
  const { role } = useAuth()
  const { assessmentId } = useContext(UserContext)
  const [fixLoading, setFixLoading] = useState(false)

  const { showMessage } = useContext(PopUpMessageContext)
  const onFix = useCallback(async () => {
    if (!assessmentId) {
      showMessage({
        text: 'No assessment id',
        type: 'error',
      })
      return
    }
    setFixLoading(true)
    try {
      // fix snippets
      await fixAssessmentInvoiceSnippets({ assessmentId })
      showMessage({
        text: 'Fixed invoice snippets',
        type: 'success',
      })
    } catch (err: any) {
      showMessage({
        text: 'Error fixing invoice snippets',
        subText: err.message,
        type: 'error',
      })
    }
    setFixLoading(false)
  }, [assessmentId, showMessage])

  return (
    <Flex w="100%">
      <PaymentReceivedHeaderItem width={`${widths[0]}px`}>
        {small ? '' : 'Description'}
      </PaymentReceivedHeaderItem>
      <PaymentReceivedHeaderItem w={`${widths[1]}px`}>Amount</PaymentReceivedHeaderItem>
      <PaymentReceivedHeaderItem w={`${widths[2]}px`}>Source</PaymentReceivedHeaderItem>
      <PaymentReceivedHeaderItem w={`${widths[3]}px`}>Files</PaymentReceivedHeaderItem>
      <PaymentReceivedHeaderItem w={`${widths[4]}px`}>Notes</PaymentReceivedHeaderItem>
      {role === 'super-admin' ? (
        <PaymentReceivedHeaderItem w={`${widths[5]}px`}>
          <Text>Invoice #</Text>
        </PaymentReceivedHeaderItem>
      ) : null}
      <PaymentReceivedHeaderItem w={`${widths[6]}px`} justifyContent="flex-end" pr={2}>
        <ActionButton onClick={onFix} isLoading={fixLoading} ml="auto" size="xs">
          FIX
        </ActionButton>
      </PaymentReceivedHeaderItem>
    </Flex>
  )
}

const EditableDollarAmount = ({
  label,
  value,
  onSubmit,
  tooltip,
  onDelete,
  index,
  field,
  ...flexProps
}: FlexProps & {
  label: string
  index?: number
  field?: Partial<Field>
  tooltip?: string
  value: any
  onSubmit?: (v: any) => Promise<UpdateCallback>
  onDelete?: () => Promise<any>
}) => (
  <Flex {...flexProps}>
    {tooltip ? (
      <Tooltip placement="top-start" label={tooltip}>
        <Text
          sx={{ ':nth-of-type(2n)': { background: 'rgb(240,240,240)' } }}
          bg={getRowBackground(index)}
          fontSize="sm"
          fontWeight={600}
          color="blackAlpha.700"
          px={3}
          py={1}>
          {label}
        </Text>
      </Tooltip>
    ) : (
      <Text
        sx={{ ':nth-of-type(2n)': { background: 'rgb(240,240,240)' } }}
        bg={getRowBackground(index)}
        fontSize="sm"
        fontWeight={600}
        color="blackAlpha.700"
        px={3}
        py={1}>
        {label}
      </Text>
    )}
    <Editable
      style={{ background: getRowBackground(index) }}
      // index={(index || 0) * 2}
      // index={index}
      onSubmit={onSubmit}
      onDelete={onDelete}
      value={value}
      field={
        {
          placeholder: label,
          optional: true,
          type: FieldTypes.DOLLAR_AMOUNT,
          ...field,
        } as Field
      }
    />
  </Flex>
)

const DesktopReceivedPayments = ({
  small,
  assessmentId,
  selectedAssessment,
  onEditClick,
  sorted,
  paymentInvoiceSnippets,
  width,
}: {
  small?: boolean
  assessmentId: string | undefined
  selectedAssessment: PopulatedAssessmentInterface | undefined
  sorted: Record<string, PaymentReceived>
  width: number
  onEditClick: (paymentId: string) => void
  paymentInvoiceSnippets: Record<string, Record<string, AssessmentInvoiceSnippet>>
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const admin = useAuth(s => s.admin)
  const widths = useMemo(() => {
    const invoicesWidth = admin ? paymentInvoicesWidth : 0
    const rowWidth = paymentRowWidth + invoicesWidth
    const scale = width / rowWidth
    return [
      Math.round(paymentDescriptionWidth * scale),
      Math.round(paymentAmountWidth * scale),
      Math.round(paymentSourceWidth * scale),
      Math.round(paymentsFilesWidth * scale),
      Math.round(notesWidth * scale),
      Math.round(paymentInvoicesWidth * scale),
      Math.round(paymentDeleteWidth * scale),
    ]
  }, [width, admin])

  const { processResponse } = useContext(PopUpMessageContext)
  return (
    <Flex ref={ref} w="100%" flexFlow="column">
      <PaymentsReceivedHeader widths={widths} small={small} />
      {assessmentId && selectedAssessment && sorted ? (
        Object.entries(sorted).map(([paymentId, p], i) => (
          <PaymentReceivedView
            small={small}
            widths={widths}
            id={paymentId}
            onDelete={() =>
              deleteReceivedPayment({ assessmentId, paymentId })
                .then(() => processResponse({ success: 'Payment deleted' }))
                .catch((err: any) =>
                  processResponse({
                    error: err.message || 'An error occurred deleting the payment',
                  }),
                )
            }
            onEditClick={() => onEditClick(paymentId)}
            invoiceSnippets={paymentInvoiceSnippets[paymentId]}
            key={paymentId}
            index={i}
            payment={{ ...p, id: paymentId }}
          />
        ))
      ) : (
        <GridItem colSpan={6}>
          <Text px={3} py={1} fontStyle="italic" color="gray" fontSize="sm">
            No received payments
          </Text>
        </GridItem>
      )}
    </Flex>
  )
}

const MobileReceivedPayments = ({
  small,
  assessmentId,
  selectedAssessment,
  onEditClick,
  sorted,
  paymentInvoiceSnippets,
}: {
  small?: boolean
  assessmentId: string | undefined
  selectedAssessment: PopulatedAssessmentInterface | undefined
  sorted: Record<string, PaymentReceived>
  onEditClick: (paymentId: string) => void
  paymentInvoiceSnippets: Record<string, Record<string, AssessmentInvoiceSnippet>>
}) => (
  <Flex gap={1} p={2} flexFlow="column" w="100%">
    {assessmentId && selectedAssessment && sorted ? (
      Object.entries(sorted).map(([paymentId, p], i) => (
        <MobilePaymentReceivedView
          small={small}
          id={paymentId}
          onDelete={() => deleteReceivedPayment({ assessmentId, paymentId })}
          onEditClick={() => onEditClick(paymentId)}
          invoiceSnippets={paymentInvoiceSnippets[paymentId]}
          key={paymentId}
          index={i}
          payment={{ ...p, id: paymentId }}
        />
      ))
    ) : (
      <GridItem colSpan={6}>
        <Text px={3} py={1} fontStyle="italic" color="gray" fontSize="sm">
          No received payments
        </Text>
      </GridItem>
    )}
  </Flex>
)

export const ReceivedPayments = ({ small, width }: { small?: boolean; width: number }) => {
  const {
    selectedAssessment,
    assessmentId,
    claims: { data: claims },
  } = useContext(UserContext)
  const {
    // balanceTowardFee,
    // balanceTowardPrm,
    populated,
    adminRef,
  } = selectedAssessment || {}
  const { financialAdj } = populated || {}
  const { data: invoiceSnippets } = useAssessmentInvoiceSnippets(assessmentId)
  const invoiceSnippetsArr = useMemo(
    () => objectToArray(invoiceSnippets || {}).sort(invoiceStatusSort),
    [invoiceSnippets],
  )

  const { appName } = useApp()
  const { payments } = usePaymentsContext()
  const { received } = payments || {}

  const [editedPayment, setEditedPayment] = useState<string | undefined>()

  const sorted = useMemo<Record<string, PaymentReceived>>(() => {
    if (!received) return {}
    return Object.entries(received)
      .sort(([, a], [, b]) => a.date - b.date)
      .reduce(
        (acc, [pKey, pValue]) => ({
          ...acc,
          [pKey]: pValue,
        }),
        {} as Record<string, PaymentReceived>,
      )
  }, [received])

  const total = useMemo(
    () => Object.values(sorted).reduce((acc, curr) => acc + curr.amount, 0) || 0,
    [sorted],
  )
  const handleSubmitPayment = useCallback(
    async (
      createdOn: number,
      data: PaymentReceived & {
        sendMessageToHb?: boolean
        messageToHb?: string
      },
    ): Promise<UpdateCallback> => {
      if (!assessmentId) return { error: 'No assessment id' }
      try {
        await addReceivedPayment({
          assessmentId,
          payment: data,
          createdOn,
          message: data.messageToHb || '',
          sendMessage: !!data.sendMessageToHb,
          appName,
        })
        return { success: 'Payment added' }
      } catch (err: any) {
        console.error(err)
        return { error: `Error adding payment: ${err?.message}` }
      }
    },
    [assessmentId, appName],
  )

  const fee = useMemo(
    () => getFee(selectedAssessment?.populated || undefined, claims || {}),
    [claims, selectedAssessment],
  )

  const balanceTowardFee = useMemo(
    () => getBalanceTowardsFee(selectedAssessment?.populated || undefined, claims || {}),
    [selectedAssessment, claims],
  )
  const balanceTowardPrm = useMemo(
    () => getBalanceTowardsPRM(selectedAssessment?.populated || undefined),
    [selectedAssessment],
  )

  const paymentInvoiceSnippets = useMemo(
    () =>
      Object.entries(sorted).reduce(
        (acc, [paymentId]) => {
          const paymentSnippets = invoiceSnippetsArr?.filter(
            invoiceSnippet => !!invoiceSnippet?.chargedPayments?.[paymentId],
          )
          const snippetsRecord = arrayToObject(paymentSnippets)
          return snippetsRecord
            ? {
                ...acc,
                [paymentId]: snippetsRecord || {},
              }
            : acc
        },
        {} as Record<string, Record<string, AssessmentInvoiceSnippet>>,
      ),
    [invoiceSnippetsArr, sorted],
  )

  const { isMobile } = useContext(ScreenContext)

  return (
    <>
      <Box w="100%" bg={isMobile ? 'green.100' : 'white'} boxShadow="2px 2px 6px #00000077">
        <Flex w="100%" bg="green.100" pt={isMobile ? 1 : 0} align="center">
          <Text py={1} px={3} fontWeight={600} color="blackAlpha.700">
            Payments Received
          </Text>
          <IconButton
            aria-label="add received payment"
            color="blackAlpha.700"
            bg="white"
            mr={2}
            ml="auto"
            boxShadow="md"
            onClick={() => setEditedPayment(`${Date.now()}`)}
            size="xs"
            icon={<AddIcon />}
          />
        </Flex>
        {isMobile ? (
          <MobileReceivedPayments
            small={small}
            assessmentId={assessmentId}
            selectedAssessment={selectedAssessment}
            onEditClick={setEditedPayment}
            sorted={sorted}
            paymentInvoiceSnippets={paymentInvoiceSnippets}
          />
        ) : (
          <DesktopReceivedPayments
            width={width}
            small={small}
            assessmentId={assessmentId}
            selectedAssessment={selectedAssessment}
            onEditClick={setEditedPayment}
            sorted={sorted}
            paymentInvoiceSnippets={paymentInvoiceSnippets}
          />
        )}
      </Box>
      <Box bg="white" shadow="md">
        <Text
          py={1}
          px={3}
          fontWeight={600}
          borderBottom="1px solid #cdcdcd"
          bg="green.100"
          color="blackAlpha.700">
          Totals
        </Text>
        <Grid templateColumns="180px 1fr">
          <EditableDollarAmount
            index={0}
            display="contents"
            tooltip="Sum of all claim charges"
            label="Fee"
            value={fee}
          />
          <EditableDollarAmount
            index={1}
            display="contents"
            label="Total Received to Date"
            value={total}
          />
          <EditableDollarAmount
            index={2}
            display="contents"
            label="Balance toward PRM"
            value={balanceTowardPrm}
          />

          <EditableDollarAmount
            index={3}
            display="contents"
            label="Financial/Contract Adjustment"
            field={{ min: -Infinity, max: 0 }}
            value={financialAdj || 0}
            onSubmit={async v =>
              adminRef
                ? updateDoc(adminRef, 'financialAdj', v)
                    .then(() => ({ success: 'Updated financial adjustment!' }))
                    .catch((err: any) => ({ error: err.message }))
                : { error: 'No admin ref' }
            }
            onDelete={
              adminRef ? () => updateDoc(adminRef, 'financialAdj', deleteField()) : undefined
            }
          />
          <EditableDollarAmount
            // px={2}
            py={1}
            index={4}
            display="contents"
            borderTop="1px solid #cdcdcd"
            label="Balance toward fee"
            value={balanceTowardFee}
          />
        </Grid>
        {assessmentId ? (
          <ReceivedPatientPaymentModal
            onSubmit={handleSubmitPayment}
            id={editedPayment}
            onClose={() => setEditedPayment(undefined)}
          />
        ) : null}
      </Box>
    </>
  )
}
