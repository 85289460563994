import { EditIcon, InfoIcon, ViewIcon } from '@chakra-ui/icons'
import { Button, Flex, Text } from '@chakra-ui/react'

import { colors, FieldMap, FieldMapValue, Form, InfoStage } from '@hb/shared'

import React, { useMemo } from 'react'
import { StatusButtonBase } from '../../Status/StatusButtonBase'
import { useStageState } from './hooks'

export const StagePreview = ({
  stage,
  data,
  index,
  onEditClick,
  corrections,
  readOnly,
  id,
  form,
}: {
  id: string
  stage: FieldMap | InfoStage
  form: Form
  index: number
  data: FieldMapValue | undefined
  corrections: FieldMapValue | undefined
  readOnly?: boolean
  onEditClick: () => void
}) => {
  const { status } = useStageState(form, stage, data, corrections, id)

  const buttonText = useMemo(() => {
    if (readOnly) return 'VIEW'
    switch (status) {
      case 'complete':
        return 'EDIT'
      case 'info':
        return 'VIEW'
      case 'in progress':
        return 'COMPLETE'
      default:
        return 'BEGIN'
    }
  }, [status, readOnly])
  return (
    <Flex
      justify="flex-start"
      px={3}
      align="center"
      py={status === 'info' ? 2 : 1}
      bg={index % 2 === 0 ? 'rgba(0,0,0,0.02)' : undefined}>
      {status !== 'info' ? (
        <StatusButtonBase
          style={{
            display: 'inline-block',
            textAlign: 'center',
            alignItems: 'center',
            width: 16,
            height: 16,
            padding: 0,
            pointerEvents: 'none',
          }}
          color={status === 'complete' ? colors.green.hex : '#cdcdcd'}>
          {status === 'complete' ? '✓' : ''}
        </StatusButtonBase>
      ) : (
        <InfoIcon color="gray.600" />
      )}
      <span
        style={{
          flexGrow: 1,
          fontSize: '16px',
          textAlign: 'left',
          marginLeft: '0.6rem',
          color: '#555',
        }}>
        {stage ? stage.name : null}
      </span>
      {status !== 'info' && !readOnly ? (
        <Button
          onClick={onEditClick}
          size="sm"
          gap={1}
          borderRadius="full"
          w={8}
          h={8}
          textShadow={status !== 'complete' ? '1px 1px 3px #00000066' : undefined}
          _hover={{
            bg: status !== 'complete' ? 'green.400' : 'gray.100',
          }}
          color={status !== 'complete' ? 'white' : 'gray.500'}
          bg={status !== 'complete' ? colors.green.hex : 'white'}
          border={status !== 'complete' ? 'none' : '1px solid #cdcdcd'}>
          <EditIcon
            filter={status !== 'complete' ? 'drop-shadow(1px 1px 3px #00000066)' : undefined}
          />
          {/* <Text lineHeight={1}>{buttonText}</Text> */}
        </Button>
      ) : (
        <Button
          onClick={onEditClick}
          size="sm"
          gap={1}
          h={7}
          bg="gray.50"
          color="gray.500"
          border="1px solid #cdcdcd">
          <ViewIcon />
          <Text lineHeight={1}>{buttonText}</Text>
        </Button>
      )}
    </Flex>
  )
}
