import {
  callInField,
  CoverageSnippet,
  FieldMap,
  getBasicInfoField,
  policyOwnerFieldMap,
} from '@hb/shared'

export const coverageSnippetStage: Record<CoverageSnippet, (medicaid: boolean) => FieldMap> = {
  'basic-info': getBasicInfoField,
  'call-in': () => callInField,
  'policy-owner': () => policyOwnerFieldMap,
}
