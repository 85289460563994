import { ZustandSetter } from '@hb/shared'
import { create } from 'zustand'
import { ModalState } from '../types/modals'

const createModalsState = <T extends string>(
  set: ZustandSetter<Record<T, ModalState>>,
  names: T[],
): Record<T, ModalState> =>
  names.reduce(
    (acc, name) => ({
      ...acc,
      [name]: {
        open: (id: string = 'root', props: Record<string, any> = {}) => {
          set(curr => {
            const currModal = curr[name]
            const newModal: ModalState = {
              ...currModal,
              openId: typeof id === 'string' ? id : 'root',
              props,
            }
            if (currModal.openId === id) return curr
            return { [name]: newModal } as Partial<Record<T, ModalState>>
          })
        },
        close: () => {
          set(curr => {
            const currModal = curr[name]
            const newModal: ModalState = {
              ...currModal,
              openId: null,
              props: {},
            }
            return { [name]: newModal } as Partial<Record<T, ModalState>>
          })
        },
        props: {},
      },
    }),
    {} as Record<T, ModalState>,
  )

export const createModalsStore = <T extends string>(names: Array<T>) =>
  create<Record<T, ModalState>>(set => createModalsState<T>(set, names))

export type AppModalKey =
  | 'scheduleCall'
  | 'assessmentHelp'
  | 'nameEntry'
  | 'contactForm'
  | 'emailVerification'
  | 'multiFactorAuth'
  | 'pushNotificationsSettings'

export const appModalKeys: AppModalKey[] = [
  'scheduleCall',
  'assessmentHelp',
  'nameEntry',
  'multiFactorAuth',
  'contactForm',
  'emailVerification',
  'pushNotificationsSettings',
]

export const useAppModals = createModalsStore(appModalKeys)
