import { Box, CircularProgress, HStack } from '@chakra-ui/react'
import { CheckboxField, UpdateCallback } from '@hb/shared'
import React, { CSSProperties, useCallback, useState } from 'react'
import Checkbox from './Checkbox/Checkbox'

export const EditableCheckbox: React.FC<{
  field: CheckboxField
  onSubmit?: (v: boolean) => Promise<UpdateCallback>
  value: any
  index?: number
  inGrid?: boolean
  style?: CSSProperties
  id: string
}> = ({ field, id, onSubmit, value, style, index, inGrid }) => {
  const [isLoading, setIsLoading] = useState(false)
  const handleChange = useCallback(
    async (v: boolean) => {
      if (!onSubmit) return { error: 'No on submit function' }
      setIsLoading(true)
      const res = await onSubmit(v)
      setIsLoading(false)
      return res
    },
    [onSubmit],
  )
  return (
    <HStack
      width="100%"
      minH="29px"
      sx={
        index !== undefined
          ? {
              [`:nth-of-type(${inGrid ? '4n - 2' : '2n'})`]: {
                background: 'rgb(245,245,245)',
              },
            }
          : undefined
      }
      style={style}
      spacing={0}
      px={1}>
      <Box
        transition="all 400ms"
        opacity={isLoading ? 1 : 0}
        width={isLoading ? '26px' : '0px'}
        overflow="hidden">
        <CircularProgress size={3} color="gray.600" isIndeterminate />
      </Box>
      <Checkbox
        readOnly={!onSubmit}
        field={field}
        input={{
          onChange: handleChange,
          value,
          name: id,
          onBlur: () => {},
          onFocus: () => {},
        }}
        meta={{}}
        style={style}
      />
    </HStack>
  )
}
