import { ViewIcon } from '@chakra-ui/icons'
import { HStack, Text } from '@chakra-ui/react'
import { Collection, CollectionItem, Template, TemplateKey } from '@hb/shared'
import React, { useContext, useMemo, useState } from 'react'
import { TemplateContext } from '../../contexts'
import { ActionButton } from '../Buttons/ActionButton'
import { Container } from '../Container'
import { EditedProps } from '../EditHeader'
import { TemplateViewModal } from './TemplateViewModal'

export const TemplatePreview: React.FC<
  EditedProps<Template> & {
    collection: Collection<Template>
    item?: Template
    templateType: TemplateKey
  }
> = ({ item, collection, templateType }) => {
  const { id } = item || {}
  const { select } = useContext(TemplateContext)
  return (
    <Container
      style={{
        height: 'auto',
        margin: 0,
        padding: '0 1rem',
        boxSizing: 'border-box',
        alignItems: 'flex-start',
      }}>
      <ActionButton
        colorScheme="gray"
        size="xs"
        mb={2}
        onClick={
          id && select
            ? () => select({ collection, templateId: id, templateType })
            : () => console.error('No select function! :(')
        }>
        View / Edit {item?.name || 'template'}
      </ActionButton>
    </Container>
  )
}

export const StandaloneTemplatePreview: React.FC<{
  collection: Collection<Template>
  templateType: TemplateKey
  item?: CollectionItem<Template>
}> = ({ item, collection, templateType }) => {
  const { id, name } = item || {}
  const [selected, setSelected] = useState(false)
  const selectedTemplate = useMemo(
    () => (id ? { collection, templateId: id, templateType } : null),
    [id, collection, templateType],
  )
  const contextData = useMemo(
    () => ({
      selected: selectedTemplate,
      select: () => {},
      deselect: () => setSelected(false),
      ref: { current: null },
    }),
    [selectedTemplate],
  )

  return (
    <HStack w="100%">
      <Text flex={1} fontFamily="Open Sans">
        {name}
      </Text>
      <ActionButton
        as="div"
        size="xs"
        gap={1}
        // color='gray.500'
        maxW="200px"
        onClick={e => {
          e.stopPropagation()
          setSelected(true)
        }}>
        <ViewIcon />
        <Text flex={1} isTruncated maxW="100%" minW="0">
          View Template
        </Text>
      </ActionButton>
      {selected ? (
        <TemplateContext.Provider value={contextData}>
          <TemplateViewModal
            templateType={templateType}
            editorVersion={item?.editorVersion || 'v1'}
            submitText={{ submit: 'Submit', confirm: 'Confirm' }}
            id={id}
            isOpen
            onClose={() => setSelected(false)}
          />
        </TemplateContext.Provider>
      ) : null}
    </HStack>
  )
}
