import { AssessmentDocument, TemplateKey } from '@hb/shared'
import { collection, CollectionReference, orderBy, query, where } from 'firebase/firestore'
import { useMemo } from 'react'
import { db } from '../../../backend/db'
import { useQuery } from '../useQuery'

export const useAssessmentDocuments = (
  patientId: string,
  assessmentId: string,
  type: TemplateKey,
) => {
  const ref = useMemo(
    () =>
      query(
        collection(db, 'users', patientId, 'documents') as CollectionReference<AssessmentDocument>,
        where('assessmentId', '==', assessmentId),
        where('type', '==', type),
        orderBy('sentOn', 'desc'),
      ),
    [assessmentId, patientId, type],
  )
  return useQuery<AssessmentDocument>(ref)
}
