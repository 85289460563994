import { Badge, Box, Flex, HStack, Image, Text } from '@chakra-ui/react'
import { colors, Fax, formatPhoneNumber, getDateTimeString, templateTypeNames } from '@hb/shared'

import React, { useState } from 'react'
import { useFile } from '../../hooks/backend/storage/downloadFile'
import { useResizeObserver } from '../../hooks/useResizeObserver'
import { StandaloneRow } from '../DataList/DataList'
import { PdfViewModal } from '../Modals/PdfView/PdfViewModal'
import { NotesPopover } from '../NotesPopover'
import { sentFaxColumns } from './columns'
import { FaxModalHeader } from './FaxModalHeader'

export const FaxView = ({ fax }: { fax: Fax }) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const { width } = useResizeObserver(ref, 'content')
  return (
    <Flex w="100%" ref={ref}>
      <StandaloneRow width={width} data={fax} columns={sentFaxColumns} />
    </Flex>
  )
}

export const FaxPreview = ({ fax }: { fax: Fax }) => {
  const [viewing, setViewing] = useState(false)

  const { created_at: createdAt } = fax || {}
  const { url } = useFile({
    path: viewing ? fax.storagePath : undefined,
  })

  const categoryText = fax.category ? templateTypeNames[fax.category] : null
  return (
    <>
      <HStack
        cursor="pointer"
        _hover={{
          border: `1px solid ${colors.green.hex}`,
          bg: 'gray.50',
        }}
        bg="white"
        onClick={() => setViewing(true)}
        py={1}
        px={2}
        borderRadius={4}
        border="1px solid #cdcdcd"
        w="100%">
        <Image opacity={0.6} src="/images/svg/fax.svg" width="22px" />
        <Text
          position="relative"
          top="1px"
          color="gray.600"
          fontWeight={500}
          fontFamily="Hero-New"
          fontSize="sm">
          Fax {fax.type === 'received' ? 'from' : 'sent to'}{' '}
          {formatPhoneNumber(fax.type === 'received' ? fax.from : fax.to)}
        </Text>
        <Box minW="0" flex={1}>
          <NotesPopover notes={fax?.notes} />
        </Box>
        <HStack ml="auto">
          {categoryText ? <Badge>{categoryText}</Badge> : null}
          <Text fontWeight={600} fontFamily="Comfortaa" color={colors.green.hex} fontSize="sm">
            {getDateTimeString(createdAt, 'short')}
          </Text>
        </HStack>
      </HStack>

      {viewing ? (
        <PdfViewModal
          url={url}
          isOpen
          onClose={() => setViewing(false)}
          header={
            <HStack justify="space-between" px={3} w="100%">
              <Text flex={1} fontSize="lg" fontFamily="Comfortaa">
                Fax {fax?.type} {createdAt ? getDateTimeString(createdAt, 'short') : ''}
              </Text>
              <FaxModalHeader fax={fax} />
            </HStack>
          }
        />
      ) : null}
    </>
  )
}
