import { PopulatedAssessment, PopulatedAssessmentInterface } from '@hb/shared'
import { createContext } from 'react'

export const emptyPopulatedAssessmentInterface: PopulatedAssessmentInterface = {
  adminData: null,
  adminRef: null,
  assessmentData: null,
  id: '',
  isPopulated: true,
  loading: false,
  populated: null,
  practiceData: null,
  practiceRef: null,
  ref: null,
  snippetData: null,
  snippetRef: null,
}

export const AssessmentContext = createContext<PopulatedAssessment | null>(null)

// export const AssessmentInterfaceContext = createContext<PopulatedAssessmentInterface>(emptyPopulatedAssessmentInterface)
