import { CloseIcon } from '@chakra-ui/icons'
import { Box, Flex, HStack, IconButton, Text, Tooltip } from '@chakra-ui/react'
import { ALPHABET } from '@hb/shared'
import React, { useMemo } from 'react'
import { useField } from 'react-final-form'
import { useFieldArray } from 'react-final-form-arrays'
import { ActionButton } from '../../../../../Buttons/ActionButton'
import { DeleteButton } from '../../../../../Buttons/DeleteButton'
import { FormElement } from '../../../../../forms/Input'
import { customDiagnosisCodeField, diagnosisCodeSelectField } from '../fields'

export const useDiagnosisCodeInputs = (index: number) => {
  const field = useMemo(
    () => ({
      ...diagnosisCodeSelectField,
      placeholder: `Diagnosis Code ${ALPHABET[index]}`,
    }),
    [index],
  )

  const customCodeField = useMemo(
    () => ({
      ...customDiagnosisCodeField,
      placeholder: `Diagnosis Code ${ALPHABET[index]}`,
      condition: null,
    }),
    [index],
  )
  return { field, customCodeField }
}

export const DiagnosisCodeInput = ({
  name,
  index,
  onDelete,
}: {
  name: string
  index: number
  onDelete: () => void
}) => {
  const {
    input: { value },
  } = useField(name, { subscription: { value: true } })
  const { field, customCodeField } = useDiagnosisCodeInputs(index)
  return (
    <HStack spacing={2} align="center" w="100%">
      <FormElement name={`${name}.diagnosisCodeSelect`} field={field} />
      {value?.diagnosisCodeSelect === 'other' ? (
        <FormElement name={`${name}.customDiagnosisCode`} field={customCodeField} />
      ) : null}
      <Box position="relative" top="4px">
        <DeleteButton noConfirm itemName="Diagnosis Code" onDelete={async () => onDelete()} />
      </Box>
    </HStack>
  )
}

export const DiagnosisCodesInput = () => {
  const { fields } = useFieldArray('DiagnosisCodes', { subscription: {} })

  return (
    <Box px={3} pb={3} w="100%">
      <Text fontWeight={600} color="gray.500" fontSize="md">
        Diagnosis Codes
      </Text>
      {fields.map((name, idx) => (
        <DiagnosisCodeInput
          index={idx}
          onDelete={() => fields.remove(idx)}
          key={name}
          name={name}
        />
      ))}
      <ActionButton mt={2} size="xs" onClick={() => fields.push({})}>
        + Add Diagnosis Code
      </ActionButton>
    </Box>
  )
}

export const DiagnosisCodesView = () => {
  const {
    input: { value },
  } = useField<
    Array<{
      diagnosisCodeSelect: string
      customDiagnosisCode: string
    }>
  >('DiagnosisCodes', { subscription: { value: true } })
  const { fields } = useFieldArray('DiagnosisCodes', { subscription: {} })
  const {
    input: { value: services },
  } = useField<Array<{ DiagnosisPointer: string[]; HCPC: string }>>('Services', {
    subscription: { value: true },
  })

  const diagnosisCodes = useMemo(() => {
    const base = value || []
    return base.map((diagnosisCode, idx) => ({
      ...diagnosisCode,
      services: services.filter(service => service.DiagnosisPointer?.includes(`${idx + 1}`)),
    }))
  }, [services, value])
  return (
    <Flex flexFlow="column" px={3} pb={3} w="100%">
      <Text fontWeight={600} color="gray.500" fontSize="md">
        Diagnosis Codes
      </Text>
      <Flex flex="row wrap" w="100%">
        {diagnosisCodes.length ? (
          diagnosisCodes.map((diagnosisCode, idx) => (
            <Flex key={idx} m={1}>
              <Tooltip
                placement="top"
                hasArrow
                textShadow="1px 1px 3px #00000077"
                label={`Attached to ${diagnosisCode.services.length ? diagnosisCode.services.map(s => s.HCPC).join(', ') : 'no services'}`}>
                <HStack
                  boxShadow="1px 1px 4px #00000055"
                  borderRadius={6}
                  px={2}
                  color="white"
                  bg="green.500"
                  spacing={1}
                  align="center">
                  <Text
                    borderRight="2px solid #efefef"
                    pr={2}
                    mr={1}
                    lineHeight={1}
                    fontSize="sm"
                    fontWeight={600}>
                    {ALPHABET[idx].toUpperCase()}
                  </Text>
                  <Text fontWeight={500} textShadow="1px 1px 2px #00000066">
                    {diagnosisCode.diagnosisCodeSelect}
                  </Text>
                  {diagnosisCode.diagnosisCodeSelect === 'other' ? (
                    <Text>{diagnosisCode.customDiagnosisCode}</Text>
                  ) : null}
                  {diagnosisCode.services.length === 0 ? (
                    <IconButton
                      aria-label="Delete Diagnosis Code"
                      icon={<CloseIcon />}
                      size="xs"
                      w={5}
                      minW="0"
                      p={0}
                      h={5}
                      color="red.500"
                      border="1px solid #aa8888"
                      borderRadius="full"
                      bg="red.300"
                      colorScheme="red"
                      onClick={() => fields.remove(idx)}
                    />
                  ) : null}
                </HStack>
              </Tooltip>
            </Flex>
          ))
        ) : (
          <Text color="gray.600" fontStyle="italic">
            No diagnosis codes - add a service to attach a diagnosis code
          </Text>
        )}
      </Flex>
    </Flex>
  )
}
