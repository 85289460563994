import React, { CSSProperties } from 'react'
import './styles.css'

export const TypingIndicator = ({ style }: { style?: CSSProperties }) => (
  <svg viewBox="0 0 40 40" style={style} className="loader">
    <circle className="dot" cx="10" cy="20" r="3" style={{ fill: 'grey' }} />
    <circle className="dot" cx="20" cy="20" r="3" style={{ fill: 'grey' }} />
    <circle className="dot" cx="30" cy="20" r="3" style={{ fill: 'grey' }} />
  </svg>
)
