import {
  medicaidProvidersCollection,
  providersCollection,
} from '../../collections/collections/insurer'
import { FieldTypes } from '../../constants'
import { IdField, InsuranceProvider } from '../../types'

export const insuranceProviderField: IdField<InsuranceProvider> = {
  type: FieldTypes.ID,
  collection: providersCollection,
  placeholder: 'Insurance Provider',
  allowOther: true,
}

export const medicaidInsuranceProviderField: IdField<InsuranceProvider> = {
  type: FieldTypes.ID,
  collection: medicaidProvidersCollection,
  placeholder: 'Medicaid Provider',
  allowOther: true,
}
