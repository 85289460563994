import { VStack } from '@chakra-ui/react'
import { FieldMapValue, patientField, USERS } from '@hb/shared'
import { db, SimpleForm, useUpdateDoc } from '@hb/shared-frontend'
import { useAuth } from '@hb/shared-frontend/store/auth'
import { FORM_ERROR } from 'final-form'
import { collection, doc } from 'firebase/firestore'
import React, { useCallback, useMemo } from 'react'

export const PatientDataForm = () => {
  const user = useAuth(s => s.user)
  const authUser = useAuth(s => s.authUser)
  const { uid } = authUser || {}
  const { fname, lname, phone, dob, voicemailOk, textOk, nickname, createdOn } = user || {}
  const update = useUpdateDoc('your profile')
  const handleSubmit = useCallback(
    async (data: FieldMapValue) => {
      if (!uid) return { [FORM_ERROR]: 'No user id found' }
      const submitted = { ...data }
      if (!createdOn) submitted.createdOn = Date.now()
      try {
        const { error } = await update(doc(collection(db, USERS), uid), '', submitted)
        if (error) return { [FORM_ERROR]: error }
        return undefined
      } catch (err: any) {
        console.error({ err })
        return {
          [FORM_ERROR]: err?.message || 'There was an error updating your data',
        }
      }
    },
    [createdOn, update, uid],
  )

  const formValue = useMemo(
    () => ({
      fname,
      lname,
      phone,
      dob,
      id: uid,
      voicemailOk,
      textOk,
      nickname,
    }),
    [fname, lname, phone, dob, uid, voicemailOk, textOk, nickname],
  )

  return (
    <VStack width="600px" maxW="100%">
      <SimpleForm theme="detailed" onSubmit={handleSubmit} value={formValue} field={patientField} />
    </VStack>
  )
}
