import { useEffect, useState } from 'react'
import { getLocalStorage } from '../utils/localStorage'

export const useLocalStorage = <T>(key: string | undefined) => {
  // get the value from local storage
  const [value, setValue] = useState<T | null>(key ? getLocalStorage(key) : null)

  // subscribe to changes in local storage
  useEffect(() => {
    if (!key) return () => {}
    const handler = () => {
      setValue(getLocalStorage(key))
    }
    window.addEventListener('storage', handler)
    return () => {
      window.removeEventListener('storage', handler)
    }
  }, [key])

  return value
}

export const useHBDeviceId = () => useLocalStorage<string>('hb_device_id')
