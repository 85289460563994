import { FieldTypes } from '../../constants/FieldTypes'
import { ReimbursementOption } from '../../types'
import { DropdownField, FieldMap } from '../../types/forms'
import { oonCondition } from './utils'

const labels = [
  {
    text: 'How does my plan determine reimbursement for out of network providers - specifically related to Maternity Care provider charges?',
    style: { display: 'block', marginBottom: 10 },
  },
  {
    text: 'In other words, what ‘system’ is reimbursement based on',
    style: { display: 'block', marginBottom: 10, color: 'black' },
  },
  {
    text: '- Medicare (some plans reimburse a % of Medicare - if so, please get the %)',
    style: { display: 'block', marginBottom: 10, color: 'black' },
  },
  {
    text: '- Reasonable & Customary (R&C) / ‘Fair Health’ rates.',
    style: { display: 'block', marginBottom: 10, color: 'black' },
  },
  {
    text: "Don't be afraid to ask for a supervisor if the representative doesn't offer the information above.",
    style: { display: 'block', marginBottom: 10, color: 'black' },
  },
]

export const reimbursementOptionField: DropdownField<ReimbursementOption> = {
  type: FieldTypes.DROPDOWN,
  hints: [
    {
      text: 'If the agent cannot answer this question, please elaborate in the notes box below',
    },
  ],
  placeholder: 'Reimbursement Option',
  labels,
  allowOther: true,
  options: [
    {
      id: 'medicare-rates',
      text: 'Medicare Rates',
    },
    {
      id: 'r-and-c',
      text: 'Reasonable and Customary / Fair Health rates',
    },
    {
      id: 'unknown',
      text: 'Unclear / Not given',
    },
  ],
}

const reimbursementOption: FieldMap = {
  name: 'Reimbursement Option',
  validate: v =>
    (v?.optionText && (v?.medicareRate || v?.reasonableAndCustomaryRate)) || v?.notes
      ? undefined
      : { notes: 'Please specify an option or explanation from the agent' },
  condition: oonCondition,
  children: {
    optionText: reimbursementOptionField,
    medicareRate: {
      type: FieldTypes.PERCENTAGE,
      placeholder: 'Medicare Rate',
      condition: value => {
        if (value) {
          if (value.optionText === 'medicare-rates') return true
        }
        return false
      },
    },
    reasonableAndCustomaryRate: {
      type: FieldTypes.PERCENTAGE,
      optional: true,
      placeholder: 'Reasonable and Customary Rate',
      condition: value => {
        if (value) {
          if (value.optionText === 'r-and-c') return true
        }
        return false
      },
    },
    notes: {
      type: FieldTypes.TEXTAREA,
      optional: true,
      placeholder: 'Notes...',
    },
  },
}

export default reimbursementOption
