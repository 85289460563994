import { Flex, FlexProps, Tooltip } from '@chakra-ui/react'
import React from 'react'

export const LabelCell: React.FC<{ label?: string } & FlexProps> = ({
  children,
  label,
  ...props
}) => {
  const b = (
    <Flex
      color="gray.500"
      alignItems="flex-start"
      p={1}
      pr={2}
      pl={2}
      whiteSpace="pre-wrap"
      fontSize="sm"
      maxW={['100px', '250px']}
      fontWeight="600"
      {...props}>
      {children}
    </Flex>
  )
  return label ? (
    <Tooltip placement="top-start" bg="#808080" label={label}>
      {b}
    </Tooltip>
  ) : (
    b
  )
}
