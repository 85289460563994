import { Flex, FlexProps } from '@chakra-ui/react'
import React from 'react'

export interface DataCellProps extends FlexProps {
  index?: number
  title?: string
}

export const DataCell: React.FC<DataCellProps> = ({ children, title, ...props }) => (
  <Flex
    title={title}
    p={1}
    fontSize="md"
    align="center"
    // background={getRowBackground(index)}
    {...props}>
    {children}
  </Flex>
)
