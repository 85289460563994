import { colors } from '../../constants/colors'
import { CustomElement, FormattedText } from '../../editor/types'
import { InfoStage } from '../../types/forms/fields'

const data: CustomElement[] = [
  {
    type: 'span',
    children: [
      {
        text: 'The next pages will guide you through calling your ',
      },
    ],
  },
  {
    children: [
      {
        text: 'insurance company to get information (required).',
      },
    ],
    type: 'span',
  },
  {
    type: 'span',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    type: 'span',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    type: 'span',
    children: [
      {
        text: 'You can read the lines in ',
      },
    ],
  },
  {
    type: 'span',
    children: [
      {
        color: colors.green.hex,
        header: true,
        text: 'green',
      } as FormattedText,
    ],
  },
  {
    type: 'span',
    children: [
      {
        text: ' ',
      },
      {
        text: 'word for word while on the ',
      },
    ],
  },
  {
    children: [
      {
        text: 'phone with the agent. Additional text will help explain so you can retrieve accurate info!',
      },
    ],
    type: 'span',
  },
  {
    type: 'span',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    children: [
      {
        text: '',
      },
    ],
    type: 'span',
  },
  {
    children: [
      {
        text: "For now, don't mention homebirth as this can cause issues getting accurate information from your plan. ",
      },
    ],
    type: 'span',
  },
  {
    children: [
      {
        text: '',
      },
    ],
    type: 'span',
  },
  {
    type: 'span',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    type: 'span',
    children: [
      {
        text: "If the agent you speak with doesn't seem helpful or understand your questions, ask for a supervisor - you are entitled to have any questions you ask answered. Note boxes are provided if you want to explain answers more in depth.",
      },
    ],
  },
]

const callInDirections: InfoStage = {
  info: true,
  name: 'Call-In Directions',
  data,
}

export default callInDirections
