import { FieldTypes, Shortcut, ShortcutMap } from '@hb/shared'

const networkItems: Record<string, ShortcutMap | Shortcut> = {
  deductible: {
    name: 'Deductible',
    shortcut: true,
    type: FieldTypes.DOLLAR_AMOUNT,
  },
  coinsurance: {
    name: 'Coinsurance',
    shortcut: true,
    type: FieldTypes.PERCENTAGE,
  },
  oopMax: {
    name: 'Out-of-pocket max',
    shortcut: true,
    type: FieldTypes.DOLLAR_AMOUNT,
  },
}

export const insuranceItems: ShortcutMap = {
  name: 'Insurance',
  shortcut: true,
  children: {
    memberId: {
      name: 'Member ID',
      shortcut: true,
      type: FieldTypes.TEXT,
    },
    allowedCharges: {
      name: 'Allowed Charges',
      shortcut: true,
      type: FieldTypes.DOLLAR_AMOUNT,
    },
    reimbursement: {
      name: 'Reimbursement',
      shortcut: true,
      type: FieldTypes.DOLLAR_AMOUNT,
    },
    inNetwork: {
      name: 'In-network coverage',
      shortcut: true,
      children: networkItems,
    },
    outOfNetwork: {
      name: 'Out-of-network coverage',
      shortcut: true,
      children: networkItems,
    },
    policyOwner: {
      name: 'Policy Owner',
      shortcut: true,
      children: {
        fname: {
          name: 'First Name',
          shortcut: true,
          type: FieldTypes.TEXT,
        },
        lname: {
          name: 'Last Name',
          shortcut: true,
          type: FieldTypes.TEXT,
        },
        sex: {
          name: 'Sex',
          shortcut: true,
          type: FieldTypes.TEXT,
        },
        dob: {
          name: 'DOB',
          shortcut: true,
          type: FieldTypes.DATE,
        },
        address: {
          name: 'Address (1 line)',
          shortcut: true,
          type: FieldTypes.TEXT,
        },
        address2: {
          name: 'Address (2 line)',
          shortcut: true,
          type: FieldTypes.TEXT,
        },
        phone: {
          name: 'Phone',
          shortcut: true,
          type: FieldTypes.TEXT,
        },
      },
    },
  },
}
