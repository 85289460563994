import { DropdownField } from '@hb/shared'
import React, { forwardRef } from 'react'
import { InputElement, InputProps } from '../../../../types/fields'
import { InputRef } from '../types'
import { MenuDropdownInput } from './MenuDropdownInput'
import { SelectInput } from './SelectInput'

export const DropdownInput: InputElement<DropdownField> = forwardRef<
  InputRef,
  InputProps<DropdownField>
>((props, ref) =>
  props.field.selectInput ? (
    <SelectInput {...props} ref={ref} />
  ) : (
    <MenuDropdownInput {...props} ref={ref} />
  ),
)
