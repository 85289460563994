import { Badge, CircularProgress, Flex, IconButton, Image, Text } from '@chakra-ui/react'
import {
  capitalizeFirstLetter,
  failedFaxStatuses,
  Fax,
  formatPhoneNumber,
  getDateTimeString,
  pendingFaxStatuses,
  templateTypeNames,
} from '@hb/shared'

import { doc, setDoc } from 'firebase/firestore'
import React from 'react'
import { FAX_RECEIVED_REF, FAX_SENT_REF } from '../../collections/firestoreCollections'
import { DataColumn, DataColumns } from '../../types/data'
import { SortButton } from '../DataList/SortButton'
import { EditableNotesPopover } from '../NotesPopover'
import { AssignToPatient } from './AssignToPatient'
import { useFaxesView } from './context'

type FaxColumn = DataColumn<Fax>

const sendDateColumn: FaxColumn = {
  title: 'Date Sent',
  Header: () => (
    <Flex align="center" pl={2}>
      <SortButton sortKey="created_at">Sent Date</SortButton>
    </Flex>
  ),
  width: 180,
  defaultSortDirection: 'asc',
  sortKey: 'created_at',
  Render: ({ data }) => <Text pl={2}>{getDateTimeString(data.created_at)}</Text>,
}

const receiveDateColumn: FaxColumn = {
  title: 'Date Received',
  Header: () => (
    <Flex align="center" pl={2}>
      <SortButton sortKey="created_at">Received Date</SortButton>
    </Flex>
  ),
  width: 180,
  sortKey: 'created_at',
  defaultSortDirection: 'asc',
  Render: ({ data }) => {
    const value =
      data.created_at !== undefined && data.created_at !== null
        ? getDateTimeString(data.created_at)
        : ''
    return <Text pl={2}>{value}</Text>
  },
}

const toColumn: FaxColumn = {
  title: 'Sent To',
  Header: () => (
    <Flex align="center" pl={2}>
      <SortButton sortKey="to">To number</SortButton>
    </Flex>
  ),
  width: 160,
  sortKey: 'to',
  Render: ({ data }) => (
    <Text textAlign="left" isTruncated color="blackAlpha.800">
      {formatPhoneNumber(data?.to)}
    </Text>
  ),
}

const fromColumn: FaxColumn = {
  title: 'Sent from',
  Header: () => (
    <Flex align="center" pl={2}>
      <SortButton sortKey="from">From #</SortButton>
    </Flex>
  ),
  width: 160,
  sortKey: 'from',
  Render: ({ data }) => (
    <Text pl={2} textAlign="left" isTruncated color="blackAlpha.800">
      {formatPhoneNumber(data?.from)}
    </Text>
  ),
}

const statusColumn: FaxColumn = {
  title: 'Status',
  Header: () => (
    <Flex align="center" pl={2}>
      <SortButton sortKey="status">Status</SortButton>
    </Flex>
  ),
  width: 120,
  sortKey: 'status',
  Render: ({ data }) => (
    <Flex align="center" py={1} gap={2}>
      <Text
        title={capitalizeFirstLetter(data.status)}
        fontSize="xs"
        isTruncated
        fontFamily="Hero-New"
        color={failedFaxStatuses.includes(data.status) ? 'red.500' : 'blackAlpha.800'}
        pl={2}>
        {data.status.toUpperCase()}
      </Text>
      {data.status && pendingFaxStatuses.includes(data.status) ? (
        <CircularProgress size="16px" isIndeterminate color="green.400" />
      ) : null}
    </Flex>
  ),
}

const fileViewColumn: FaxColumn = {
  title: 'View PDF',
  Header: () => <Text>PDF</Text>,
  width: 120,
  Render: ({ data }) => {
    const { viewFax, viewedFax, faxId, viewedFile } = useFaxesView()
    const { id, type, storagePath } = data || {}
    return storagePath ? (
      <IconButton
        aria-label="View Fax"
        fontWeight={500}
        bg="red.500"
        border="1px solid #ff2222"
        _hover={{ bg: 'red.400' }}
        // color='white'
        onClick={() => viewFax({ id, type })}
        isLoading={faxId === id && !(viewedFax && viewedFile)}
        overflow="hidden"
        w="100%"
        fontFamily="Hero-New"
        size="xs"
        variant="ghost"
        icon={<Image position="relative" top="4px" src="/images/svg/pdf.svg" height="45px" />}
      />
    ) : (
      <Flex>
        {data.status === 'failed' ? (
          <Text pl={2} color="red.500">
            Error
          </Text>
        ) : (
          <Text pl={2}>Processing...</Text>
        )}
      </Flex>
    )
  },
}

const noteColumn: FaxColumn = {
  title: 'Notes',
  Header: () => (
    <Flex align="center" pl={2}>
      Notes
    </Flex>
  ),
  // width: 215,
  Render: ({ data }) => (
    <EditableNotesPopover
      onSubmit={v =>
        setDoc(
          doc(data.type === 'received' ? FAX_RECEIVED_REF : (FAX_SENT_REF as any), data.id),
          { notes: v },
          { merge: true },
        )
      }
      notes={data.notes}
    />
  ),
}

const faxCategoryColumn: FaxColumn = {
  title: 'Category',
  Header: () => (
    <>
      <Flex align="center" pl={2}>
        <SortButton sortKey="category">Category</SortButton>
      </Flex>
    </>
  ),
  width: 140,
  Render: ({ data }) => {
    const categoryText = data.category ? templateTypeNames[data.category] : null
    return categoryText ? <Badge>{categoryText}</Badge> : null
  },
}

const patientColumn: FaxColumn = {
  title: 'Patient Name',
  Header: () => (
    <>
      <Flex align="center" pl={2}>
        <SortButton sortKey="patient">Patient</SortButton>
      </Flex>
    </>
  ),
  width: 200,
  sortKey: 'patientLast',
  Render: ({ data }) => <AssignToPatient fax={data} />,
}

export const sentFaxColumns: DataColumns<Fax> = {
  status: statusColumn,
  patient: patientColumn,
  media_url: fileViewColumn,
  category: faxCategoryColumn,
  note: noteColumn,
  sendDate: sendDateColumn,
  to: toColumn,
}

export const receivedFaxColumns: DataColumns<Fax> = {
  status: statusColumn,
  patient: patientColumn,
  media_url: fileViewColumn,
  note: noteColumn,
  receiveDate: receiveDateColumn,
  from: fromColumn,
}
