import { get as nestedGet } from 'nested-property'
import { FieldTypes } from '../../constants'
import { LinkElement } from '../../editor'
import {
  CustomElement,
  ImageElement,
  TemplateFieldElement,
  VariableElement,
} from '../../editor/types'
import { Field, FieldMapValue, SignatureField } from '../../types'

import { formatDollarValue } from '../data'
import { getDateString, getDateTimeString } from '../dates'
import { formatPhoneNumber, notUndefinedOrNull } from '../fields'

const getDropdownValue = (value: any, field: any) => {
  const { options } = field
  const match = options?.find((o: { text: string; id: string }) =>
    typeof o === 'string' ? o === value : o.id === value,
  )
  if (!match) return value
  return typeof match === 'string' ? match : match.text
}

export const isVariableOrFieldNode = (
  node: CustomElement,
): node is VariableElement | TemplateFieldElement =>
  node.type === 'variable' || node.type === 'field'
export const isSignatureFieldNode = (
  node: CustomElement,
): node is TemplateFieldElement<SignatureField> =>
  node.type === 'field' && node.field.type === FieldTypes.SIGNATURE
export const isImageNode = (node: CustomElement): node is ImageElement => node.type === 'image'
export const isLinkNode = (node: CustomElement): node is LinkElement => node.type === 'link'

const isPositionedImageNode = (node: ImageElement) => !!node.options?.position
const isPositionedVariableNode = (node: VariableElement) => !!node.imageOptions?.position

export const isPositionedNode = (node: CustomElement) => {
  switch (node.type) {
    case 'variable':
      return isPositionedVariableNode(node)
    case 'image':
      return isPositionedImageNode(node)
    default:
      return false
  }
}

const formatValue = (value: any, field: any) => {
  const { type } = field || {}
  if (value === undefined || value === null) return undefined
  switch (type) {
    case FieldTypes.PERCENTAGE:
      return notUndefinedOrNull(value) ? `${value}%` : undefined
    case FieldTypes.DOLLAR_AMOUNT:
      return formatDollarValue(value.toString())
    case FieldTypes.DATE:
      return getDateString(value, 'short')
    case FieldTypes.DATETIME:
      return getDateTimeString(value)
    case FieldTypes.DROPDOWN:
      return getDropdownValue(value, field)
    case FieldTypes.ALTERNATE:
      return value.main
    case FieldTypes.PHONE:
      return formatPhoneNumber(value)
    case FieldTypes.BOOLEAN:
      return value ? 'Yes' : 'No'
    default:
      if (!value) return undefined
      return `${value}`
  }
}

export function getFieldValue(field: Field, path: string, data?: FieldMapValue) {
  return formatValue(nestedGet(data || {}, path), field)
}
