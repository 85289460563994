import { Box, Grid, GridProps } from '@chakra-ui/react'
import React, { PropsWithChildren } from 'react'

export const DataGrid = (props: GridProps) => (
  <Grid templateColumns="fit-content(25%) minmax(0,1fr)" {...props} />
)

export const GridItemWrapper = ({ children }: PropsWithChildren) => (
  <Box sx={{ ':nth-of-type(2n)': { background: 'rgb(245,245,245)' } }} display="contents">
    {children}
  </Box>
)
