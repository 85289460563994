import { useColorMode } from '@chakra-ui/react'
import { AppName, AppVersionData, getAppDisplayName, version } from '@hb/shared'
import 'firebase/firestore'
import React, { ReactNode, useContext, useEffect, useMemo } from 'react'
import { useDocument } from '../hooks/backend/useDocument'
import { AppData } from '../types/app'

const useAppData = (appName: AppName): AppData => {
  const appVersion = useDocument<AppVersionData>(`apps/${appName}/${version}`, 'current')
  const appDisplayName = useMemo(() => getAppDisplayName(appName), [appName])
  return {
    appName,
    appVersion,
    appDisplayName,
  }
}

export const AppContext = React.createContext<AppData>({
  appName: 'NONE',
  appDisplayName: 'NONE',
  appVersion: {
    data: null,
    loading: false,
    onDelete: async () => Promise.resolve(false),
    onSave: () => Promise.resolve(false),
    refetch: () => {},
    ref: null,
    error: null,
  },
})

export const useApp = () => useContext(AppContext)

export const AppProvider = ({ children, appName }: { children: ReactNode; appName: AppName }) => {
  const appData = useAppData(appName)

  const { setColorMode } = useColorMode()
  useEffect(() => {
    setColorMode('light')
  }, [setColorMode])
  return <AppContext.Provider value={appData}>{children}</AppContext.Provider>
}
