import React from 'react'

type Theme = 'basic' | 'detailed'

export type ThemeContextData = {
  theme?: Theme
  hideLabels?: boolean
  hideHints?: boolean
  hideInfo?: boolean
  tooltipError?: boolean
  placeholderAbove?: boolean
}

export const ThemeContext = React.createContext<ThemeContextData>({
  theme: undefined,
  hideLabels: false,
  hideInfo: false,
  hideHints: false,
  tooltipError: false,
  placeholderAbove: false,
})
