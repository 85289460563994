import { PopUpMessageData } from '@hb/shared'
import { createContext } from 'react'

export const PopUpMessageContext = createContext<PopUpMessageData>({
  message: null,
  showMessage: () => {},
  showInfo: () => {},
  showSuccess: () => {},
  processResponse: () => ({ error: 'Uninitialized' }),
  showError: () => {},
  closeMessage: () => {},
})
