import { HStack, Text } from '@chakra-ui/react'
import { colors, Fax, getDateTimeString, WithId } from '@hb/shared'
import React from 'react'
import { PdfViewModal } from '../Modals/PdfView/PdfViewModal'
import { FaxModalHeader } from './FaxModalHeader'

export const SelectedFaxPdfModal = ({
  viewedFax,
  viewedFile,
  onClose,
}: {
  viewedFax: WithId<Fax> | null
  viewedFile: string | undefined
  onClose: () => void
}) => {
  const { created_at: createdAt, type } = viewedFax || {}
  return viewedFax && viewedFile ? (
    <PdfViewModal
      url={viewedFile}
      isOpen={!!viewedFile}
      onClose={onClose}
      header={
        <HStack px={3} bg="gray.100" w="100%">
          <Text
            fontWeight={600}
            fontFamily="Comfortaa"
            color={colors.green.hex}
            fontSize="md"
            px={2}
            whiteSpace="none"
            flex={1}
            minW="0"
            isTruncated
            py={3}>
            Fax {type} {createdAt ? getDateTimeString(createdAt) : ''}
          </Text>
          {viewedFax ? <FaxModalHeader fax={viewedFax} /> : null}
        </HStack>
      }
    />
  ) : null
}
