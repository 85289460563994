import { Circle } from '@chakra-ui/react'
import { UserStatus, USER_STATUS } from '@hb/shared'
import React from 'react'
import { useDocument } from '../../../hooks/backend/useDocument'
import './styles.css'

export const OnlineIndicator = ({ uid }: { uid: string }) => {
  const { data } = useDocument<UserStatus>(USER_STATUS, uid)
  return (
    <Circle
      position="absolute"
      bottom="-10%"
      right="-10%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      size={3.5}
      bg="gray.100">
      <Circle
        className={data?.online ? 'user-online-badge' : 'user-offline-badge'}
        size={2.5}
        bg={data?.online ? 'green.500' : 'gray.400'}
      />
    </Circle>
  )
}
