import { Box, Flex } from '@chakra-ui/react'
import {
  colors,
  DBTextStage as IDBTextStage,
  EditorVersion,
  emptyInitialTemplateValue,
} from '@hb/shared'
import React, { useMemo, useState } from 'react'
import { createEditor, Descendant } from 'slate'
import { withHistory } from 'slate-history'
import {
  Editable as SlateEditable,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  withReact,
} from 'slate-react'
import { CustomEditor } from '../../types/editor'
import { DefaultModal, DefaultModalProps } from '../Modals/DefaultModal'
import { DefaultRenderLeaf } from '../RichText/DefaultRenderLeaf'
import { RenderNonFieldElement } from '../RichText/Elements/RenderNonFieldElement'
import { getWithVariables } from '../Templates/withVariables'
import { pipe } from '../utils'

const RenderLeaf = (props: RenderLeafProps & { editor: CustomEditor }) => (
  <DefaultRenderLeaf mode="Edit" version="v1" {...props} />
)

const RenderElement = (props: RenderElementProps & { editor: CustomEditor }) => (
  <RenderNonFieldElement readOnly mode="Edit" version="v1" {...props} />
)

export const DBTextStage: React.FC<{
  content?: IDBTextStage
  onSave?: (data: IDBTextStage) => void
  style?: React.CSSProperties
  version: EditorVersion
}> = ({ content, style, version }) => {
  const { text } = content || {}

  const decorators = useMemo(() => [getWithVariables(version)], [version])
  const [editor] = useState<CustomEditor>(
    decorators
      ? pipe(...decorators)(withReact(withHistory(createEditor())))
      : withReact(withHistory(createEditor())),
  )
  return (
    <Flex
      style={{
        fontSize: '16px',
        height: 'auto',
        fontWeight: 300,
        padding: '0.25rem',
        marginBottom: '0.5rem',
        justifyContent: 'flex-start',
        background: colors.green.hex,
        boxShadow: `0 0 4px ${colors.green.hex}cc`,
        borderRadius: '4px',
        ...style,
      }}>
      <Slate
        initialValue={text || emptyInitialTemplateValue}
        editor={editor}
        // value={value || initialValue}
      >
        <Box
          style={{
            boxSizing: 'border-box',
            justifyContent: 'flex-start',
            background: 'white',
            position: 'relative',
            width: '100%',
          }}>
          <SlateEditable
            readOnly
            renderLeaf={props => <RenderLeaf {...props} editor={editor} />}
            renderElement={props => <RenderElement {...props} editor={editor} />}
            style={{
              boxSizing: 'border-box',
              background: '#fbfbfb',
              padding: '0.8rem',
            }}
          />
        </Box>
      </Slate>
    </Flex>
  )
}

export const DBTextViewModal = ({
  value,
  ...modalProps
}: Omit<DefaultModalProps, 'render'> & {
  value: Descendant[]
}) => (
  <DefaultModal
    {...modalProps}
    isCentered
    overlayHeader
    headerProps={{
      background: `${colors.pink.hex}cc`,
      borderBottom: '1px solid #999999',
    }}
    render={() => (
      <Flex w="auto" height="auto" bg="gray.100" p={2}>
        {/* <ReadOnlyEditor
            version={version}
            style={{
              background: 'white',
              boxShadow: '0 0 4px #000000cc',
            }}
            {...sizeProps}
            document={document}
            decorators={decorators}
            onFormSubmit={onFormSubmit}
            withDownload={withDownload}
            value={value}
          /> */}
        <DBTextStage version="v1" content={{ text: value }} />
      </Flex>
    )}
  />
)
