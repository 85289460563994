import { ChevronDownIcon } from '@chakra-ui/icons'
import { Box, Button, Collapse, Flex, Stack, Text } from '@chakra-ui/react'
import {
  FAQItem,
  FAQList,
  Info,
  InfoBox,
  InfoHeader,
  InfoText,
  InlineLink,
  MyAssessmentShortcut,
  PageContainer,
  PageHeader,
  ScheduleCallModal,
  siteVersions,
  useAppModals,
  ViewHeaderImage,
} from '@hb/shared-frontend'
import React, { useState } from 'react'
import { useImagesLoaded } from '../../hooks/useImagesLoaded'
import { benefitsAndCoverageList } from './benefitsAndCoverage'
import { claimsFaqList } from './claims'
import faqImage from './faqImage.png'
import { insuranceTermsFaq } from './insuranceTerms'
import { PatientsPageLink } from './PatientsPageLink'

export const FAQItemView = ({
  body,
  title,
  subtitle,
  size = 'md',
}: FAQItem & { size?: 'sm' | 'md' | 'lg' }) => {
  const [isIn, setIsIn] = useState(false)
  return (
    <Flex width="100%" direction="column">
      <Button
        onClick={() => setIsIn(i => !i)}
        borderTopRadius={18}
        borderBottomRadius={isIn ? 0 : 18}
        fontWeight={400}
        fontSize={size === 'sm' ? 'large' : 'xl'}
        height="auto"
        py={size === 'sm' ? 3 : 4}
        boxShadow="1px 1px 4px rgba(0,0,0,0.4)"
        px={size === 'sm' ? 4 : 6}
        whiteSpace="pre-wrap"
        textAlign="left"
        color="white"
        transition="all 300ms"
        background="#43AD9E"
        _hover={{ background: '#65CFBF' }}
        fontFamily="hero-new">
        <Box>
          <Text>{title}</Text>
          {subtitle ? (
            <Text color="gray.100" fontFamily="Open Sans" mt={1} fontSize="md">
              {subtitle}
            </Text>
          ) : null}
        </Box>
        <ChevronDownIcon transform={`rotate(${isIn ? 180 : 0}deg)`} ml="auto" color="white" />
      </Button>
      <Collapse in={isIn}>
        <Info
          fontSize={size === 'sm' ? 'xs' : 'sm'}
          // textAlign={['left', 'left', 'justify']}
          py={4}
          px={2}
          boxShadow="inset 0 0 6px rgba(0,0,0,0.4)"
          bg="whiteAlpha.500"
          width="100%"
          borderBottomRadius={18}>
          {body}
        </Info>
      </Collapse>
    </Flex>
  )
}

export const FAQListView = ({
  name,
  items,
  description,
  size = 'md',
}: FAQList & { size?: 'sm' | 'md' | 'lg' }) => (
  <Box>
    <InfoHeader
      fontSize={size === 'sm' ? 'lg' : '28px'}
      style={{
        textAlign: size === 'sm' ? 'left' : 'center',
        opacity: 1,
        color: '#43AD9E',
        textShadow: '0px 0px 7px rgba(255,255,255,1)',
      }}>
      {name}
      {/* {name.toUpperCase()} */}
    </InfoHeader>
    {description ? <Box mt={4}>{description}</Box> : null}
    <Stack width="100%" mt={6} mb={size === 'sm' ? 6 : 12} spacing={size === 'sm' ? 4 : 6}>
      {items.map((item, i) => (
        <FAQItemView size={size} key={i} {...item} />
      ))}
    </Stack>
  </Box>
)

const ResourcesView = () => {
  const { loaded, onLoad } = useImagesLoaded(1)
  const { scheduleCall } = useAppModals()
  return (
    <PageContainer
      bg={siteVersions.prod.background}
      transition="opacity 300ms"
      opacity={loaded ? 1 : 0}>
      <PageHeader>{'FAQ & Resources'}</PageHeader>
      <ViewHeaderImage onLoad={onLoad} src={faqImage} />
      <InfoBox spacing={2}>
        <InfoText>
          See below for FAQ and a glossary of terms to help translate insurance language into plain
          English! If you need to contact us about assessing coverage from your insurance plan, you
          can do so by{' '}
          <InlineLink onClick={() => scheduleCall.open('faq')}>clicking here</InlineLink> to
          schedule a call.
        </InfoText>
        <InfoText mb={2}>
          Otherwise, please see our <PatientsPageLink /> to get started on a free assessment.
        </InfoText>
      </InfoBox>
      <Stack mt={14} spacing={6}>
        <FAQListView {...benefitsAndCoverageList} />
        <FAQListView {...claimsFaqList} />
        <FAQListView {...insuranceTermsFaq} />
      </Stack>
      <ScheduleCallModal id="faq" />
      <MyAssessmentShortcut />
    </PageContainer>
  )
}

export default ResourcesView
