import { CheckIcon } from '@chakra-ui/icons'
import { Collapse, Divider, Flex, Text } from '@chakra-ui/react'
import {
  AdditionalCoverageId,
  ASSESSMENTS,
  colors,
  ConfirmCoverageStage,
  ConfirmCoverageStatus,
  getConfirmCoverageStatus,
  getCoverageComplete,
  getCurrentlyOnMedicaidPlan,
  getHasMedicaidCoverage,
  getInsuranceCoverageField,
  getOnlyOnMedicaidPlan,
  InsuranceCoverage,
  InsuranceCoverageId,
  isInsuranceCoverageRequest,
  loqRequiredOptions,
  separateAdditionalPlansAndRequests,
} from '@hb/shared'
import useResizeObserver from '@react-hook/resize-observer'
import { collection, doc, writeBatch } from 'firebase/firestore'

import React, { PropsWithChildren, useCallback, useContext, useMemo, useRef, useState } from 'react'
import { db } from '../../../backend/db'
import { PopUpMessageContext } from '../../../contexts'
import { FormsViewContext } from '../../../contexts/FormsViewContext'
import { useAuth } from '../../../store'
import { SolidActionButton } from '../../Buttons/ActionButton'
import { DefaultModal } from '../../Modals/DefaultModal'
import { CoverageRequestView } from './CoverageRequestView'
import { CoverageStage } from './CoverageStage'
import { FormsViewAdditionalPlans } from './FormsViewAdditionalPlans'

const getInitialStage = (status: ConfirmCoverageStatus): ConfirmCoverageStage | null => {
  if (status.primaryCoverage?.incomplete?.length) return 'primaryCoverage'
  if (status.medicaidCoverage?.incomplete?.length) return 'medicaidCoverage'
  const additionalPlan = Object.keys(status.additionalPlans || {}).find(
    p => !!status.additionalPlans[p as AdditionalCoverageId]?.incomplete.length,
  )
  if (additionalPlan) return `additionalPlans.${additionalPlan}`
  return 'confirm'
}

export const ConfirmCoverageModal = ({ onClose }: { onClose: () => void }) => {
  const {
    assessment: { populated: assessment },
    assessmentId,
  } = useContext(FormsViewContext)
  const { data, additionalPlans: additionalPlansAndRequests } = assessment || {}
  const { plans: additionalPlans, requests } = useMemo(
    () => separateAdditionalPlansAndRequests(additionalPlansAndRequests),
    [additionalPlansAndRequests],
  )

  const option = useMemo(() => assessment?.mergedData?.['insurance-info']?.option, [assessment])
  const primaryCoverage = useMemo<InsuranceCoverage | undefined>(() => {
    const d = data?.['insurance-info']?.primaryCoverage as InsuranceCoverage | undefined

    return d ? { ...d, id: 'primaryCoverage' } : d
  }, [data])

  const medicaidCoverage = useMemo<InsuranceCoverage | undefined>(() => {
    const d = data?.['insurance-info']?.medicaidCoverage as InsuranceCoverage | undefined

    return d ? { ...d, id: 'medicaidCoverage' } : d
  }, [data])

  const hasPrimaryCoverage = useMemo(() => !getOnlyOnMedicaidPlan(data), [data])

  const hasMedicaidCoverage = useMemo(() => getHasMedicaidCoverage(data), [data])

  const isCurrentlyOnMedicaid = useMemo(() => getCurrentlyOnMedicaidPlan(data), [data])

  const primaryCoverageField = useMemo(
    () =>
      hasPrimaryCoverage
        ? getInsuranceCoverageField(
            false,
            loqRequiredOptions.includes(option),
            !isCurrentlyOnMedicaid,
          )
        : null,
    [option, hasPrimaryCoverage, isCurrentlyOnMedicaid],
  )

  const medicaidCoverageField = useMemo(
    () => (hasMedicaidCoverage ? getInsuranceCoverageField(true, false) : null),
    [hasMedicaidCoverage],
  )

  const status = useMemo<ConfirmCoverageStatus>(
    () => getConfirmCoverageStatus(assessment),
    [assessment],
  )

  const incompletePlans = useMemo(() => {
    const incomplete: {
      propPath:
        | 'data.insurance-info.primaryCoverage'
        | 'data.insurance-info.medicaidCoverage'
        | `additionalPlans.${string}`
      label: 'primary' | 'medicaid' | 'potential'
      coverage: InsuranceCoverage | undefined
      id: InsuranceCoverageId
    }[] = []
    if (status.primaryCoverage?.incomplete?.length) {
      incomplete.push({
        id: 'primaryCoverage',
        label: 'primary',
        propPath: 'data.insurance-info.primaryCoverage',
        coverage: primaryCoverage,
      })
    }
    if (status.medicaidCoverage?.incomplete?.length) {
      incomplete.push({
        id: 'medicaidCoverage',
        label: 'medicaid',
        propPath: 'data.insurance-info.medicaidCoverage',
        coverage: medicaidCoverage,
      })
    }
    Object.keys(status.additionalPlans || {})
      .filter(p => status.additionalPlans[p as AdditionalCoverageId]?.incomplete.length)
      .forEach(p => {
        const coverage = additionalPlans?.[p as AdditionalCoverageId]
        if (coverage) {
          incomplete.push({
            id: p as InsuranceCoverageId,
            propPath: `additionalPlans.${p}`,
            label: 'potential',
            coverage,
          })
        }
      })
    return incomplete
  }, [status, additionalPlans, medicaidCoverage, primaryCoverage])

  const [stage, setStage] = useState<ConfirmCoverageStage | null>(getInitialStage(status))
  const allComplete = useMemo(
    () => (assessment ? getCoverageComplete(status) : false),
    [status, assessment],
  )

  const onOpenToggle = useCallback((s: ConfirmCoverageStage | null) => {
    setStage(prev => (prev === s ? null : s))
  }, [])

  const selectedAdditionalPlanId = useMemo(() => {
    if (stage?.startsWith('additionalPlans')) return stage.split('.')[1]
    return null
  }, [stage])

  const { showMessage } = useContext(PopUpMessageContext)

  const [confirmLoading, setConfirmLoading] = useState(false)
  const onConfirm = useCallback(async () => {
    const { authUser } = useAuth.getState()
    if (!authUser) {
      showMessage({
        type: 'error',
        text: 'User not found',
        subText: 'You may have been signed out. Please sign in again.',
      })
      return
    }
    setConfirmLoading(true)
    try {
      const assessmentRef = doc(collection(db, ASSESSMENTS), assessmentId)
      const batch = writeBatch(db)
      // update confirmation status
      batch.update(assessmentRef, 'additionalPlansConfirmedOn', Date.now())
      batch.update(assessmentRef, 'additionalPlansConfirmedBy', authUser.uid)
      await batch.commit()
      onClose()
    } catch (e: any) {
      showMessage({
        type: 'error',
        text: 'Error updating assessment',
        subText: e.message,
      })
      setConfirmLoading(false)
    }
  }, [assessmentId, onClose, showMessage])

  const [contentWidth, setContentWidth] = useState<number>(0)
  const contentRef = useRef<HTMLDivElement>(null)
  const onContentResize = useCallback((e: ResizeObserverEntry) => {
    setContentWidth(e.contentRect.width)
  }, [])
  useResizeObserver(contentRef, onContentResize)

  const incompletePlan = useMemo(() => {
    const requestIds = Object.keys(requests || {})
    if (requestIds.length) {
      const request = requests[requestIds[0]]
      return {
        id: requestIds[0],
        label: 'coverage request',
        coverage: { ...request, id: requestIds[0] },
      }
    }
    return incompletePlans[0]
  }, [incompletePlans, requests])

  let incompletePlanBody: PropsWithChildren['children'] = null
  if (incompletePlan && assessment && assessmentId) {
    incompletePlanBody =
      incompletePlan.coverage && isInsuranceCoverageRequest(incompletePlan.coverage) ? (
        <CoverageRequestView
          assessment={assessment}
          assessmentId={assessmentId}
          request={incompletePlan.coverage}
        />
      ) : (
        <CoverageStage
          id={incompletePlan.id as InsuranceCoverageId}
          key={incompletePlan.id}
          width={contentWidth}
          alwaysOpen
          assessmentId={assessmentId}
          coverage={incompletePlan.coverage}
          isOpen
        />
      )
  }

  return (
    <DefaultModal
      overlayHeader
      isOpen
      size="2xl"
      onClose={onClose}
      contentProps={{ bg: 'gray.100' }}
      render={() =>
        assessment && assessmentId ? (
          <Flex gap={2} p={3} flexFlow="column" w="100%">
            <Text p={1} fontSize="lg" lineHeight={1} fontWeight={600} color="gray.600">
              Confirm Coverage
            </Text>
            {incompletePlan ? (
              <Flex w="100%" flexFlow="column">
                <Text p={2} fontSize="md" color="gray.600">
                  We need some information about your {incompletePlan.label} coverage.
                </Text>
                {incompletePlanBody}
              </Flex>
            ) : (
              <Flex flexFlow="column" p={2} gap={2} border="1px solid #cdcdcd" bg="white" w="100%">
                {primaryCoverageField ? (
                  <>
                    <CoverageStage
                      id="primaryCoverage"
                      width={contentWidth}
                      assessmentId={assessmentId}
                      coverage={primaryCoverage}
                      optional={isCurrentlyOnMedicaid}
                      isOpen={stage === 'primaryCoverage'}
                      onOpenToggle={() => onOpenToggle('primaryCoverage')}
                    />
                    <Divider />
                  </>
                ) : null}
                {medicaidCoverageField ? (
                  <>
                    <CoverageStage
                      width={contentWidth}
                      id="primaryCoverage"
                      assessmentId={assessmentId}
                      coverage={medicaidCoverage}
                      isOpen={stage === 'medicaidCoverage'}
                      onOpenToggle={() => onOpenToggle('medicaidCoverage')}
                    />
                    <Divider />
                  </>
                ) : null}
                <FormsViewAdditionalPlans
                  assessment={assessment}
                  assessmentId={assessmentId}
                  openPlanId={selectedAdditionalPlanId}
                  onOpenPlan={planId => onOpenToggle(`additionalPlans.${planId}`)}
                />
              </Flex>
            )}
            <Collapse in={allComplete} style={{ width: '100%' }}>
              <Flex w="100%">
                <SolidActionButton
                  borderRadius="full"
                  ml="auto"
                  onClick={onConfirm}
                  isLoading={confirmLoading}
                  bg={colors.green.hex}
                  color="white">
                  <CheckIcon filter="drop-shadow(1px 1px 3px #00000066)" w={4} h={4} mr={2} />
                  <Text>Done!</Text>
                </SolidActionButton>
              </Flex>
            </Collapse>
          </Flex>
        ) : (
          <Flex p={3}>
            <Text>No assessment found</Text>
          </Flex>
        )
      }
    />
  )
}
