import { FieldTypes } from '../constants'
import { FieldMap } from '../types'
import { getFullName } from '../utils/users'
import { alternateFaxField } from './shared'

export const physicianField: FieldMap = {
  name: 'Physician',
  optional: true,
  initExpanded: false,
  toName: getFullName,
  children: {
    name: { type: FieldTypes.TEXT, placeholder: 'Name' },
    willAssist: {
      type: FieldTypes.CHECKBOX,
      placeholder: 'Provider willing to assist with gap requests',
    },
    taxId: {
      type: FieldTypes.ALTERNATE,
      placeholder: 'Tax ID',
      fieldType: FieldTypes.TEXT,
      optional: true,
    },
    npi: {
      type: FieldTypes.ALTERNATE,
      placeholder: 'NPI',
      fieldType: FieldTypes.TEXT,
      optional: true,
    },
    address: {
      type: FieldTypes.ALTERNATE,
      placeholder: 'Address',
      fieldType: FieldTypes.TEXTAREA,
    },
    phone: {
      type: FieldTypes.ALTERNATE,
      placeholder: 'Phone',
      fieldType: FieldTypes.PHONE,
    },
    fax: alternateFaxField,
  },
}
