import { CheckIcon } from '@chakra-ui/icons'
import { Button, Center, HStack, Text, Tooltip } from '@chakra-ui/react'
import { colors, getDateTimeString, getFullName } from '@hb/shared'
import React, { useCallback, useContext, useState } from 'react'
import { useCachedUser } from '../../../collections/hooks/cached'
import { AppContext, UserContext } from '../../../contexts'
import { useMe } from '../../../hooks/backend/useMe'
import { useUpdateDoc } from '../../../hooks/backend/useUpdateDoc'

const PracticeAssessmentClearedSection = () => {
  const {
    selectedAssessment: { populated: selectedAssessment },
  } = useContext(UserContext)
  const { clearedBy, clearedOn } = selectedAssessment || {}
  const { appName } = useContext(AppContext)
  const text = clearedOn ? 'CLEARED FOR COVERAGE' : 'NOT CLEARED FOR COVERAGE'
  const color = clearedOn ? colors.green.hex : '#ababab'
  const { data: clearedByUser } = useCachedUser(clearedBy || null)
  return (
    <HStack>
      <Tooltip
        placement="top"
        hasArrow
        label={
          clearedOn
            ? `Cleared by ${
                clearedByUser ? getFullName(clearedByUser) : 'Loading...'
              } on ${getDateTimeString(clearedOn)}`
            : 'Awaiting to be cleared by HB'
        }>
        <HStack
          bg={clearedOn ? 'transparent' : 'whitesmoke'}
          px={2}
          cursor="default"
          py={1}
          spacing={1}
          borderRadius={6}
          border={`1px solid ${clearedOn ? 'transparent' : color}`}>
          {clearedOn ? (
            <Center w="20px" h="20px" borderRadius={6} bg={color}>
              <CheckIcon width="14px" color="white" />
            </Center>
          ) : null}
          {clearedOn && appName === 'app' ? null : (
            <Text
              fontSize="sm"
              fontWeight={600}
              whiteSpace="nowrap"
              fontFamily="Hero-New"
              color={color}>
              {text}
            </Text>
          )}
        </HStack>
      </Tooltip>
    </HStack>
  )
}

const AdminAssessmentClearedSection = () => {
  const {
    selectedAssessment: { populated: selectedAssessment, snippetRef },
  } = useContext(UserContext)
  const { clearedOn } = selectedAssessment || {}

  const me = useMe()
  const [hovered, setHovered] = useState(false)
  const update = useUpdateDoc('cleared status')
  const [isLoading, setIsLoading] = useState(false)
  const handleClick = useCallback(() => {
    if (!me) return
    setIsLoading(true)
    try {
      if (clearedOn) {
        update(snippetRef, '', { clearedOn: null, clearedBy: null })
      } else {
        update(snippetRef, '', { clearedOn: Date.now(), clearedBy: me.uid })
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }, [clearedOn, snippetRef, update, me])

  const clearedOnText = clearedOn ? 'CLEARED FOR COVERAGE' : 'NOT CLEARED FOR COVERAGE'
  let text = clearedOnText
  let color = clearedOn ? colors.green.hex : '#ababab'
  if (hovered) {
    text = clearedOn ? 'MARK AS NOT CLEARED' : 'MARK AS CLEARED'
    color = clearedOn ? '#ababab' : colors.green.hex
  }
  return (
    <HStack>
      <Tooltip label={clearedOnText}>
        <Button
          onClick={handleClick}
          isLoading={isLoading}
          transition="all 300ms"
          onPointerEnter={() => setHovered(true)}
          onPointerLeave={() => setHovered(false)}
          bg="whitesmoke"
          fontFamily="Hero-New"
          overflow="hidden"
          size="sm"
          fontSize="xs"
          gap={1}
          px={0}
          minW="0"
          width={clearedOn && !hovered ? '50px' : '210px'}
          borderRadius={6}
          border={`1px solid ${color}`}>
          {clearedOn && !hovered ? (
            <Center w="20px" h="20px" borderRadius={6} bg={color}>
              <CheckIcon width="14px" color="white" />
            </Center>
          ) : null}
          {clearedOn && !hovered ? null : <Text color={color}>{text}</Text>}
        </Button>
      </Tooltip>
    </HStack>
  )
}

export const AssessmentClearedSection = () => {
  const { appName } = useContext(AppContext)
  return appName === 'providers-app' ? (
    <PracticeAssessmentClearedSection />
  ) : (
    <AdminAssessmentClearedSection />
  )
}
