import { InitialsField } from '@hb/shared'
import React, { forwardRef } from 'react'
import { InputElement, InputProps } from '../../../../types/fields'
import InputContainer from '../Shared/InputContainer'
import { TextInputElement } from '../Text/styles'

const InitialsInput: InputElement<InitialsField> = forwardRef<
  HTMLInputElement,
  InputProps<InitialsField>
>((props, ref) => {
  const { field, input, disabled, readOnly } = props

  const { placeholder } = field || {}
  return (
    <InputContainer {...props} style={{ width: '80px', padding: '0 0.3rem', ...props.style }}>
      <TextInputElement
        ref={ref}
        disabled={disabled}
        readOnly={readOnly}
        maxLength={3}
        placeholder={placeholder}
        type={'text'}
        autoComplete={field.autocomplete}
        {...input}
      />
    </InputContainer>
  )
})

export default InitialsInput
