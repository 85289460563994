import { BaseLabel } from '@hb/shared'
import React, { FC } from 'react'
import { Container } from '../../Container'

const Hint: FC<BaseLabel> = ({ text, style }) => (
  <span
    style={{
      fontSize: '15px',
      color: '#777',
      padding: '0.5rem 0',
      ...style,
    }}>
    <i>{text}</i>
  </span>
)

const renderHint = (label: BaseLabel, index?: number) =>
  typeof label === 'string' ? (
    <Hint key={`label_${index}`} text={label} />
  ) : (
    <Hint key={`label_${index}`} {...label} />
  )

const Hints: FC<{ hints?: BaseLabel[] }> = ({ hints }) => {
  if (!hints) return null
  return (
    <Container
      style={{
        paddingBottom: '5px',
        display: 'block',
        alignItems: 'flex-start',
      }}>
      {hints.map((l, index) => renderHint(l, index))}
    </Container>
  )
}

export default Hints
