import { DeleteIcon } from '@chakra-ui/icons'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Flex,
  HStack,
  IconButton,
  IconButtonProps,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import React, { ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { PopUpMessageContext } from '../../contexts/PopUpMessage/PopUpMessageContext'

const defaultAlertBodyText = "Are you sure? You can't undo this action afterwards."
export const DeleteConfirmAlert = ({
  confirmActive,
  onCancel,
  onConfirm,
  body = defaultAlertBodyText,
  actionName = 'Delete',
  actionLoading,
  itemName,
}: {
  confirmActive: boolean
  onCancel: () => void
  onConfirm: () => void
  body?: string | ReactNode
  actionLoading?: boolean
  actionName?: string
  itemName: string
}) => {
  const cancelRef = useRef<HTMLButtonElement>(null)
  return (
    <AlertDialog
      isCentered
      isOpen={confirmActive}
      leastDestructiveRef={cancelRef}
      onClose={() => onCancel()}>
      <AlertDialogOverlay>
        <AlertDialogContent gap={0}>
          <AlertDialogBody>
            <VStack align="flex-start" py={1}>
              <Text fontFamily="Hero-New" fontSize="lg" fontWeight={500}>
                {actionName} {itemName}?
              </Text>
              {typeof body === 'string' ? (
                <Text fontFamily="Hero-New" fontSize="md">
                  {body}
                </Text>
              ) : (
                body
              )}
              <HStack justify="flex-end" w="100%">
                <Button size="sm" ref={cancelRef} onClick={onCancel}>
                  Cancel
                </Button>
                <Button
                  size="sm"
                  isLoading={actionLoading}
                  color="red.100"
                  colorScheme="red"
                  onClick={onConfirm}>
                  {actionName}
                </Button>
              </HStack>
            </VStack>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export const DeleteButton: React.FC<
  Partial<IconButtonProps> & {
    onDelete: (() => Promise<any | void>) | (() => void)
    itemName: string
    actionName?: string
    noConfirm?: boolean
    text?: string
    alertBody?: string | ReactNode
  }
> = ({ onDelete, itemName, noConfirm, alertBody, text, actionName = 'Delete', ...props }) => {
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [confirmActive, setConfirmActive] = useState(false)
  const confirmTimer = useRef<any>()
  const isMounted = useRef(true)
  useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])

  const { showError } = useContext(PopUpMessageContext)

  const handleDelete = useCallback(async () => {
    if (confirmTimer.current) clearTimeout(confirmTimer.current)
    setConfirmActive(false)
    if (onDelete) {
      setDeleteLoading(true)
      try {
        await onDelete()
      } catch (err: any) {
        console.error(err)
        showError(err?.message || 'Error deleting')
        console.error(err)
      }
      if (isMounted.current) {
        setDeleteLoading(false)
      }
    } else {
      console.error('No delete function')
    }
  }, [onDelete, showError])

  return (
    <>
      {text ? (
        <Button
          variant="ghost"
          size={props.size || 'md'}
          aria-label="delete"
          _hover={{ color: 'red.600', bg: 'red.200' }}
          color="red.600"
          // bg='red.300'
          onClick={e => {
            e.stopPropagation()
            if (noConfirm) handleDelete()
            else setConfirmActive(true)
          }}
          isLoading={deleteLoading}
          {...props}>
          <Flex align="center" gap={1}>
            <DeleteIcon />
            <Text>{text}</Text>
          </Flex>
        </Button>
      ) : (
        <Tooltip
          hasArrow
          colorScheme="red"
          bg="gray.50"
          color="red.600"
          placement="top"
          label={`${actionName} ${itemName}`}>
          <IconButton
            variant="ghost"
            size={props.size || 'xs'}
            aria-label={actionName}
            _hover={{ color: 'red.600', bg: 'red.200' }}
            color="red.500"
            // bg='red.300'
            icon={<DeleteIcon />}
            onClick={e => {
              e.stopPropagation()
              if (noConfirm) handleDelete()
              else setConfirmActive(true)
            }}
            isLoading={deleteLoading}
            {...props}
          />
        </Tooltip>
      )}
      <DeleteConfirmAlert
        confirmActive={confirmActive}
        onCancel={() => {
          setConfirmActive(false)
        }}
        actionLoading={deleteLoading}
        onConfirm={handleDelete}
        actionName={actionName}
        body={alertBody}
        itemName={itemName}
      />
    </>
  )
}
