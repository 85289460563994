import { InfoIcon } from '@chakra-ui/icons'
import { Box, BoxProps, IconButton, IconButtonProps } from '@chakra-ui/react'
import { WithId } from '@hb/shared'
import React from 'react'
import { CopyTooltip } from './CopyTooltip'

export const CopyId = <T extends WithId>({
  item,
  buttonProps,
  ...props
}: {
  item: T | null
  buttonProps?: Omit<IconButtonProps, 'aria-label'>
} & BoxProps) => (
  <Box position="absolute" bottom="0.25rem" right="0.25rem" {...props}>
    <CopyTooltip
      placement="left"
      bg="gray.600"
      prefix="ID"
      label={item ? item.id : 'nothing to copy'}>
      <IconButton
        size="sm"
        variant="ghost"
        aria-label="ID"
        icon={<InfoIcon color="gray.500" />}
        {...buttonProps}
      />
    </CopyTooltip>
  </Box>
)
