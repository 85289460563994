import { FieldMap, FieldTypes, TextAreaField } from '@hb/shared'

export const commentTextField: TextAreaField = {
  type: FieldTypes.TEXTAREA,
  placeholder: 'Text',
}
export const commentField: FieldMap = {
  name: 'Comment',
  children: {
    text: commentTextField,
  },
}
