import { getCoverageStatus, isInsuranceCoverageRequest } from '@hb/shared'
import { useMemo } from 'react'
import { useMyAssessments } from '../../contexts'
import { View } from '../../types/views'

export const useNestedViews = (views: View[]) =>
  useMemo(() => {
    const publicViews = []
    const userViews = []
    const adminViews = []
    const superAdminViews = []
    for (let i = 0; i < views.length; i += 1) {
      const v = views[i]
      if (v.onMenu) {
        if (v.access) {
          if (v.access.indexOf('user') !== -1) {
            userViews.push(v)
          } else if (v.access.indexOf('admin') !== -1) {
            adminViews.push(v)
          } else if (v.access.indexOf('super-admin') !== -1) {
            superAdminViews.push(v)
          }
        } else {
          publicViews.push(v)
        }
      }
    }

    return {
      publicViews,
      userViews,
      adminViews,
      superAdminViews,
    }
  }, [views])

export const useMyPendingCallInRequests = () => {
  const { assessmentsArr } = useMyAssessments()

  return useMemo(
    () =>
      Array.from(
        new Set(
          assessmentsArr.reduce((acc, assessment) => {
            const { additionalPlans } = assessment || {}
            const hasCallInRequest = Object.entries(additionalPlans || {}).some(([, plan]) => {
              if (isInsuranceCoverageRequest(plan)) return true
              const planHasRequest = !!Object.keys(plan.callInRequests || {}).length
              const status = getCoverageStatus('additionalPlans', plan)
              return !!(planHasRequest && status?.incomplete.length)
            })
            if (hasCallInRequest) acc.push(assessment.id)
            return acc
          }, [] as Array<string>),
        ),
      ),
    [assessmentsArr],
  )
}
