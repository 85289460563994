import { HStack, Text, VStack } from '@chakra-ui/react'
import { colors, dateToTimeString, TimeField } from '@hb/shared'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { InputElement, InputProps } from '../../../../types/fields'
import { InputRef } from '../types'
import { TimePicker } from './TimePicker'

export const TimeInput: InputElement<TimeField> = forwardRef<InputRef, InputProps<TimeField>>(
  (props, ref) => {
    const {
      input,
      field,
      style,
      meta: { active },
    } = props

    // const [error, setError] = useState<string | null>(null)
    const { onChange, value, onFocus, onBlur } = input
    const {
      // maxDate,
      defaultToNow,
    } = field

    // date field value

    useImperativeHandle(ref, () => ({
      focus: onFocus,
      blur: onBlur,
    }))

    const [date] = useState(new Date())
    useEffect(() => {
      if (!value && defaultToNow) {
        const d = new Date()
        onChange(dateToTimeString(d))
      }
    }, [value, defaultToNow, onChange])

    const [myTimezone] = useState(
      date.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2],
    )

    return (
      <VStack spacing={0} align="flex-start" style={style}>
        <HStack minW="370px">
          <TimePicker active={active} value={value} onChange={onChange} />
        </HStack>
        <Text py={1} fontSize="sm" color="gray.500">
          Using timezone:
          <span
            style={{
              fontFamily: 'Open Sans',
              background: colors.green.hex,
              marginLeft: '0.3rem',
              color: 'white',
              textShadow: '1px 1px 3px #00000077',
              padding: '0 0.3rem',
              fontSize: '0.8rem',
              borderRadius: '0.3rem',
              fontWeight: 600,
            }}>
            {myTimezone.toUpperCase()}
          </span>
        </Text>
      </VStack>
    )
  },
)
