import {
  AdminAdminData,
  AdminPracticeData,
  ADMIN_MIDWIVES_DATA,
  ADMIN_USERS_DATA,
  Assessment,
  ASSESSMENTS,
  ASSESSMENT_THREADS,
  Claim,
  CLAIMS,
  CollectionItem,
  DIRECT_MESSAGE_THREADS,
  FAX_RECEIVED_COLLECTION,
  FAX_SENT_COLLECTION,
  GROUP_THREADS,
  InsuranceProvider,
  INSURANCE_PROVIDERS,
  MessageThread,
  MESSAGING_WIDGET,
  MIDWIVES,
  PracticeWithAdmin,
  PRACTICE_ACCESS,
  PRACTICE_THREADS,
  ReceivedFax,
  SentFax,
  Template,
  User,
  UserInvite,
  UserRoleItem,
  USERS,
  USER_INVITES,
  USER_ROLES,
} from '@hb/shared'
import { collection, CollectionReference, DocumentData } from 'firebase/firestore'
import { db } from '../backend/db'

const getCollectionRef = <T extends DocumentData>(path: string) =>
  collection(db, path) as CollectionReference<CollectionItem<T>>

export const USERS_REF = getCollectionRef<User>(USERS)
export const ASSESSMENTS_REF = getCollectionRef<Assessment>(ASSESSMENTS)
export const ASSESSMENT_THREADS_REF = getCollectionRef<MessageThread>(ASSESSMENT_THREADS)
export const PRACTICE_THREADS_REF = getCollectionRef<MessageThread>(PRACTICE_THREADS)
export const PRACTICE_ACCESS_REF = getCollectionRef<UserRoleItem>(PRACTICE_ACCESS)
export const GROUP_THREADS_REF = getCollectionRef<MessageThread>(GROUP_THREADS)
export const DIRECT_MESSAGE_THREADS_REF = getCollectionRef<MessageThread>(DIRECT_MESSAGE_THREADS)
export const USER_ROLES_REF = getCollectionRef<UserRoleItem>(USER_ROLES)
export const MIDWIVES_REF = getCollectionRef<PracticeWithAdmin>(MIDWIVES)
export const MIDWIVES_ADMIN_REF = getCollectionRef<AdminPracticeData>(ADMIN_MIDWIVES_DATA)
export const USERS_ADMIN_REF = getCollectionRef<AdminAdminData>(ADMIN_USERS_DATA)
export const USER_INVITES_REF = getCollectionRef<UserInvite>(USER_INVITES)
export const CLAIMS_REF = getCollectionRef<Claim>(CLAIMS)
export const INSURANCE_PROVIDERS_REF = getCollectionRef<InsuranceProvider>(INSURANCE_PROVIDERS)
export const FAX_RECEIVED_REF = getCollectionRef<ReceivedFax>(FAX_RECEIVED_COLLECTION)
export const MESSAGING_WIDGET_REF = getCollectionRef(MESSAGING_WIDGET)

export const TEMPLATES_REF = collection(db, 'templates') as CollectionReference<Template>
export const AUTH_TEMPLATES_REF = collection(db, 'authTemplates') as CollectionReference<Template>
export const CLAIM_TEMPLATES_REF = collection(db, 'claimTemplates') as CollectionReference<Template>
export const CLINICALS_TEMPLATES_REF = collection(
  db,
  'clinicalsTemplates',
) as CollectionReference<Template>
export const AUTH_APPEALS_TEMPLATES_REF = collection(
  db,
  'authAppealsTemplates',
) as CollectionReference<Template>
export const AUTH_INSTRUCTIONS_TEMPLATES_REF = collection(
  db,
  'authInstructionsTemplates',
) as CollectionReference<Template>
export const INVOICES_RECEIPTS_TEMPLATES_REF = collection(
  db,
  'invoicesReceiptsTemplates',
) as CollectionReference<Template>

export const FAX_SENT_REF = getCollectionRef<SentFax>(FAX_SENT_COLLECTION)
