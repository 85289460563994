export const INSURERS = 'insurance-providers'

export const USERS = 'users'
export const USER_STATUS = 'user-status'
export const USER_ROLES = 'user-roles'
export const ADMINS = 'admins'
export const USERS_ADMIN = 'users-admin'
export const USER_INVITES = 'user-invites'

export const MIDWIVES = 'midwives'
export const MIDWIVES_ADMIN = 'midwives-admin'
export const PRACTICES = 'practices'
export const PRACTICE_ACCESS = 'practice-access'

export const ASSESSMENTS = 'assessments'
export const ASSESSMENTS_ADMIN = 'assessments-admin'
export const ASSESSMENT_SNIPPETS = 'assessment-snippets'
export const PAYMENTS_LOG = 'payments-log'
export const ASSESSMENT_INVOICE_SNIPPETS = 'assessment-invoices'
export const ASSESSMENT_THREADS = 'assessment-threads'
export const PRACTICE_THREADS = 'practice-threads'
export const GROUP_THREADS = 'group-threads'
export const DELETED_THREADS = 'deleted-threads'
export const DIRECT_MESSAGE_THREADS = 'direct-message-threads'

export const CLAIMS = 'claims'
export const CLAIMS_ABILITY = 'claims-ability'
export const CLAIMS_ABILITY_DETAIL = 'claims-ability-detail'
export const INVOICES = 'invoices'
export const INVOICES_DELETED = 'invoices-deleted'
export const INVOICING_SETTINGS = 'invoicing-settings'

export const APPOINTMENTS = 'appointments'
export const CONSENT_FORMS = 'consent-forms'
export const CONSENT_FORM_TEMPLATES = 'consent-form-templates'
export const VISITS = 'visits'
export const VISIT_DRAFTS = 'visit-drafts'

export const ADMIN_MIDWIVES_DATA = 'midwives-admin'
export const ADMIN_USERS_DATA = 'users-admin'
export const INSURANCE_PROVIDERS = 'insurance-providers'

export const THREADS = 'threads'
export const MESSAGING_WIDGET = 'messaging-widget'

// DEVOPS
export const BUG_REPORTS = 'bug-reports'
