import { PopulatedAdminData } from '@hb/shared'
import { createContext } from 'react'

export const AdminProfileContext = createContext<PopulatedAdminData>({
  adminUserRef: null,
  loading: false,
  user: null,
  userRef: null,
  userRoleRef: null,
})
