import {
  Button,
  Flex,
  FocusLock,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  Stack,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  CheckboxField,
  FieldMap,
  FieldTypes,
  getDateString,
  LogEntry,
  OnUploadProgress,
  TextAreaField,
  UpdateCallback,
  UserGroup,
} from '@hb/shared'
import { FormApi } from 'final-form'
import cloneDeep from 'lodash.clonedeep'
import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { Form } from 'react-final-form'
import { ScreenContext, useApp } from '../../../../contexts'
import { useAuth } from '../../../../store/auth'
import { ActionLog } from '../../../ActionLog'
import { ActionButton } from '../../../Buttons'
import { getRowBackground } from '../../../DataView'
import { FormPopover, FormPopoverProps } from '../../../DataView/GenericEditModal'
import { FormElement } from '../../../forms'
import { SubmitOnEnter } from '../../../forms/FinalForm/SubmitOnEnter'
import { InputRef } from '../../../forms/Input/types'
import { DefaultModal } from '../../../Modals/DefaultModal'
import { KeyboardShortcutTooltip } from '../../../shared/HotKeyTooltip'
import { UrgentBadge } from '../../UrgentBadge'
import { UserBadge } from '../../UserBadge'

const logNotesField: TextAreaField = {
  type: FieldTypes.TEXTAREA,
  placeholder: 'Notes',
}

const urgentField: CheckboxField = {
  type: FieldTypes.CHECKBOX,
  placeholder: 'URGENT',
}

const newLogField: FieldMap = {
  name: 'Log Entry',
  children: {
    text: logNotesField,
    urgent: urgentField,
  },
}

export const LogEntryPopover = ({
  onSubmit,
  isNew,
  ...props
}: Omit<FormPopoverProps<LogEntry>, 'field'> & {
  createdOn?: number
  isNew?: boolean
}) => {
  const me = useAuth(s => s.user)
  const handleSubmit = async (
    data: Partial<LogEntry>,
    onUploadProgress: OnUploadProgress,
    form: FormApi<LogEntry>,
  ): Promise<UpdateCallback> => {
    if (onSubmit) {
      const submitted = cloneDeep(data)
      submitted.updatedOn = Date.now()
      if (me) {
        submitted.updatedBy = me.id
        if (!submitted.createdBy) submitted.createdBy = me.id
      }
      return onSubmit(submitted as LogEntry, onUploadProgress, form)
    }
    return { error: 'Error submitting' }
  }
  const field = useMemo(
    () => ({
      ...newLogField,
      name: isNew ? 'Add log entry' : 'Edit log entry',
    }),
    [isNew],
  )
  return <FormPopover<LogEntry> isLazy focusLock onSubmit={handleSubmit} field={field} {...props} />
}

export const LogEntryModal = ({
  onSubmit,
  onClose,
  isOpen,
  data,
}: Omit<FormPopoverProps<LogEntry>, 'field' | 'onSubmit'> & {
  createdOn?: number
  isOpen: boolean
  onSubmit: (updated: LogEntry) => Promise<UpdateCallback>
  onClose: () => void
}) => {
  const me = useAuth(s => s.user)
  const admin = useAuth(s => s.admin)
  const { appName } = useApp()
  const handleSubmit = async (updated: Partial<LogEntry>): Promise<UpdateCallback> => {
    if (onSubmit) {
      const submitted = cloneDeep(updated)
      let updatedByGroup: UserGroup = 'patient'
      if (admin && appName === 'app') {
        updatedByGroup = 'admin'
      } else if (appName === 'providers-app') {
        updatedByGroup = 'practice'
      }
      const now = Date.now()
      if (!me) return { error: 'Error submitting' }
      submitted.updatedOn = now
      submitted.updatedBy = me.id
      submitted.updatedByGroup = updatedByGroup
      if (!submitted.createdBy) {
        submitted.createdBy = me.id
        submitted.createdByGroup = updatedByGroup
        submitted.createdOn = now
      }
      return onSubmit(submitted as LogEntry).then(res => {
        if (res.success) onClose()
        return res
      })
    }
    return { error: 'Error submitting' }
  }

  const textElRef = useRef<InputRef>(null)
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        textElRef.current?.focus()
      }, 50)
    }
  }, [isOpen])
  return (
    <DefaultModal
      isOpen={isOpen}
      overlayHeader
      initialFocusRef={textElRef}
      onClose={onClose}
      render={() => (
        <Form
          initialValues={data}
          onSubmit={handleSubmit}
          render={({ submitting, handleSubmit: submit }) => (
            <VStack spacing={0} align="flex-start" py={2} px={4} w="100%">
              <Text fontSize="lg">Log Entry</Text>
              <FormElement ref={textElRef} name="text" field={logNotesField} />
              <FormElement name="urgent" field={urgentField} />
              <HStack spacing={4} w="100%" justify="flex-end">
                <Button onClick={onClose} variant="link">
                  Cancel
                </Button>
                <KeyboardShortcutTooltip shortcut="Shift + Enter">
                  <ActionButton isLoading={submitting} onClick={submit} size="sm">
                    Save
                  </ActionButton>
                </KeyboardShortcutTooltip>
              </HStack>
              <SubmitOnEnter withShift />
            </VStack>
          )}></Form>
      )}
    />
  )
}

const EditableLogEntryView: React.FC<
  LogEntry & {
    createdOn: number
    index?: number
    selected?: boolean
    select: () => void
    deselect: () => void
    update: (data: LogEntry) => Promise<UpdateCallback>
    placement?: PopoverProps['placement']
  }
> = ({ index, update, deselect, selected, select, placement, ...log }) => {
  const {
    text,
    urgent,
    createdOn,
    createdBy,
    updatedBy,
    updatedOn,
    updatedByGroup,
    createdByGroup,
  } = log

  const { isMobile } = useContext(ScreenContext)
  const usedPlacement = useMemo(() => {
    if (isMobile) return 'bottom'
    return placement || 'left'
  }, [isMobile, placement])
  return (
    <Flex
      opacity={0.8}
      _hover={{ opacity: 1 }}
      bg={getRowBackground(index)}
      onClick={select}
      cursor="pointer"
      w="100%"
      // borderBottom='1px solid #dedede'
      p={1}
      pl={3}>
      <FocusLock>
        <Popover strategy="fixed" placement={usedPlacement} trigger="hover">
          <PopoverTrigger>
            <Text
              // borderBottom='1px solid rgba(0,0,0,0.5)'
              h="20px"
              // color={color || 'black'}
              cursor="pointer"
              opacity={0.7}
              mt="2px"
              fontSize="sm"
              fontWeight="500">
              {getDateString(createdOn, 'short', false)}
            </Text>
          </PopoverTrigger>
          <PopoverContent width="auto">
            <PopoverBody width="auto">
              <Stack divider={<StackDivider />}>
                <ActionLog
                  style={{ padding: 0 }}
                  action="Created"
                  on={createdOn}
                  by={createdBy}
                  group={createdByGroup}
                />
                {updatedOn ? (
                  <ActionLog
                    style={{ margin: 0, padding: 0 }}
                    action="Updated"
                    group={updatedByGroup}
                    on={updatedOn}
                    by={updatedBy}
                  />
                ) : null}
              </Stack>
            </PopoverBody>
            <PopoverArrow />
          </PopoverContent>
        </Popover>
      </FocusLock>
      <Flex whiteSpace="pre-wrap" pl={2} pr={2} opacity={0.8}>
        {text}
      </Flex>
      <Flex mt="2px" h="20px" as="span" ml="auto">
        {urgent ? <UrgentBadge color="#333" mr={2} urgent /> : null}
        <UserBadge
          userGroup={updatedByGroup || createdByGroup}
          userId={updatedBy || createdBy || ''}
        />
      </Flex>
      {selected ? (
        <LogEntryModal isOpen={selected} onClose={deselect} onSubmit={update} data={log} />
      ) : null}
    </Flex>
  )
}

const ReadOnlyLogEntryView: React.FC<
  LogEntry & {
    createdOn: number
    index?: number
  }
> = ({ index, ...log }) => {
  const {
    text,
    urgent,
    createdOn,
    createdBy,
    updatedBy,
    updatedOn,
    updatedByGroup,
    createdByGroup,
  } = log
  return (
    <Flex
      opacity={0.8}
      _hover={{ opacity: 1 }}
      bg={getRowBackground(index)}
      cursor="pointer"
      w="100%"
      // borderBottom='1px solid #dedede'
      p={1}
      pl={3}>
      <Popover trigger="hover">
        <PopoverTrigger>
          <Text
            // borderBottom='1px solid rgba(0,0,0,0.5)'
            h="20px"
            // color={color || 'black'}
            cursor="pointer"
            opacity={0.7}
            mt="2px"
            fontSize="sm"
            fontWeight="500">
            {getDateString(createdOn, 'short', false)}
          </Text>
        </PopoverTrigger>
        <PopoverContent width="auto">
          <PopoverBody width="auto">
            <Stack divider={<StackDivider />}>
              <ActionLog style={{ padding: 0 }} action="Created" on={createdOn} by={createdBy} />
              {updatedOn ? (
                <ActionLog
                  style={{ margin: 0, padding: 0 }}
                  action="Updated"
                  on={updatedOn}
                  group={updatedByGroup}
                  by={updatedBy}
                />
              ) : null}
            </Stack>
          </PopoverBody>
          <PopoverArrow />
        </PopoverContent>
      </Popover>
      <Flex whiteSpace="pre-wrap" pl={2} pr={2} opacity={0.8}>
        {text}
      </Flex>
      <Flex mt="2px" h="20px" as="span" ml="auto">
        {urgent ? <UrgentBadge color="#444" mr={2} urgent /> : null}
        <UserBadge
          userGroup={updatedByGroup || createdByGroup}
          userId={updatedBy || createdBy || ''}
        />
      </Flex>
    </Flex>
  )
}

export const LogEntryView: React.FC<
  LogEntry & {
    createdOn: number
    index?: number
    selected?: boolean
    select?: () => void
    deselect?: () => void
    update?: (data: LogEntry) => Promise<UpdateCallback>
    placement?: PopoverProps['placement']
  }
> = ({ index, update, placement = 'right', select, deselect, ...log }) => {
  if (update && select && deselect) {
    return (
      <EditableLogEntryView
        index={index}
        update={update}
        placement={placement}
        select={select}
        deselect={deselect}
        {...log}
      />
    )
  }
  return <ReadOnlyLogEntryView index={index} {...log} />
}
