import { getPracticeAssessmentDocPath } from '../collections/collections/assessments'
import { ASSESSMENTS, ASSESSMENTS_ADMIN } from '../collections/names'
import { UserGroup } from '../types'
import { FileField, ListField } from '../types/forms/fields'
import { createFileField } from '../utils/fields'

// admin files are stored in the assessment's patient-files directory as an artifact of the legacy system
export const getAssessmentFilesDir = (assessmentId: string, access: UserGroup) => {
  switch (access) {
    case 'admin':
      return `assessments/${assessmentId}/admin-files`
    case 'practice':
      return `assessments/${assessmentId}/practice-files`
    case 'patient':
      return `assessments/${assessmentId}/shared-files`
    default:
      throw new Error(`Invalid access: ${access}`)
  }
}

export const userGroupToFileIdPrefix = (access: UserGroup) => {
  switch (access) {
    case 'admin':
      return 'adminFiles'
    case 'practice':
      return 'practiceFiles'
    case 'patient':
      return 'files'
    default:
      throw new Error(`Invalid access: ${access}`)
  }
}

export const getAssessmentFilesDocPath = (
  assessmentId: string,
  access: UserGroup,
  practiceId: string | undefined | null,
) => {
  switch (access) {
    case 'admin':
      return `${ASSESSMENTS_ADMIN}/${assessmentId}`
    case 'patient':
      return `${ASSESSMENTS}/${assessmentId}`
    case 'practice':
      if (!practiceId) return null
      return getPracticeAssessmentDocPath(practiceId, assessmentId)
    default:
      throw new Error(`Invalid access: ${access}`)
  }
}

export const getPatientFileField = (name: string): FileField =>
  createFileField({
    name,
    placeholder: 'Patient File',
  })

export const getPatientFilesField = (name: string): ListField => ({
  itemFields: getPatientFileField(name),
  name: 'Files',
  itemName: 'File',
})
