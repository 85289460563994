import { CloseIcon } from '@chakra-ui/icons'
import { Center, Flex, IconButton } from '@chakra-ui/react'
import {
  A4_DIMS_PIXELS,
  CustomElement,
  FileDBValue,
  ImageElementOptions,
  Mode,
  UserFieldItem,
  VariableElement,
} from '@hb/shared'
import React, {
  CSSProperties,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDrag } from 'react-use-gesture'
import { ReactEditor, RenderElementProps } from 'slate-react'
import { useFile, UseFileData } from '../../../../hooks/backend/storage/downloadFile'
import { useFilePath } from '../../../../hooks/backend/storage/useFilePath'
import dragPanIcon from '../../../../icons/drag_pan_thin.svg'
import errorIcon from '../../../../icons/error_red.svg'
import imageIcon from '../../../../icons/image.svg'
import notesIcon from '../../../../icons/notes.svg'
import resizeArrowIcon from '../../../../icons/resize_arrow.svg'
import { SvgIcon } from '../../../../icons/SvgIcon'
import { CustomEditor } from '../../../../types/editor'
import { LoadingImage } from '../../../shared/LoadingImage'
import { TextEditorContext } from '../../context'
import { ImageElementBox } from '../ImageElement'
import './styles.css'
import { getNestedName } from './utils'

const moveIconStyle: CSSProperties = {
  width: '80%',
  height: '80%',
  opacity: 0.7,
  filter: 'invert(1)',
}
const MoveOverlay = ({
  node,
  editor,
}: {
  node: CustomElement & UserFieldItem
  editor: CustomEditor
}) => {
  const dragStart = React.useRef({ x: 0, y: 0 })
  const [isDragging, setIsDragging] = useState(false)
  const { width = 100 } = node?.imageOptions || {}

  const { baseZoom } = useContext(TextEditorContext)
  const onMoveDrag: Parameters<typeof useDrag<React.PointerEvent>>[0] = useCallback(
    ({ movement: [dx, dy], event, first, last }) => {
      event.stopPropagation()
      if (first) {
        dragStart.current = node?.imageOptions?.position || { x: 0, y: 0 }
        setIsDragging(true)
      }
      if (last) {
        setIsDragging(false)
      }
      const maxX = Math.max(0, A4_DIMS_PIXELS[0] - width)
      const maxY = Math.max(0, A4_DIMS_PIXELS[1] - width)
      requestAnimationFrame(() => {
        const path = ReactEditor.findPath(editor, node)
        editor.setNodes(
          {
            imageOptions: {
              ...node?.imageOptions,
              position: {
                x: Math.max(0, Math.min(dragStart.current.x + dx / baseZoom, maxX)),
                y: Math.max(0, Math.min(dragStart.current.y + dy / baseZoom, maxY)),
              },
            },
          },
          { at: path },
        )
      })
    },
    [editor, node, baseZoom, width],
  )
  const bind = useDrag(onMoveDrag)

  return (
    <Center
      userSelect="none"
      position="absolute"
      left={0}
      top={0}
      cursor={isDragging ? 'grabbing' : 'move'}
      w="100%"
      h="100%"
      {...bind()}>
      <img style={moveIconStyle} src={dragPanIcon} alt="move" draggable={false} />
    </Center>
  )
}

const ResizeOverlay = ({ node, editor }: { node: VariableElement; editor: CustomEditor }) => {
  const dragStartWidth = React.useRef(0)
  const { position } = node?.imageOptions || {}

  const [isDragging, setIsDragging] = useState(false)

  const { baseZoom } = useContext(TextEditorContext)
  const width = useMemo(() => node.imageOptions?.width || 100, [node])
  const onResizeDrag: Parameters<typeof useDrag<React.PointerEvent>>[0] = useCallback(
    ({ movement: [dx], event, first, last }) => {
      event.stopPropagation()
      if (first) {
        dragStartWidth.current = node?.imageOptions?.width || 100
        setIsDragging(true)
      }
      if (last) {
        setIsDragging(false)
      }
      const maxWidth = A4_DIMS_PIXELS[0] - (node?.imageOptions?.position?.x || 0)
      requestAnimationFrame(() => {
        const newWidth = Math.min(Math.max(25, dragStartWidth.current + dx / baseZoom), maxWidth)
        const path = ReactEditor.findPath(editor, node)
        editor.setNodes({ imageOptions: { ...node?.imageOptions, width: newWidth } }, { at: path })
      })
    },
    [editor, node, baseZoom],
  )
  const bind = useDrag(onResizeDrag)

  const strokeWidth = Math.min(1, width / 200)

  return (
    <>
      <Center
        userSelect="none"
        position="absolute"
        // left='-7.5%'
        // top='-7.5%'
        w="100%"
        h="100%">
        <div className="resize-container-inner">
          <div className="animated-dashed-border">
            <svg viewBox="0 0 100 100" preserveAspectRatio="none">
              <path strokeWidth={strokeWidth} d="M 2,2 L 98,2 L 98,98 L 2,98 Z" />
            </svg>
          </div>
        </div>
        {position ? <MoveOverlay node={node} editor={editor} /> : null}
        <div
          style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
          {...bind()}
          className="resize-handle">
          <img src={resizeArrowIcon} alt="resize" draggable={false} />
        </div>
      </Center>
    </>
  )
}

const ImageVariableElementEditOverlay = ({
  node,
  editor,
  options,
  onDelete,
  active: _active,
}: {
  node: CustomElement & UserFieldItem
  editor: CustomEditor
  options?: ImageElementOptions
  onDelete: () => void
  active: boolean
}) => {
  const [mounted, setMounted] = React.useState(false)
  useEffect(() => {
    setTimeout(() => {
      setMounted(true)
    }, 10)
  }, [])

  const { width = 100, position } = options || {}

  const onMakeInline = useCallback(() => {
    // unset position from node
    const path = ReactEditor.findPath(editor, node)
    const newOptions = { ...options }
    delete newOptions.position
    editor.setNodes({ imageOptions: newOptions }, { at: path })
  }, [editor, node, options])

  const onMakePositional = useCallback(() => {
    // set position to node
    const path = ReactEditor.findPath(editor, node)
    editor.setNodes({ imageOptions: { ...options, position: { x: 0, y: 0 } } }, { at: path })
  }, [editor, node, options])

  const active = _active && mounted
  return (
    <Flex
      position="absolute"
      top={0}
      w="100%"
      h="100%"
      left={0}
      opacity={active ? 1 : 0}
      pointerEvents={active ? 'auto' : 'none'}
      transition="opacity 0.3s"
      bg="blackAlpha.500">
      <Center w="100%" h="100%" position="relative">
        <ResizeOverlay node={node as VariableElement} editor={editor} />
        {width > 50 ? (
          <IconButton
            variant="ghost"
            borderRadius="full"
            minW="0"
            minH="0"
            w="16px"
            pos="absolute"
            right={1}
            bottom={1}
            h="16px"
            color="white"
            bg="red.400"
            onClick={e => {
              e.stopPropagation()
              onDelete()
            }}
            aria-label="Remove variable"
            icon={<CloseIcon width={2} />}
          />
        ) : null}
        <IconButton
          aria-label="Make inline"
          variant="unstyled"
          borderRadius="full"
          minW="0"
          display="flex"
          alignItems="center"
          justifyContent="center"
          minH="0"
          w="20px"
          pos="absolute"
          left="-10px"
          top="-10px"
          boxShadow="1px 1px 3px #00000077"
          h="20px"
          color="gray.600"
          bg="white"
          onClick={position ? onMakeInline : onMakePositional}
          icon={
            position ? (
              <SvgIcon draggable={false} width="16px" opacity={0.5} src={notesIcon} />
            ) : (
              <SvgIcon draggable={false} width="20px" opacity={0.5} src={dragPanIcon} />
            )
          }
        />
      </Center>
      <span className="field-name-tooltip">{getNestedName(node.path)}</span>
    </Flex>
  )
}

export const FileVariableElement = ({
  node,
  editor,
  value,
  mode,
  nodeStyle,
  onPointerDown,
  isSelected,
  onDelete,
  children,
  attributes,
}: PropsWithChildren<{
  node: CustomElement & UserFieldItem
  editor: CustomEditor
  value?: FileDBValue
  mode: Mode
  attributes: RenderElementProps['attributes']
  onPointerDown: (event: React.PointerEvent<Element>) => void
  onDelete: () => void
  isSelected: boolean
  nodeStyle?: CSSProperties
}>) => {
  const { path, imageOptions } = node || {}
  const { downloadPath, recentlyUploaded } = useFilePath(value)
  const fileArgs = useMemo<UseFileData>(
    () => ({
      path: downloadPath,
      pathLoading: recentlyUploaded,
      value,
    }),
    [downloadPath, recentlyUploaded, value],
  )
  const { url, loading } = useFile(fileArgs)
  const { width = 100, opacity = 1, rotate = 0 } = imageOptions || {}
  const placeholderImage = useMemo(() => {
    if (loading) return imageIcon
    return mode === 'Edit' ? imageIcon : errorIcon
  }, [mode, loading])

  const boxStyle = useMemo<CSSProperties>(
    () => ({
      cursor: mode === 'Edit' ? 'pointer' : 'default',
      boxShadow: mode === 'Edit' && !url ? '0 0 0 1px #cdcdcd' : 'none',
      borderRadius: '4px',
      ...nodeStyle,
    }),
    [nodeStyle, mode, url],
  )

  const body = (
    <LoadingImage
      opacity={opacity}
      transform={`rotate(${rotate}deg)`}
      src={url}
      draggable={false}
      placeholderSrc={placeholderImage}
      style={{ width: `${width}px` }}
      loading={loading}
      alt={path}
      width={width}
      height={url ? undefined : width}
    />
  )
  return (
    <ImageElementBox
      style={boxStyle}
      onPointerDown={mode === 'Edit' ? onPointerDown : undefined}
      attributes={attributes}
      options={imageOptions}>
      {body}
      <span style={{ display: 'none' }}>{children}</span>
      {mode === 'Edit' && isSelected ? (
        <ImageVariableElementEditOverlay
          editor={editor}
          options={imageOptions}
          onDelete={onDelete}
          node={node}
          active={isSelected}
        />
      ) : null}
    </ImageElementBox>
  )
}
