import { AddIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  HStack,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { colors, FieldMapValue, newPatientField, newPracticePatientField } from '@hb/shared'

import { FORM_ERROR } from 'final-form'
import React, { useCallback, useContext, useMemo } from 'react'
import { inviteUser } from '../../backend/auth'
import { useApp } from '../../contexts'
import { PopUpMessageContext } from '../../contexts/PopUpMessage/PopUpMessageContext'
import { usePracticeAccess } from '../../contexts/PracticeAccess/PracticeAccessProvider'
import { PatientIcon } from '../../icons/PatientIcon'
import { SimpleForm } from '../forms/FinalForm/SimpleForm'
import { DefaultModal } from '../Modals'

export const AddPatientPopover = () => {
  const { selectedPracticeId } = usePracticeAccess()
  const { processResponse } = useContext(PopUpMessageContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { appName } = useApp()
  const field = useMemo(
    () => (appName === 'app' ? newPatientField : newPracticePatientField),
    [appName],
  )
  const handleSubmit = useCallback(
    async (data: FieldMapValue) => {
      if (!selectedPracticeId) {
        return { [FORM_ERROR]: 'No practice selected' }
      }
      return inviteUser(appName, data, selectedPracticeId).then(res => {
        // processResponse(res)
        if (res.error) {
          processResponse({ error: res.error })
          return { [FORM_ERROR]: res.error }
        }
        processResponse({ success: 'Patient added' })
        onClose()
        return undefined
      })
    },
    [processResponse, selectedPracticeId, appName, onClose],
  )
  return (
    <Popover isOpen={isOpen} onClose={onClose} onOpen={onOpen} strategy="fixed">
      <PopoverTrigger>
        <Box>
          <Tooltip hasArrow placement="bottom" label="Add New Patient">
            <Button
              variant="link"
              size="md"
              fontWeight={300}
              fontFamily="hero-new"
              bg={colors.green.hex}
              color="gray.800"
              _hover={{ bg: 'green.300' }}>
              <HStack spacing={1} pr={2} pl={3} py={1}>
                <AddIcon width={3} color="white" /> <PatientIcon />
              </HStack>
            </Button>
          </Tooltip>
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader
            bg="gray.100"
            borderTopRadius={6}
            fontFamily="Comfortaa"
            fontWeight={600}
            color={colors.green.hex}>
            Add Patient
          </PopoverHeader>
          {isOpen ? <SimpleForm theme="detailed" field={field} onSubmit={handleSubmit} /> : null}
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export const AddPatientModal = () => {
  const { selectedPracticeId } = usePracticeAccess()
  const { processResponse } = useContext(PopUpMessageContext)
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { appName } = useApp()
  const handleSubmit = useCallback(
    async (data: FieldMapValue) => {
      if (!selectedPracticeId) {
        return { [FORM_ERROR]: 'No practice selected' }
      }
      return inviteUser(appName, data, selectedPracticeId).then(res => {
        if (res.error) {
          processResponse({ error: res.error })
          return { [FORM_ERROR]: res.error }
        }
        processResponse({ success: 'Patient added' })
        // history.push(`/patients/${res.success}`)
        onClose()
        return undefined
      })
    },
    [processResponse, selectedPracticeId, appName, onClose],
  )

  const field = useMemo(
    () => (appName === 'app' ? newPatientField : newPracticePatientField),
    [appName],
  )

  return (
    <Box>
      <Tooltip hasArrow placement="bottom" label="Add New Patient">
        <Button
          variant="link"
          size="md"
          fontWeight={300}
          fontFamily="hero-new"
          bg={colors.green.hex}
          color="gray.800"
          onClick={() => onOpen()}
          _hover={{ bg: 'green.300' }}>
          <HStack spacing={1} pr={2} pl={3} py={1}>
            <AddIcon width={3} color="white" /> <PatientIcon />
          </HStack>
        </Button>
      </Tooltip>
      <DefaultModal
        overlayHeader
        isOpen={isOpen}
        onClose={onClose}
        render={() => (
          <Box>
            <Flex bg="gray.50" w="100%" borderBottom="1px solid #cdcdcd" px={4} pt={4} pb={3}>
              <Text fontWeight={500} color="gray.500" lineHeight={1} fontFamily="Hero-New">
                Add Patient
              </Text>
            </Flex>
            <SimpleForm
              boxProps={{ pt: 0 }}
              theme="detailed"
              field={field}
              onSubmit={handleSubmit}
            />
          </Box>
        )}
      />
    </Box>
  )
}
