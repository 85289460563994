import { Flex, Text } from '@chakra-ui/react'
import {
  ASSESSMENTS,
  getCoverageConfirmationRequired,
  getUniqueRandomId,
  isInsuranceCoverageRequest,
  PopulatedAssessment,
  separateAdditionalPlansAndRequests,
} from '@hb/shared'

import { collection, doc, updateDoc } from 'firebase/firestore'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { db } from '../../../backend/db'
import { PopUpMessageContext } from '../../../contexts/PopUpMessage/PopUpMessageContext'
import { useAuth } from '../../../store/auth'
import { ActionButton, SolidActionButton } from '../../Buttons/ActionButton'
import { AdditionalPlansView } from '../AdditionalPlansView'
import { SecondaryCoverageForm } from './SecondaryCoverageForm'

export const FormsViewAdditionalPlans = ({
  assessment,
  assessmentId,
  openPlanId,
  onOpenPlan,
}: {
  assessmentId: string
  assessment: PopulatedAssessment
  openPlanId: string | null
  onOpenPlan: (id: string | null) => void
}) => {
  const { plans } = assessment || {}
  const confirmationRequired = useMemo(
    () => (assessment ? getCoverageConfirmationRequired(assessment) : true),
    [assessment],
  )
  const authUser = useAuth(s => s.authUser)
  const { additionalPlans: additionalPlansAndRequests } = assessment || {}
  const { plans: additionalPlans, requests } = useMemo(
    () => separateAdditionalPlansAndRequests(additionalPlansAndRequests),
    [additionalPlansAndRequests],
  )

  const additionalPlanIds = useMemo(
    () => Object.keys(additionalPlans || {}).map(p => p.split('.')[1] || ''),
    [additionalPlans],
  )
  const { secondaryCoverage } = useMemo(() => {
    const coverage = Object.entries(additionalPlans || {}).find(([, plan]) =>
      isInsuranceCoverageRequest(plan) ? false : plan.label === 'secondary',
    )
    if (!coverage) {
      return {
        secondaryCoverage: null,
        secondaryCoverageId: `additionalPlans.${getUniqueRandomId(additionalPlanIds)}`,
      }
    }
    return {
      secondaryCoverage: coverage[1],
      secondaryCoverageId: coverage[0],
    }
  }, [additionalPlans, additionalPlanIds])

  const [secondaryCoverageType, setSecondaryCoverageType] = useState<
    'non-medicaid' | 'medicaid' | null
  >(null)
  const [loading, setLoading] = useState(false)

  const { showMessage } = useContext(PopUpMessageContext)
  const onConfirmNoPlans = useCallback(async () => {
    if (!authUser) return
    setLoading(true)
    try {
      updateDoc(doc(collection(db, ASSESSMENTS), assessmentId), {
        additionalPlansConfirmedOn: Date.now(),
        additionalPlansConfirmedBy: authUser.uid,
      })
    } catch (err: any) {
      showMessage({
        text: 'Error updating assessment',
        subText: err.message,
        type: 'error',
      })
    }
    setLoading(false)
  }, [authUser, assessmentId, showMessage])

  let body = null
  if (confirmationRequired && !secondaryCoverage) {
    body = secondaryCoverageType ? (
      <SecondaryCoverageForm
        assessment={assessment}
        assessmentId={assessmentId}
        isMedicaid={secondaryCoverageType === 'medicaid'}
        onBack={() => setSecondaryCoverageType(null)}
      />
    ) : (
      <Flex bg="white" flexFlow="column" w="100%" px={3}>
        <Text px={2} py={1} color="gray.600">
          Is there another plan that covers or will cover you during the course of your pregnancy?
        </Text>
        <Flex mt={2} w="100%" gap={2} flexFlow="column">
          <ActionButton w="100%" onClick={onConfirmNoPlans} isLoading={loading} size="sm">
            I do not have secondary coverage
          </ActionButton>
          <SolidActionButton
            onClick={() => setSecondaryCoverageType('non-medicaid')}
            flex={1}
            size="sm">
            I have secondary coverage (not medicaid)
          </SolidActionButton>
          <SolidActionButton
            onClick={() => setSecondaryCoverageType('medicaid')}
            flex={1}
            size="sm">
            I have secondary coverage through medicaid
          </SolidActionButton>
        </Flex>
      </Flex>
    )
  } else {
    body = (
      <AdditionalPlansView
        noHeader
        assessment={assessment}
        openPlanId={openPlanId}
        plans={plans}
        requests={requests}
        onOpenPlan={onOpenPlan}
        assessmentId={assessmentId}
      />
    )
  }

  return (
    <Flex pb={2} flexFlow="column" w="100%">
      <Flex align="center" px={3} w="100%">
        <Text fontSize="md" fontWeight={600}>
          Additional Plans
        </Text>
      </Flex>
      {body}
    </Flex>
  )
}
