import { CloseIcon } from '@chakra-ui/icons'
import {
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import { DateField, DateTimeField, getDateString } from '@hb/shared'
import React, { useContext, useMemo } from 'react'
import { DayPicker } from 'react-day-picker'
import { ThemeContext } from '../../../../contexts/ThemeContext'

const currYear = new Date().getFullYear()

export const DatePicker = ({
  value,
  onChange,
  isOpen,
  onBlur,
  onFocus,
  name,
  date,
  utc,
  field,
}: {
  value?: number
  onChange: (v: number | string | undefined) => void
  isOpen: boolean
  onFocus: () => void
  name: string
  onBlur: () => void
  date: Date | null
  utc?: boolean
  field: DateField | DateTimeField
}) => {
  const { theme } = useContext(ThemeContext)
  const { optional, minDate, isoFormat } = field
  const minYear = useMemo(() => {
    if (!minDate) return 1923
    if (minDate === 'now') return new Date().getFullYear()
    return new Date(minDate).getFullYear()
  }, [minDate])

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onFocus}
      onClose={onBlur}
      trigger="click"
      strategy="fixed"
      closeDelay={100}
      placement="bottom">
      <HStack>
        <PopoverTrigger>
          <HStack
            spacing={2}
            px={theme === 'basic' ? 2 : 3}
            pl={theme === 'basic' ? 1 : 2}
            cursor="pointer"
            py={theme === 'basic' ? 1 : 2}
            background="white"
            boxShadow="md"
            width="150px"
            border={theme === 'basic' ? undefined : '1px solid #cdcdcd'}
            _hover={{ background: 'gray.50' }}
            borderRadius={6}
            color={value ? 'gray.700' : 'gray.500'}
            // style={style}
          >
            <Text
              // w='100%'
              fontFamily="hero-new"
              bg="transparent"
              flex={theme === 'basic' ? undefined : 1}
              variant="unstyled"
              display="flex"
              justifyContent="flex-start"
              // mx={1}
              // fontSize={theme === 'basic' ? 'sm' : 'md'}
              fontSize="sm"
              // size='sm'
              px={2}
              py={theme === 'basic' ? 1 : 0}
              color="inherit"
              fontWeight="normal">
              {value ? getDateString(value, 'short', !utc) : 'Date'}
            </Text>
            {value ? (
              <IconButton
                ml="auto"
                size="xs"
                width="20px"
                minW="0"
                height="20px"
                bg="gray.100"
                borderRadius="full"
                color="white"
                aria-label="Clear"
                icon={<CloseIcon color="gray.500" w={2} h={2} />}
                onClick={e => {
                  e.stopPropagation()
                  onChange(undefined)
                }}
              />
            ) : null}
          </HStack>
        </PopoverTrigger>
      </HStack>
      <PopoverContent zIndex={2} onFocus={onFocus}>
        <PopoverArrow />
        <PopoverCloseButton />
        <HStack>
          <DayPicker
            required={!optional}
            // disabled={disabled}
            defaultMonth={date || undefined}
            captionLayout="dropdown-buttons"
            fromYear={minYear}
            toYear={currYear + 5}
            styles={{
              caption: {
                fontFamily: 'hero-new',
                fontWeight: '400',
                color: '#555',
              },
              head: {
                fontFamily: 'hero-new',
              },
              nav: {
                fontFamily: 'hero-new',
              },
            }}
            modifiersStyles={{
              selected: {
                background: 'rgb(67, 173, 158)',
              },
            }}
            id={name}
            mode="single"
            selected={date || undefined}
            onMonthChange={updated => {
              if (!value) return
              const modified = new Date(
                updated.getTime() - (utc ? 0 : updated.getTimezoneOffset() * 60 * 1000),
              )
              modified.setUTCDate((date || new Date()).getUTCDate())
              onChange(isoFormat ? modified?.toISOString().split('T')[0] : modified?.getTime())
            }}
            onDayClick={updated => {
              if (isoFormat) {
                onChange(updated?.toISOString().split('T')[0])
              } else {
                onChange(
                  updated
                    ? updated.getTime() - (utc ? 0 : updated.getTimezoneOffset() * 60 * 1000)
                    : undefined,
                )
                onBlur()
              }
            }}
          />
        </HStack>
      </PopoverContent>
    </Popover>
  )
}
