import { AddIcon, CloseIcon } from '@chakra-ui/icons'
import {
  Box,
  HStack,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  FieldMap,
  FieldMapValue,
  FieldTypes,
  InvoicePaymentInstallment,
  PracticeInvoice,
  ReportPaymentInstallmentArgs,
  WithId,
} from '@hb/shared'
import { FORM_ERROR } from 'final-form'
import React, { useCallback, useContext, useMemo } from 'react'
import { reportPaymentInstallment } from '../../backend'
import { useApp } from '../../contexts/AppContext'
import { PopUpMessageContext } from '../../contexts/PopUpMessage/PopUpMessageContext'
import { SimpleForm } from '../forms'
import { DefaultModal } from '../Modals'

const recordPaymentField: FieldMap = {
  name: 'Record payment',
  children: {
    amount: {
      type: FieldTypes.DOLLAR_AMOUNT,
      placeholder: 'Payment Amount',
    },
    paidOn: {
      type: FieldTypes.DATE,
      defaultToNow: true,
      placeholder: 'Payment Date',
    },
    type: {
      placeholder: 'Payment Type (e.g. Cash, Check, ACH)',
      type: FieldTypes.TEXT,
    },
  },
}

export const RecordInvoicePayment = ({
  invoice,
  onClose,
  installmentId,
}: {
  invoice: WithId<PracticeInvoice>
  onClose: () => void
  installmentId?: string
}) => {
  const { appName } = useApp()

  const initValue = useMemo(() => {
    if (installmentId) {
      return invoice.installments?.[installmentId] || {}
    }
    return {}
  }, [invoice, installmentId])

  const { processResponse } = useContext(PopUpMessageContext)

  const onSubmit = useCallback(
    async (data: FieldMapValue) => {
      if (!invoice.id) {
        return processResponse({ error: 'Invoice not found' })
      }
      if (!data.amount) {
        return processResponse({ error: 'Amount is required' })
      }
      if (!data.paidOn) {
        return processResponse({ error: 'Payment Date is required' })
      }
      if (!data.type) {
        return processResponse({ error: 'Payment Type is required' })
      }
      const submitted: ReportPaymentInstallmentArgs = {
        appName,
        installment: data as InvoicePaymentInstallment,
        invoiceId: invoice.id,
      }

      if (installmentId) {
        submitted.installmentId = installmentId
      }

      return reportPaymentInstallment(submitted)
        .then(() => {
          onClose()
          return undefined
        })
        .catch((err: any) => {
          processResponse({ error: err?.message || 'Error recording payment' })
          return { [FORM_ERROR]: 'Error recording payment' }
        })
    },
    [appName, installmentId, invoice, processResponse, onClose],
  )
  return (
    <VStack spacing={0} align="flex-start">
      <HStack spacing={4} w="100%" p={2} bg="green.400">
        <Text
          color="white"
          fontWeight={500}
          fontFamily="Hero-New"
          textShadow="1px 1px 4px #00000066">
          Record Payment for Invoice #{invoice.invoiceNumber}
        </Text>
        <IconButton
          bg="blackAlpha.300"
          color="gray.50"
          ml="auto"
          size="xs"
          borderRadius="full"
          aria-label="Close"
          icon={<CloseIcon />}
          _hover={{
            bg: 'blackAlpha.400',
            color: 'gray.100',
          }}
          onClick={onClose}
        />
      </HStack>
      <SimpleForm field={recordPaymentField} value={initValue} onSubmit={onSubmit} />
    </VStack>
  )
}

export const RecordPaymentModal = ({
  invoice,
  installmentId,
  isOpen,
  onClose,
}: {
  invoice: WithId<PracticeInvoice>
  installmentId?: string
  isOpen: boolean
  onClose: () => void
}) => (
  <DefaultModal
    overlayHeader
    isOpen={isOpen}
    onClose={onClose}
    closeDisabled
    render={() => (
      <RecordInvoicePayment invoice={invoice} onClose={onClose} installmentId={installmentId} />
    )}
  />
)

export const RecordPaymentButton = ({
  invoice,
  installmentId,
}: {
  invoice: WithId<PracticeInvoice>
  installmentId?: string
}) => (
  <Popover closeOnBlur={false} closeOnEsc={false} placement="bottom-end" strategy="fixed" isLazy>
    {({ onClose }) => (
      <>
        <PopoverTrigger>
          <Box>
            <Tooltip
              label={invoice.sentOn ? 'Record Payment' : 'Send invoice to record a payment'}
              placement="top"
              bg={invoice.sentOn ? 'green.500' : 'gray.500'}
              hasArrow>
              <IconButton
                aria-label="Record Payment"
                variant="unstyled"
                color="white"
                height="100%"
                display="flex"
                bg={invoice.sentOn ? 'green.400' : 'gray.400'}
                borderRadius="full"
                alignItems="center"
                minH="0"
                minW="0"
                w="24px"
                h="24px"
                size="sm"
                boxShadow="1px 1px 4px #00000077"
                _hover={{
                  bg: invoice.sentOn ? 'green.100' : 'gray.500',
                  color: invoice.sentOn ? 'green.500' : 'gray.200',
                }}
                icon={<AddIcon />}
              />
            </Tooltip>
          </Box>
        </PopoverTrigger>
        <Portal>
          <PopoverContent w="auto">
            <RecordInvoicePayment
              invoice={invoice}
              onClose={onClose}
              installmentId={installmentId}
            />
          </PopoverContent>
        </Portal>
      </>
    )}
  </Popover>
)
