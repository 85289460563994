import { DateField } from '@hb/shared'
import React, { forwardRef, useContext } from 'react'
import { ScreenContext } from '../../../../contexts'
import { InputElement, InputProps } from '../../../../types/fields'
import { NativeDateInput } from './NativeDateInput'
import { PopoverDateInput } from './PopoverDateInput'

export const DateInput: InputElement<DateField> = forwardRef<
  { focus: () => void },
  InputProps<DateField>
>((props, ref) => {
  const { isMobile } = useContext(ScreenContext)
  return props.field.nativeInput && !isMobile ? (
    <NativeDateInput {...props} ref={ref} />
  ) : (
    <PopoverDateInput {...props} ref={ref} />
  )
})
