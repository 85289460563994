import { DeleteIcon } from '@chakra-ui/icons'
import {
  Button,
  ButtonProps,
  Divider,
  HStack,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import {
  colors,
  FieldMap,
  FieldMapValue,
  FieldTypes,
  FileDBValue,
  getPatientFileField,
  getPlansArray,
  insuranceCardBackField,
  insuranceCardFrontField,
  PopulatedNode,
  SendFaxArgs,
  UpdateCallback,
} from '@hb/shared'

import arrayMutators from 'final-form-arrays'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays'
import { ReactEditor, useSlate } from 'slate-react'
import { PopUpMessageContext, ThemeContext } from '../../contexts'
import { UserContext } from '../../contexts/UserContext'
import { BlueButton } from '../Buttons'
import { FileView, FormElement } from '../forms'
import { DefaultModal } from '../Modals/DefaultModal'
import { TextEditorContext } from '../RichText/context'
import { useTemplateView } from './contexts'

import { TemplateErrorsAlert } from './TemplateErrorsAlert'

export const useOnFax = () => {
  const { onFax } = useTemplateView()
  // TODO: show a dialog to collect phone number to fax to
  const [textToSend, setTextToSend] = useState<string | null>(null)
  const [faxSending, setFaxSending] = useState(false)
  const [faxErrors, setFaxErrors] = useState<Array<PopulatedNode> | null>(null)
  const { processResponse } = useContext(PopUpMessageContext)

  const handleSubmitFaxNumber = useCallback(
    async ({
      faxToNumber,
      notes,
      fileIds,
    }: Omit<SendFaxArgs, 'assessmentId' | 'html' | 'category'>): Promise<UpdateCallback> => {
      if (!onFax) {
        return { error: 'Internal error - missing submit function' }
      }
      if (!textToSend) {
        return { error: 'internal error - missing text to send' }
      }
      setFaxSending(true)
      const res = await onFax({
        faxToNumber,
        notes,
        html: textToSend,
        fileIds,
      })
      if (res.success) {
        setTextToSend(null)
        setFaxErrors(null)
      }
      setFaxSending(false)
      return processResponse(res)
    },
    [textToSend, onFax, processResponse],
  )

  const handleFax = useCallback((text: string) => {
    setTextToSend(text)
  }, [])

  return {
    faxSending,
    faxErrors,
    textToSend,
    handleFax,
    handleSubmitFaxNumber,
    onCancel: () => {
      setTextToSend(null)
      setFaxErrors(null)
    },
  }
}

const faxDetails: FieldMap = {
  name: 'Fax Details',
  children: {
    faxNumber: {
      type: FieldTypes.PHONE,
      placeholder: 'Number to fax to',
    },
    notes: {
      type: FieldTypes.TEXTAREA,
      optional: true,
      placeholder: 'Notes...',
    },
  },
} as const

const getInsuranceCardField = (id: string) => {
  if (id.endsWith('front')) {
    return insuranceCardFrontField
  } else {
    return insuranceCardBackField
  }
}

const FilePreview = ({
  file,
  onClick,
  onDelete,
  id,
}: {
  file: FileDBValue
  assessmentId: string
  onClick?: () => void
  onDelete?: () => void
  id: string
}) => {
  const field = useMemo(() => {
    const [firstSegment] = id.split('.')
    switch (firstSegment) {
      case 'files':
        return getPatientFileField(id.substring(6))
      case 'adminFiles':
        return getPatientFileField(id.substring(11))
      case 'practiceFiles':
        return getPatientFileField(id.substring(14))
      default:
        return getInsuranceCardField(id)
    }
  }, [id])

  return (
    <HStack
      bg="white"
      position="relative"
      _hover={onClick ? { bg: 'blackAlpha.100' } : undefined}
      cursor={onClick ? 'pointer' : 'default'}
      onClick={onClick}
      aria-label={file?.name}
      spacing={0}
      h="auto"
      w="100%"
      align="center">
      <HStack minW="0" flex={1}>
        <FileView field={field} data={file} />
      </HStack>
      {onDelete ? (
        <Button
          position="absolute"
          h="30px"
          w="30px"
          variant="ghost"
          size="xs"
          right={1}
          _hover={{ bg: 'blackAlpha.100' }}
          top={1}
          colorScheme="red"
          onClick={onDelete}>
          <DeleteIcon w={3} h={3} color="red.600" />
        </Button>
      ) : null}
    </HStack>
  )
}

const AdditionalFilesField = ({ fields }: FieldArrayRenderProps<string, any>) => {
  const { selectedAssessment, assessmentId } = useContext(UserContext)
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { adminFiles, practiceFiles, files, plans } = selectedAssessment?.populated || {}

  const allFiles = useMemo(() => {
    const plansArr = getPlansArray(plans || {})
    const cardImages = plansArr.reduce(
      (acc, plan) => {
        if (plan.insuranceCard?.front) {
          acc[`${plan.id}.insuranceCard.front`] = plan.insuranceCard.front
        }
        if (plan.insuranceCard?.back) {
          acc[`${plan.id}.insuranceCard.back`] = plan.insuranceCard.back
        }
        return acc
      },
      {} as Record<string, FileDBValue>,
    )

    let res: Record<string, FileDBValue> = Object.entries(files || {}).reduce(
      (acc, [id, data]) => ({ ...acc, [`files.${id}`]: { ...data } }),
      {} as Record<string, FileDBValue>,
    )
    res = {
      ...res,
      ...Object.entries(adminFiles || {}).reduce(
        (acc, [id, data]) => ({ ...acc, [`adminFiles.${id}`]: { ...data } }),
        {} as Record<string, FileDBValue>,
      ),
      ...Object.entries(practiceFiles || {}).reduce(
        (acc, [id, data]) => ({ ...acc, [`practiceFiles.${id}`]: { ...data } }),
        {} as Record<string, FileDBValue>,
      ),
    }
    return { ...cardImages, ...res }
  }, [files, adminFiles, practiceFiles, plans])
  return (
    <VStack pt={2} spacing={2} w="100%" align="flex-start">
      <HStack justify="space-between" w="100%">
        <Text color="gray.600" fontFamily="Hero-New" fontSize="sx">
          Additional Files
        </Text>
        <Popover
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          onOpen={onOpen}
          strategy="fixed">
          <PopoverTrigger>
            <Button size="xs" bg={colors.green.hex} color="white">
              + Add File
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent maxH="500px" h="auto" overflowY="auto" w="auto">
              <PopoverCloseButton />
              <PopoverBody width="auto" p={0}>
                <VStack p={2}>
                  {assessmentId && files
                    ? Object.entries(allFiles).map(([key, value]) => (
                        <FilePreview
                          onClick={() => {
                            fields.push(key)
                            onClose()
                          }}
                          key={key}
                          assessmentId={assessmentId}
                          file={value}
                          id={key}
                        />
                      ))
                    : null}
                </VStack>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      </HStack>
      <VStack
        border="1px solid #cdcdcd"
        borderRadius={6}
        overflow="hidden"
        spacing={0}
        divider={<Divider />}
        pointerEvents="auto"
        w="100%"
        align="flex-start">
        {files && assessmentId && fields?.length ? (
          fields.map((fieldId, index) => (
            <Field
              key={fieldId}
              name={fieldId}
              render={({ input: { value } }) => (
                <FilePreview
                  onDelete={() => fields.remove(index)}
                  assessmentId={assessmentId}
                  file={allFiles?.[value]}
                  id={value}
                />
              )}
            />
          ))
        ) : (
          <Text color="gray.600" px={2} py={1}>
            No files added
          </Text>
        )}
      </VStack>
    </VStack>
  )
}

export const FaxDetailsEntryModal = ({
  onSubmit,
  isOpen,
  onCancel,
}: {
  isOpen: boolean
  onSubmit: (
    args: Omit<SendFaxArgs, 'html' | 'assessmentId' | 'category'>,
  ) => Promise<UpdateCallback>
  onCancel: () => void
}) => {
  const submit = useCallback(
    (data: FieldMapValue) =>
      onSubmit({
        faxToNumber: data.faxNumber,
        notes: data.notes,
        fileIds: data.fileIds,
      }),
    [onSubmit],
  )
  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={onCancel}
      overlayHeader
      render={() => (
        <ThemeContext.Provider value={{ theme: 'detailed' }}>
          <Form onSubmit={submit} mutators={{ ...arrayMutators }}>
            {({ handleSubmit, submitting }) => (
              <VStack w="100%" py={3} px={5} align="flex-start" spacing={0}>
                <FormElement name="faxNumber" field={faxDetails.children.faxNumber} />
                <FormElement name="notes" field={faxDetails.children.notes} />
                <FieldArray name="fileIds">
                  {({ fields, meta }) => <AdditionalFilesField meta={meta} fields={fields} />}
                </FieldArray>
                <HStack w="100%" pt={2}>
                  <Button
                    onClick={handleSubmit}
                    isLoading={submitting}
                    ml="auto"
                    size="sm"
                    bg={colors.green.hex}
                    color="white">
                    Send Fax
                  </Button>
                </HStack>
              </VStack>
            )}
          </Form>
        </ThemeContext.Provider>
      )}
    />
  )
}

export const EditorFaxButton = ({
  onCancel,
  faxErrors,
  handleFax,
  faxSending,
  handleSubmitFaxNumber,
  textToSend,
  ...props
}: ButtonProps & ReturnType<typeof useOnFax>) => {
  const editor = useSlate()
  const { mode } = useContext(TextEditorContext)
  const isEnabled = mode === 'View' && !faxSending

  return (
    <>
      <Tooltip
        placement="top"
        hasArrow
        label={mode === 'Edit' ? 'Must be in View mode to send fax' : null}>
        <BlueButton
          mt={0}
          size="xs"
          style={{ fontSize: '13px' }}
          isLoading={faxSending}
          onClick={() => {
            if (isEnabled) handleFax(ReactEditor.toDOMNode(editor, editor).innerHTML as any)
          }}
          {...props}
          // pointerEvents = {isEnabled ? 'auto' : 'none'}
          opacity={isEnabled ? '1' : '0.5'}>
          Send Fax
        </BlueButton>
      </Tooltip>
      <TemplateErrorsAlert errors={faxErrors} onCancel={onCancel} />
      <FaxDetailsEntryModal
        onCancel={onCancel}
        isOpen={!!textToSend}
        onSubmit={handleSubmitFaxNumber}
      />
    </>
  )
}
