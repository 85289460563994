import { QuestionIcon } from '@chakra-ui/icons'
import { IconButton } from '@chakra-ui/react'
import { colors } from '@hb/shared'
import { useAppModals } from '@hb/shared-frontend'
import React from 'react'

export const HelpButton = () => {
  const {
    assessmentHelp: { open },
  } = useAppModals()
  return (
    <IconButton
      borderRadius="full"
      aria-label="help"
      ml="auto"
      //  color='white'
      size="sm"
      // background='transparent'
      boxShadow={`0 0 6px ${colors.green.hex}`}
      bg={colors.green.hex}
      // bg='white'
      // boxShadow='md'
      fontWeight={800}
      color="whiteAlpha.900"
      _hover={{ background: 'green.400' }}
      icon={<QuestionIcon h={7} w={7} />}
      // pos='absolute'
      onClick={() => open()}
    />
  )
}
