import { Text, Tooltip } from '@chakra-ui/react'
import React from 'react'

export const EllipsisText = ({ text, maxWidth }: { text: string; maxWidth?: number }) => (
  <Tooltip placement="top" hasArrow label={text}>
    <Text maxW={maxWidth} px={1} whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
      {text && text !== 'zzz' ? text : 'None'}
    </Text>
  </Tooltip>
)
