import {
  capitalizeFirstLetter,
  FieldTypes,
  Payment,
  PaymentRecord,
  PaymentType,
  PopulatedAssessment,
  Shortcut,
  ShortcutMap,
} from '@hb/shared'

const baseReceivedDropdownChildren: Record<string, Shortcut | ShortcutMap> = {
  totalFromPatient: {
    name: 'Total received from patient',
    shortcut: true,
    type: FieldTypes.DOLLAR_AMOUNT,
  },
  totalFromInsurer: {
    name: 'Total received from insurer',
    shortcut: true,
    type: FieldTypes.DOLLAR_AMOUNT,
  },
}

const getThreePayments = (type: PaymentType): PaymentRecord<Payment> => ({
  [`${type}-0`]: {
    type,
    amount: 0,
    // date: Date.now(),
  },
  [`${type}-1`]: {
    type,
    amount: 0,
  },
  [`${type}-2`]: {
    type,
    amount: 0,
  },
})

const initialRetainerShortcut: Shortcut = {
  name: 'Initial Retainer',
  shortcut: true,
  type: FieldTypes.DOLLAR_AMOUNT,
}

const initialHomeBirthRetainerShortcut: Shortcut = {
  name: 'Initial Home Birth Retainer',
  shortcut: true,
  type: FieldTypes.DOLLAR_AMOUNT,
}

const threeDeposits = getThreePayments('deposit')

const getDeposits = (payments?: PaymentRecord<Payment>): PaymentRecord<Payment> =>
  Object.entries(payments || {}).reduce((acc, [key, payment]) => {
    const { type } = payment
    if (type !== 'deposit') return acc
    return {
      ...acc,
      [key]: payment,
    }
  }, {} as PaymentRecord<Payment>)

// const threeHomeBirthRetainers = getThreePayments('home-birth-retainer')
// const allDropdownPayments = { ...threeDeposits }
const getPaymentsDropdownChildren = (payments?: PaymentRecord<Payment>) => {
  const deposits = getDeposits(payments)
  return Object.values(
    Object.keys(deposits).length > 3 ? (deposits as PaymentRecord<Payment>) : threeDeposits,
  ).reduce(
    (acc, value) => {
      const { type } = value
      const currTypeLength = Object.keys(acc[type]?.children || {}).length
      const typeName =
        type === 'home-birth-retainer' ? 'Birth Assistant Retainer' : capitalizeFirstLetter(type)

      return {
        ...acc,
        [type]: {
          shortcut: true,
          name: typeName,
          children: {
            ...acc[type]?.children,
            [currTypeLength]: {
              type: FieldTypes.DOLLAR_AMOUNT,
              shortcut: true,
              name: `${typeName} ${currTypeLength + 1}`,
            } as Shortcut,
          },
        } as ShortcutMap,
      }
    },
    {
      initialRetainer: initialRetainerShortcut,
      initialHomeBirthRetainer: initialHomeBirthRetainerShortcut,
    } as Record<string, ShortcutMap | Shortcut>,
  )
}

const getPaymentsDueDropdown = (assessment: PopulatedAssessment | undefined): ShortcutMap => ({
  name: 'Payments Due',
  shortcut: true,
  children: {
    ...getPaymentsDropdownChildren(assessment?.payments?.due),
  },
})

const getPaymentsReceivedDropdown = (assessment: PopulatedAssessment | undefined): ShortcutMap => ({
  name: 'Payments Received',
  shortcut: true,
  children: {
    ...baseReceivedDropdownChildren,
    ...getPaymentsDropdownChildren(assessment?.payments?.received),
  },
})

const getBilledChargesItem = (code: string): Shortcut => ({
  name: code,
  shortcut: true,
  type: FieldTypes.TEXT,
})

const dropdownBilledChargeCodes = [
  '59400',
  '59425',
  '59426',
  '59410',
  '99344',
  '99349',
  'S9443',
  '99461',
]
const billedChargesDropdown: ShortcutMap = {
  name: 'Billed Charges',
  shortcut: true,
  children: dropdownBilledChargeCodes.reduce(
    (acc, code) => ({
      ...acc,
      [code]: getBilledChargesItem(code),
    }),
    {} as Record<string, Shortcut>,
  ),
}

export const getPaymentsDropdown = (assessment: PopulatedAssessment | undefined): ShortcutMap => ({
  name: 'Cost',
  shortcut: true,
  children: {
    prm: {
      name: 'PRM',
      shortcut: true,
      type: FieldTypes.DOLLAR_AMOUNT,
    },
    reimbursement: {
      name: 'Reimbursement',
      shortcut: true,
      type: FieldTypes.DOLLAR_AMOUNT,
    },
    due: getPaymentsDueDropdown(assessment),
    received: getPaymentsReceivedDropdown(assessment),
    billedCharges: billedChargesDropdown,
  },
})
