import { UserProfileTabName } from './types'

export const adminProfileTabNames: Array<UserProfileTabName> = [
  'patient',
  'assessment',
  'payments',
  'auth',
  'claims',
  'files',
  'visits',
]

export const practiceProfileTabNames: Array<UserProfileTabName> = [
  'patient',
  'assessment',
  'files',
  'visits',
]
