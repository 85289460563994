import { EditIcon } from '@chakra-ui/icons'
import { BadgeProps, Button, IconButton } from '@chakra-ui/react'
import { colors, Status, StatusType } from '@hb/shared'
import * as React from 'react'
import { useStatusButton } from './hooks'

export const StatusButton: React.FC<
  {
    type: StatusType
    status: Status
    onClick?: () => void
    text?: string
  } & BadgeProps
> = ({ type, status, onClick, text }) => {
  const { buttonText } = useStatusButton(type, status)
  return status === 'complete' ? (
    <IconButton
      float="right"
      aria-label="edit"
      icon={<EditIcon width={3.5} height={3.5} />}
      color={colors.green.hex}
      onClick={onClick}
      variant={onClick ? 'ghost' : 'unstyled'}
      sx={{ padding: '0.5rem 0.8rem', cursor: onClick ? 'pointer' : 'default' }}
      size="sm"
      borderRadius="full"
    />
  ) : (
    <Button
      onClick={onClick}
      fontWeight={500}
      float="right"
      color={status === 'info' ? '#777' : colors.green.hex}
      sx={{ padding: '0.5rem', cursor: onClick ? 'pointer' : 'default' }}
      h="auto"
      variant={onClick ? 'ghost' : 'unstyled'}
      minH="0"
      // borderColor={colors.green.hex}
      lineHeight="10px"
      size="xs">
      {text || buttonText}
    </Button>
    // <Badge
    //   fontWeight={500}
    //   py='0.1rem'
    //   onClick={onClick}
    //   style={{
    //     pointerEvents: onClick ? 'auto' : 'none',
    //     cursor: onClick ? 'pointer' : 'default',
    //   }}
    //   variant='outline'
    //   // background={buttonColor}
    //   // color={buttonBackground}
    //   colorScheme={colorScheme}
    //   {...badgeProps}
    // >
    //   {text || buttonText}
    // </Badge>
  )
}
