import { Flex } from '@chakra-ui/react'
import { AssessmentDocument, colors, ConsentForm, EditorVersion } from '@hb/shared'
import React from 'react'
import { DefaultModal, DefaultModalProps } from '../Modals/DefaultModal'
import { useEditorWidth } from './hooks'
import { ReadOnlyEditor } from './TextEditor'
import { ReadOnlyEditorProps } from './types'

export const TextViewModal = ({
  ...props
}: Omit<ReadOnlyEditorProps, 'width' | 'baseZoom' | 'height'> &
  Omit<DefaultModalProps, 'render'> & {
    version: EditorVersion
    document?: AssessmentDocument | ConsentForm
  }) => {
  const {
    decorators,
    value,
    withDownload,
    header,
    document,
    version,
    onFormSubmit,
    ...modalProps
  } = props
  const sizeProps = useEditorWidth()

  return (
    <DefaultModal
      isCentered
      overlayHeader={!header}
      header={header}
      headerProps={{
        background: `${colors.pink.hex}cc`,
        borderBottom: '1px solid #999999',
      }}
      contentProps={{
        p: 0,
        width: 'auto',
        maxW: 'unset',
        height: 'auto',
        maxH: 'unset',
      }}
      bodyProps={{ p: 0, style: { width: 'auto', height: 'auto' } }}
      {...modalProps}
      render={() => (
        <Flex w="auto" height="auto" bg="gray.100" p={2}>
          <ReadOnlyEditor
            version={version}
            style={{
              background: 'white',
              boxShadow: '0 0 4px #000000cc',
            }}
            {...sizeProps}
            document={document}
            decorators={decorators}
            onFormSubmit={onFormSubmit}
            withDownload={withDownload}
            value={value}
          />
        </Flex>
      )}
    />
  )
}
