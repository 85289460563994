import {
  Assessment,
  AssessmentSnippet,
  ASSESSMENT_SNIPPETS,
  getPracticeAssessmentCollectionPath,
} from '@hb/shared'
import { collection, orderBy, Query, query, where } from 'firebase/firestore'
import { useMemo } from 'react'
import { db } from '../../../backend/db'
import { ASSESSMENTS_REF } from '../../../collections/firestoreCollections'
import { useApp } from '../../../contexts/AppContext'
import { usePracticeAccess } from '../../../contexts/PracticeAccess'
import { useQuery } from '../useQuery'

export const useUserAssessments = (userId?: string, includeArchived?: boolean) => {
  const { appName } = useApp()
  const { selectedPracticeId } = usePracticeAccess()
  const ref = useMemo(() => {
    if (!userId) return null
    if (appName === 'providers-app' && !selectedPracticeId) return null
    let baseRef =
      appName === 'providers-app'
        ? query(
            ASSESSMENTS_REF,
            where('midwifeId', '==', selectedPracticeId!),
            where('patientId', '==', userId),
          )
        : query(ASSESSMENTS_REF, where('patientId', '==', userId))
    if (!includeArchived) {
      baseRef = query(baseRef, where('archivedOn', '==', null))
    }
    return query(baseRef, orderBy('createdOn', 'desc'))
  }, [userId, includeArchived, appName, selectedPracticeId])
  return useQuery<Assessment>(ref)
}

export const useUserAssessmentSnippets = (userId?: string, archived?: boolean) => {
  const { appName } = useApp()
  const { selectedPracticeId } = usePracticeAccess()
  const ref = useMemo(() => {
    if (userId) {
      if (appName === 'providers-app' && !selectedPracticeId) return null
      let q = query(
        collection(
          db,
          appName === 'providers-app'
            ? getPracticeAssessmentCollectionPath(selectedPracticeId!)
            : ASSESSMENT_SNIPPETS,
        ),
        where('patientId', '==', userId),
        orderBy('createdOn', 'desc'),
      ) as Query<AssessmentSnippet>

      // if (archived !== undefined) q = q.where('archived', '==', archived)
      if (archived !== undefined) {
        q = query(q, where('archived', '==', archived))
      }
      return q
    }
    return null
  }, [userId, archived, appName, selectedPracticeId])
  return useQuery<AssessmentSnippet>(ref)
}
