import {
  AddAdditionalPatientToInvoiceArgs,
  AddLineItemToAssessmentInvoiceArgs,
  RemoveAdditionalPatientFromInvoiceArgs,
  RemoveLineItemFromAssessmentInvoiceArgs,
  SendInvoiceArgs,
  SetCustomFeePercentageArgs,
  UpdateCallback,
} from '@hb/shared'
import { httpsCallable } from 'firebase/functions'
import { functions } from './functions'

export const removeOmissionFromInvoice = httpsCallable<{
  assessmentId: string
  invoiceId: string
  paymentId: string
}>(functions, 'removeOmissionFromInvoice')
export const omitPaymentFromInvoice = httpsCallable<{
  assessmentId: string
  invoiceId: string
  paymentId: string
  reason: string
}>(functions, 'omitPaymentFromInvoice')
export const addLineItemToAssessmentInvoice = httpsCallable<
  AddLineItemToAssessmentInvoiceArgs,
  UpdateCallback
>(functions, 'addLineItemToAssessmentInvoice')
export const removeLineItemFromAssessmentInvoice = httpsCallable<
  RemoveLineItemFromAssessmentInvoiceArgs,
  UpdateCallback
>(functions, 'removeLineItemFromAssessmentInvoice')
export const addAdditionalPatientToInvoice = httpsCallable<
  AddAdditionalPatientToInvoiceArgs,
  UpdateCallback
>(functions, 'addAdditionalPatientToInvoice')
export const removeAdditionalPatientFromInvoice = httpsCallable<
  RemoveAdditionalPatientFromInvoiceArgs,
  UpdateCallback
>(functions, 'removeAdditionalPatientFromInvoice')
export const sendInvoice = httpsCallable<SendInvoiceArgs>(functions, 'sendInvoice')

export const getUserIdFromEmail = httpsCallable<{ email: string }, string | null>(
  functions,
  'getUserIdFromEmail',
)

export const setCustomFeePercentage = httpsCallable<SetCustomFeePercentageArgs>(
  functions,
  'setCustomFeePercentage',
)

export const archiveInvoice = httpsCallable<{ invoiceId: string }>(functions, 'archiveInvoice')
export const unarchiveInvoice = httpsCallable<{ invoiceId: string }>(functions, 'unarchiveInvoice')
export const deleteInvoice = httpsCallable<{ invoiceId: string }>(functions, 'deleteInvoice')
export const restoreDeletedInvoice = httpsCallable<{ invoiceId: string }>(
  functions,
  'restoreDeletedInvoice',
)

export const sendInvoiceNotificationEmail = httpsCallable<{
  invoiceId: string
  sendTo: string
}>(functions, 'sendInvoiceNotificationEmail')
export const manuallyMovePaymentToInvoice = httpsCallable<{
  paymentId: string
  fromInvoiceId: string
  toInvoiceId: string
  assessmentId: string
}>(functions, 'manuallyMovePaymentToInvoice')
