import { useMemo } from 'react'
import { useApp } from '../../../contexts/AppContext'
import { adminProfileTabNames, practiceProfileTabNames } from './constants'

export const useProfileTabNames = () => {
  const { appName } = useApp()
  return useMemo(
    () => (appName === 'app' ? adminProfileTabNames : practiceProfileTabNames),
    [appName],
  )
}
