import {
  Box,
  Button,
  Center,
  CircularProgress,
  Divider,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  VStack,
} from '@chakra-ui/react'
import { colors, getDateTimeString, getFullName, Reaction, reactionIcons } from '@hb/shared'
import { arrayRemove, DocumentReference, updateDoc } from 'firebase/firestore'
import React from 'react'
import { useCachedUser } from '../../collections/hooks/cached'
import { useAuth } from '../../store'
import { DeleteButton } from '../Buttons/DeleteButton'
import { useAddReaction } from './hooks'

export const ReactionsPopoverContent = ({
  docRef,
  fieldPath,
}: {
  docRef: DocumentReference
  fieldPath: string
}) => {
  const { addReaction, addingReaction } = useAddReaction(docRef, fieldPath)
  return (
    <Portal>
      <PopoverContent
        bg="gray.100"
        borderRadius="20px"
        border="none"
        filter="drop-shadow(1px 1px 4px #00000066)"
        w="114px">
        <HStack flexFlow="row wrap" p={1} spacing={1}>
          {Object.keys(reactionIcons).map(type => (
            <IconButton
              size="sm"
              borderRadius="full"
              key={type}
              boxShadow="inset 1px 1px 3px #00000033"
              bg="gray.50"
              aria-label={type}
              icon={<Box>{reactionIcons[type as Reaction['type']]}</Box>}
              onClick={() => addReaction(type as Reaction['type'])}
              isLoading={addingReaction}
            />
          ))}
        </HStack>
        <PopoverArrow bg="gray.100" />
      </PopoverContent>
    </Portal>
  )
}

const ReactionDetailView = ({
  reaction,
  docRef,
  fieldPath,
}: {
  reaction: Reaction
  docRef: DocumentReference
  fieldPath: string
}) => {
  const authUser = useAuth(s => s.authUser)
  const { type, createdBy, createdOn } = reaction
  const reactedByMe = authUser?.uid === createdBy
  const { data: user } = useCachedUser(createdBy)

  return (
    <HStack spacing={1} pl={1} py={1} pr={2}>
      {user ? (
        <>
          <HStack pl={1}>
            <Text fontSize="xs" color="gray.600">
              {reactionIcons[type]}
            </Text>
            <Text
              whiteSpace="none"
              color="gray.600"
              fontFamily="Open Sans"
              fontSize="xs"
              fontWeight={600}>
              {getFullName(user)} on {getDateTimeString(createdOn, 'short')}
            </Text>
          </HStack>
          {reactedByMe ? (
            <DeleteButton
              noConfirm
              actionName="Remove"
              itemName="reaction"
              onDelete={() => updateDoc(docRef, fieldPath, arrayRemove(reaction))}
              isLoading={false}
              size="xs"
            />
          ) : null}
        </>
      ) : (
        <HStack>
          <CircularProgress color={colors.green.hex} size={5} isIndeterminate />
          <Text fontSize="sm" color="gray.600">
            Loading user
          </Text>
        </HStack>
      )}
    </HStack>
  )
}

const ReactionView = ({
  reaction,
}: {
  reaction: Reaction
  docRef: DocumentReference
  fieldPath: string
}) => {
  const { type } = reaction

  return (
    <Box cursor="inherit" w="18px">
      <Center w="25px" fontSize="12px" h="25px" bg="white" borderRadius="full">
        <Text position="relative" lineHeight={1} bottom="1px" as="span">
          {reactionIcons[type]}
        </Text>
      </Center>
    </Box>
  )
}

export const Reactions = ({
  reactions,
  docRef,
  fieldPath,
}: {
  reactions: Array<Reaction>
  docRef: DocumentReference
  fieldPath: string
}) =>
  reactions.length ? (
    <Popover placement="bottom-end" trigger="hover" isLazy>
      <PopoverTrigger>
        <Button
          height="12px"
          variant="unstyled"
          display="flex"
          filter="drop-shadow(1px 1px 2px #00000055)"
          position="relative"
          justifyContent="flex-end"
          w="100%">
          {reactions.map((reaction, idx) => (
            <ReactionView docRef={docRef} fieldPath={fieldPath} key={idx} reaction={reaction} />
          ))}
        </Button>
      </PopoverTrigger>
      <PopoverContent width="auto" maxW="300px">
        <VStack align="flex-start" divider={<Divider />} spacing={0} flexFlow="column">
          {reactions.map((reaction, idx) => (
            <ReactionDetailView
              key={idx}
              reaction={reaction}
              docRef={docRef}
              fieldPath={fieldPath}
            />
          ))}
        </VStack>
      </PopoverContent>
    </Popover>
  ) : null
