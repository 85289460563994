import { Box, Center, HStack, Text } from '@chakra-ui/react'
import { BugReport, BUG_REPORTS } from '@hb/shared'
import { collection, doc, DocumentReference } from 'firebase/firestore'
import React, { useContext, useMemo } from 'react'
import { db } from '../../../backend'
import { useDocument } from '../../../hooks/backend/useDocument'
import { BugReportsList } from './BugReportsList'
import { BugReportView } from './BugReportView'
import { BugReportsContext } from './context'
import { useBugReports } from './hooks'

const SelectedBugReportView = () => {
  const { selectedBugReportId, deselectBugReport } = useContext(BugReportsContext)

  const docRef = useMemo(
    () =>
      selectedBugReportId
        ? (doc(collection(db, BUG_REPORTS), selectedBugReportId) as DocumentReference<BugReport>)
        : null,
    [selectedBugReportId],
  )
  const { data: withoutId, loading } = useDocument<BugReport>(BUG_REPORTS, selectedBugReportId)

  const selectedBugReport = useMemo(
    () => (withoutId && selectedBugReportId ? { ...withoutId, id: selectedBugReportId } : null),
    [withoutId, selectedBugReportId],
  )

  if (!selectedBugReportId) {
    return (
      <Center w="100%" h="100%">
        <Text>Select a bug report</Text>
      </Center>
    )
  }
  if (!selectedBugReport && loading) {
    return (
      <Center w="100%" h="100%">
        <HStack>
          <Text>Loading...</Text>
        </HStack>
      </Center>
    )
  }
  if (!selectedBugReport) {
    return (
      <Center w="100%" h="100%">
        <Text color="red.600">Select a bug report</Text>
      </Center>
    )
  }
  return (
    <BugReportView onClose={deselectBugReport} docRef={docRef!} bugReport={selectedBugReport} />
  )
}

export const BugReportsFullView = () => {
  const bugReportsData = useBugReports()
  return (
    <BugReportsContext.Provider value={bugReportsData}>
      <HStack
        spacing={0}
        bg="white"
        borderRadius={6}
        overflow="hidden"
        boxShadow="1px 1px 3px #00000077"
        h="100%"
        w="100%">
        <Box borderRight="1px solid #ababab" h="100%" w="375px">
          <BugReportsList fullHeight />
        </Box>
        <Box h="100%" bg="gray.100" overflowY="auto" p={2} flex={1}>
          <SelectedBugReportView />
        </Box>
      </HStack>
    </BugReportsContext.Provider>
  )
}

export const BugReportsPopoverContent = ({ onClose }: { onClose: () => void }) => {
  const bugReportsData = useBugReports()

  return (
    <BugReportsContext.Provider value={bugReportsData}>
      <BugReportsList onClose={onClose} />
    </BugReportsContext.Provider>
  )
}
