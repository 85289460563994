import React, { createContext, FC, PropsWithChildren, useContext, useMemo, useState } from 'react'

export type SearchBarContextData = {
  query: string
  updateQuery: (q: string) => void
  searchQuery: string
}
export const SearchBarContext = createContext<SearchBarContextData>({
  query: '',
  updateQuery: () => {},
  searchQuery: '',
})
export const useSearchBarState = (
  transformQuery?: (query: string) => string,
): SearchBarContextData => {
  const [query, setQuery] = useState('')
  const searchQuery = useMemo(
    () => (transformQuery ? transformQuery(query) : query),
    [query, transformQuery],
  )
  return {
    query,
    searchQuery,
    updateQuery: s => setQuery(s),
  }
}

export const SearchBarProvider: FC<
  PropsWithChildren<{ transformQuery?: (query: string) => string }>
> = ({ children, transformQuery }) => (
  <SearchBarContext.Provider value={useSearchBarState(transformQuery)}>
    {children}
  </SearchBarContext.Provider>
)

export const useSearch = () => useContext(SearchBarContext)
