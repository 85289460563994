import { FAQList, InfoText, InlineLink } from '@hb/shared-frontend'
import React from 'react'

export const insuranceTermsFaq: FAQList = {
  name: 'Insurance Terms',
  items: [
    {
      title: 'EOB (Explanation of Benefits)',
      body: (
        <InfoText>
          Ever receive a statement in the mail from your insurance company after a doctor’s visit
          showing charges, etc.? This is referred to as an EOB, or explanation of benefits. This
          document specifies what charges are submitted to your insurer for services a provider or
          facility has performed for you. It also shows a breakdown of how the insurance plan came
          up with reimbursement for your provider, and what is still owed according to your plan
          benefits - for instance, whether or not you owe a deductible or coinsurance amount to your
          provider. EOBs indicate member responsibility amounts (what you owe as the insured),
          indicating plan deductibles, co-pays, and/or coinsurance.
        </InfoText>
      ),
    },
    {
      title: 'Deductible',
      body: (
        <InfoText>
          A deductible is a dollar amount (for the plan year) you must pay before coverage kicks in
          for services. There can be both an individual deductible, and/or a family deductible on
          insurance plans. For maternity care, most often the individual deductible applies. On
          rarer occasions the family deductible applies. For example, if you have a $2000 annual
          deductible (individual), your insurance company will expect you to pay up to this amount
          for services directly to the provider or facility you receive services from before
          insurance comes up with reimbursement based on your plan benefits.
        </InfoText>
      ),
    },
    {
      title: 'Coinsurance',
      body: (
        <InfoText>
          Insurance plans often have an annual coinsurance that applies (in addition to the plan’s
          deductible). This coinsurance is most often in the form of a percentage or ‘split’ with
          you as the member on what amount of the bill the insurer will cover. For example: If you
          have a 80/20 coinsurance, your plan will expect you as the member to pay 20% of the bill,
          while your plan covers 80%, after the plan deductible has been applied. Keep in mind that
          coinsurance only applies to Allowed Charges by the plan (see below for definition of
          allowed charges).
        </InfoText>
      ),
    },
    {
      title: 'Co-pay',
      body: (
        <InfoText>
          This is typically a lower dollar amount required by some insurance plans to pay up front
          at a visit. For instance, you may have a $25 copay for a doctor’s visit, or a $40 copay
          for a specialist visit. Hospital admissions often have a one-time admission copay of $500
          before coverage kicks in.
        </InfoText>
      ),
    },
    {
      title: 'Out of Pocket Max',
      body: (
        <>
          <InfoText>
            Your Out of Pocket max is meant to be the ‘ceiling’ on how much you will pay out of
            pocket for the year, although *this terminology can be misleading when it comes to out
            of network benefits. Your out of pocket max is mostly related to your annual coinsurance
            (a percentage split you share on costs with your insurer for the year). Some people
            don’t realize they have a coinsurance on top of their deductible that applies. Sometimes
            your deductible is included in the annual out of pocket max, and sometimes it is not.
            Example: You have an 80/20 coinsurance for the year that applies with an out of pocket
            max of $3000. If charges for a service you receive are $20,000, technically you are
            responsible for 20% of charges towards the 80/20 coinsurance, however, because your out
            of pocket max is $3000, this is the most you will pay towards your percentage of
            coinsurance (up to $3000 total member responsibility). Because major surgery or
            procedures can get into the many thousands of dollars, insurance companies do the heavy
            lifting on covering the larger part of the bill, which of course is why you have
            insurance - for unexpected medical bills! The out of pocket max puts that ceiling on
            catastrophic charges from hospitals or emergent care so you are not stuck with
            additional bills in the thousands.
          </InfoText>
          <InfoText>
            *The Out of pocket max can be misleading if your provider is out of network and does not
            accept the plan’s allowed charges for services (as out of network providers often
            don’t). When they disagree, the out of pocket max makes little difference on how much
            you will actually pay out of pocket for services. You can talk more with our team about
            this if you received an assessment and have questions!
          </InfoText>
        </>
      ),
    },
    {
      title: 'In-Network',
      body: (
        <InfoText>
          When you go in-network with your insurance plan, you are choosing a provider or
          facility/hospital that participates with the insurance plan. This also means these
          entities (in-network providers or facilities) contract with your insurer under the plan
          coverage rules, simplifying your out of pocket costs towards care. Although coverage can
          be easier to research when you go in-network, it does not necessarily mean you will not
          have significant out of pocket costs for care. To understand in-network coverage, it is
          good to research your annual out of pocket max, plan deductible and coinsurance to find
          out what additional out of pocket costs you may have if you stay in-network. ‘In-network’
          also means the ‘higher benefit level’ of coverage from your plan (as opposed to out of
          network benefits).
        </InfoText>
      ),
    },
    {
      title: 'Out of Network',
      body: (
        <>
          <InfoText>
            Out of network providers do not officially contract with insurers, which also means they
            do not always accept the insurance plan’s reimbursement rates for care (nor are they
            required to as they are non-participating). This means that coverage can be a little
            more complicated, as insurance companies assign limitations on reimbursement for
            services that out of network providers don’t always agree with. Although many of the
            providers Hamilton Billing assists are non-participating, they are often still able to
            obtain adequate coverage for services either through a patient’s out of network benefits
            via negotiations, or by exceptions granted on coverage.
          </InfoText>
          <InfoText>
            If you choose one of our providers (who are mostly non-participating), your out of
            network plan benefits, exclusions or max allowed charges may apply. If your plan allows
            for an exception on coverage at the in-network benefit level, this means there may be
            room for an agreement on coverage. Otherwise, you may have a balance for care outside of
            coverage from your plan.
          </InfoText>
        </>
      ),
    },
    {
      title: 'Allowed Charges or Max Reimbursable Charges',
      body: (
        <>
          <InfoText>
            This is the most your insurer will allow an out of network provider or facility to
            charge for care. Your midwife’s fee may conflict with a plan’s max allowed charges (may
            be more than what the plan will allow their practice to charge). Out of network
            providers are not obligated to accept an allowed charge imposed by an insurance company.
            In turn, an insurer is not obligated to accept an out of network provider’s fee for
            services, which can put financial burden on the patient if allowed charges from the
            insurer are extremely low.
          </InfoText>
          <InfoText>
            Insurers use different methods to come up with a max allowed charge for a service. For
            instance - some insurers structure plans to allow charges based on the medicare fee
            schedule (the lowest dollar amount possible), *thereafter applying plan deductibles and
            coinsurance, resulting in negligible reimbursement. Plans that base allowed charges on a
            percentage of the medicare fee schedule often discourage patients from leaving the
            plan’s network as coverage can be extremely low or negligible.
          </InfoText>
          <InfoText>
            However - some plans are structured to allow charges based on Reasonable & Customary
            (R&C) or <b>FAIR Health rates</b> - which typically means better reimbursement from the
            plan for out of network providers as under these circumstances, allowed charges are much
            higher and closer to acceptable rates by providers. FAIR health rates identify an
            average of what providers in a geographic area charge for similar services. These rates
            are generally higher and can even be researched on{' '}
            <InlineLink onClick={() => window.open('fairhealth.org', '_blank')}>
              fairhealth.org
            </InlineLink>
            .
          </InfoText>
          <InfoText>
            *Keep in mind that the insurance plan will impose an allowed charge for services, then
            subtract your annual deductible and coinsurance (your member responsibility) from this
            allowed amount to come up with actual reimbursement from the plan.
          </InfoText>
        </>
      ),
    },
    {
      title: 'CPT and ICD-10 Codes',
      body: (
        <InfoText>
          A CPT code is a procedure code, used to represent a service. For instance - the CPT code
          for full maternity care services (termed ‘global’ maternity care) is CPT code 59400. This
          is a way for insurance companies to translate a service. You can search this code on any
          search engine to get the full definition. You can also use CPT codes to research coverage
          or FAIR Health rates. ICD-10 Codes are Diagnosis codes, which generally indicate the
          reason for a service to be performed by a provider. These go on claims to your insurance
          company in order to translate services properly.
        </InfoText>
      ),
    },
    {
      title: 'Fully Funded or ‘Fully Insured’ Plans',
      body: (
        <InfoText>
          Fully insured plans are required to follow state insurance law or ‘mandates’ for the state
          in which they are based. If your plan is based in New York State and is fully insured, the
          plan can not legally refuse coverage of homebirth (when performed by a midwife). In
          essence, this means it must acknowledge the state mandate for coverage of midwives.
        </InfoText>
      ),
    },
    {
      title: 'Self-Funded Plans',
      body: (
        <InfoText>
          Self-funded plans are federally regulated therefore not subject to state mandates. They
          are generally employer funded and under different rules than fully insured plans. What
          does this mean for maternity coverage? Technically if a self-funded plan has a policy
          against covering homebirth (for instance), it is within their right to deny coverage for
          this birth option even if your plan has out of network benefits. *Not all self-funded
          plans are against covering homebirth, but some insurance companies have strong opposition
          to covering this option for maternity care. For example - Aetna has a general policy on
          self-funded plans to deny coverage for homebirth, and because self-funded plans do not
          have to follow New York state insurance law (mandating coverage for midwives), they are
          legally within their right to deny coverage for this service. On the other hand - fully
          insured Aetna plans based in New York typically grant a one-time in-network exception on
          coverage for homebirth due to their obligation to follow New York state insurance law.
          Self-funded plans are simply exempt from state laws, which means less protection for you
          as the consumer if you have a conflict on their denying coverage for a service.
          Self-funded plans defer to the employer, which also means your employer will likely have
          the final say on coverage if issues arise.
        </InfoText>
      ),
    },
  ],
}
