import { FieldMapValue } from '@hb/shared'
import { diagnosisCodeOptions } from './Ability/fields'

const formDiagnosisCodesToAbility = (data?: Array<FieldMapValue>) =>
  (data || []).map(({ diagnosisCodeSelect, customDiagnosisCode }) =>
    diagnosisCodeSelect === 'other' ? customDiagnosisCode : diagnosisCodeSelect,
  )
export const formDataToAbilityData = (
  data: FieldMapValue,
  assessmentId: string,
  draftId?: string | null,
) => {
  const { DiagnosisCodes, ...rest } = data
  const submitted: FieldMapValue = {
    assessment: assessmentId,
    DiagnosisCodes: formDiagnosisCodesToAbility(DiagnosisCodes),
    ...rest,
  }
  if (draftId) submitted.draftId = draftId
  return submitted
}

const orderedDiagnosisCodeKeys = [
  'Principal',
  'Other1',
  'Other2',
  'Other3',
  'Other4',
  'Other5',
  'Other6',
  'Other7',
]

const abilityDiagnosisCodesToForm = (data?: Record<string, string> | Array<string>) => {
  const diagnosisCodeIds = diagnosisCodeOptions.map(o => o.id)
  return orderedDiagnosisCodeKeys.reduce(
    (acc, key, keyIndex) => {
      const code = Array.isArray(data) ? data[keyIndex]?.toLowerCase() : data?.[key]?.toLowerCase()
      if (!code) return acc
      const newEntry = diagnosisCodeIds.includes(code)
        ? {
            diagnosisCodeSelect: code,
          }
        : {
            diagnosisCodeSelect: 'other',
            customDiagnosisCode: code,
          }
      return [...acc, newEntry]
    },
    [] as Array<{ diagnosisCodeSelect: string; customDiagnosisCode?: string }>,
  )
}

const abilityServicesToForm = (data: Record<number, FieldMapValue>) =>
  Object.entries(data).map(([id, service]) => ({
    id,
    ...service,
    DiagnosisPointer:
      typeof service.DiagnosisPointer === 'string'
        ? service.DiagnosisPointer.split(',').map((s: string) => s.trim()) || []
        : [],
  }))

export const abilityDataToFormData = (data: FieldMapValue) => {
  const { DiagnosisCodes10, DiagnosisCodes, ...rest } = data
  const formData: FieldMapValue = {
    ...rest,
    Services: abilityServicesToForm(data.Services || {}),
    DiagnosisCodes: abilityDiagnosisCodesToForm(DiagnosisCodes10 || DiagnosisCodes || {}),
    primaryCoverageAuthRef: data.Insurances?.[1]?.PriorAuthNo,
    secondaryCoverageAuthRef: data.Insurances?.[2]?.PriorAuthNo,
  }
  return formData
}

export const toClaimName = (data: FieldMapValue) =>
  `${data?.serviceType?.toString().toUpperCase() || 'NO SERVICE TYPE'}${
    data.serviceType === 'other' && data.serviceTypeOther ? ` (${data.serviceTypeOther})` : ''
  }: ${data.status?.toString().toUpperCase()} - ${
    data.serviceDates || 'No dates of service'
  } - ${data.cptCodes || 'No cpt codes'}`
