import { FAQList, InfoText } from '@hb/shared-frontend'
import React from 'react'
import { PatientsPageLink } from './PatientsPageLink'

export const claimsFaqList: FAQList = {
  name: 'Claims and Reimbursement from your Insurer',
  items: [
    {
      title: 'How does the insurance process work?',
      subtitle: 'Do I submit a bill to my insurer? When does my insurance plan pay for services?',
      body: (
        <>
          <InfoText>
            Hamilton Billing submits claims to your insurer on behalf of your provider after
            services are rendered, which typically means soon after delivery of your newborn or
            after any additional services performed (even if outside of maternity care). *We do not
            submit claims to your insurance company before services are rendered - this is not
            permitted.
          </InfoText>
          <InfoText>
            Insurance plans typically take 30-45 days to process claims, resulting in an initial
            payment or sometimes no payment if they do not recognize an exception or authorization
            on file (for instance). Insurance companies often overlook authorizations on the first
            round of claims processing and have to be notified separately via phone to reprocess.
            This can delay claims processing and/or reimbursement from your plan by an additional
            30-45 days (or longer). Furthermore, it is often a process to get insurance companies to
            acknowledge issues that arise regarding any balance, previously quoted rates of
            reimbursement, or authorizations on file. This can delay reimbursement or the completion
            of claims processing for months.
          </InfoText>
          <InfoText>
            It is important to have patience during this process, and to understand that our team
            does not control the length of time it takes for claims to process - your insurer does.
            Hamilton Billing also can’t guarantee an outcome on claims, even though we have years of
            experience dealing with issues that arise during the claims process. Our team works hard
            behind the scenes to ensure claims process correctly for the highest benefit level of
            coverage your plan will allow. It’s always best to check in with us if you have
            additional questions. We will get back to you as soon as we have an update from
            following up with your insurer!
          </InfoText>
        </>
      ),
    },
    {
      title: 'How much do I owe my provider for care?',
      body: (
        <>
          <InfoText>
            If you signed on with one of our providers we likely sent you an assessment at start of
            care indicating potential out of pocket costs for services. This may have even included
            a deposit schedule during pregnancy to count towards any applicable plan deductibles
            and/or coinsurance, or amounts of the fee your plan may not cover. Please refer to your
            original assessment if you are currently pregnant and have questions about coverage or
            payments during care towards what you may owe your provider. If you have not visited our{' '}
            <PatientsPageLink /> to receive an assessment on your plan coverage, you can do so to
            get information on out of pocket costs for care tailored to your insurance plan.
          </InfoText>
          <InfoText>
            If services have already been completed, our team has likely submitted claims to your
            insurance company, which takes time to process. Once claims complete processing and our
            team has confirmed there is no additional amount the insurance company will reimburse
            for services, we will be in touch to go over any balance that remains (if applicable).
          </InfoText>
        </>
      ),
    },
    {
      title:
        'I made deposits during care and it looks like my insurance plan paid for services. When can I expect a refund?',
      body: (
        <InfoText>
          Deposits are only refundable once our team has confirmed claims have finished processing
          with no additional follow up required, and when your balance is completed for care. This
          can take months if we are going back and forth with your insurance company on processing
          claims correctly. On average deposits are refunded anywhere from four months to eight
          months after services are completed. Please be patient with us in this process as it is
          often the insurance company that delays claims processing due to complications that are
          beyond our control. The insurer ultimately extends the length of time it takes to get
          claims completed as they are mostly in control of this process.
        </InfoText>
      ),
    },
    {
      title: 'What is a retainer and why am I being asked to pay this?',
      body: (
        <>
          <InfoText>
            Most providers require a sign-on retainer in order to officially join their practice.
            The retainer serves several functions in a provider’s practice and is non-refundable
            once paid. By paying the retainer, you officially secure an available slot in your
            provider’s practice, meaning nobody else who wants to sign on can take that
            availability, which is limited. If your retainer has not been paid, there is no
            guarantee you will officially hold a spot in the provider’s practice.
          </InfoText>
          <InfoText>
            Not only is the retainer a placeholder, but it often counts towards any number of costs
            related to care or inadequate coverage. For instance: your retainer may apply towards an
            annual deductible, coinsurance, or copay required by your plan. Or - it may also count
            towards additional services outside of maternity care that your plan does not cover (for
            example: newborn care, complaints of issues not related to pregnancy, etc).. These are
            just some examples of how the retainer gets applied toward services. Regardless of the
            details or how retainers are allocated, retainers are simply required from all patients
            who join the practice and are non-refundable.
          </InfoText>
        </>
      ),
    },
    {
      title:
        'My insurance company said I need to appeal in order for claims to process - how do I do this?',
      body: (
        <>
          <InfoText>
            Appeals are sometimes a part of the coverage process, although this advice from insurers
            can be misleading, depending on whom you speak with. Sometimes an appeal is simply a
            phone call to your insurance company done verbally over the phone. Sometimes it is a
            written appeal you submit to your insurance company as the member. In other instances it
            is better if the provider submits an appeal. Our team is here to help you understand the
            best appeals strategy for an outcome sooner rather than later - there is a lot of
            misinformation and sometimes taking certain actions can have patients running in circles
            with no end in sight. Please circle back to our team if you have questions about an
            appeal or next steps, as we have experience dealing with this process and want to help.
            Please also be aware that although we can provide guidance to advocate for you as the
            patient, it is ultimately your responsibility as the member to submit a member appeal,
            whether verbally over the phone or in writing.
          </InfoText>
        </>
      ),
    },
    {
      title: 'How can I check the progress of my claims?',
      body: (
        <InfoText>
          Your insurance company may have a portal online you can log into to check the status of
          claims that are processing. Furthermore, you can always reach out to our team if you have
          a specific question about your claim, or if you receive any documentation/EOBs from your
          insurer, which you can share with us by forwarding (so we can help with any required next
          steps). Ultimately, only your insurer can answer questions about where claims are in the
          adjudication process (pending, completed, denied, etc.).
        </InfoText>
      ),
    },
  ],
}
