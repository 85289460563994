import { cssVar, extendTheme } from '@chakra-ui/react'
import { colors } from '@hb/shared'

const $arrowBg = cssVar('popper-arrow-bg')
export const chakraTheme = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  components: {
    Tooltip: {
      baseStyle: {
        bg: 'green.500',
        [$arrowBg.variable]: 'colors.green.500',
      },
    },
  },
  colors: {
    gray: {
      //   900: '#333333',
      800: '#424242',
      //   700: '#525252',
      //   600: '#616161',
      //   500: '#707070',
      //   400: '#808080',
      //   300: '#8f8f8f',
      200: '#c8c8c8',
      //   100: '#b5b5b5',
    },
    green: {
      900: '#0f2422',
      800: '#1d4943',
      700: '#2b6e65',
      600: colors.green.hex,
      500: '#43AD9E',
      400: '#6fc3ba',
      300: '#93d2cc',
      200: '#b7e1dd',
      100: '#dbf0ee',
    },
  },
  styles: {
    global: {
      '.js-focus-visible :focus:not([data-focus-visible-added])': {
        outline: 'none',
        boxShadow: 'none',
        // boxShadow: '0px 2px 5px red',
      },
    },
  },
})
