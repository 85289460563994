export type VersionKey = 'dev' | 'staging' | 'prod'

export const version: VersionKey = 'prod'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const isNotProd = version !== 'staging'

export const releaseDate = '2024-11-14 00:45:59'

// new Date().toISOString().split('.')[0].replace('T', ' ')
