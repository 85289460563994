import { Text, VStack } from '@chakra-ui/react'
import { FieldMapValue, PracticeInvoiceAdditionalPatient } from '@hb/shared'

import React, { useCallback, useContext } from 'react'
import { addAdditionalPatientToInvoice } from '../../../backend/invoices'
import { PopUpMessageContext } from '../../../contexts/PopUpMessage/PopUpMessageContext'
import { SimpleForm } from '../../forms'
import { DefaultModal } from '../../Modals'
import { additionalPatientField } from './field'

export const EditAdditionalPatientModal = ({
  onClose,
  patient,
  patientId,
  invoiceId,
}: {
  onClose: () => void
  patient: PracticeInvoiceAdditionalPatient
  patientId: string
  invoiceId: string
}) => {
  const { showError, showSuccess } = useContext(PopUpMessageContext)
  const onSubmit = useCallback(
    async (data: FieldMapValue) => {
      try {
        const { data: res } = await addAdditionalPatientToInvoice({
          additionalPatientId: patientId,
          invoiceId,
          fname: data.fname || patient.fname,
          lname: data.lname || patient.lname,
          nickname: data.nickname || patient.lname,
          edd: data.edd || patient.edd,
          deliveredOn: data.deliveredOn || patient.deliveredOn,
        })
        if (res.success) {
          showSuccess('Additional patient added successfully')
          onClose()
        } else {
          showError(res.error || 'Error adding additional patient')
        }
        return res
      } catch (err: any) {
        const errMessage = err?.message || 'Error adding additional patient'
        showError(errMessage)
        return { error: errMessage }
      }
    },
    [invoiceId, onClose, showError, showSuccess, patientId, patient],
  )

  return (
    <DefaultModal
      overlayHeader
      portalProps={{ appendToParentPortal: true }}
      isOpen
      onClose={onClose}
      render={() => (
        <VStack align="flex-start" spacing={0}>
          <Text px={4} pt={4} fontFamily="Hero-New">
            Edit Additional Patient
          </Text>
          <SimpleForm
            theme="detailed"
            value={patient}
            field={additionalPatientField}
            onSubmit={onSubmit}
          />
        </VStack>
      )}
    />
  )
}
