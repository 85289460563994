import {
  PracticeVisitDraft,
  SubmittedVisitData,
  toSearchString,
  User,
  UserRoleItem,
} from '@hb/shared'

export const populateVisitDraft = (
  draft: SubmittedVisitData,
  assessmentId: string,
  patient: User | UserRoleItem,
): PracticeVisitDraft => {
  const patientFirst = patient.fname || ''
  const patientLast = patient.lname || ''
  if (!patientFirst) {
    throw new Error('Patient first name is required')
  }
  if (!patientLast) {
    throw new Error('Patient last name is required')
  }
  const keywordsSearchString = draft.keywords
    ? draft.keywords
        .split(',')
        .map(k => k.trim())
        .join('|')
    : ''
  return {
    ...draft,
    assessmentId,
    patientFirst,
    patientLast,
    searchString: toSearchString(
      `${patientFirst}|${patientLast}|${draft.date}|${draft.startTime}${keywordsSearchString ? `|${keywordsSearchString}` : ''}`,
    ),
  }
}
