export const getInovalonClaimStatusColor = (status?: string) => {
  switch (status) {
    case 'PAID':
    case 'ACK_FINAL':
      return 'green.500'
    case 'ACK_PENDING':
      return 'yellow.500'
    case 'ERROR':
    case 'DENIED':
      return 'red.500'
    default:
      return 'gray.600'
  }
}
