import { MouseEventHandler } from 'react'

const filterEvent =
  (callback?: () => void): MouseEventHandler<any> =>
  e => {
    e.preventDefault()
    e.stopPropagation()
    if (callback) callback()
  }
export const filteredEvents = (onClick?: () => void) => ({
  onMouseUp: filterEvent(),
  onMouseDown: filterEvent(),
  onClick: filterEvent(onClick),
})
