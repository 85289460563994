import { FileDBValue, getResizedStoragePath, UnuploadedFileDBValue } from '@hb/shared'
import { useEffect, useMemo, useState } from 'react'

export const useFilePath = (value?: FileDBValue | UnuploadedFileDBValue) => {
  const [recentlyUploaded, setRecentlyUploaded] = useState<boolean>(
    !!(value?.uploadedOn && value.uploadedOn > Date.now() - 1000 * 5),
  )

  useEffect(() => {
    if (value?.uploadedOn && recentlyUploaded) {
      const timeSinceUpload = Date.now() - value.uploadedOn
      setRecentlyUploaded(true)
      const timeout = setTimeout(
        () => {
          setRecentlyUploaded(false)
        },
        5000 - timeSinceUpload + 1000,
      )
      return () => clearTimeout(timeout)
    }
    return () => {}
  }, [recentlyUploaded, value])
  const downloadPath = useMemo(
    () => (value?.storagePath ? getResizedStoragePath(value.storagePath, value.type) : null),
    [value],
  )

  return {
    downloadPath: value?.uploadedOn && !recentlyUploaded ? downloadPath : undefined,
    recentlyUploaded,
  }
}
