import {
  assessmentTemplatesCollection,
  authAppealsTemplatesCollection,
  authInstructionsTemplatesCollection,
  authTemplatesCollection,
  claimTemplatesCollection,
  clinicalsTemplatesCollection,
  Field,
  FieldMap,
  FieldTypes,
  IdField,
  Template,
} from '@hb/shared'

const separateTemplatesField: Field = {
  type: FieldTypes.CHECKBOX,
  placeholder: 'Separate templates for fully insured/self-funded',
  optional: true,
}

const assessmentTemplateField: Field = {
  condition: v => !v?.separateTemplates,
  type: FieldTypes.ID,
  labels: [{ text: 'Assessment Template' }],
  collection: assessmentTemplatesCollection,
  placeholder: 'Assessment template',
  optional: true,
}

const fullyInsuredTemplateField: Field = {
  condition: v => v?.separateTemplates,
  type: FieldTypes.ID,
  collection: assessmentTemplatesCollection,
  labels: [{ text: 'Fully Insured Assessment Template' }],
  placeholder: 'Fully-insured assessment template',
  optional: true,
}

const loqNotRequiredField: Field = {
  type: FieldTypes.CHECKBOX,
  // labels: [{text: `Don't require LOQ for this insurer`}],
  placeholder: 'LOQ not required for this insurer',
  optional: true,
}

const planLoqNotRequiredField: Field = {
  ...loqNotRequiredField,
  placeholder: 'LOQ not required for this plan',
}

const selfFundedTemplateField: Field = {
  condition: v => v?.separateTemplates,
  type: FieldTypes.ID,
  collection: assessmentTemplatesCollection,
  labels: [{ text: 'Self-funded Assessment Template' }],
  placeholder: 'Self-funded assessment template',
  optional: true,
}
const planFields: FieldMap = {
  name: 'Plan',
  children: {
    name: {
      type: FieldTypes.TEXT,
      placeholder: 'Plan Name',
    },
    loqNotRequired: planLoqNotRequiredField,
    separateTemplates: separateTemplatesField,
    templateId: assessmentTemplateField,
    fullyInsuredTemplateId: fullyInsuredTemplateField,
    selfFundedTemplateId: selfFundedTemplateField,
  },
}

const authTemplateField: IdField<Template> = {
  type: FieldTypes.ID,
  placeholder: 'Authorization Template',
  labels: [{ text: 'Authorization Template' }],
  collection: authTemplatesCollection,
  optional: true,
}

// use for collection
export const insuranceProviderFields: FieldMap = {
  name: 'Insurance Provider',
  children: {
    name: {
      type: FieldTypes.TEXT,
      placeholder: 'Name',
      optional: true,
    },
    abilityId: {
      type: FieldTypes.TEXT,
      optional: true,
      placeholder: 'Payer ID - required for claim submission',
    },
    internalPayerId: {
      type: FieldTypes.TEXT,
      optional: true,
      placeholder: 'Internal Ability Payer ID',
    },
    hasMedicaidOption: {
      type: FieldTypes.CHECKBOX,
      optional: true,
      placeholder: 'Has a Medicaid option',
    },
    separateTemplates: separateTemplatesField,
    defaultTemplateId: assessmentTemplateField,
    fullyInsuredTemplateId: fullyInsuredTemplateField,
    selfFundedTemplateId: selfFundedTemplateField,
    authTemplateId: authTemplateField,
    authAppealsTemplateId: {
      type: FieldTypes.ID,
      placeholder: 'Auth Appeals Template',
      labels: [{ text: 'Auth Appeals Template' }],
      collection: authAppealsTemplatesCollection,
      optional: true,
    },
    authInstructionsTemplateId: {
      type: FieldTypes.ID,
      placeholder: 'Auth Instructions Template',
      labels: [{ text: 'Auth Instructions Template' }],
      collection: authInstructionsTemplatesCollection,
      optional: true,
    },
    authSheet: {
      type: FieldTypes.TEXTAREA,
      placeholder: 'Auth Cheat Sheet...',
      labels: [{ text: 'Authorization cheat sheet' }],
      optional: true,
    },
    claimsSheet: {
      type: FieldTypes.TEXTAREA,
      placeholder: 'Claims Cheat Sheet...',
      labels: [{ text: 'Claims cheat sheet' }],
      optional: true,
    },
    claimTemplateId: {
      type: FieldTypes.ID,
      placeholder: 'Claim Template',
      labels: [{ text: 'Claim Template' }],
      collection: claimTemplatesCollection,
      optional: true,
    },
    clinicalsTemplateId: {
      type: FieldTypes.ID,
      placeholder: 'Clinicals Template',
      labels: [{ text: 'Clinicals Template' }],
      collection: clinicalsTemplatesCollection,
      optional: true,
    },
    plans: {
      name: 'Plans',
      itemFields: planFields,
      labels: [{ text: 'Plans' }],
      itemName: 'Plan',
      optional: true,
    },
    loqNotRequired: loqNotRequiredField,
    cptCodes: {
      name: 'CPT Codes',
      itemName: 'Code',
      optional: true,
      itemFields: {
        type: FieldTypes.TEXT,
        placeholder: 'CPT Code',
      },
    },
    icdCodes: {
      name: 'ICD-10 Codes',
      itemName: 'Code',
      optional: true,
      itemFields: {
        type: FieldTypes.TEXT,
        placeholder: 'ICD-10 Code',
      },
    },
    redFlagged: {
      type: FieldTypes.CHECKBOX,
      placeholder: 'Red flag this insurer',
    },
    redFlaggedReason: {
      placeholder: 'Red flagged reason',
      condition: v => v?.redFlagged,
      type: FieldTypes.TEXT,
      optional: true,
    },
  },
}
