import { Preview } from '@hb/shared'
import { useState } from 'react'

export const usePreview = () => {
  const [preview, setPreview] = useState<Preview | null>(null)
  return {
    preview,
    closePreview: () => setPreview(null),
    openPreview: (p: Preview) => setPreview(p),
  }
}
