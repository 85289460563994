const commonTlds = ['com', 'org', 'net', 'int', 'edu', 'gov', 'mil', 'app']

const includesCommonTld = (text: string): boolean =>
  text.includes('localhost') || commonTlds.some(tld => text.includes(`.${tld}`))

export const getValidUrl = (text: string, isRetry?: boolean): string | null => {
  let isValid = false
  if (!includesCommonTld(text)) return null
  if (text.includes('@')) return null
  try {
    new URL(text)
    isValid = true
  } catch (_e) {
    if (!isRetry && includesCommonTld(text)) {
      return getValidUrl(`https://${text}`, true)
    }
    isValid = false
  }
  return isValid ? text : null
}
export const isUrl = (text: string, isRetry?: boolean): boolean =>
  getValidUrl(text, isRetry) !== null
