import { EmailIcon, PhoneIcon } from '@chakra-ui/icons'
import { Box, Stack, StackProps, Text } from '@chakra-ui/react'
import { colors } from '@hb/shared'
import React, { forwardRef } from 'react'
import { useAppModals } from '../../store/modals'
import { ActionButton } from '../Buttons/ActionButton'
import { ContactFormModal } from './Contact/ContactFormModal'
import { DefaultModal } from './DefaultModal'
import { ScheduleCallModal } from './ScheduleCallModal'

export const ContactButtons = forwardRef<HTMLDivElement, StackProps>(
  ({ children, ...props }, ref) => {
    const { scheduleCall, contactForm } = useAppModals()

    return (
      <Stack ref={ref} direction={['column', 'column', 'row']} {...props}>
        {children}
        <ActionButton
          gap={2}
          size="sm"
          dropShadow="sm"
          bg="white"
          flex={['unset', 'unset', 1]}
          onClick={() => scheduleCall.open('contactButtons')}>
          <PhoneIcon />
          <Text>Schedule a call</Text>
        </ActionButton>
        <ActionButton
          gap={2}
          bg="white"
          flex={['unset', 'unset', 1]}
          size="sm"
          ml={[0, 0, 'auto']}
          onClick={() => contactForm.open('contactButtons')}>
          <EmailIcon />
          <Text>Send us a message</Text>
        </ActionButton>
        <ContactFormModal id="contactButtons" />
        <ScheduleCallModal id="contactButtons" />
      </Stack>
    )
  },
)
export const AssessmentHelpModal = ({ id = 'root' }: { id?: string }) => {
  const { assessmentHelp } = useAppModals()
  return assessmentHelp.openId === id ? (
    <DefaultModal
      isOpen
      onClose={assessmentHelp.close}
      size="md"
      isCentered
      headerProps={{ bg: 'white', color: colors.green.hex }}
      header={
        <Text py={3} px={4} fontSize="xl" fontFamily="Comfortaa" color={colors.green.hex}>
          {' '}
          Need some help?
        </Text>
      }
      render={() => (
        <Box bg="gray.50" px={6} pb={8}>
          <Text fontFamily="Open Sans" fontWeight={400} fontSize="md" px={2} pt={4} mb={4}>
            If you are having difficulties answering these questions, we are here to help! <br /> If
            at all possible, please try to complete any questions you can to the best of your
            abilities and then schedule a call.
            <br />
            <br /> If you cannot answer all of these questions, you can schedule a call or send us a
            message below:
          </Text>
          <ContactButtons />
        </Box>
      )}
    />
  ) : null
}
