import { Flex, VStack } from '@chakra-ui/react'
import { PracticeInvoice } from '@hb/shared'
import React from 'react'
import { ActionLog } from '../../ActionLog'

export const InvoiceEmailNotificationsView = ({ invoice }: { invoice: PracticeInvoice }) =>
  invoice.emailNotifications?.length ? (
    <VStack pt={2} w="100%" spacing={0} align="flex-end">
      {invoice.emailNotifications?.map(({ sentToEmail, sentBy, sentOn }) => (
        <Flex key={`${sentOn}`}>
          <ActionLog
            group="admin"
            by={sentBy}
            on={sentOn}
            action={`Notification email sent to ${sentToEmail}`}
          />
        </Flex>
      ))}
    </VStack>
  ) : null
