import {
  EditorVersion,
  getShortcutArgs,
  PopulatedNode,
  TemplateKey,
  UpdateCallback,
} from '@hb/shared'
import React, { PropsWithChildren, useCallback, useContext, useMemo } from 'react'
import { Descendant } from 'slate'
import { UserContext } from '../../../contexts/UserContext'
import { saveAssessmentDraft, submitAssessmentResults } from '../../../hooks/backend/assessments'
import { EditMode, TemplateDataProvider } from './data'
import { TemplateInsurancePlansProvider, useTemplateInsurancePlans } from './plan'
import { TemplateViewProvider } from './view'

export type AssessmentDataProviderProps = {
  type: TemplateKey
  editMode: EditMode
}
export const AssessmentDataProvider = ({
  children,
  type,
  editMode,
}: PropsWithChildren<AssessmentDataProviderProps>) => {
  const { selectedPlan } = useTemplateInsurancePlans()
  const { selectedAssessment, user } = useContext(UserContext)
  const shortcutArgs = useMemo(
    () => getShortcutArgs(user, selectedAssessment?.populated, selectedPlan),
    [user, selectedAssessment, selectedPlan],
  )

  return (
    <TemplateDataProvider editMode={editMode} type={type} data={shortcutArgs}>
      {children}
    </TemplateDataProvider>
  )
}

const AssessmentEditorProviderBody = ({
  children,
  initialText,
}: PropsWithChildren<{ initialText: Descendant[] }>) => {
  const { assessmentId } = useContext(UserContext)

  const { selectedPlanId } = useTemplateInsurancePlans()
  const onSubmit = useCallback(
    async (text: PopulatedNode[], version: EditorVersion) => {
      if (!assessmentId) return { error: 'internal error' }
      return submitAssessmentResults({
        assessmentId,
        results: text,
        editorVersion: version,
        coverageId: selectedPlanId,
      })
        .then(res => res.data as UpdateCallback)
        .catch(err => ({ error: err.message }))
      // submit
    },
    [assessmentId, selectedPlanId],
  )

  const onSave = useCallback(
    async (value: Descendant[], version: EditorVersion) => {
      if (!assessmentId) return { error: 'internal error' }
      return saveAssessmentDraft({
        assessmentId,
        editorVersion: version,
        draft: value || initialText,
      })
        .then(res => res.data as UpdateCallback)
        .catch(err => ({ error: err.message }))
    },
    [assessmentId, initialText],
  )

  return (
    <TemplateViewProvider initialText={initialText} onSave={onSave} onSubmit={onSubmit}>
      <AssessmentDataProvider editMode="document" type="assessments">
        {children}
      </AssessmentDataProvider>
    </TemplateViewProvider>
  )
}

export const AssessmentEditorProvider = ({
  children,
  initialText,
}: PropsWithChildren<{ initialText: Descendant[] }>) => (
  <TemplateInsurancePlansProvider>
    <AssessmentEditorProviderBody initialText={initialText}>
      {children}
    </AssessmentEditorProviderBody>
  </TemplateInsurancePlansProvider>
)
