import { multiFactor, MultiFactorSession } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { auth } from '../../../backend'

export const useMultiFactorSession = (onLoad?: (session: MultiFactorSession) => void) => {
  const [multiFactorSession, setMultiFactorSession] = useState<MultiFactorSession | null>(null)
  const [loadingSession, setLoadingSession] = useState(true)

  useEffect(() => {
    const user = auth.currentUser
    if (!user) return
    multiFactor(user)
      .getSession()
      .then(session => {
        setLoadingSession(false)
        if (onLoad) onLoad(session)
        setMultiFactorSession(session)
      })
      .catch(e => {
        console.error(e)
        setLoadingSession(false)
      })
  }, [onLoad])

  return { multiFactorSession, loadingSession }
}
