import { getAppRole } from '@hb/shared'
import { useMemo } from 'react'
import { useApp, usePracticeAccess } from '../../../contexts'
import { useAuth } from '../../../store'

export const useAppRole = () => {
  const { appName } = useApp()
  const authUser = useAuth(s => s.authUser)
  const claims = useAuth(s => s.claims)

  const { selectedPracticeId } = usePracticeAccess()

  return useMemo(
    () => getAppRole(appName, authUser?.uid || null, claims, selectedPracticeId),
    [appName, authUser, claims, selectedPracticeId],
  )
}
