import {
  Flex,
  forwardRef,
  HeadingProps,
  Image,
  ImageProps,
  Spinner,
  Stack,
  StackProps,
  Text,
  TextProps,
} from '@chakra-ui/react'
import React from 'react'
import { Header } from '../Header'

const infoItemWidth = ['100%', '100%', '50%']

export const InfoHeader = (props: HeadingProps) => (
  <Header
    fontSize={['md', 'xl']}
    color="gray.900"
    fontWeight={600}
    fontFamily="Comfortaa"
    textAlign="left"
    {...props}
  />
)

export const InfoImage = (props: ImageProps) => (
  <Flex width={infoItemWidth} px={4}>
    <Image objectFit="contain" fallback={<Spinner />} {...props} />
  </Flex>
)

export const InfoText = (props: TextProps) => (
  <Text
    px={3}
    // fontSize={[
    fontSize="md"
    // fontWeight={400}
    fontWeight={500}
    fontFamily="Open Sans"
    color="gray.600"
    // textAlign='left'
    {...props}
  />
)
export const Info = forwardRef<StackProps, 'div'>((props: StackProps, ref) => (
  <Stack
    spacing={2}
    px={4}
    py={2}
    direction="column"
    textAlign={['center', 'center', 'left']}
    align={['center', 'center', 'flex-start']}
    width={infoItemWidth}
    ref={ref}
    {...props}
  />
))
