import {
  ASSESSMENTS,
  FieldMapValue,
  getCoverageLabel,
  getPlanPropPath,
  InsuranceCoverage,
  WithId,
} from '@hb/shared'
import { FORM_ERROR, ValidationErrors } from 'final-form'
import { collection, doc, updateDoc } from 'firebase/firestore'
import React, { createContext, PropsWithChildren, useCallback, useContext, useMemo } from 'react'
import { db } from '../../../backend'
import { useApp } from '../../../contexts'
import { PopUpMessageContext } from '../../../contexts/PopUpMessage/PopUpMessageContext'
import { addMetadata } from '../../../utils'
import { CoverageStageProps } from './types'

export type CoverageViewContextValue = {
  coverage: InsuranceCoverage | undefined
  isMedicaid: boolean
  optional?: boolean
  propPath: string
  assessmentId: string
  isNew?: boolean
  handleSubmit: (data: FieldMapValue) => Promise<ValidationErrors>
  baseStoragePath: string
  adminView?: boolean
}

export const CoverageViewContext = createContext<CoverageViewContextValue>({
  assessmentId: '',
  baseStoragePath: '',
  coverage: undefined,
  handleSubmit: async () => ({}),
  isMedicaid: false,
  propPath: '',
})

export const CoverageViewProvider = ({
  children,
  coverage,
  assessmentId,
  id,
  adminView,
  request,
}: PropsWithChildren<CoverageStageProps>) => {
  const { showMessage } = useContext(PopUpMessageContext)

  const propPath = useMemo(() => getPlanPropPath(!!adminView, id), [adminView, id])
  const baseStoragePath = useMemo(
    () => `${ASSESSMENTS}/${assessmentId}/${propPath.replace(/\./g, '/')}`,
    [propPath, assessmentId],
  )

  const ref = useMemo(() => doc(collection(db, ASSESSMENTS), assessmentId), [assessmentId])
  const { appName } = useApp()

  const handleSubmit = useCallback(
    async (data: FieldMapValue) => {
      if (!ref) {
        showMessage({
          text: 'An error occurred',
          subText:
            'Please send us a message on the contact page and we will get back to you as soon as possible',
          type: 'error',
        })
        return { [FORM_ERROR]: 'An error occurred' }
      }
      try {
        const submitted: FieldMapValue = { ...coverage, ...data }
        if (request && !coverage?.fromRequest) submitted.fromRequest = request
        if (request?.withCallInForm) {
          submitted.callInRequests = {
            [Date.now()]: addMetadata(
              {
                on: Date.now(),
                by: 'admin',
                notes: `From coverage request | ${request.message}`,
              },
              appName,
              true,
            ),
            ...submitted.callInRequests,
          }
        }
        const withMetadata = addMetadata(
          submitted,
          appName,
          !(coverage as InsuranceCoverage)?.createdOn,
        )
        await updateDoc(ref, propPath, withMetadata)
        showMessage({
          type: 'success',
          text: `${getCoverageLabel(id, coverage || null)} coverage updated!`,
        })
      } catch (err: any) {
        showMessage({
          text: 'An error occurred',
          subText:
            err.message ||
            'Please send us a message on the contact page and we will get back to you as soon as possible',
          type: 'error',
        })
        return {
          [FORM_ERROR]:
            err.message ||
            'An error occurred. Please send us a message on the contact page and we will get back to you as soon as possible',
        }
      }
      return undefined
    },
    [ref, showMessage, propPath, coverage, appName, request, id],
  )

  const contextData = useMemo<CoverageViewContextValue>(
    () => ({
      coverage: coverage as WithId<InsuranceCoverage> | undefined,
      isMedicaid: coverage?.type === 'medicaid',
      propPath,
      assessmentId,
      baseStoragePath,
      adminView,
      handleSubmit,
    }),
    [coverage, propPath, assessmentId, adminView, handleSubmit, baseStoragePath],
  )

  return <CoverageViewContext.Provider value={contextData}>{children}</CoverageViewContext.Provider>
}
