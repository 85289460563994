import { Reaction } from '@hb/shared'
import { arrayUnion, DocumentReference, updateDoc } from 'firebase/firestore'
import { useCallback, useContext, useState } from 'react'
import { PopUpMessageContext, useApp } from '../../contexts'
import { addCreatedMetadata } from '../../utils'

export const useAddReaction = (docRef: DocumentReference, propPath: string) => {
  const { processResponse } = useContext(PopUpMessageContext)
  const { appName } = useApp()
  const [addingReaction, setAddingReaction] = useState(false)
  const addReaction = useCallback(
    async (type: Reaction['type']) => {
      setAddingReaction(true)
      try {
        await updateDoc(docRef, propPath, arrayUnion(addCreatedMetadata({ type }, appName)))
      } catch (err: any) {
        processResponse({
          error: err?.message || 'An error occurred adding the reaction',
        })
      }
      setAddingReaction(false)
    },
    [docRef, propPath, processResponse, appName],
  )

  return { addReaction, addingReaction }
}
