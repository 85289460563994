import { Text } from '@chakra-ui/react'
import React from 'react'

import { useImagesLoaded } from '../../../hooks/useImagesLoaded'
import { useAppModals } from '../../../store/modals'

import { useApp } from '../../../contexts/AppContext'
import { MyAssessmentShortcut } from '../../Assessments/MyAssessmentShortcut'
import { BigGreenButton } from '../../Buttons/BigGreenButton'
import { PageHeader } from '../../Header'
import { InfoStack } from '../../Info/InfoFlex'
import { ContactFormModal } from '../../Modals/Contact/ContactFormModal'
import { ScheduleCallModal } from '../../Modals/ScheduleCallModal'
import { PageContainer } from '../PageContainer'
import { ViewHeaderImage } from '../ViewHeaderImage'
import contactImage from './contactImage.png'

export const ContactView: React.FC = () => {
  const { scheduleCall, contactForm } = useAppModals()
  const { loaded, onLoad } = useImagesLoaded(1)
  const { appName } = useApp()
  return (
    <PageContainer transition="opacity 300ms" opacity={loaded ? 1 : 0}>
      <PageHeader>CONTACT US</PageHeader>
      <ViewHeaderImage src={contactImage} onLoad={onLoad} />
      <InfoStack my={8}>
        <BigGreenButton mb={0} onClick={() => scheduleCall.open('ContactView')} fontWeight={300}>
          Schedule a call
        </BigGreenButton>

        <BigGreenButton
          onClick={() => contactForm.open('ContactView')}
          whiteSpace="pre-wrap"
          flexDirection="column"
          mb={0}
          fontWeight={300}>
          <Text>Send us a message</Text>
        </BigGreenButton>
      </InfoStack>
      {appName === 'app' ? <MyAssessmentShortcut /> : null}
      <ContactFormModal id="ContactView" />
      <ScheduleCallModal id="ContactView" />
    </PageContainer>
  )
}
