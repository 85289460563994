import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { UserContext } from '../../contexts'
import { useMobileLayout } from '../../hooks/useMobileLayout'
import { Expandable } from '../Expandable'
import { AdditionalPlansView } from './AdditionalPlansView'
import { CoverageStage } from './Coverage'

export const AdminInsurancePlansView = ({ preview }: { preview?: boolean }) => {
  const {
    selectedAssessment: { populated: selectedAssessment },
    assessmentId,
  } = useContext(UserContext)
  const { nextActions } = selectedAssessment || {}

  const { plans } = selectedAssessment || {}

  const { ref, width } = useMobileLayout()
  if (!selectedAssessment || !assessmentId) {
    return (
      <Flex flexFlow="column" p={3}>
        <Text>Select an assessment to see plans</Text>
      </Flex>
    )
  }

  return (
    <Flex
      ref={ref}
      bg={preview ? 'transparent' : 'gray.50'}
      w="100%"
      boxShadow={preview ? 'none' : '1px 1px 4px #00000055'}
      borderRadius={preview ? 0 : 6}
      py={preview ? 0 : 2}
      flexFlow="column">
      <Flex w="100%" gap={preview ? 1 : 2} flexFlow="column" px={preview ? 1 : 3} py={2}>
        {plans?.isMedicaid ? (
          <CoverageStage
            id="medicaidCoverage"
            adminView
            width={width}
            assessmentId={assessmentId}
            nextAction={nextActions?.medicaidCoverage}
            coverage={plans?.medicaidCoverage}
          />
        ) : (
          <CoverageStage
            width={width}
            id="primaryCoverage"
            adminView
            assessmentId={assessmentId}
            nextAction={nextActions?.primaryCoverage}
            coverage={plans?.primaryCoverage}
          />
        )}
        {plans?.secondaryCoverage ? (
          <CoverageStage
            width={width}
            id={plans.secondaryCoverage.id}
            adminView
            assessmentId={assessmentId}
            nextAction={nextActions?.additionalPlans?.[plans.secondaryCoverage.id]}
            coverage={plans.secondaryCoverage}
          />
        ) : null}
      </Flex>
      {assessmentId ? (
        <AdditionalPlansView
          adminView
          preview={preview}
          assessmentId={assessmentId}
          assessment={selectedAssessment}
          plans={plans}
        />
      ) : null}
    </Flex>
  )
}

export const CollapsibleInsurancePlansView = () => (
  <Expandable
    borderRadius={6}
    headerProps={{ bg: 'gray.50', borderRadius: 4 }}
    border="1px solid #cdcdcd"
    header={() => (
      <Flex py={1} px={2} align="center" justify="space-between" w="100%">
        <Text fontSize="md" color="gray.600" fontWeight={500}>
          Insurance Plans
        </Text>
      </Flex>
    )}>
    <Box px={1} w="100%">
      <AdminInsurancePlansView preview />
    </Box>
  </Expandable>
)
