import { StoreApi, UseBoundStore } from 'zustand'

type State = object

type WithSelectors<S> = S extends { getState: () => infer T }
  ? S & { use: { [K in keyof T]: () => T[K] } }
  : never

const createSelectors = <S extends UseBoundStore<StoreApi<State>>>(_store: S) => {
  const store = _store as WithSelectors<typeof _store>
  store.use = {}
  const stateKeys = Object.keys(store.getState()) as Array<keyof State>
  for (let i = 0; i < stateKeys.length; i += 1) {
    const k = stateKeys[i]
    ;(store.use as any)[k] = () => store(s => s[k as keyof typeof s])
  }

  return store
}

export default createSelectors
