import { createStandaloneToast } from '@chakra-ui/react'

import { chakraTheme } from './chakraTheme'

const { toast: standaloneToast, ToastContainer } = createStandaloneToast({
  defaultOptions: { position: 'top' },
  theme: chakraTheme,
})

export const showSuccess = (successMessage: string) =>
  standaloneToast({
    description: successMessage,
    status: 'success',
  })

export const showError = (errorMessage: string) =>
  standaloneToast({ description: errorMessage, status: 'error' })
export { ToastContainer, standaloneToast }
