import { Template, TemplateKey, UpdateCallback } from '@hb/shared'
import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react'
import { TemplateDataProvider } from './data'

type TemplateEditorContextValue = {
  isLoading: boolean
  onSubmit: (template: Template) => Promise<UpdateCallback>
  template: Template | null
}

const TemplateEditorContext = createContext<TemplateEditorContextValue>({
  onSubmit: () => Promise.resolve({}),
  isLoading: false,
  template: null,
})

export const TemplateEditorProvider = ({
  children,
  onSubmit,
  type,
  template,
  isLoading,
  shortcutArgs,
}: PropsWithChildren<{
  shortcutArgs: Record<string, any>
  type: TemplateKey
  onSubmit: (updated: Template) => Promise<UpdateCallback>
  template: Template | null
  isLoading?: boolean
}>) => {
  const contextValue = useMemo<TemplateEditorContextValue>(
    () => ({
      type,
      onSubmit,
      template,
      isLoading: isLoading || false,
    }),
    [type, onSubmit, template, isLoading],
  )

  return (
    <TemplateDataProvider data={shortcutArgs} editMode="template" type={type}>
      <TemplateEditorContext.Provider value={contextValue}>
        {children}
      </TemplateEditorContext.Provider>
    </TemplateDataProvider>
  )
}

export const useTemplateEditor = () => useContext(TemplateEditorContext)
