import { Popover, PopoverTrigger } from '@chakra-ui/react'
import { getFullName, UpdateAdminColorArgs } from '@hb/shared'
import { doc } from 'firebase/firestore'
import React, { useCallback, useContext, useMemo } from 'react'
import { db } from '../../../../backend/db'
import { updateAdminColorFunction } from '../../../../backend/functions'
import { PopUpMessageContext, useApp } from '../../../../contexts'
import { DataListContext } from '../../../../contexts/DataListContext'
import { DataGridContext } from '../../../../contexts/UserGridContext'
import { useUpdateDoc } from '../../../../hooks'
import { GridItemPreviewContent } from '../../../DataView/GridItemPreviewContent'
import { SortColorPopoverContent } from '../../../Users/Profile/SortColorPopover'
import { SortColorCircle } from '../../../Users/UrgentBadge'
import { SortButton } from '../../SortButton'

// const ListSortColorPopoverContent = ({
//   item,
//   propName = 'sortColor',
//   notesPropName,
//   title,
//   label,
//   notesLabel,
//   updateRef,
// }: {
//   label?: string
//   propName?: string
//   title: string
//   notesPropName?: string
//   item: any
//   updateRef: DocumentReference
//   notesLabel?: string
// }) => {
//   const { [propName]: color } = item || {}
//   const notes = notesPropName ? item[notesPropName] : undefined
//   const update = useUpdateDoc(label?.toLowerCase())
//   return (
//     <SortColorPopoverContent
//       title={title}
//       label={label}
//       notesLabel={notesLabel}
//       onSubmit={async (v) => {
//         const updated: Record<string, any> = {}
//         if (notesPropName && (v.notes !== undefined)) updated[notesPropName] = v.notes
//         if (v.color !== undefined) updated[propName] = v.color
//         await update(updateRef, '', updated)
//       }}
//       notes={notes}
//       color={color}
//     />
//   )
// }

export const ListSortColorPopover = ({
  sortKey = 'sortColor',
  notesPropName,
  notesLabel,
}: {
  sortKey?: string
  notesPropName?: string
  notesLabel?: string
}) => {
  const { closePreview, item, preview, collection } = useContext(DataGridContext)
  const { sortBy } = useContext(DataListContext)
  const { id } = preview || {}
  // const { urgentColor = colors.red.hex } = getIsUrgent(item) || {}
  const value = item?.[sortKey]
  const notes = notesPropName ? item?.[notesPropName] : undefined
  const updateRef = useMemo(
    () =>
      id
        ? doc(db, collection === 'assessment-snippets' ? 'assessments-admin' : collection, id)
        : null,
    [collection, id],
  )
  const update = useUpdateDoc('color')
  const { processResponse } = useContext(PopUpMessageContext)
  const { appName } = useApp()
  const handleSubmit = useCallback(
    async (args: Partial<UpdateAdminColorArgs>) => {
      const { color, notes: updatedNotes } = args
      if (!id) return
      if (collection === 'user-roles') {
        try {
          const updated: Partial<UpdateAdminColorArgs> = {}
          if (color !== undefined) updated.color = color
          if (updatedNotes !== undefined) updated.notes = updatedNotes
          await updateAdminColorFunction({
            appName,
            id,
            practiceId: null,
            ...updated,
          })
        } catch (e: any) {
          processResponse({ error: e?.message || 'Error updating color' })
          console.error(e)
        }
        return
      }
      if (!updateRef) return
      await update(updateRef, sortKey, color)
    },
    [collection, id, update, updateRef, processResponse, sortKey, appName],
  )
  return (
    <Popover
      onClose={closePreview}
      closeOnBlur={false}
      isOpen={!!item}
      trigger="click"
      isLazy
      placement="right">
      <PopoverTrigger>
        <SortButton noArrow sortKey={sortKey}>
          <SortColorCircle onClick={() => sortBy('sortColor')} mx="auto" />
        </SortButton>
      </PopoverTrigger>
      <GridItemPreviewContent previewId="sortColor">
        {updateRef ? (
          <SortColorPopoverContent
            title={getFullName(item)}
            onSubmit={handleSubmit}
            notes={notes}
            notesLabel={notesLabel}
            color={value}
          />
        ) : null}
      </GridItemPreviewContent>
    </Popover>
  )
}
