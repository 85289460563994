import { FieldMap, FieldTypes, UpdateCallback } from '@hb/shared'

import React, { useCallback, useContext } from 'react'
import { PopUpMessageContext } from '../../contexts/PopUpMessage/PopUpMessageContext'
import { ThemeContext } from '../../contexts/ThemeContext'
import { sendPasswordResetEmail } from '../../hooks/backend/user'
import { EditModalProps, GenericEditModal } from '../DataView'

const forgotPassword: FieldMap = {
  name: 'Forgot your password?',
  children: {
    email: {
      type: FieldTypes.EMAIL,
      labels: [{ text: "We'll send you a link to reset your password" }],
      placeholder: 'Email',
    },
  },
}

export const ForgotPasswordModal = (props: Omit<EditModalProps, 'field' | 'onSubmit'>) => {
  const { processResponse } = useContext(PopUpMessageContext)

  const handleSubmit = useCallback(
    async ({ email }: { email?: string }): Promise<UpdateCallback> => {
      if (!email) return { error: 'Email required' }
      return sendPasswordResetEmail({ email })
        .then(() =>
          processResponse({
            success: 'A password reset link has been sent to your email!',
          }),
        )
        .catch(err => {
          console.error(err)
          let error = 'Hmm, something went wrong'
          if (err.code === 'auth/user-not-found') {
            error = "We couldn't find that email address in our system"
          }
          return processResponse({
            error,
          })
        })
    },
    [processResponse],
  )

  return (
    <ThemeContext.Provider value={{ hideLabels: false, placeholderAbove: false }}>
      <GenericEditModal size="md" onSubmit={handleSubmit} field={forgotPassword} {...props} />
    </ThemeContext.Provider>
  )
}
