import { Template, TemplateKey } from '../../types/templates'
import { AppCollectionDataTypes } from '../types'
import { Collection, Collections } from '../types/collection'
import { PracticeAppCollectionDataTypes } from '../types/practiceApp'
import { adminsCollection } from './admins'
import { assessmentsCollection } from './assessments'
import { medicaidProvidersCollection, providersCollection } from './insurer'
import { invoicesCollection } from './invoices'
import { patientsCollection } from './patients'
import { midwivesCollection } from './practice'
import {
  assessmentTemplatesCollection,
  authAppealsTemplatesCollection,
  authInstructionsTemplatesCollection,
  authTemplatesCollection,
  claimTemplatesCollection,
  clinicalsTemplatesCollection,
  consentFormTemplatesCollection,
  invoiceTemplatesCollection,
} from './templates'

export type AppCollections = Collections<AppCollectionDataTypes>
export type PracticeAppCollections = Collections<PracticeAppCollectionDataTypes>
export const appCollections: AppCollections = {
  assessments: assessmentsCollection,
  practices: midwivesCollection,
  providers: providersCollection,
  medicaidProviders: medicaidProvidersCollection,
  authAppealsTemplates: authAppealsTemplatesCollection,
  authInstructionsTemplates: authInstructionsTemplatesCollection,
  authTemplates: authTemplatesCollection,
  claimTemplates: claimTemplatesCollection,
  consentFormTemplates: consentFormTemplatesCollection,
  clinicalsTemplates: clinicalsTemplatesCollection,
  templates: assessmentTemplatesCollection,
  invoicesReceiptsTemplates: invoiceTemplatesCollection,
  admins: adminsCollection,
  invoices: invoicesCollection,
  patients: patientsCollection,
}

export const templateCollections: Record<TemplateKey, Collection<Template>> = {
  assessments: assessmentTemplatesCollection,
  authAppeals: authAppealsTemplatesCollection,
  authInstructions: authInstructionsTemplatesCollection,
  authorizations: authTemplatesCollection,
  claims: claimTemplatesCollection,
  clinicals: clinicalsTemplatesCollection,
  consentForm: consentFormTemplatesCollection,
  invoiceAndReceipt: invoiceTemplatesCollection,
}

export const practiceAppCollections: PracticeAppCollections = {
  providers: providersCollection,
}
