import { FieldTypes } from '../constants/FieldTypes'
import { DropdownField, PhoneField, TextField } from '../types'

export const enrollmentTypeField: DropdownField = {
  type: FieldTypes.DROPDOWN,
  placeholder: 'Select an option to enroll with',
  options: [
    { id: 'phone', text: 'Phone (SMS)' },
    { id: 'totp', text: 'Authenticator App' },
  ],
}

export const twoFactorPhoneField: PhoneField = {
  type: FieldTypes.PHONE,
  placeholder: 'Phone Number for 2 Factor Authentication',
}

export const twoFactorCodeField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Verification Code',
}

export const phoneDisplayNameField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Phone nickname (optional, e.g. "Cell Phone")',
  validate: (v: string) => {
    if (!v) return undefined
    if (v.length < 4) return 'Display name should be at least 3 characters'
    return undefined
  },
}

export const authenticatorDisplayNameField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Authenticator nickname (e.g. "Google Authenticator")',
  validate: (v: string) => {
    if (!v) return 'Required'
    if (v.length < 4) return 'Display name should be at least 3 characters'
    return undefined
  },
}
