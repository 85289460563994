import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Box, Collapse, HStack, Text, VStack } from '@chakra-ui/react'
import {
  colors,
  defaultFieldValidate,
  formatPhoneNumber,
  phoneDisplayNameField,
  twoFactorCodeField,
  twoFactorPhoneField,
  UpdateCallback,
} from '@hb/shared'
import { multiFactor, PhoneAuthProvider, PhoneMultiFactorGenerator } from 'firebase/auth'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { auth } from '../../../backend'
import { PopUpMessageContext } from '../../../contexts'
import { signInCallback, signOut, useAuth } from '../../../store'
import { ActionButton, SolidActionButton } from '../../Buttons'
import { CollapseError } from '../../CollapseError'
import { StandaloneInput } from '../../forms'
import { InputRef } from '../../forms/Input/types'
import { useMultiFactorSession } from './hooks'
import { StepNumberSpan } from './StepNumberSpan'

const PhoneEnrollEnterNumber = ({
  verificationId,
  sendingMessage,
  loadingSession,
  setPhoneFocused,
  phoneFocused,
  sendVerificationCode,
  onBack,
}: {
  verificationId: string | null
  sendingMessage: boolean
  setPhoneFocused: (f: boolean) => void
  loadingSession: boolean
  phoneFocused: boolean
  sendVerificationCode: (p: string, displayName: string) => void
  onBack: () => void
}) => {
  const [phone, setPhone] = useState('')
  const [displayName, setDisplayName] = useState('')
  const phoneError = useMemo(() => defaultFieldValidate(twoFactorPhoneField, phone), [phone])

  const inputRef = useRef<InputRef>(null)
  useEffect(() => {
    if (inputRef.current && !loadingSession && !verificationId) {
      inputRef.current.focus()
    }
  }, [verificationId, loadingSession])

  return (
    <Collapse unmountOnExit in={!verificationId} style={{ width: '100%' }}>
      <Text px={2} fontFamily="Hero-New" fontSize="md" color="gray.600">
        <StepNumberSpan step={2} />
        Enter your phone number to receive a verification code.
      </Text>
      <Box w="100%" py={1} px={2}>
        <StandaloneInput
          ref={inputRef}
          theme="detailed"
          field={twoFactorPhoneField}
          onFocus={() => setPhoneFocused(true)}
          onBlur={() => setPhoneFocused(false)}
          onChange={p => {
            setPhone(p)
          }}
          value={phone}
        />
        <StandaloneInput
          theme="detailed"
          field={phoneDisplayNameField}
          onChange={v => {
            setDisplayName(v)
          }}
          value={displayName}
        />
      </Box>
      <CollapseError error={!phone || phoneFocused ? undefined : phoneError} />
      <HStack p={1} w="100%">
        <ActionButton
          size="sm"
          bg="white"
          border="1px solid #cdcdcd"
          color="gray.500"
          onClick={onBack}
          _hover={{
            bg: 'gray.100',
          }}>
          Back to Step 1
        </ActionButton>
        <SolidActionButton
          ml="auto"
          size="sm"
          onClick={() => sendVerificationCode(phone, displayName)}
          opacity={!phone || phoneError ? 0.5 : 1}
          pointerEvents={!phone || phoneError ? 'none' : 'auto'}
          isLoading={sendingMessage || loadingSession}
          isDisabled={!!phoneError || !phone || sendingMessage}>
          Send Verification Code
        </SolidActionButton>
      </HStack>
    </Collapse>
  )
}

const PhoneEnrollEnterCode = ({
  onBack,
  onVerify,
  verifiedPhone,
}: {
  onBack: () => void
  verifiedPhone: string
  onVerify: (c: string) => Promise<UpdateCallback>
}) => {
  const [code, setCode] = useState('')

  const [verifyingCode, setVerifyingCode] = useState(false)

  const handleVerify = useCallback(() => {
    setVerifyingCode(true)
    onVerify(code).finally(() => setVerifyingCode(false))
  }, [code, onVerify])

  return (
    <VStack px={1} w="100%" align="flex-start">
      <Text pt={1} fontFamily="Hero-New" fontSize="md" color="gray.600">
        <StepNumberSpan step={3} />
        Please enter the code sent to {formatPhoneNumber(verifiedPhone)}.
      </Text>
      <StandaloneInput
        theme="detailed"
        field={twoFactorCodeField}
        value={code}
        onChange={setCode}
      />
      <HStack w="100%">
        <ActionButton
          size="sm"
          bg="white"
          border="1px solid #cdcdcd"
          isDisabled={verifyingCode}
          color="gray.500"
          onClick={onBack}
          _hover={{
            bg: 'gray.100',
          }}>
          Back to Step 2
        </ActionButton>
        <ActionButton
          ml="auto"
          size="sm"
          onClick={handleVerify}
          bg={colors.green.hex}
          isLoading={verifyingCode}
          color="white"
          border="none">
          Verify
        </ActionButton>
      </HStack>
    </VStack>
  )
}

export const PhoneEnroll = ({ onBack }: { onBack: () => void }) => {
  const [verificationId, setVerificationId] = useState('')
  const [phoneFocused, setPhoneFocused] = useState(false)
  const [verifiedPhone, setVerifiedPhone] = useState('')
  const [verifiedDisplayName, setVerifiedDisplayName] = useState('')
  const user = useAuth(s => s.enrollingUser)

  const { multiFactorSession, loadingSession } = useMultiFactorSession()
  const [sendingMessage, setSendingMessage] = useState(false)
  const [needsRecentLogin, setNeedsRecentLogin] = useState(false)

  const { processResponse } = useContext(PopUpMessageContext)
  const sendVerificationCode = useCallback(
    (phone: string, displayName: string) => {
      if (!multiFactorSession) {
        processResponse({
          error: 'No multi-factor session',
        })
        return
      }
      const { recaptchaVerifier } = useAuth.getState()
      const phoneAuthProvider = new PhoneAuthProvider(auth)
      setVerifiedPhone(phone)
      setVerifiedDisplayName(displayName)
      const phoneInfoOptions = {
        phoneNumber: `+1${phone}`,
        session: multiFactorSession,
      }
      setSendingMessage(true)
      phoneAuthProvider
        .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
        .then(setVerificationId)
        .catch(e => {
          // recaptchaVerifier.clear()
          if (e.code === 'auth/requires-recent-login') {
            setNeedsRecentLogin(true)
            processResponse({
              error: 'Please log in again to enroll your phone',
            })
            console.error(e.code)
          }
          console.error(e)
        })
        .finally(() => setSendingMessage(false))
    },
    [multiFactorSession, processResponse],
  )

  const verifyCode = useCallback(
    async (code: string) => {
      if (!verificationId) return processResponse({ error: 'No verification ID' })
      if (!user) return processResponse({ error: 'No user' })
      const cred = PhoneAuthProvider.credential(verificationId, code)
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)
      return multiFactor(user)
        .enroll(multiFactorAssertion, verifiedDisplayName)
        .then(() => {
          setVerificationId('')
          setVerifiedPhone('')
          setVerifiedDisplayName('')
          signInCallback(user)
          onBack()
          return processResponse({ success: 'Phone enrolled' })
        })
        .catch((e: any) => processResponse({ error: e.message || 'An error occurred' }))
    },
    [verificationId, user, processResponse, verifiedDisplayName, onBack],
  )

  return (
    <Box w="100%">
      <Collapse unmountOnExit in={!needsRecentLogin} style={{ width: '100%' }}>
        <PhoneEnrollEnterNumber
          verificationId={verificationId}
          sendingMessage={sendingMessage}
          setPhoneFocused={setPhoneFocused}
          loadingSession={loadingSession}
          phoneFocused={phoneFocused}
          sendVerificationCode={sendVerificationCode}
          onBack={onBack}
        />
      </Collapse>
      <Collapse unmountOnExit in={needsRecentLogin} style={{ width: '100%' }}>
        <VStack border="1px solid #cdcdcd" py={2} bg="white" px={3} borderRadius={4} w="100%">
          <Text fontFamily="Open Sans">
            You must have a fresh login session to enroll your phone. Please sign out and then sign
            back in to continue.
          </Text>
          <SolidActionButton
            gap={1}
            onClick={() => {
              setNeedsRecentLogin(false)
              useAuth.getState().recaptchaVerifier.clear()
              signOut()
            }}>
            <Text>Sign Out</Text>
            <ArrowForwardIcon w={5} h={5} filter="drop-shadow(1px 1px 3px rgba(0,0,0,0.5))" />
          </SolidActionButton>
        </VStack>
      </Collapse>
      <Collapse unmountOnExit in={!!verificationId} style={{ width: '100%' }}>
        <PhoneEnrollEnterCode
          verifiedPhone={verifiedPhone}
          onBack={() => setVerificationId('')}
          onVerify={verifyCode}
        />
      </Collapse>
    </Box>
  )
}
