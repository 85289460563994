import { Box, Button, Divider, HStack, Text, VStack } from '@chakra-ui/react'
import { colors, objectToArray, PracticeInvoice, WithId } from '@hb/shared'
import React, { useMemo, useState } from 'react'
import { AddAdditionalPatientModal } from './AddAdditionalPatientModal'
import { InvoiceAdditionalPatientView } from './InvoiceAdditionalPatientView'

export const InvoiceAdditionalPatientsView = ({
  invoice,
  numAssessments,
}: {
  invoice: WithId<PracticeInvoice>
  numAssessments: number
}) => {
  const [isAddingPatient, setIsAddingPatient] = useState(false)

  const additionalPatientsArr = useMemo(() => {
    const additionalPatients = objectToArray(invoice.additionalPatients || {})
    return additionalPatients.sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10))
  }, [invoice])

  return (
    <Box
      bg={`${colors.green.hex}88`}
      w="100%"
      borderBottom="1px solid #ababab"
      borderTop="1px solid #ababab">
      <VStack
        align="flex-start"
        bg="white"
        borderLeft="1px solid #cdcdcd"
        borderRight="1px solid #cdcdcd"
        w="100%"
        spacing={0}>
        <HStack py={1} px={2} bg="#efefef" w="100%">
          <Text fontFamily="Open Sans" fontWeight={600} fontSize="sm" color="gray.600">
            ADDITIONAL PATIENTS (NOT IN SYSTEM)
          </Text>
          <Button
            ml="auto"
            onClick={() => setIsAddingPatient(true)}
            border={`1px solid ${colors.green.hex}`}
            color={colors.green.hex}
            bg="white"
            height="24px"
            fontSize="xs"
            fontWeight={500}
            fontFamily="Hero-New">
            + ADD ADDITIONAL PATIENT
          </Button>
        </HStack>
        {additionalPatientsArr.length ? (
          additionalPatientsArr.map((patient, index) => (
            <InvoiceAdditionalPatientView
              index={numAssessments + index}
              invoiceId={invoice.id}
              key={patient.id}
              patient={patient}
            />
          ))
        ) : (
          <Text py={1} px={2} color="gray.800" fontFamily="Open Sans" fontSize="md">
            No additional patients
          </Text>
        )}
        <Divider />

        {isAddingPatient ? (
          <AddAdditionalPatientModal
            onClose={() => setIsAddingPatient(false)}
            invoiceId={invoice.id}
          />
        ) : null}
      </VStack>
    </Box>
  )
}
