import { CheckIcon } from '@chakra-ui/icons'
import { Box, Flex, Text } from '@chakra-ui/react'
import { CheckboxField, colors, FieldTypes } from '@hb/shared'
import { Expandable, FormsViewContext, useUpdateDoc } from '@hb/shared-frontend'
import { EditableCheckbox } from '@hb/shared-frontend/components/forms/Input/EditableCheckbox'
import React, { PropsWithChildren, useContext } from 'react'

const field: CheckboxField = {
  type: FieldTypes.CHECKBOX,
  placeholder: 'I have read and I agree to the assessment terms',
}

export const Disclaimer = ({
  accepted,
  children,
  title,
  index,
}: PropsWithChildren<{
  accepted: boolean
  title: string
  index: number
}>) => {
  const { assessment } = useContext(FormsViewContext)
  const update = useUpdateDoc(`disclaimer ${index}`)
  return (
    <Expandable
      initExpanded={!accepted && index === 1}
      header={() => (
        <Flex py={2} px={1} align="center" justify="space-between">
          <Flex align="center">
            <Flex
              w={accepted ? 4 : 0}
              opacity={accepted ? 1 : 0}
              transform={`translate(${accepted ? 0 : -5}px)`}
              transition="all 333ms cubic-bezier(0.85, 0, 0.15, 1)">
              <CheckIcon mr={3} w={3.5} h={3.5} color={colors.green.hex} />
            </Flex>
            <Text ml={2} color="gray.600" fontWeight={500}>
              {title}
            </Text>
          </Flex>
        </Flex>
      )}
      mb={4}
      borderRadius={4}
      border="1px solid #00000022"
      boxShadow="1px 1px 3px #00000077">
      <Flex flexFlow="column" w="100%" gap={1} px={3} py={2}>
        <Box py={1} px={3} fontWeight={300} borderBottom="1px solid #cdcdcd">
          {children}
        </Box>
        <EditableCheckbox
          value={accepted}
          id={`disclaimer-${index}`}
          field={field}
          onSubmit={val => update(assessment?.ref, `disclaimers.disclaimer${index}Accepted`, val)}
        />
      </Flex>
    </Expandable>
  )
}
