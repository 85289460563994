import { colors } from '@hb/shared'
import React from 'react'
import { CustomRenderLeafProps } from '../../types/editor'
import { useNodeStyle } from './hooks'

export const DefaultRenderLeaf = (props: CustomRenderLeafProps) => {
  const { children, leaf, attributes, version } = props
  const style = useNodeStyle(version, leaf)
  if (leaf.url) {
    style.color = leaf.color || colors.indigo.hex
    return (
      <a target="_blank" href={leaf.url} {...attributes} style={style}>
        {children}
      </a>
    )
  }
  return (
    <span {...attributes} style={style}>
      {children}
    </span>
  )
}
