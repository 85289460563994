import {
  IconButton,
  Image,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react'
import React from 'react'
import bugReportIcon from '../../../icons/bug_report.svg'
import { BugReportsPopoverContent } from './BugReportsContent'

export const BugReportsPopover = () => (
  <Popover closeOnBlur={false} placement="right-start" strategy="fixed">
    {({ onClose }) => (
      <>
        <PopoverTrigger>
          <IconButton
            aria-label="Bug Reports"
            icon={<Image filter="invert(90%)" width={5} src={bugReportIcon} alt="Bug Reports" />}
            variant="ghost"
            size="sm"
            bg="red.600"
            filter="drop-shadow(1px 1px 3px #00000077)"
            borderRadius="full"
            _hover={{ bg: 'red.300' }}
            _active={{ bg: 'red.300' }}
          />
        </PopoverTrigger>
        <Portal>
          <PopoverContent maxH="600px" overflowY="auto" width="450px" maxW="100%">
            <BugReportsPopoverContent onClose={onClose} />
          </PopoverContent>
        </Portal>
      </>
    )}
  </Popover>
)
