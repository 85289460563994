import { AddPracticeUserArgs, EditPracticeUserArgs, UpdateCallback } from '@hb/shared'
import { httpsCallable } from 'firebase/functions'
import { functions } from './functions'

export const addPracticeUser = httpsCallable<AddPracticeUserArgs, UpdateCallback>(
  functions,
  'addPracticeUser',
)
export const editPracticeUser = httpsCallable<EditPracticeUserArgs, UpdateCallback>(
  functions,
  'editPracticeUser',
)
