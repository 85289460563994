import {
  Field,
  FieldMap,
  FieldMapValue,
  FieldTypes,
  FileDBValue,
  FileField,
  findFormElementsWithDataUrls,
  getFormElementHasAnyDataUrls,
  getFormElementHasAnyFileFields,
  getFormElementName,
  isField,
  isFieldMap,
  isInfoStage,
  isListField,
  ListField,
  UnuploadedFileDBValue,
  UploadProgress,
} from '@hb/shared'
import { FORM_ERROR, ValidationErrors } from 'final-form'
import { getDatabase } from 'firebase/database'
import {
  collection,
  deleteDoc as firebaseDeleteDoc,
  doc,
  getDoc,
  initializeFirestore,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import cloneDeep from 'lodash.clonedeep'
import { app } from './app'
import { deleteFile, getResizedStoragePath, uploadFile } from './storage'

export const db = initializeFirestore(app, { ignoreUndefinedProperties: true })
export const realtimeDb = getDatabase(app)

export const processFileFieldData = async (
  baseStoragePath: string,
  fieldPathSegments: string[],
  field: FileField,
  data: FileDBValue | UnuploadedFileDBValue,
  prevData: UnuploadedFileDBValue | FileDBValue | undefined,
  onUploadProgress: (progress: UploadProgress) => void,
): Promise<FileDBValue | null> => {
  const deletePrevFile = async () => {
    if (prevData?.storagePath) {
      // delete file from storage
      await deleteFile(getResizedStoragePath(prevData.storagePath, prevData.type))
    }
  }
  if (!data) {
    await deletePrevFile()
    return null
  }

  if (data.dataUrl) {
    await deletePrevFile()
    const storagePath = [baseStoragePath, ...fieldPathSegments].join('/')
    // get file from dataUrl
    const file = await fetch(data.dataUrl).then(res => res.blob())

    onUploadProgress({ complete: false, label: field.placeholder, progress: 0 })
    // upload file to storage
    await uploadFile(storagePath, file, progress =>
      onUploadProgress({
        complete: progress === 100,
        label: field.placeholder,
        progress,
      }),
    )
    const now = Date.now()
    const updated: FileDBValue = {
      ...data,
      id: `${now}`,
      storagePath,
      type: file.type,
      uploadedOn: now,
    }
    delete updated.dataUrl
    return updated
  }
  return data as FileDBValue
}

const processFieldData = async (
  baseStoragePath: string,
  fieldPathSegments: string[],
  field: Field,
  data: any,
  prevData: any,
  onUploadProgress: (progress: UploadProgress) => void,
) => {
  switch (field.type) {
    case 'file':
      return processFileFieldData(
        baseStoragePath,
        fieldPathSegments,
        field,
        data,
        prevData,
        onUploadProgress,
      )
    default:
      return data
  }
}

const processListFieldData = async (
  baseStoragePath: string,
  fieldPathSegments: string[],
  field: ListField,
  data: any[] | undefined,
  prevData: any[] | undefined,
  onUploadProgress: (progress: Record<string, UploadProgress>) => void,
) => {
  if (!data) return []
  let uploads: Record<string, UploadProgress> = {}
  const now = Date.now()
  const itemField = field.itemFields
  return Promise.all(
    data.map((item, i) => {
      const id = item?.id
      const prevValue = id ? prevData?.find(prev => prev.id === id) : undefined
      const pathSegments = [...fieldPathSegments, id || `${now + i}`]
      const promise = (
        isField(itemField)
          ? processFieldData(
              baseStoragePath,
              [...fieldPathSegments, id || `${now + i}`],
              itemField,
              item,
              prevValue,
              progress => {
                uploads[pathSegments.join('.')] = progress
                onUploadProgress(uploads)
              },
            )
          : processFieldMapData(baseStoragePath, itemField, item, prevValue, progress => {
              uploads = { ...uploads, ...progress }
              onUploadProgress(uploads)
            })
      ) as Promise<any>
      return promise.then(updated => (updated ? { ...updated, id: id || `${now + i}` } : null))
    }),
  )
}

export const processFieldMapData = async (
  baseStoragePath: string,
  field: FieldMap,
  data: any,
  prevData: any,
  onUploadProgress: (progress: Record<string, UploadProgress>) => void,
  fieldPathSegments?: string[],
): Promise<FieldMapValue> => {
  const cloned = cloneDeep(data || {})

  if (getFormElementHasAnyDataUrls(field, data) && !baseStoragePath) {
    throw new Error('Data URLs found in fields, no base storage path provided')
  }

  let uploads: Record<string, UploadProgress> = {}
  // find all nested file fields and upload them to storage
  const onProgress = (fieldKey: string, progress: UploadProgress) => {
    uploads[fieldKey] = progress
    onUploadProgress(uploads)
  }

  const onRecordProgress = (progress: Record<string, UploadProgress>) => {
    uploads = { ...uploads, ...progress }
    onUploadProgress(uploads)
  }

  const promises = Promise.all(
    Object.entries(field.children).map(async ([key, childField]) => {
      const fieldData = cloned[key]
      const combinedSegments = fieldPathSegments ? [...fieldPathSegments, key] : [key]
      if (isField(childField)) {
        const processed = await processFieldData(
          baseStoragePath,
          combinedSegments,
          childField,
          fieldData,
          prevData?.[key],
          progress => onProgress(combinedSegments.join('.'), progress),
        )
        if (childField.type === FieldTypes.ID) {
          const itemId = key.endsWith('Id') ? key.slice(0, -2) : null
          if (itemId) delete cloned[itemId]
        }
        return {
          key,
          data: processed,
        }
      }
      if (isListField(childField)) {
        return {
          key,
          data: await processListFieldData(
            baseStoragePath,
            combinedSegments,
            childField,
            fieldData,
            prevData?.[key],
            progress => onRecordProgress(progress),
          ),
        }
      }
      if (isInfoStage(childField)) {
        return null
        // do nothing
      }
      if (isFieldMap(childField)) {
        return {
          key,
          data: await processFieldMapData(
            baseStoragePath,
            childField,
            fieldData,
            prevData?.[key],
            progress => {
              const withSegments = Object.entries(progress).reduce(
                (acc, [fieldKey, fieldProgress]) => ({
                  ...acc,
                  [`${combinedSegments.join('.')}.${fieldKey}`]: fieldProgress,
                }),
                {} as Record<string, UploadProgress>,
              )
              onRecordProgress(withSegments)
            },
            combinedSegments,
          ),
        }
      }
      return null
    }),
  )

  const res: Array<{ key: string; data: any } | null> = await promises

  onUploadProgress({})
  const unprocessedKeys = Object.keys(cloned).filter(key => !field.children[key])
  const unprocessed = unprocessedKeys.reduce(
    (acc, key) => ({ ...acc, [key]: cloned[key] }),
    {} as FieldMapValue,
  )

  return res.reduce((acc, withKey) => {
    if (!withKey) return acc
    const { data: fieldData, key } = withKey
    if (fieldData === null) return acc
    return { ...acc, [key]: fieldData }
  }, unprocessed as FieldMapValue)
}

export const processFormElementData = async (
  baseStoragePath: string | undefined,
  field: Field | FieldMap | ListField,
  data: any,
  prevData: any,
  onUploadProgress: (progress: Record<string, UploadProgress>) => void,
  fieldPathSegments?: string[],
) => {
  if (getFormElementHasAnyFileFields(field) && !baseStoragePath) {
    const fieldName = getFormElementName(field)
    throw new Error(`baseStoragePath is required for file fields - missing in field ${fieldName}`)
  }
  if (isField(field)) {
    return processFieldData(
      baseStoragePath || '',
      fieldPathSegments || [],
      field,
      data,
      prevData,
      v => onUploadProgress({ value: v }),
    )
  }
  if (isListField(field)) {
    return processListFieldData(
      baseStoragePath || '',
      fieldPathSegments || [],
      field,
      data,
      prevData,
      onUploadProgress,
    )
  }
  return processFieldMapData(baseStoragePath || '', field, data, prevData, onUploadProgress)
}

export const saveDoc = async (
  collectionPath: string,
  id: string | null,
  field: FieldMap | null,
  data: FieldMapValue,
  onUploadProgress?: (progress: Record<string, UploadProgress>) => void,
): Promise<ValidationErrors | string> => {
  const docRef = id ? doc(db, collectionPath, id) : doc(collection(db, collectionPath))
  const docData = id ? await getDoc(docRef).then(fetched => fetched.data()) : undefined
  const baseStoragePath = `${collectionPath}/${docRef.id}`
  const processedData = field
    ? await processFieldMapData(
        baseStoragePath,
        field,
        data,
        docData,
        onUploadProgress || (() => {}),
      )
    : data
  if (field) {
    const withDataUrls = findFormElementsWithDataUrls(field, processedData)
    if (withDataUrls.length) {
      throw new Error(
        `Data URLs found in fields: ${withDataUrls.map(f => f.fieldPathSegments.join('.')).join(', ')}`,
      )
    }
  }
  const promise = docData ? updateDoc(docRef, processedData) : setDoc(docRef, processedData)
  return promise
    .then(() => docRef.id)
    .catch(e => ({ [FORM_ERROR]: e?.message || 'An error occurred' }))
}

export const deleteDoc = async (collectionPath: string, id: string, field: FieldMap | null) => {
  const docRef = doc(db, collectionPath, id)
  const docData = await getDoc(docRef).then(fetched => fetched.data())
  if (field) {
    const baseStoragePath = `${collectionPath}/${docRef.id}`
    await processFieldMapData(baseStoragePath, field, undefined, docData, () => {})
  }
  await firebaseDeleteDoc(docRef)
}
