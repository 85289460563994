import { Center, Collapse } from '@chakra-ui/react'
import * as React from 'react'
import { useEffect, useState } from 'react'

export const CollapseError: React.FC<{
  error?: string | null
  style?: React.CSSProperties
}> = ({ error, style }) => {
  const [prevError, setPrevError] = useState<string | null>(null)
  useEffect(() => {
    if (error) setPrevError(error)
  }, [error])

  return (
    <Collapse style={{ width: '100%' }} in={!!error}>
      <Center width="100%" bg="red.500" fontSize="sm" color="white" p={1} style={style}>
        <span style={{ textAlign: 'center', fontFamily: 'Open Sans' }}>{prevError}</span>
      </Center>
    </Collapse>
  )
}
