import { FieldTypes } from '../../constants'
import {
  DropdownOptionItem,
  Field,
  FieldCondition,
  FieldMap,
  InfoStage,
  Stage,
} from '../../types/forms/fields'
import { createFileField } from '../../utils/fields'
import { getInsuranceCoverageField } from '../insurance/insuranceCoverage'

export const insuranceInfoOptions: DropdownOptionItem[] = [
  {
    text: 'I am covered by one insurance plan (I am not covered by Medicaid)',
    id: 'one-plan',
  },
  {
    text: 'I am covered by two insurance plans (can include Medicaid)',
    id: 'two-plans',
  },
  {
    text: 'I am covered by Medicaid or a Medicaid Managed plan only (example: Health First Medicaid, UHC Community Plan etc.)',
    id: 'medicaid',
  },
  {
    text: "I don't have insurance coverage",
    id: 'no-coverage',
  },
  {
    text: 'I have insurance but it will change/end before I deliver',
    id: 'coverage-will-change',
  },
  {
    text: 'I have multiple options for coverage',
    id: 'multiple-options',
    redFlagReason: 'Multiple coverage options',
  },
  {
    text: 'I am not pregnant but would like information on coverage',
    id: 'not-pregnant',
  },
]

const twoPlansNote: InfoStage = {
  info: true,
  name: 'Notice',
  condition: v => selectCoverageOption('two-plans')(v) && v?.onePlanIsMedicaid === false,
  data: [
    {
      type: 'span',
      children: [
        {
          text: `Please note your assessment will include information for your primary insurance only. 

Secondary coverage may or may not cover the deductible or coinsurance from your primary insurer.  

If you'd like more information on your secondary insurance, please schedule a call with us at the end of the form.`,
        },
      ],
    },
  ],
}

const employmentWillChangeNote: InfoStage = {
  info: true,
  name: 'Notice',
  condition: v =>
    selectCoverageOption('coverage-will-change')(v) &&
    v?.coverageWillChangeDescription === 'employment-coverage-will-change',
  data: [
    {
      type: 'span',
      children: [
        {
          text: `Please use information on your future plan to complete this form so we can provide an assessment.\n 
You may need to contact HR in order to get all the necessary information.`,
        },
      ],
    },
  ],
}

const employmentWillChangeUnknownNote: InfoStage = {
  info: true,
  name: 'Notice',
  condition: v =>
    selectCoverageOption('coverage-will-change')(v) &&
    v?.coverageWillChangeDescription === 'employment-coverage-will-change-unknown',
  data: [
    {
      type: 'span',
      children: [
        {
          text: 'We cannot provide an assessment until you have information on your future insurance plan. Please return to this website when you do, or provide us with information you do have at this time so we can try to assist further below. If you do have information on your future, select "I am covered by one insurance plan" on the first dropdown above.\nYou can also schedule a call with us if you have further questions - Help Button (?) or Contact Page',
        },
      ],
    },
  ],
}

const coverageWillDropNote: InfoStage = {
  info: true,
  name: 'Notice',
  condition: v =>
    selectCoverageOption('coverage-will-change')(v) &&
    v?.coverageWillChangeDescription === 'coverage-will-drop',
  data: [
    {
      type: 'span',
      children: [
        {
          text: 'If you believe your coverage will end before you deliver, press finish to see options for choosing a plan through New York State of Health.\nYou can also schedule a call with us if you have further questions - Help Button (?) or Contact Page',
        },
      ],
    },
  ],
}

const addedToAnotherPlanNote: InfoStage = {
  info: true,
  name: 'Notice',
  condition: v =>
    selectCoverageOption('coverage-will-change')(v) &&
    v?.coverageWillChangeDescription === 'added-to-another-plan',
  data: [
    {
      type: 'span',
      children: [
        {
          text: 'Please use information on your future plan to complete this form so we can provide an assessment',
        },
      ],
    },
  ],
}

export const selectCoverageOption =
  (option: string | string[]): FieldCondition =>
  v => {
    if (!v?.option) return false
    if (typeof option === 'string') return v.option === option
    return option.includes(v.option)
  }

const coverageWillChangeFields: Record<string, Field | InfoStage> = {
  coverageWillChangeDescription: {
    condition: selectCoverageOption('coverage-will-change'),
    type: FieldTypes.DROPDOWN,
    labels: [{ text: 'Please choose one:' }],
    placeholder: 'Coverage will change description',
    options: [
      {
        text: 'My employment will change/My employer is choosing another health plan that I have/can get information on.',
        id: 'employment-coverage-will-change',
        redFlagReason: 'Coverage will change during pregnancy',
      },
      {
        text: "My employment will change/My employer is choosing another health plan but I don't know the options.",
        id: 'employment-coverage-will-change-unknown',
        redFlagReason: 'Coverage will change during pregnancy',
      },
      {
        text: 'My coverage will drop before I deliver',
        id: 'coverage-will-drop',
        redFlagReason: 'Coverage will drop during pregnancy',
      },
      {
        text: 'I will be added to another plan before I deliver',
        id: 'added-to-another-plan',
        redFlagReason: 'Coverage will change during pregnancy',
      },
    ],
  },
  newCoverageStartDate: {
    condition: v =>
      selectCoverageOption('coverage-will-change')(v) &&
      v?.coverageWillChangeDescription?.startsWith('employment-coverage-will-change'),
    type: FieldTypes.DATE,
    placeholder: 'New coverage start date',
    labels: [{ text: 'When will your new coverage begin?' }],
    minDate: 'now',
  },
  employmentWillChangeNote,
  employmentWillChangeUnknownNote,
  coverageWillDropNote,
  addedToAnotherPlanNote,
}

const partnerIndividualNote: InfoStage = {
  info: true,
  name: 'Notice',
  condition: v =>
    selectCoverageOption('multiple-options')(v) &&
    v?.multipleOptionsDescription === 'partner-individual',
  data: [
    {
      type: 'span',
      children: [
        {
          text: `You can only enroll in your parents' plan if you are under 26 years old. \n
You can only enroll in your partner's plan if you are married or in a registered domestic partnership in New York state`,
        },
      ],
    },
  ],
}
const confirmPartnerIndividualNote: InfoStage = {
  info: true,
  name: 'Notice',
  condition: v =>
    selectCoverageOption('multiple-options')(v) &&
    v?.multipleOptionsDescription === 'partner-individual' &&
    v?.confirmPartnerPlan === true,
  data: [
    {
      type: 'span',
      children: [
        {
          text: `Please use information on your partner / family member's plan to complete this form so we can provide an assessment.\n 
You may need to contact HR in order to get all the necessary information.`,
        },
      ],
    },
  ],
}

const partnerEmployerNote: InfoStage = {
  info: true,
  name: 'Notice',
  condition: v =>
    selectCoverageOption('multiple-options')(v) &&
    v?.multipleOptionsDescription === 'partner-employer',
  data: [
    {
      type: 'span',
      children: [
        {
          text: `Hamilton Billing provides 1 free assessment per patient. 
Any additional plans you would like us to assess are $25 per assessment.\n
We recommend you choose your current plan for us to assess, or the plan you will likely be on two months prior to your estimated due date`,
        },
      ],
    },
  ],
}

const multipleEmployerNote: InfoStage = {
  info: true,
  name: 'Notice',
  condition: v =>
    selectCoverageOption('multiple-options')(v) &&
    v?.multipleOptionsDescription === 'multiple-employer',
  data: [
    {
      type: 'span',
      children: [
        {
          text: `Please ask your HR department for the following: \n
1) A PDF overview of your plan options
2) Please ask your HR the following questions on all the plans being offered:`,
        },
      ],
    },
  ],
}

const assessmentOnCurrentPlanNote: InfoStage = {
  info: true,
  name: 'Notice',
  condition: v =>
    selectCoverageOption('multiple-options')(v) &&
    v?.multipleOptionsDescription === 'partner-employer' &&
    v?.planToAssess === 'current-plan',
  data: [
    {
      type: 'span',
      children: [
        {
          text: 'Please complete the "Primary Coverage" section with your current plan and complete the "Secondary Coverage" section with your alternative insurance plan',
        },
      ],
    },
  ],
}

const assessmentPartnerPlanNote: InfoStage = {
  info: true,
  name: 'Notice',
  condition: v =>
    selectCoverageOption('multiple-options')(v) &&
    v?.multipleOptionsDescription === 'partner-employer' &&
    v?.planToAssess === 'partner-plan',
  data: [
    {
      type: 'span',
      children: [
        {
          text: 'Please complete the "Primary Coverage" section with your partner/family member\'s plan and complete the "Secondary Coverage" section with your current plan',
        },
      ],
    },
  ],
}

const notPregnantNote: InfoStage = {
  info: true,
  name: 'Notice',
  condition: selectCoverageOption('not-pregnant'),
  data: [
    {
      type: 'span',
      children: [
        {
          text: `Hamilton Billing is happy to provide you with an assessment, however, please be aware that we only provide one free assessment per pregnancy. We recommend patients circle back with us when they are pregnant. 

In the meantime, click "Finish" to see some general information on insurance plans we often work with. 
          
If you would like to complete an assessment now, please choose the coverage option that currently applies from the drop down above and indicate in one of the notes boxes that you are not pregnant.`,
        },
      ],
    },
  ],
}

const multipleCoverageOptionsFields: Record<string, Field | InfoStage> = {
  multipleOptionsDescription: {
    type: FieldTypes.DROPDOWN,
    condition: selectCoverageOption('multiple-options'),
    labels: [{ text: 'Please choose one' }],
    placeholder: 'Multiple options description',
    options: [
      {
        text: 'My partner / family member has a plan I believe I can enroll in, and/or I can get a plan as an individual through New York State of Health.',
        id: 'partner-individual',
      },
      {
        text: 'My partner / family member has a plan that I believe I can enroll in, and I also have a coverage option through my employer',
        id: 'partner-employer',
      },
      {
        text: 'My employer is offering multiple plan options',
        id: 'multiple-employer',
      },
      {
        text: 'Other',
        id: 'other',
      },
    ],
  },
  partnerIndividualNote,
  partnerEmployerNote,
  multipleEmployerNote,
  confirmPartnerPlan: {
    type: FieldTypes.BOOLEAN,
    condition: v =>
      selectCoverageOption('multiple-options')(v) &&
      v?.multipleOptionsDescription === 'partner-individual',
    placeholder: "I can enroll in my partner/family member's plan",
    yesText: 'Continue',
    noText: 'Go Back',
  },
  confirmPartnerIndividualNote,
  planToAssess: {
    condition: v =>
      selectCoverageOption('multiple-options')(v) &&
      v?.multipleOptionsDescription === 'partner-employer',
    type: FieldTypes.DROPDOWN,
    placeholder: 'Which plan do you want an assessment on?',
    options: [
      {
        text: 'I want an assessment on my current plan',
        id: 'current-plan',
      },
      {
        text: 'I want an assessment on a plan through my partner / family member',
        id: 'partner-plan',
      },
    ],
  },
  assessmentOnCurrentPlanNote,
  assessmentPartnerPlanNote,
  planCoverageType: {
    condition: v =>
      selectCoverageOption('multiple-options')(v) &&
      v?.multipleOptionsDescription === 'multiple-employer',
    type: FieldTypes.TEXTAREA,
    labels: [
      {
        text: 'Are all the plans fully insured, self funded, or split-funded?',
      },
    ],
    placeholder: 'Plans fully insured/self-funded/split-funded',
  },
  reimbursementSystem: {
    condition: v =>
      selectCoverageOption('multiple-options')(v) &&
      v?.multipleOptionsDescription === 'multiple-employer',
    type: FieldTypes.TEXTAREA,
    labels: [
      {
        text: 'How do these plans determine reimbursement for OON providers? (Please clarify if they are using the reasonable and customary system versus the Medicare fee schedule)',
      },
    ],
    placeholder: 'Reimbursement System',
  },
  reimbursementRate: {
    condition: v =>
      selectCoverageOption('multiple-options')(v) &&
      v?.multipleOptionsDescription === 'multiple-employer',
    type: FieldTypes.TEXTAREA,
    labels: [
      {
        text: 'Please indicate the percentage of that system (Ex: 80% reasonable and customary; 110% medicare rates)',
      },
    ],
    placeholder: 'Reimbursement rate',
  },
  employmentOptionsFile: createFileField({
    condition: v =>
      selectCoverageOption('multiple-options')(v) &&
      v?.multipleOptionsDescription === 'multiple-employer',
    placeholder: 'Employment options PDF',
    fileType: 'pdf',
    name: 'employment-options',
  }),
  coverageStartDate: {
    condition: v =>
      selectCoverageOption('multiple-options')(v) &&
      v?.multipleOptionsDescription === 'multiple-employer',
    type: FieldTypes.DATE,
    placeholder: 'Coverage start date',
    labels: [{ text: 'When will coverage start?' }],
  },
  multipleOptionsOtherNotes: {
    condition: v =>
      selectCoverageOption('multiple-options')(v) && v?.multipleOptionsDescription === 'other',
    type: FieldTypes.TEXTAREA,
    placeholder: 'Multiple options explanation',
    labels: [
      {
        text: 'Please explain...',
      },
    ],
  },
  notPregnantNote,
}

// one plan, two plan options
const primaryCoverageCondition: FieldCondition = v =>
  selectCoverageOption(['one-plan', 'two-plans'])(v) ||
  (selectCoverageOption('coverage-will-change')(v) &&
    ['employment-coverage-will-change', 'added-to-another-plan'].includes(
      v?.coverageWillChangeDescription,
    )) ||
  (selectCoverageOption('multiple-options')(v) &&
    ['partner-individual'].includes(v?.multipleOptionsDescription) &&
    v?.confirmPartnerPlan) ||
  (selectCoverageOption('multiple-options')(v) &&
    v?.multipleOptionsDescription &&
    v.multipleOptionsDescription === 'partner-employer')

const primaryCoverageNote: InfoStage = {
  info: true,
  name: 'Notice',
  condition: v => v?.option === 'two-plans',
  data: [
    {
      type: 'span',
      children: [
        {
          text: `If you are covered by more than one insurance plan, your primary coverage is likely the plan you've been on the longest. 
Please do additional research to confirm your primary insurer.`,
        },
      ],
    },
  ],
}

const primaryCoverageNote2: InfoStage = {
  info: true,
  name: 'Notice',
  condition: selectCoverageOption('two-plans'),
  data: [
    {
      type: 'span',
      children: [
        {
          text: 'If one of your plans is Medicaid, enter your other plan as your primary coverage.',
        },
      ],
    },
  ],
}

export const primaryCoverageField: FieldMap = {
  initExpanded: true,
  children: {
    ...getInsuranceCoverageField().children,
  },
  condition: primaryCoverageCondition,
  name: 'Primary Coverage',
}

const medicaidCoverageCondition = (v: any) =>
  selectCoverageOption('medicaid')(v) ||
  (selectCoverageOption('two-plans')(v) && v?.onePlanIsMedicaid)

export const medicaidCoverageField: FieldMap = {
  ...getInsuranceCoverageField(true),
  name: 'Medicaid Coverage',
  initExpanded: true,
  condition: medicaidCoverageCondition,
}

export const insuranceInfoStage: Stage = {
  name: 'Insurance Info',
  initExpanded: true,
  calculate: [
    {
      field: 'confirmPartnerPlan',
      updates: {
        confirmPartnerPlan: (confirmValue: any) => {
          if (!confirmValue) return undefined
          return true
        },
        multipleOptionsDescription: (confirmValue: any, allValues: any) => {
          if (confirmValue === false) return undefined
          return allValues.multipleOptionsDescription
        },
      },
    },
  ],
  storagePath: 'insurance-info',
  children: {
    option: {
      type: FieldTypes.DROPDOWN,
      options: insuranceInfoOptions,
      labels: [{ text: 'Which of these describes your coverage?' }],
      placeholder: 'Coverage Description',
    },
    onePlanIsMedicaid: {
      labels: [{ text: 'Is one of your insurance plans through Medicaid?' }],
      condition: selectCoverageOption('two-plans'),
      type: FieldTypes.BOOLEAN,
      placeholder: 'One plan is through Medicaid',
      redFlagYesReason: '2 plans, 1 Medicaid',
    },
    twoPlansNote,
    noCoverageDescription: {
      type: FieldTypes.DROPDOWN,
      condition: selectCoverageOption('no-coverage'),
      placeholder: 'Please specify...',
      options: [
        { text: 'I want to pay out of pocket', id: 'out-of-pocket' },
        { text: 'I need advice on obtaining coverage', id: 'need-advice' },
      ],
    },
    ...coverageWillChangeFields,
    ...multipleCoverageOptionsFields,
    primaryCoverageNote,
    primaryCoverageNote2,
    primaryCoverage: primaryCoverageField,
    // remove this?
    medicaidCoverage: medicaidCoverageField,
    // secondaryCoverage: secondaryCoverageField,
  },
}

// export const summarizedInsuranceInfo: Form = {
//   name: 'Insurance Summary',
//   description: 'A few details about your insurance info',
//   stages: {
//     summary: {
//       name: 'Insurance card, in network summary',
//       children: {
//         insuranceCard: getInsuranceCardField('primary-insurance-docs'),
//         inNetworkDeductible: inNetworkDeductibleField,
//         inNetworkCoinsurance: inNetworkCoinsuranceField,
//       },
//     },
//   },
// }
