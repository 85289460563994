import { Flex } from '@chakra-ui/react'
import { midwivesCollection } from '@hb/shared'
import React, { useContext } from 'react'
import { midwifeFields } from '../../collections/fields/practice'
import { PracticeContext } from '../../contexts/PracticeContext'
import { useUpdateCollection } from '../../hooks/useAppCollections'
import { DataView } from '../DataView'

export const LegacyMidwifeView = () => {
  const { practice: provider } = useContext(PracticeContext)
  const { updateItem } = useUpdateCollection(midwivesCollection)
  return (
    <Flex w="100%" direction="column">
      <DataView
        onSubmit={v => updateItem(provider?.id || '', '', v)}
        data={provider}
        field={midwifeFields}
      />
    </Flex>
  )
}
