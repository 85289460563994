import { Box } from '@chakra-ui/react'
import * as React from 'react'
import LoadingIcon, { LoadingProps } from './LoadingIcon'

export const LoadingOverlay: React.FC<LoadingProps> = ({ style, ...props }) => (
  <LoadingIcon
    style={{
      background: 'rgba(255,255,255,0.9)',
      left: 0,
      width: '100%',
      zIndex: 3,
      height: '100%',
      ...style,
    }}
    {...props}
  />
)

export const LoadingView = ({ text }: { text: string }) => (
  <Box position="relative" bg="white" height="80px" width="100%" borderRadius="4px" shadow="md">
    <LoadingIcon text={text} loading />
  </Box>
)
