import { Note } from '@hb/shared'
import { httpsCallable } from 'firebase/functions'
import { functions } from '../../../backend/functions'

const updateInvoiceAdminNoteFunction = httpsCallable<{
  adminNote: Note
  invoiceId: string
}>(functions, 'updateInvoiceAdminNote')

export const updateInvoiceAdminNote = async (invoiceId: string, adminNote: Note) =>
  updateInvoiceAdminNoteFunction({ invoiceId, adminNote })
    .then(() => ({ success: 'Updated note' }))
    .catch(err => ({ error: err.message }))
