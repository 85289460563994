import { Box } from '@chakra-ui/react'
import { FieldMapValue, Form } from '@hb/shared'
import React from 'react'
import { StagePreview } from './StagePreview'

export const FormPreview = ({
  form,
  data,
  corrections,
  readOnly,
  onStageEdit,
}: {
  form: Form
  data: FieldMapValue | undefined
  corrections: FieldMapValue | undefined
  readOnly?: boolean
  onStageEdit: (stageId: string) => void
}) => (
  <Box w="100%">
    {Object.entries(form.stages).map(([stageId, stage], index) => (
      <StagePreview
        index={index}
        form={form}
        id={stageId}
        corrections={corrections}
        readOnly={readOnly}
        onEditClick={() => onStageEdit(stageId)}
        key={stageId}
        stage={stage}
        data={data}
      />
    ))}
  </Box>
)
