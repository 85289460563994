import { midwivesCollection } from '../../collections/collections/practice'
import { FieldTypes } from '../../constants'
import { PracticeWithAdmin } from '../../types'
import { BooleanField, DateField, IdField, Stage } from '../../types/forms/fields'
import { getMidwifeFullName } from '../../utils/providers'

export const midwifeId: IdField<PracticeWithAdmin> = {
  collection: midwivesCollection,
  type: FieldTypes.ID,
  getItemText: item => getMidwifeFullName(item),
  placeholder: 'Midwife',
  labels: [{ text: 'Which midwife would you like to work with?' }],
}

export const patientLmpField: DateField = {
  type: FieldTypes.DATE,
  labels: [{ text: 'What was the first day of your last menstrual cycle?' }],
  placeholder: 'First day of last menstrual cycle',
  optional: true,
}

export const eddField: DateField = {
  type: FieldTypes.DATE,
  // validate: validateEdd,
  minDate: 'now',
  placeholder: 'Expected Date of Delivery',
}

export const hasPriorCSectionField: BooleanField = {
  type: FieldTypes.BOOLEAN,
  labels: [{ text: 'Have you had a Cesarean section in the past?' }],
  placeholder: 'Previous Cesarean section?',
}

export const basePriorCSectionDateField: DateField = {
  type: FieldTypes.DATE,
  labels: [{ text: 'What was the date of your most recent C-Section?' }],
  placeholder: 'Date of most recent C-Section',
}
export const priorCSectionDateField: DateField = {
  ...basePriorCSectionDateField,
  condition: val => !!val?.hasPriorCSection,
}

export const seenAnotherProviderField: BooleanField = {
  type: FieldTypes.BOOLEAN,
  labels: [
    {
      text: 'Have you seen another provider for this pregnancy for any prenatal care?',
    },
  ],
  placeholder: "I've seen another provider",
}

export const deliveryStage: Stage = {
  name: 'Pregnancy',
  storagePath: 'pregnancy',
  children: {
    edd: {
      ...eddField,
      labels: [{ text: 'What is your expected delivery date?' }],
    },
    seenAnotherProvider: seenAnotherProviderField,
    deliveryLocation: {
      type: FieldTypes.DROPDOWN,
      placeholder: 'Delivery type',
      labels: [{ text: 'What type of delivery are you interested in?' }],
      options: [
        {
          id: 'home-birth',
          text: 'Home birth',
        },
        { id: 'facility-birth', text: 'Facility birth' },
        {
          id: 'birthing-center-birth',
          text: 'Birthing center birth',
          redFlagReason: 'Birthing center birth',
        },
        {
          id: 'undecided',
          text: 'Undecided',
          redFlagReason: 'Undecided on birth location',
        },
      ],
    },
    midwifeId,
    lmp: patientLmpField,
    hasPriorCSection: hasPriorCSectionField,
    priorCSectionDate: priorCSectionDateField,
  },
}
