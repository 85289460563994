import { useMemo, useRef } from 'react'
import { useContentWidth } from './useContentWidth'
import { useResizeObserver } from './useResizeObserver'

export const useScreen = () => {
  const bodyRef = useRef<HTMLElement>(typeof document !== 'undefined' ? document.body : null)
  const { width = 0, height = 0 } = useResizeObserver(bodyRef, 'border')
  const contentWidth = useContentWidth(width)
  const isMobile = useMemo(() => width < 800, [width])
  return useMemo(
    () => ({
      height,
      width,
      contentWidth,
      contentHeight: isMobile ? height : height - 60,
      isMobile,
    }),
    [height, width, contentWidth, isMobile],
  )
}
