import { Center, HStack, Spinner, VStack } from '@chakra-ui/react'
import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { ScreenContext } from '../../../contexts'
import { DefaultModal } from '../DefaultModal'
import './styles.css'

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

interface PdfViewModalProps {
  isOpen: boolean
  onClose: () => void
  url?: string
  header: string | ReactElement
}

const PageNumberIndicator = ({
  containerRef,
  numPages,
}: {
  containerRef: React.RefObject<HTMLDivElement>
  numPages: number | null
}) => {
  const screen = useContext(ScreenContext)
  const [page, setPage] = useState(1)
  useEffect(() => {
    const container = containerRef.current
    if (numPages === null || !container) return () => {}
    const pages = container.querySelectorAll('.pdf-view-page')
    const pageHeights = Array.from(pages).map(p => p.clientHeight)
    const onScroll = () => {
      const { scrollTop } = container.parentElement || {}
      if (scrollTop === undefined) return
      let sum = 0
      for (let i = 0; i < pageHeights.length; i += 1) {
        sum += pageHeights[i]
        if (sum > scrollTop) {
          setPage(i + 1)
          break
        }
      }
    }
    container.addEventListener('scroll', onScroll, { passive: true })
    container.addEventListener('wheel', onScroll, { passive: true })
    return () => {
      container.removeEventListener('scroll', onScroll)
      container.removeEventListener('wheel', onScroll)
    }
  }, [containerRef, numPages, screen])
  return (
    <Center w="100%" position="absolute" bottom="10px">
      <Center
        bg="rgba(0,0,0,0.3)"
        color="white"
        fontSize="sm"
        fontWeight={600}
        height="30px"
        p={1}
        px={4}
        borderRadius={4}
        boxShadow="inset 0 0 6px rgba(0,0,0,0.4)"
        textShadow="0 0 3px black">
        {numPages !== null ? `Page ${page} / ${numPages}` : 'Loading...'}
      </Center>
    </Center>
  )
}

export const PdfViewModal = ({
  url,
  header,
  children,
  ...modalProps
}: PropsWithChildren<PdfViewModalProps>) => {
  const [numPages, setNumPages] = useState<number | null>(null)
  const screen = useContext(ScreenContext)

  useEffect(() => {
    if (!url) setNumPages(null)
  }, [url])
  const onDocumentLoadSuccess = useCallback(({ numPages: num }: { numPages: number }) => {
    setNumPages(num)
  }, [])

  const { width, height } = useMemo(() => {
    const w = Math.min(screen.width, 1000)
    return { width: w, height: Math.max(screen.height, w * 1.4143) }
  }, [screen])
  const containerRef = React.useRef<HTMLDivElement>(null)
  return (
    <DefaultModal
      scrollBehavior="inside"
      header={header}
      {...modalProps}
      bodyProps={{
        padding: 0,
        overflow: 'hidden',
        width: `${width}px`,
        display: 'flex',
        flexFlow: 'column',
        height: `${height - 50}px`,
      }}
      contentProps={{
        overflow: 'hidden',
        minW: 0,
        maxW: width,
        width: 'auto',
        height: 'auto',
      }}
      render={() =>
        url ? (
          <>
            <VStack
              spacing={0}
              width={`${width}px`}
              height={`${height - 50}px`}
              overflowY="auto"
              position="relative"
              align="center"
              px={2}
              py={4}>
              {children ? <HStack height="40px">{children}</HStack> : null}
              <Document
                inputRef={containerRef}
                loading={
                  <Center w={`${width - 30}px`} h={`${height - 30 * 1.4143}px`}>
                    <Spinner />
                  </Center>
                }
                onLoadSuccess={onDocumentLoadSuccess}
                file={url}>
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    className="pdf-view-page"
                    loading={
                      <Center w={`${width - 30}px`} h={`${height - 30 * 1.4143}px`}>
                        <Spinner />
                      </Center>
                    }
                    width={width - 30 * 1.4143}
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                  />
                ))}
              </Document>
            </VStack>
            {numPages !== null && numPages > 1 ? (
              <PageNumberIndicator numPages={numPages} containerRef={containerRef} />
            ) : null}
          </>
        ) : (
          <Center w={`${width}px`} h={`${height}px`}>
            <Spinner />
          </Center>
        )
      }
    />
  )
}
