import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react'
import { FieldMap, FieldStructure, getFieldStructure, ShareCorrections } from '@hb/shared'

import * as React from 'react'
import { useCallback, useContext, useMemo, useState } from 'react'
// import { AssessmentInterfaceContext } from '../../contexts/AssessmentContext'
import { PopUpMessageContext } from '../../contexts/PopUpMessage/PopUpMessageContext'

import { useAppGroup } from '../../hooks/backend/user/useAppGroup'
import { useAuth } from '../../store/auth'
import { ActionButton, SolidActionButton } from '../Buttons/ActionButton'

const FieldMapStructureView = ({
  structure,
  depth = 0,
}: {
  structure: FieldStructure
  depth?: number
}) => {
  return (
    <Box
      borderTop={depth ? '1px solid #ababab' : 'none'}
      borderLeft={depth ? '4px solid #ababab' : 'none'}
      bg="white">
      <Text w="100%" px={2} py={depth ? 0 : 1}>
        {structure.name}
      </Text>
      {structure.children.map((field, i) => (
        <Box key={i}>
          {typeof field === 'string' ? (
            <Text>{field}</Text>
          ) : (
            <FieldMapStructureView depth={depth + 1} structure={field} />
          )}
        </Box>
      ))}
    </Box>
  )
}

const ShareCorrectionsConfirm = ({
  fields,
  willShare,
  onConfirm,
  onCancel,
}: {
  fields: FieldMap
  willShare: boolean
  onConfirm: () => void
  onCancel: () => void
}) => {
  const fieldStructure = useMemo(() => getFieldStructure(fields), [fields])
  return (
    <Flex flexFlow="column" gap={2} w="100%" p={3}>
      <Text lineHeight={1.2}>
        {willShare ? 'Share' : 'Unshare'} the following corrections with the patient?
      </Text>
      <Box border="1px solid #cdcdcd" borderRadius={4} overflow="hidden">
        <FieldMapStructureView structure={fieldStructure} />
      </Box>
      <Flex gap={2} p={2} w="100%" justify="flex-end">
        <ActionButton bg="white" size="sm" onClick={onCancel}>
          Cancel
        </ActionButton>
        <SolidActionButton size="sm" onClick={onConfirm}>
          {willShare ? 'Share' : 'Unshare'}
        </SolidActionButton>
      </Flex>
    </Flex>
  )
}

export const ShareCorrectionsButton = ({
  fields,
  shared,
  onSubmit,
}: {
  fields: FieldMap
  shared: ShareCorrections | null
  onSubmit: (shared: ShareCorrections | null) => Promise<void>
}) => {
  const { showMessage } = useContext(PopUpMessageContext)
  const me = useAuth(s => s.authUser)
  const userGroup = useAppGroup()
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const handleShareCorrections = useCallback(
    async (shouldShare: boolean) => {
      const uid = me?.uid

      const onError = (message?: string | null) => {
        showMessage({
          type: 'error',
          text: `Error ${shouldShare ? 'sharing' : 'unsharing'} corrections`,
          subText: message || '',
        })
      }
      if (!uid || !userGroup || userGroup === 'patient') {
        onError('Not signed in')
        return
      }
      try {
        setIsLoading(true)
        // const docRef = doc(db, `${ASSESSMENTS}/${assessmentId}`) as DocumentReference<Assessment>
        const updated: ShareCorrections | null = shouldShare
          ? {
              by: uid,
              on: Date.now(),
              group: userGroup,
            }
          : null
        // await updateDoc(docRef, `corrections.${path}.correctionsShared`, updated)
        await onSubmit(updated)
        setIsOpen(false)
        showMessage({
          type: 'success',
          text: `${shouldShare ? 'Shared' : 'Unshared'} corrections`,
        })
      } catch (err: any) {
        onError(err.message)
      }
      setIsLoading(false)
    },
    [me, userGroup, showMessage, onSubmit],
  )
  return (
    <Box onClick={e => e.stopPropagation()} ml="auto">
      <Popover
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        strategy="fixed"
        placement="left-start"
        isLazy>
        {({ onClose }) => (
          <>
            <PopoverTrigger>
              <ActionButton gap={1} isLoading={isLoading} borderRadius="full" size="xs">
                {shared ? <ViewOffIcon /> : <ViewIcon />}
                <Text>{shared ? 'Unshare' : 'Share'} Corrections</Text>
              </ActionButton>
            </PopoverTrigger>
            <Portal>
              <PopoverContent bg="gray.50">
                <PopoverBody p={0}>
                  <ShareCorrectionsConfirm
                    fields={fields}
                    willShare={!shared}
                    onConfirm={() => handleShareCorrections(!shared)}
                    onCancel={onClose}
                  />
                </PopoverBody>
                <PopoverArrow bg="gray.50" />
              </PopoverContent>
            </Portal>
          </>
        )}
      </Popover>
    </Box>
  )
}
