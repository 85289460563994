import { CloseIcon } from '@chakra-ui/icons'
import { Divider, Flex, IconButton, Text, VStack } from '@chakra-ui/react'
import {
  addressField,
  DollarAmountField,
  faxField,
  FieldMap,
  FieldTypes,
  InvoicingSettings,
  INVOICING_SETTINGS,
  PercentageField,
  phoneField,
} from '@hb/shared'
import { AnimatePresence, motion } from 'framer-motion'

import React from 'react'
import { useDocument, useUpdateDoc } from '../../hooks'
import { DataView } from '../DataView'
import { EditableRow } from '../forms'

const above12000FeePercentageField: PercentageField = {
  type: FieldTypes.PERCENTAGE,
  placeholder: 'Invoice fee ($12,000 or above)',
}

const below12000FeePercentageField: PercentageField = {
  type: FieldTypes.PERCENTAGE,
  placeholder: 'Invoice fee (under $12,000)',
}

const clearingHouseFeeField: DollarAmountField = {
  type: FieldTypes.DOLLAR_AMOUNT,
  placeholder: 'Clearing House Fee (Added to each monthly invoice)',
}

const invoiceHeaderAddressField: FieldMap = {
  ...addressField,
  name: 'Address',
}

const invoiceHeaderField: FieldMap = {
  name: 'Invoice Header (Appears on the top of every invoice)',
  children: {
    address: invoiceHeaderAddressField,
    phone: phoneField,
    fax: faxField,
    mobile: { ...phoneField, placeholder: 'Mobile' },
  },
}

export const InvoiceSettingsView = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  const { data: feesSettings, ref: invoicingSettingsFeesRef } = useDocument<
    InvoicingSettings['fees']
  >(INVOICING_SETTINGS, 'fees')

  const { data: templateSettings, ref: invoicingSettingsTemplatesRef } = useDocument<
    InvoicingSettings['template']
  >(INVOICING_SETTINGS, 'template')

  const update = useUpdateDoc()
  return (
    <AnimatePresence>
      {isOpen ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key="invoice-settings"
          style={{
            width: '100%',
            position: 'absolute',
            zIndex: 4,
            top: 0,
            left: 0,
            height: '100%',
            background: '#000000aa',
          }}
          onClick={onClose}>
          <motion.div
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.9 }}
            onClick={e => e.stopPropagation()}
            key="invoice-settings"
            style={{ width: '100%' }}>
            <VStack w="100%" p={8} align="center">
              <VStack
                spacing={0}
                align="flex-start"
                borderRadius={6}
                boxShadow="md"
                bg="gray.50"
                maxW="800px"
                w="100%">
                <Flex borderBottom="1px solid #cdcdcd" w="100%" align="center" px={3} py={2}>
                  <Text lineHeight={1} fontSize="lg" fontFamily="Comfortaa">
                    Invoice Settings
                  </Text>
                  <IconButton
                    ml="auto"
                    size="xs"
                    borderRadius="full"
                    aria-label="Close"
                    icon={<CloseIcon />}
                    onClick={onClose}
                    variant="ghost"
                  />
                </Flex>
                <Flex flexFlow="column" w="100%" p={3}>
                  <Text px={3} fontSize="md" fontFamily="Comfortaa">
                    Fees
                  </Text>
                  <EditableRow
                    label="Under $12,000"
                    value={feesSettings?.under12000}
                    onSubmit={v => update(invoicingSettingsFeesRef, 'under12000', v)}
                    field={below12000FeePercentageField}
                  />
                  <EditableRow
                    label="$12,000 or above"
                    value={feesSettings?.other}
                    onSubmit={v => update(invoicingSettingsFeesRef, 'other', v)}
                    field={above12000FeePercentageField}
                  />
                  <EditableRow
                    label="Clearing house fee"
                    value={feesSettings?.clearingHouseFee}
                    onSubmit={v => update(invoicingSettingsFeesRef, 'clearingHouseFee', v)}
                    field={clearingHouseFeeField}
                  />
                  <Divider />
                  <DataView
                    alwaysExpanded
                    data={templateSettings?.header}
                    onSubmit={v => update(invoicingSettingsTemplatesRef, 'header', v)}
                    field={invoiceHeaderField}
                  />
                </Flex>
              </VStack>
            </VStack>
          </motion.div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  )
}
