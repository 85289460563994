import { Box } from '@chakra-ui/react'
import { FloatingContainer, Header } from '@hb/shared-frontend'
import { SignInContent } from '@hb/shared-frontend/components/Auth/SignInContent'
import React from 'react'

const SignInToView: React.FC<{ message: string }> = ({ message }) => (
  <Box>
    <FloatingContainer w={['300px', '400px']} py={0} px={0}>
      <Header
        p={3}
        px={4}
        // py={2}
        style={{
          borderBottom: '1px solid #cdcdcd',
          textAlign: 'left',
        }}>
        {message}
      </Header>
    </FloatingContainer>
    <FloatingContainer w={['300px', '400px']} mt={4} py={0} px={0}>
      <SignInContent />
    </FloatingContainer>
  </Box>
)

export default SignInToView
