import { ArrowBackIcon, EditIcon } from '@chakra-ui/icons'
import { AnyObject, CollectionItem, colors, FieldTypes } from '@hb/shared'
import { DraggableProvidedDragHandleProps } from '@hello-pangea/dnd'
import React, { CSSProperties, PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'
import dragIcon from '../icons/hamburger.svg'
import redFlagIcon from '../icons/red-flag.svg'
import saveIcon from '../icons/save.svg'
import { DeleteButton } from './Buttons/DeleteButton'
import { HeaderButton } from './Buttons/HeaderButton'
import { Container } from './Container'
import { FormElement } from './forms'

export interface EditControls {
  value?: any
  setProperty: (key: string, value: any) => void
  changes?: boolean
  onReset?: () => void
}

export type EditedProps<T extends AnyObject> = {
  width: number
  size?: 'small' | 'medium' | 'large'
  item?: CollectionItem<T>
  expanded?: boolean
  dirty?: boolean
  toggleExpanded?: () => void
  onDelete?: () => Promise<any>
  placeholder: string
  itemName: string
  dragHandleProps?: DraggableProvidedDragHandleProps
  showRedFlag?: boolean
}
type ConfirmAction = 'reset' | 'delete' | null

const ConfirmMessage: React.FC<{
  action: ConfirmAction
  confirm: () => void
  cancel: () => void
}> = ({ action, confirm, cancel }) => {
  const text = useMemo(() => {
    switch (action) {
      case 'reset':
        return 'Discard Changes?'
      case 'delete':
        return 'Confirm Delete?'
      default:
        return null
    }
  }, [action])

  const Span = ({ children, style }: PropsWithChildren<{ style?: CSSProperties }>) => (
    <span
      style={{
        fontSize: '12px',
        width: '100%',
        color: '#777',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        ...style,
      }}>
      {children}
    </span>
  )

  return (
    <>
      <HeaderButton
        label={text || ''}
        width={150}
        style={{
          transition: 'width 500ms',
        }}
        active={!!action}
        onClick={confirm}>
        <Span
          style={{
            color: action === 'delete' ? 'red' : '#777',
          }}>
          {text}
        </Span>
      </HeaderButton>
      <HeaderButton label="CANCEL" width={60} active={!!action} onClick={cancel}>
        <Span>Cancel</Span>
      </HeaderButton>
    </>
  )
}

const EditHeader = <T extends AnyObject>({
  onDelete,
  placeholder,
  dragHandleProps,
  size = 'medium',
  expanded,
  toggleExpanded,
  itemName,
  showRedFlag,
}: EditedProps<T>) => {
  const { reset, submit } = useForm()
  const { dirty } = useFormState()

  // const nameWidth = useMemo(
  //   () =>
  //     width -
  //     (showRedFlag ? 30 : 0) -
  //     (dragHandleProps ? 40 : 0) -
  //     (onSave && changes ? 30 : 0) -
  //     (onDelete && !changes ? 50 : 0) -
  //     (changes ? 30 : 0) -
  //     (toggleExpanded ? 60 : 0),
  //   [
  //     width,
  //     onDelete,
  //     onSave,
  //     changes,
  //     toggleExpanded,
  //     dragHandleProps,
  //     showRedFlag,
  //   ]
  // )

  const [action, setAction] = useState<ConfirmAction>(null)

  const confirm = useCallback(() => {
    switch (action) {
      case 'reset':
        reset()
        if (toggleExpanded) toggleExpanded()
        break
      case 'delete':
        if (onDelete) onDelete()
        break
      default:
        break
    }
    setAction(null)
  }, [action, onDelete, reset, toggleExpanded])

  const cancel = () => setAction(null)

  const onEditClick = () => {
    if (toggleExpanded) toggleExpanded()
  }
  const visible = !toggleExpanded || expanded

  return (
    <Container
      style={{
        height: 'auto',
        flexFlow: 'row',
        display: 'flex',
        alignItems: 'center',
        padding: '0.2rem 0.5rem',
        justifyContent: 'space-between',
      }}>
      <HeaderButton label="REORDER" size={size} active={!!dragHandleProps}>
        <img
          {...dragHandleProps}
          src={dragIcon}
          style={{ height: '28px', padding: '5px', marginLeft: '3px' }}
          alt="drag"
        />
      </HeaderButton>

      <FormElement
        name="name"
        field={{ type: FieldTypes.TEXT, placeholder: placeholder || 'Name' }}
      />

      <Field name="redFlagged">
        {({ input }) => (
          <HeaderButton
            label="RED FLAG"
            size={size}
            onClick={() => input.onChange({ target: { value: !input.value } })}
            active={showRedFlag && !action}>
            <img
              src={redFlagIcon}
              style={{
                height: '70%',
                filter: `grayscale(${input.value ? 0 : 100}%)`,
              }}
              alt="red-flag"
            />
          </HeaderButton>
        )}
      </Field>

      <HeaderButton
        label="RESET"
        size={size}
        onClick={dirty ? () => setAction('reset') : onEditClick}
        active={visible && (dirty || toggleExpanded) && !action}>
        <ArrowBackIcon color="gray.600" />
      </HeaderButton>
      <HeaderButton
        size={size}
        label="SAVE"
        onClick={submit}
        active={!!(visible && dirty && !action)}>
        <img src={saveIcon} style={{ height: '60%' }} alt="save" />
      </HeaderButton>
      {/* <Container
        style={{
          width: !action && toggleExpanded ? 60 : 0,
          height: 'auto',
          overflow: 'hidden',
          fontSize: '13px',
        }}
      >
        <Button
          onClick={toggleExpanded}
          style={{ fontSize: '13px', whiteSpace: 'nowrap' }}
        >
          {expanded ? '- HIDE' : '+ VIEW'}
        </Button>
      </Container> */}
      <HeaderButton
        label="EDIT"
        width={40}
        size={size}
        onClick={onEditClick}
        active={toggleExpanded && !visible}>
        <EditIcon color={colors.green.hex} />
      </HeaderButton>
      {onDelete ? <DeleteButton itemName={itemName} onDelete={onDelete} /> : null}

      <ConfirmMessage action={action} confirm={confirm} cancel={cancel} />
    </Container>
  )
}

export default EditHeader
