import { get as nestedGet } from 'nested-property'
import { PopulatedAssessment } from '../types'
import { Assessment } from '../types/assessments/assessment'

export const getCorrectedValue = (
  assessment?: Assessment | PopulatedAssessment | null,
  path?: string,
) => {
  const formData = (assessment as Assessment)?.data || (assessment as any)?.formData || {}

  return nestedGet(assessment?.corrections, path || '') || nestedGet(formData, path || '')
}

export const getCoveragePath = (assessment?: Assessment | PopulatedAssessment | null) => {
  const coverageOption = getCorrectedValue(assessment, 'insurance-info.option')
  switch (coverageOption) {
    case 'medicaid':
      return 'medicaidCoverage'
    default:
      return 'primaryCoverage'
  }
}
export const getInsuranceProviderId = (assessment?: Assessment | PopulatedAssessment | null) =>
  getCorrectedValue(assessment, `insurance-info.${getCoveragePath(assessment)}.insuranceProviderId`)
