import {
  FieldMapValue,
  nestedRemoveNaN,
  SubmitAssessmentResultsArgs,
  UpdateAssessmentAnswersArgs,
  UpdateCallback,
} from '@hb/shared'
import { deleteDoc, doc, updateDoc } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { useCallback, useContext, useState } from 'react'
import { functions } from '../../backend/functions'
import { ASSESSMENTS_REF } from '../../collections/firestoreCollections'
import { PopUpMessageContext, useApp, usePracticeAccess, UserContext } from '../../contexts'

export type CreateNewAssessmentArgs = {
  patientId?: string
  name?: string
  practiceId?: string
  sendInvite?: boolean
}

export const createNewAssessment = httpsCallable<CreateNewAssessmentArgs>(
  functions,
  'createNewAssessment',
)
export const submitAssessmentAnswers = httpsCallable(functions, 'submitAssessmentAnswers')

const sanitizeInsuranceCoverage = (coverage?: FieldMapValue) => {
  if (!coverage) return undefined
  const res = { ...coverage }
  if (res.insuranceProvider) delete res.insuranceProvider
  if (res.noMemberId) res.memberId = ''
  return res
}

const updateAssessmentAnswersFunction = httpsCallable<UpdateAssessmentAnswersArgs>(
  functions,
  'updateAssessmentAnswers',
)

export const updateAssessmentAnswers = ({ id, data, appName }: UpdateAssessmentAnswersArgs) => {
  const submitted = nestedRemoveNaN(data)
  if (submitted?.delivery?.midwife) delete submitted.delivery.midwife
  if (submitted?.['insurance-info']?.primaryCoverage) {
    submitted['insurance-info'].primaryCoverage = sanitizeInsuranceCoverage(
      submitted?.['insurance-info']?.primaryCoverage,
    )
  }
  if (submitted?.['insurance-info']?.secondaryCoverage) {
    submitted['insurance-info'].secondaryCoverage = sanitizeInsuranceCoverage(
      submitted?.['insurance-info']?.secondaryCoverage,
    )
  }
  if (submitted?.['insurance-info']?.medicaidCoverage) {
    submitted['insurance-info'].medicaidCoverage = sanitizeInsuranceCoverage(
      submitted?.['insurance-info']?.medicaidCoverage,
    )
  }
  return updateAssessmentAnswersFunction({ id, data: submitted, appName })
}

export const updateAssessmentField = async (
  assessmentId: string,
  path: string,
  data: any,
): Promise<UpdateCallback> => {
  const assessmentRef = doc(ASSESSMENTS_REF, assessmentId)
  // const existing = await getDoc(assessmentRef)
  // const existingData = existing.data()
  // const existingFieldValue = get(existingData, path)
  const fullPath = `corrections.${path}`
  return updateDoc(assessmentRef, { [fullPath]: data })
    .then(() => ({ success: 'Updated field' }))
    .catch(err => ({ error: err?.message || 'Error updating answers' }))
}

export const updateAssessmentViewed = httpsCallable(functions, 'updateAssessmentViewed')
export const submitAssessmentResults = httpsCallable<SubmitAssessmentResultsArgs>(
  functions,
  'submitAssessmentResults',
)
export const saveAssessmentDraft = httpsCallable(functions, 'saveAssessmentDraft')
export const moveAssessmentAuthorization = httpsCallable(functions, 'moveAssessmentAuthorization')
export const copyAssessmentLogs = httpsCallable(functions, 'copyAssessmentLogs')

export const fixFilesFunction = httpsCallable(functions, 'movePatientFilesToAssessment')
export const fixDoubleRenameFunction = httpsCallable(functions, 'fixDoubleResized')

export const deleteAssessment = (id: string): Promise<UpdateCallback> =>
  deleteDoc(doc(ASSESSMENTS_REF, id))
    .then(() => ({ success: 'ok' }))
    .catch(() => ({ error: 'error' }))

export const useCreateAssessment = () => {
  const { user, selectAssessment } = useContext(UserContext)

  const { appName } = useApp()
  const { selectedPracticeId } = usePracticeAccess()
  const { processResponse } = useContext(PopUpMessageContext)
  const [confirmingNewAssessment, setConfirmingNewAssessment] = useState(false)
  const [addLoading, setAddLoading] = useState(false)
  const createAssessment = useCallback(
    async (data: {
      name: string
      sendInvite: boolean
      copyPreviousData: boolean
      notes: string
      edd: string
    }) => {
      const { name, sendInvite, copyPreviousData, notes, edd } = data
      if (!user) {
        return processResponse({
          error: 'No user selected to create assessment for',
        })
      }
      if (!name && appName === 'app') {
        return processResponse({ error: 'No assessment name entered' })
      }
      if (appName === 'providers-app' && !edd) {
        return processResponse({ error: 'No EDD entered' })
      }
      if (appName === 'providers-app' && !selectedPracticeId) {
        return processResponse({
          error: 'No practice selected to create assessment for',
        })
      }
      setAddLoading(true)
      try {
        const submitted: Record<string, any> = {
          patientId: user.id,
          name,
          sendInvite: !!sendInvite,
          notes,
          edd,
          copyPreviousData: !!copyPreviousData,
        }
        if (selectedPracticeId) submitted.practiceId = selectedPracticeId
        const res = await createNewAssessment(submitted)
        if (typeof res.data === 'string') {
          selectAssessment(res.data)
        }
        setAddLoading(false)
        setConfirmingNewAssessment(false)
        return processResponse({ success: 'Assessment created!' })
      } catch (err: any) {
        console.error(err?.message)
        setAddLoading(false)
        return processResponse({ error: err.message })
      }
    },
    [user, processResponse, selectAssessment, selectedPracticeId, appName],
  )
  return {
    confirmingNewAssessment,
    setConfirmingNewAssessment,
    addLoading,
    createAssessment,
  }
}
