import { CollectionsState } from '@hb/shared'

import { create } from 'zustand'

import createSelectors from './selectors'

// Define a store for managing collections

const useCollectionsBase = create<CollectionsState<any>>(() => ({}))

// const publishToSubscriber

export const useCollections = createSelectors(useCollectionsBase)
