import { Flex, Text } from '@chakra-ui/react'
import {
  coverageRequestField,
  FieldMapValue,
  InsuranceCoverageRequest,
  UpdateCallback,
  WithId,
} from '@hb/shared'
import React, { useCallback, useContext } from 'react'
import { requestCoverage } from '../../../backend/functions'
import { AppContext } from '../../../contexts'
import { PopUpMessageContext } from '../../../contexts/PopUpMessage/PopUpMessageContext'
import { SimpleForm } from '../../forms/FinalForm/SimpleForm'
import { DefaultModal } from '../../Modals/DefaultModal'

export const EditCoverageRequestModal = ({
  onClose,
  request,
  assessmentId,
}: {
  onClose: () => void
  request: WithId<InsuranceCoverageRequest>
  assessmentId: string
}) => {
  const { processResponse } = useContext(PopUpMessageContext)

  const { appName } = useContext(AppContext)
  const handleRequestNewPlan = useCallback(
    async (data: FieldMapValue): Promise<UpdateCallback> => {
      if (!assessmentId) {
        return processResponse({ error: 'No assessment selected' })
      }

      const { message, withCallInForm, sendEmail } = data
      try {
        await requestCoverage({
          assessmentId,
          appName,
          requestId: request.id,
          message: message || '',
          withCallInForm,
          sendEmail: !!sendEmail,
        })
      } catch (err: any) {
        return processResponse({
          error: err?.message || 'Error requesting coverage',
        })
      }
      onClose()
      return processResponse({ success: 'Coverage requested' })
    },
    [assessmentId, processResponse, onClose, request, appName],
  )

  return (
    <DefaultModal
      isOpen
      overlayHeader
      onClose={onClose}
      size="xl"
      render={() => (
        <Flex py={4} px={2} w="100%" flexFlow="column">
          <Text px={3} fontFamily="Hero-New" fontSize="lg" lineHeight={1} color="gray.600">
            Edit Coverage Request
          </Text>
          <SimpleForm
            onSubmit={handleRequestNewPlan}
            field={coverageRequestField}
            value={request}
            boxProps={{ p: 0 }}
          />
        </Flex>
      )}
    />
  )
}
