import { HStack } from '@chakra-ui/react'
import { colors } from '@hb/shared'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { ThemeContext } from '../../../../contexts'
import { InputMenu } from '../InputMenu'
import {
  amPmField,
  amPmOptions,
  hourField,
  hourOptions,
  minuteField,
  minuteOptions,
} from './fields'
import { TempTimeValue } from './types'
import { constructTimeString, deconstructTimeString } from './utils'

export const TimePicker = ({
  value,
  onChange,
  active,
}: {
  value?: string
  onChange: (v: string | undefined) => void
  active?: boolean
}) => {
  const temp = useRef<Partial<TempTimeValue>>(deconstructTimeString(value || '12:00 AM'))
  useEffect(() => {
    temp.current = value ? deconstructTimeString(value) : {}
  }, [value])

  const [hours, setHours] = useState(temp.current.hours)
  const [minutes, setMinutes] = useState(temp.current.minutes)
  const [amPm, setAmPm] = useState(temp.current.amPm || 'AM')

  const [openSegment, setOpenSegment] = useState<'hours' | 'minutes' | 'amPm' | null>(null)

  const { theme } = useContext(ThemeContext)

  useEffect(() => {
    const deconstructed = deconstructTimeString(value || '12:00 AM')
    setHours(deconstructed.hours)
    setMinutes(deconstructed.minutes)
    setAmPm(deconstructed.amPm)
    temp.current = deconstructed
  }, [value])

  const handleChange = useCallback(
    (key: 'hours' | 'minutes' | 'amPm', v: number | string | undefined) => {
      if (key === 'hours') {
        temp.current.hours = v as number
      } else if (key === 'minutes') {
        temp.current.minutes = v as number
      } else if (key === 'amPm') {
        temp.current.amPm = v as string
      }
      setOpenSegment(null)
      const { hours: currHours, minutes: currMinutes, amPm: currAmPm } = temp.current
      if (currHours !== undefined && currMinutes !== undefined && currAmPm) {
        onChange(constructTimeString(temp.current as TempTimeValue))
      }
    },
    [onChange],
  )

  return (
    <HStack
      spacing={1}
      px={theme === 'basic' ? 2 : 3}
      pl={theme === 'basic' ? 1 : 2}
      cursor="pointer"
      py={theme === 'basic' ? 1 : '0.4rem'}
      background="white"
      boxShadow={`1px 3px 4px ${colors.blue.hex}${active ? '77' : '00'}`}
      border={theme === 'basic' ? undefined : '1px solid #cdcdcd'}
      _hover={{ background: 'gray.50' }}
      borderRadius={6}
      color={value ? 'gray.700' : 'gray.500'}>
      <InputMenu<number>
        options={hourOptions}
        boxProps={{ width: 'auto', py: 0 }}
        inputProps={{
          px: 0,
          py: 0,
          width: '24px',
          height: '24px',
          textAlign: 'center',
          border: 'none',
          borderBottom: '1px solid #cdcdcd',
        }}
        onOpen={() => setOpenSegment('hours')}
        onClose={() => setOpenSegment(null)}
        inputField={hourField}
        isOpen={openSegment === 'hours'}
        onChange={v => {
          handleChange('hours', v)
        }}
        value={hours}
      />
      <span>:</span>
      <InputMenu<number>
        options={minuteOptions}
        boxProps={{ width: 'auto' }}
        inputProps={{
          px: 0,
          py: 0,
          width: '24px',
          height: '24px',
          lineHeight: 1,
          textAlign: 'center',
          border: 'none',
          borderBottom: '1px solid #cdcdcd',
        }}
        onOpen={() => setOpenSegment('minutes')}
        onClose={() => setOpenSegment(null)}
        inputField={minuteField}
        isOpen={openSegment === 'minutes'}
        onChange={v => {
          handleChange('minutes', v)
        }}
        value={minutes}
      />
      <InputMenu<string>
        options={amPmOptions}
        boxProps={{ width: 'auto' }}
        inputProps={{
          px: 1,
          py: 0,
          width: '32px',
          height: '22px',
          textAlign: 'center',
          border: 'none',
          borderBottom: '1px solid #cdcdcd',
        }}
        onOpen={() => setOpenSegment('amPm')}
        onClose={() => setOpenSegment(null)}
        inputField={amPmField}
        isOpen={openSegment === 'amPm'}
        onChange={v => {
          if (v === 'AM' || v === 'PM') {
            handleChange('amPm', v)
            setAmPm(v)
          }
        }}
        value={amPm}
      />
    </HStack>
  )
}
