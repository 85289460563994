import { CloseIcon } from '@chakra-ui/icons'
import { IconButton, Image } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { ScreenContext } from '../../contexts/ScreenContext'
import loadingIcon from '../../icons/loading.svg'
import { ImageViewModalProps } from '../../types/modals'
import { DefaultModal } from './DefaultModal'

export const ImageViewModal: React.FC<ImageViewModalProps> = ({ src, ...props }) => {
  const screen = useContext(ScreenContext)
  const [width, setWidth] = useState<number | null>(null)
  const [height, setHeight] = useState<number | null>(null)
  return (
    <DefaultModal
      overlayHeader
      isCentered
      // header={header}
      bodyProps={{
        width: 'auto',
        height: 'auto',
        background: '#00000066',
      }}
      headerTextProps={{
        top: 1.5,
        left: 2,
        color: 'white',
        textShadow: '1px 1px 4px black',
        fontSize: '0.9rem',
        filter: 'drop-shadow(0 0 4px #00000077)',
      }}
      noCloseButton
      contentProps={{
        p: 0,
        width: 'auto',
        background: 'transparent',
        overflow: 'hidden',
        maxH: height || '100%',
        maxW: width || '100%',
      }}
      render={() => (
        <>
          <TransformWrapper>
            <TransformComponent>
              <div
                style={{
                  height: height || '120px',
                  width: width || '120px',
                  display: 'flex',
                  justifyContent: 'center',
                  transition: 'height 300ms ease, width 300ms ease',
                  alignItems: 'center',
                }}>
                <Image
                  w="100%"
                  h="100%"
                  objectFit="contain"
                  opacity={height ? 1 : 0}
                  transition={`opacity 300ms ease ${height ? 0 : 180}ms`}
                  onLoad={e => {
                    const { naturalHeight, naturalWidth } = e.target as HTMLImageElement
                    setWidth(Math.min(naturalWidth || 50, screen.width))
                    setHeight(Math.min(naturalHeight || 200, screen.height))
                  }}
                  src={src}
                />
                <Image
                  w="100px"
                  h="100px"
                  objectFit={'contain'}
                  position="absolute"
                  pointerEvents="none"
                  opacity={height ? 0 : 1}
                  src={loadingIcon}
                />
              </div>
            </TransformComponent>
          </TransformWrapper>
          <IconButton
            aria-label="Close"
            variant="ghost"
            size="xs"
            position="absolute"
            onClick={props.onClose}
            _hover={{
              bg: 'blackAlpha.400',
            }}
            borderRadius="full"
            top={1}
            right={1}
            icon={
              <CloseIcon w={3.5} h={3.5} color="white" filter="drop-shadow(1px 1px 3px #000000)" />
            }
          />
        </>
      )}
      {...props}
    />
  )
}
