export const getLocalStorage = (key: string) => {
  const item = localStorage.getItem(key)
  return item ? JSON.parse(item) : null
}

export const setLocalStorage = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value))
}

export const getHBDeviceId = () => {
  const deviceId = getLocalStorage('hb_device_id')
  if (deviceId) return deviceId
  const newDeviceId = crypto.randomUUID()
  setLocalStorage('hb_device_id', newDeviceId)
  return newDeviceId
}
