import { CalendarIcon, EmailIcon } from '@chakra-ui/icons'
import { Box, Flex, StackProps, Text } from '@chakra-ui/react'
import {
  capitalizeFirstLetterOfEachWord,
  colors,
  formatDollarValue,
  formatPaymentType,
  getDateString,
  getDateTimeString,
  PaymentReminder,
  PopulatedPayment,
  UpdateCallback,
} from '@hb/shared'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { ScreenContext } from '../../../../contexts'
import { AppContext } from '../../../../contexts/AppContext'

import { PopUpMessageContext } from '../../../../contexts/PopUpMessage/PopUpMessageContext'
import { UserContext } from '../../../../contexts/UserContext'
import { sendOverduePaymentReminder } from '../../../../hooks/backend/user/userUtils'
import { DeleteButton } from '../../../Buttons'
import { getRowBackground } from '../../../DataView'
import { DateTimePickerModal } from '../../../Modals/DatePickers'
import { NotesPopover } from '../../../NotesPopover'
import { SquareBadge, SquareBadgeProps } from '../../../Status'
import { usePaymentColors } from './hooks'

const StatusBadge: React.FC<
  SquareBadgeProps & {
    active?: boolean
    inactiveLabel?: string
    activeLabel?: string
  }
> = ({ active, inactiveLabel, activeLabel, ...props }) => (
  <SquareBadge label={active ? activeLabel : inactiveLabel} {...props} />
)

const DueDateBadge = ({
  dueDate,
  onChange,
}: {
  dueDate?: number
  onChange?: (v?: number) => Promise<any>
}) => {
  const { dueDatePassed, daysUntilDueDate } = useMemo(() => {
    const days = dueDate && (dueDate - Date.now()) / (1000 * 60 * 60 * 24)
    return {
      dueDatePassed: days ? days < 0 : undefined,
      daysUntilDueDate: Math.ceil(days || 0),
    }
  }, [dueDate])
  const [isOpen, setIsOpen] = useState(false)
  const activeLabel = useMemo(() => {
    if (daysUntilDueDate === undefined) return 'Due date passed'
    if (daysUntilDueDate) {
      return `Due date passed (${-1 * daysUntilDueDate} days ago)`
    }
    return 'Due today'
  }, [daysUntilDueDate])
  const inactiveLabel = useMemo(() => {
    if (dueDate) {
      return onChange
        ? `Due in ${daysUntilDueDate} days - click to edit`
        : `Due in ${daysUntilDueDate} day${daysUntilDueDate === 1 ? '' : 's'}`
    }
    return onChange ? 'Due date not set - Click to edit' : 'Error calculating due date'
  }, [daysUntilDueDate, dueDate, onChange])
  const colorScheme = useMemo(() => {
    if (dueDatePassed) return 'blue'
    if (dueDate) return 'gray'
    return 'orange'
  }, [dueDate, dueDatePassed])
  const color = useMemo(() => {
    if (dueDatePassed) return 'blue.500'
    if (dueDate) return 'gray.500'
    return 'orange.500'
  }, [dueDatePassed, dueDate])
  return (
    <>
      <StatusBadge
        cursor={onChange ? 'pointer' : 'default'}
        onClick={onChange ? () => setIsOpen(true) : undefined}
        active={!!dueDatePassed}
        activeLabel={activeLabel}
        inactiveLabel={inactiveLabel}
        colorScheme={colorScheme}>
        <CalendarIcon color={color} />
      </StatusBadge>
      {onChange ? (
        <DateTimePickerModal
          header="Payment Due Date"
          isOpen={isOpen}
          isCentered
          onClose={() => setIsOpen(false)}
          value={dueDate}
          onChange={onChange}
        />
      ) : null}
    </>
  )
}

const ReminderEmailBadge = ({
  sentOn,
  paymentComplete,
  scheduledOn,
  onClick,
  remindersSent,
  confirmReminder,
}: {
  sentOn?: number
  remindersSent?: Array<PaymentReminder>
  paymentComplete?: boolean
  scheduledOn?: number
  confirmReminder?: boolean
  onClick?: () => void | Promise<void>
}) => {
  const { processResponse } = useContext(PopUpMessageContext)

  const [loading, setLoading] = useState(false)
  const sentRemindersString = useMemo(
    () =>
      remindersSent
        ? remindersSent.map(r => `Reminder sent ${getDateTimeString(r.sentOn)}\n`).join('')
        : '',
    [remindersSent],
  )
  const inactiveLabel = useMemo(() => {
    if (scheduledOn) {
      return `${sentRemindersString}Automatic reminder scheduled for ${getDateString(
        scheduledOn,
        'short',
      )}`
    }
    if (loading) return 'Loading...'
    if (confirmReminder) return 'Click again to send reminder email'
    if (remindersSent?.length)
      return `${sentRemindersString}Click to send overdue payment reminder email`

    return `${sentRemindersString}Click to send overdue payment reminder email`
  }, [scheduledOn, confirmReminder, loading, sentRemindersString, remindersSent])

  const activeLabel = useMemo(() => {
    if (loading) return 'Loading...'
    if (confirmReminder) return 'Click again to send reminder email'
    return paymentComplete
      ? `${sentRemindersString}Automatic reminder cancelled (Patient has paid)`
      : `${sentRemindersString}Automatic reminder sent on ${getDateString(
          sentOn as number,
          'short',
        )}`
  }, [paymentComplete, confirmReminder, loading, sentRemindersString, sentOn])

  const mobileText = useMemo(() => {
    if (sentOn) return 'Reminder Email Sent'
    if (paymentComplete) return 'Payment Complete'
    if (scheduledOn) return 'Scheduled'
    if (confirmReminder) return 'Confirm'
    return 'Send Email Reminder'
  }, [paymentComplete, scheduledOn, confirmReminder, sentOn])
  const { isMobile } = useContext(ScreenContext)
  return (
    <StatusBadge
      tooltipProps={{
        isOpen: confirmReminder || loading ? true : undefined,
        whiteSpace: 'pre-wrap',
      }}
      ml={1}
      w="auto"
      active={!!(sentOn || paymentComplete)}
      inactiveLabel={inactiveLabel}
      activeLabel={activeLabel}
      cursor="pointer"
      onClick={async () => {
        if (!onClick) return
        setLoading(true)
        try {
          await onClick()
        } catch (err: any) {
          processResponse({ error: err.message })
        }
        setLoading(false)
      }}
      colorScheme={sentOn || paymentComplete ? 'blue' : 'gray'}>
      <Flex align="center">
        <EmailIcon color={sentOn || paymentComplete ? 'blue.500' : 'gray.500'} />
        {isMobile ? (
          <Text
            ml={1}
            fontSize="xs"
            fontFamily="Open Sans"
            lineHeight={1}
            color={sentOn || paymentComplete ? 'blue.500' : 'gray.500'}>
            {mobileText}
          </Text>
        ) : null}
      </Flex>
    </StatusBadge>
  )
}

const PaidBadge = ({
  paymentComplete,
  paymentStatus,
  amountPaid,
}: {
  paymentComplete: boolean
  paymentStatus: string
  amountPaid?: number
}) => {
  const { colorScheme, color } = usePaymentColors({
    paymentComplete,
    amountPaid,
  })
  const { isMobile } = useContext(ScreenContext)
  const status = paymentComplete ? 'Paid' : 'Unpaid'
  return (
    <StatusBadge
      ml={1}
      width={isMobile ? 'auto' : undefined}
      cursor="pointer"
      active={paymentComplete}
      inactiveLabel={paymentStatus}
      activeLabel={paymentStatus}
      colorScheme={colorScheme}>
      <Text pointerEvents="none" fontWeight={600} color={color} as="span">
        {isMobile ? status : '$'}
      </Text>
    </StatusBadge>
  )
}
// const [isOpen, setIsOpen] = useState(false)

const PaymentDueDateView = ({
  dueDate,
  ...props
}: { dueDate: number | undefined } & StackProps) => (
  <Flex align="center" {...props}>
    <Text mr={2} fontSize="sm" opacity={0.6} fontStyle="italic">
      {dueDate ? `Due on ${getDateString(dueDate, 'short')}` : 'No due date'}
    </Text>
    <DueDateBadge dueDate={dueDate} />
  </Flex>
)

export const PaymentView: React.FC<{
  payment?: PopulatedPayment
  id?: string
  index?: number
  update?: (path: string, v: any) => Promise<UpdateCallback>
  onDelete?: () => Promise<UpdateCallback>
}> = ({ payment, index, onDelete, id }) => {
  const { processResponse } = useContext(PopUpMessageContext)
  const { dueDate, amount, amountPaid, type, remindersSent } = payment || {}

  const { appName } = useContext(AppContext)
  const { assessmentId } = useContext(UserContext)
  const [confirmingReminder, setConfirmingReminder] = useState<string | null>(null)
  useEffect(() => {
    if (confirmingReminder) {
      const t = setTimeout(() => {
        setConfirmingReminder(null)
      }, 2000)

      return () => {
        clearTimeout(t)
      }
    }
    return () => {}
  }, [confirmingReminder])

  const paymentComplete = useMemo(() => {
    if (!payment) return false
    return (
      payment.amountPaid > (payment.amount || 0) ||
      Math.abs(payment.amountPaid - (payment.amount || 0)) < 0.005
    )
  }, [payment])

  // 10 days  before due date
  const reminderScheduledOn = useMemo(() => {
    if (!payment) return undefined
    if (payment.amountPaid >= (payment.amount || 0)) return undefined
    if (remindersSent?.length) return undefined
    if (dueDate && dueDate > Date.now()) {
      return Math.max(Date.now(), dueDate - 1000 * 60 * 60 * 24 * 10)
    }
    return undefined
  }, [dueDate, payment, remindersSent])

  const { paymentStatus } = useMemo(() => {
    if (!payment?.amount) {
      return {
        paymentStatus: 'N/A - No Payment Amount',
      }
    }

    if (paymentComplete) {
      return {
        paymentStatus: `${formatDollarValue(amountPaid)}/${formatDollarValue(payment.amount || 0)}`,
      }
    }
    return {
      paymentStatus: `${formatDollarValue(amountPaid)}/${formatDollarValue(payment.amount || 0)}`,
    }
  }, [amountPaid, payment, paymentComplete])
  const { isMobile } = useContext(ScreenContext)
  return (
    <Flex
      align={isMobile ? 'flex-start' : 'center'}
      pt={isMobile ? 0 : 1}
      pb={isMobile ? 2 : 1}
      px={isMobile ? 0 : 2}
      w="100%"
      borderRadius={isMobile ? 6 : 0}
      boxShadow={isMobile ? '1px 1px 6px #00000077' : 'none'}
      flexFlow={isMobile ? 'column' : 'row'}
      gap={1}
      bg={getRowBackground(index)}>
      <Text
        fontSize="sm"
        fontWeight={600}
        bg={isMobile ? colors.pink.hex : 'transparent'}
        borderTopRadius={6}
        w={isMobile ? '100%' : 'auto'}
        px={isMobile ? 2 : 0}
        py={isMobile ? 1 : 0}
        borderBottom={isMobile ? '1px solid #cdcdcd' : 'none'}
        color="gray.600"
        isTruncated>
        {type ? capitalizeFirstLetterOfEachWord(formatPaymentType(type)) : ''}
      </Text>
      <Flex w={isMobile ? '100%' : 'auto'} px={isMobile ? 2 : 0} align="center">
        <Flex>
          <Text fontSize="sm" color={paymentComplete ? 'green' : 'blackAlpha.700'} fontWeight={600}>
            {formatDollarValue(amount)}
          </Text>
        </Flex>
        {isMobile ? <PaymentDueDateView ml="auto" dueDate={dueDate} /> : null}
      </Flex>
      <Box minW="0" flex={isMobile ? 'unset' : 1}>
        <NotesPopover notes={payment?.notes} />
      </Box>
      <Flex
        justify={isMobile ? 'space-between' : 'flex-start'}
        w={isMobile ? '100%' : 'auto'}
        ml="auto"
        px={isMobile ? 1 : 0}>
        {isMobile ? null : <PaymentDueDateView dueDate={dueDate} />}
        {appName === 'app' ? (
          <ReminderEmailBadge
            paymentComplete={paymentComplete}
            confirmReminder={confirmingReminder === id}
            remindersSent={remindersSent}
            onClick={
              confirmingReminder
                ? () =>
                    sendOverduePaymentReminder({
                      assessmentId,
                      id,
                    }).then(() => processResponse({ success: 'Reminder email sent!' }))
                : () => setConfirmingReminder(id || '')
            }
            scheduledOn={reminderScheduledOn}
          />
        ) : null}
        <PaidBadge
          amountPaid={amountPaid}
          paymentComplete={paymentComplete}
          paymentStatus={paymentStatus}
        />
      </Flex>
      {payment && onDelete ? (
        <DeleteButton itemName="Due payment" ml={1} onDelete={onDelete} />
      ) : null}
    </Flex>
  )
}
