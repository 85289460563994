import useResizeObserver from '@react-hook/resize-observer'
import { useCallback, useMemo, useRef, useState } from 'react'

export const useMobileLayout = (threshold: number = 600) => {
  const ref = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState(0)
  const handleResize = useCallback((e: ResizeObserverEntry) => {
    setWidth(e.borderBoxSize[0].inlineSize)
  }, [])

  useResizeObserver(ref, handleResize)

  const mobileLayout = useMemo(() => width < threshold, [width, threshold])

  return { ref, mobileLayout, width }
}
