import { DeleteIcon } from '@chakra-ui/icons'
import { forwardRef, IconButton, IconButtonProps } from '@chakra-ui/react'
import { UpdateCallback } from '@hb/shared'
import React, { FC } from 'react'
import { ActionConfirm } from './ActionConfirm'

export const DeleteAlertButton = forwardRef<Omit<IconButtonProps, 'aria-label'>, 'button'>(
  (props, ref) => (
    <IconButton
      ref={ref}
      _hover={{ background: 'blackAlpha.200' }}
      colorScheme="red"
      size="xs"
      variant="ghost"
      aria-label="delete"
      icon={<DeleteIcon />}
      {...props}
    />
  ),
)

export const DeleteAlert: FC<{
  isOpen: boolean
  onClose: () => void
  onConfirm: () => Promise<UpdateCallback>
  itemName: string
}> = ({ itemName, ...props }) => (
  <ActionConfirm
    willUnmount
    header={`Delete ${itemName}?`}
    body="Are you sure? You can't undo this action"
    confirmText="Delete"
    colorScheme="red"
    {...props}
  />
)
