import { FieldLabels } from '@hb/shared'
import { useContext, useMemo } from 'react'
import { FormLabelsContext } from '../../../../contexts/FormLabelsContext'

export default function usePopulatedLabels(labels?: FieldLabels) {
  const { value } = useContext(FormLabelsContext)
  return useMemo(
    () => labels && (typeof labels === 'function' ? labels(value) : labels),
    [labels, value],
  )
}
