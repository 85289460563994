import { Text } from '@chakra-ui/react'
import { InlineLink } from '@hb/shared-frontend'
import React from 'react'
import { Link } from 'react-router-dom'

export const PatientsPageLink = () => (
  <Text as="span">
    <Link style={{ opacity: 1 }} to="/patients">
      <InlineLink>Patients</InlineLink>
    </Link>{' '}
    page
  </Text>
)
