import { BooleanField, FieldTypes } from '@hb/shared'
import React, { forwardRef } from 'react'
import { InputElement, InputProps } from '../../../../types/fields'
import { MenuDropdownInput } from '../Select/MenuDropdownInput'

const BooleanDropdown: InputElement<BooleanField> = forwardRef<
  { focus: () => void },
  InputProps<BooleanField>
>((props, ref) => {
  const { field } = props
  const { yesText = 'Yes', noText = 'No' } = field
  return (
    <MenuDropdownInput
      ref={ref}
      {...props}
      field={{
        ...field,
        type: FieldTypes.DROPDOWN,
        options: [
          { id: 'Yes', text: yesText },
          { id: 'No', text: noText },
        ],
      }}
    />
  )
})

export default BooleanDropdown
