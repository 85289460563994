import {
  AnyObject,
  AppName,
  Collection,
  CombinedCollection,
  CombinedCollectionEntry,
  FieldMapValue,
  getBaseCollection,
  UpdateCallback,
  WithMetadata,
} from '@hb/shared'
import {
  collection as firestoreCollection,
  CollectionReference,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore'
import { set } from 'nested-property'
import { db } from '../../backend'
import { addMetadata } from '../../utils/data'

export const getCollectionRef = <Data extends AnyObject>(
  collection: Collection<Data> | CombinedCollectionEntry<Data>,
): CollectionReference<Data> => {
  const baseCollection = getBaseCollection(collection)
  if (!baseCollection) throw new Error('No collection')
  return firestoreCollection(db, baseCollection.refPath) as CollectionReference<Data>
}

export const addItem = async <Data extends AnyObject>(
  appName: AppName,
  collection: Collection<Data>,
  data: FieldMapValue,
): Promise<string> => {
  // setLoading(true)
  const baseCollection = getBaseCollection(collection)
  if (!baseCollection) throw new Error('No collection')
  const { noRanks } = baseCollection
  const ref = getCollectionRef(collection)
  if (data.name) {
    try {
      const existsRes = await getDocs(query(ref, where('name', '==', data.name)))
      if (existsRes.empty) {
        const submitted = addMetadata(data, appName, true)
        if (!noRanks) {
          const items = await getDocs(ref)
          submitted.rank = items?.docs?.length || 0
        }
        const newDoc = doc(ref)
        await setDoc(newDoc, submitted as AnyObject)
        return newDoc.id
      }
      throw new Error('Name exists')
    } catch (err: any) {
      console.error({ baseCollection })
      throw new Error(`Error adding item: ${err.message}`)
    }
  } else {
    throw new Error('No name')
  }
}

const updateCombinedCollectionItem = async <Data extends AnyObject = AnyObject>(
  appName: AppName,
  collection: CombinedCollection<Data>,
  id: string,
  path: string,
  data: FieldMapValue,
): Promise<void> => {
  const batch = writeBatch(db)
  const { index, otherCollections } = collection

  // make sure item exists in all collections
  const missingItems = await Promise.all(
    Object.values(otherCollections).map(async other => {
      const otherCollection = getBaseCollection(other)
      if (!otherCollection) throw new Error('No collection')
      const ref = getCollectionRef(other)
      const docRef = doc(ref, id)
      const otherDoc = await getDoc(docRef)
      return { name: other.name, exists: otherDoc.exists(), docRef }
    }),
  )

  const toBeCreated = missingItems.filter(item => !item.exists)

  await Promise.all(
    toBeCreated.map(async ({ docRef }) => {
      await setDoc(docRef, {}, { merge: true })
    }),
  )

  if (path) {
    const key = path.split('.')[0]
    if (index.propNames.includes(key as keyof Data)) {
      batch.update(doc(getCollectionRef(getBaseCollection(index)), id), path, data)
    } else {
      Object.values(otherCollections).forEach(other => {
        if (other.propNames.includes(key as keyof Data)) {
          batch.update(doc(getCollectionRef(getBaseCollection(other)), id), path, data)
        }
      })
    }
  } else {
    const updated: Record<string, AnyObject> = {}
    Object.entries(data).forEach(([key, value]) => {
      if (index.propNames.includes(key.split('.')[0] as keyof Data)) {
        updated.index = { ...updated.index, [key]: value }
      } else {
        Object.entries(otherCollections).forEach(([name, other]) => {
          if (other.propNames.includes(key.split('.')[0] as keyof Data)) {
            updated[name] = { ...updated[name], [key]: value }
          }
        })
      }
    })
    Object.entries(updated).forEach(([name, value]) => {
      if (name === 'index') {
        const baseIndexCollection = getBaseCollection(index)
        batch.update(
          doc(getCollectionRef(baseIndexCollection), id),
          addMetadata(value, appName, false),
        )
      } else {
        const otherCollection = otherCollections[name]
        const ref = getCollectionRef(otherCollection)
        batch.update(doc(ref, id), addMetadata(value, appName, false))
      }
    })
  }

  await batch.commit()
}

export const updateItem = async <Data extends AnyObject>(
  appName: AppName,
  collection: Collection<Data>,
  id: string,
  path: string,
  data: any,
): Promise<void> => {
  const { _type } = collection

  if (_type === 'combinedCollection') {
    await updateCombinedCollectionItem(appName, collection, id, path, data)
  } else {
    const baseCollection = getBaseCollection(collection)
    if (!baseCollection) throw new Error('No collection')
    const ref = getCollectionRef(collection)
    const docRef = doc(ref, id)
    const docExists = (await getDoc(docRef))?.exists()
    if (docExists && path) {
      await updateDoc(docRef, path, data)
    } else {
      let withoutMetadata: Partial<WithMetadata<Data>> = {}
      if (path) set(withoutMetadata, path, data)
      else withoutMetadata = data
      const updated = addMetadata(withoutMetadata, appName, false)
      await setDoc(docRef, updated, { merge: true })
    }
  }
}

export const deleteItem = async <Data extends AnyObject>(
  collection: Collection<Data>,
  id: string,
): Promise<UpdateCallback> => {
  const { _type } = collection
  if (_type === 'combinedCollection') {
    const combinedCollection = collection as CombinedCollection
    const batch = writeBatch(db)
    Object.values(combinedCollection.otherCollections).forEach(other => {
      const baseOtherCollection = getBaseCollection(other)
      batch.delete(doc(getCollectionRef(baseOtherCollection), id))
    })
    const baseIndexCollection = getBaseCollection(combinedCollection.index)
    batch.delete(doc(getCollectionRef(baseIndexCollection), id))
    return batch
      .commit()
      .catch(err => {
        console.error(err)
        throw new Error(`Error deleting item: ${err.message}`)
      })
      .then(() => ({ success: 'Deleted item' }))
  }

  const baseCollection = getBaseCollection(collection as Collection<AnyObject>)
  if (!baseCollection) throw new Error('No collection')
  const ref = getCollectionRef(collection)
  const docRef = doc(ref, id)
  try {
    await deleteDoc(docRef)
    return { success: 'Deleted item' }
  } catch (err: any) {
    return { error: `Error deleting item: ${err.message}` }
  }
}

export const saveItem = async <Data extends AnyObject>(
  appName: AppName,
  collection: Collection<Data>,
  data: FieldMapValue,
): Promise<UpdateCallback> => {
  if (data.id) {
    return updateItem(appName, collection, data.id, '', data)
      .then(() => ({ success: 'Updated item' }))
      .catch(err => ({ error: `Error updating item: ${err.message}` }))
  }
  return addItem(appName, collection, data).then(id => ({
    success: `Added item with id: ${id}`,
  }))
}
