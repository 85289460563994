import { FieldTypes } from '../constants/FieldTypes'
import {
  DateField,
  DateTimeField,
  DropdownField,
  ListField,
  MultipleSelectField,
  NumberField,
  TextAreaField,
  TextField,
  TimeField,
} from '../types/forms/fields'
import { addMinutesToTimeString } from '../utils/dates'

export const newVisitDateField: DateField = {
  type: FieldTypes.DATE,
  isoFormat: true,
  placeholder: 'Date of Visit',
  defaultToNow: true,
}

export const newVisitStartTimeField: TimeField = {
  type: FieldTypes.TIME,
  placeholder: 'Start Time',
  defaultToNow: true,
}

export const newVisitEndTimeField: TimeField = {
  type: FieldTypes.TIME,
  placeholder: 'End Time',
  presets: values => {
    const { startTime } = values || {}
    if (!startTime) return []
    return [
      { data: addMinutesToTimeString(startTime, 15), title: '15m' },
      { data: addMinutesToTimeString(startTime, 30), title: '30m' },
      { data: addMinutesToTimeString(startTime, 45), title: '45m' },
      { data: addMinutesToTimeString(startTime, 60), title: '1h' },
      { data: addMinutesToTimeString(startTime, 90), title: '1h 30m' },
      { data: addMinutesToTimeString(startTime, 120), title: '2h' },
    ]
  },
}

export const existingVisitStartTimeField: DateTimeField = {
  type: FieldTypes.DATETIME,
  placeholder: 'Start Time',
}

export const existingVisitEndTimeField: DateTimeField = {
  type: FieldTypes.DATETIME,
  placeholder: 'End Time',
}

export const gravidaField: NumberField = {
  type: FieldTypes.NUMBER,
  withStepper: true,
  placeholder: 'Gravida',
}

export const paraField: ListField = {
  horizontalConfig: {
    itemWidth: 170,
  },
  itemFields: {
    withStepper: true,
    type: FieldTypes.NUMBER,
    placeholder: 'Para',
    min: 0,
  },
  itemName: 'Para',
  name: 'Para',
}

export const currentPregnancyField: DropdownField = {
  type: FieldTypes.DROPDOWN,
  options: [
    { id: 'singleton', text: 'Singleton Pregnancy' },
    {
      id: 'singleton-confirmed-ultrasound',
      text: 'Singleton Pregnancy Confirmed via Ultrasound',
    },
    {
      id: 'singleton-presumed',
      text: 'Singleton pregnancy presumed, further assessment in coming weeks to confirm',
    },
  ],
  placeholder: 'Current Pregnancy',
}

export const visitHistoryField: MultipleSelectField = {
  type: FieldTypes.MULTIPLE_SELECT,
  options: [
    { id: 'none', text: 'No history of C/S' },
    { id: 'no-other-surgical-history', text: 'No other surgical history' },
    {
      id: 'prior-pregnancy-vaginal',
      text: 'Prior pregnancy resulting in vaginal delivery',
    },
    { id: 'no-prior-pregnancy', text: 'No prior pregnancy history' },
  ],
  placeholder: 'History',
}

export const historyDetailsField: TextAreaField = {
  type: FieldTypes.TEXTAREA,
  placeholder: 'History Details',
  optional: true,
}

export const deliveryPlanField: DropdownField = {
  type: FieldTypes.DROPDOWN,
  options: [
    {
      id: 'spontaneous-no-induction',
      text: 'Patient aiming for spontaneous, vaginal delivery of newborn, no plan for induction',
    },
    {
      id: 'homebirth-pending',
      text: 'Interested in homebirth, criteria reviewed for home setting, aiming for spontaneous vaginal delivery at home',
    },
  ],
  placeholder: 'Delivery Plan',
}
/*
 The drop-down options should be: 
 1)Should patient’s condition change during labor at home from low to high-risk; or if failure to progress during labor occurs, patient will be transferred to nearest hospital upon assessment. Should patient risk out of care prior to labor, transfer to a higher level of care will be coordinated. 
 2) Should patient risk out of care at any point during pregnancy, coordination of care will occur, including transfer to the nearest hospital if symptoms occur during labor at home that would risk out patient for home delivery.

*/
export const backupDeliveryPlanField: DropdownField = {
  type: FieldTypes.DROPDOWN,
  placeholder: 'Delivery plan in case need for transfer',
  options: [
    {
      text: 'Should patient’s condition change during labor at home from low to high-risk; or if failure to progress during labor occurs, patient will be transferred to nearest hospital upon assessment. Should patient risk out of care prior to labor, transfer to a higher level of care will be coordinated.',
      id: 'transfer',
    },
    {
      text: 'Should patient risk out of care at any point during pregnancy, coordination of care will occur, including transfer to the nearest hospital if symptoms occur during labor at home that would risk out patient for home delivery.',
      id: 'transfer-if-symptoms',
    },
  ],
}

export const deliveryPlanDetailsField: TextAreaField = {
  type: FieldTypes.TEXTAREA,
  placeholder: 'Additional Details',
  optional: true,
}

export const visitHospitalField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Hospital Name',
  hints: [
    {
      text: 'Nearest Hospital within reasonable distance in case need for transfer occurs from home',
    },
  ],
}

export const riskAssessmentField: DropdownField = {
  type: FieldTypes.DROPDOWN,
  options: [
    { id: 'low', text: 'Low Risk, appropriate for midwifery care' },
    { id: 'info-required', text: 'Further assessment needed' },
  ],
  placeholder: 'Risk Assessment',
}

export const keywordsField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Keywords, separated by commas',
  optional: true,
}

export const allergyNotesField: TextAreaField = {
  type: FieldTypes.TEXTAREA,
  placeholder: 'Allergy Notes',
  optional: true,
}
