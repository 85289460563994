import { CloseIcon } from '@chakra-ui/icons'
import { IconButton, Tooltip } from '@chakra-ui/react'
import {
  colors,
  FieldTypes,
  FileDBValue,
  getTemplateValue,
  VariableElement as IVariableElement,
} from '@hb/shared'
import React, { FC, useCallback, useMemo } from 'react'
import { Transforms } from 'slate'
import { ReactEditor, RenderElementProps } from 'slate-react'
import { CustomEditor, CustomRenderElementProps } from '../../../../types/editor'
import { useTemplateData } from '../../../Templates/contexts'
import { useNonEditable } from '../../../Templates/useNonEditable'
import { useElementStyle, useNodeStyle } from '../../hooks'

import { FileVariableElement } from './FileVariableElement'
import './styles.css'
import { getNestedName } from './utils'

// takes a dot-separated path and returns an arrow-separated name with each segment capitalized

export const VariableElement: FC<RenderElementProps & CustomRenderElementProps> = ({
  children,
  element,
  mode,
  attributes,
  editor,
  version,
}) => {
  const node = element as IVariableElement
  const { name, path, optional } = node

  const { shortcutArgs } = useTemplateData()
  const value = useMemo(() => getTemplateValue(node, shortcutArgs), [node, shortcutArgs])
  const style = useElementStyle(mode, node, version)
  const nodeStyle = useNodeStyle(version, node.children[0])

  const { onPointerDown, isSelected } = useNonEditable(editor, node, mode)

  const missingValue = !value
  let borderColor = missingValue ? '#ff5555' : '#dedede'
  if (isSelected) borderColor = colors.blue.hex

  const onDelete = useCallback(() => {
    const location = ReactEditor.findPath(editor, node)
    Transforms.removeNodes(editor as CustomEditor, { at: location })
  }, [editor, node])

  if (node.dataType === FieldTypes.FILE) {
    return (
      <FileVariableElement
        node={node}
        editor={editor}
        isSelected={isSelected}
        onPointerDown={onPointerDown}
        onDelete={onDelete}
        attributes={attributes}
        nodeStyle={style}
        value={value as FileDBValue}
        mode={mode}>
        {children}
      </FileVariableElement>
    )
  }

  if (mode === 'Edit') {
    return (
      <code
        contentEditable={false}
        title={`${getNestedName(path)}${optional ? ' (optional)' : ''}`}
        onPointerDown={onPointerDown}
        style={{
          position: 'relative',
          borderWidth: '2px',
          boxSizing: 'border-box',
          // border: `1px ${optional ? 'dashed #ababab' : 'solid #dedede'}`,
          borderStyle: optional ? 'dashed' : 'solid',
          borderColor,
          borderRadius: '4px',
          cursor: 'pointer',
          alignItems: 'center',
          userSelect: 'none',
          padding: '0 5px',
          ...style,
        }}>
        <span
          {...attributes}
          style={{
            ...nodeStyle,
            userSelect: 'none',
          }}>
          {children}
        </span>
        {isSelected ? (
          <IconButton
            variant="ghost"
            borderRadius="full"
            position="absolute"
            right="-8px"
            top="-8px"
            minW="0"
            ml={1}
            minH="0"
            w="16px"
            title="Remove"
            h="16px"
            color="white"
            bg="red.400"
            onClick={e => {
              e.stopPropagation()
              onDelete()
            }}
            aria-label="Remove variable"
            icon={<CloseIcon width={2} />}
          />
        ) : null}
      </code>
    )
  }
  if (value) {
    return (
      <span
        contentEditable={false}
        {...attributes}
        style={{ userSelect: 'none', ...style, ...nodeStyle }}
        title={name}>
        {value}
        <span style={{ display: 'none' }}>{children}</span>
      </span>
    )
  }
  if (!optional) {
    return (
      <Tooltip placement="top" bg="gray.50" color="red.600" hasArrow label={'Missing value'}>
        <span
          contentEditable={false}
          {...attributes}
          style={{
            userSelect: 'none',
            ...style,
            background: '#cc0000cc',
            border: '1px solid #aa0000',
            borderRadius: '4px',
            color: 'white',
            padding: '0 5px',
          }}>
          <span style={{ ...nodeStyle, color: 'white' }}>{name}</span>
          <span style={{ display: 'none' }}>{children}</span>
        </span>
      </Tooltip>
    )
  }
  return null
}
