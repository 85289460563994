import { Button, ButtonProps } from '@chakra-ui/react'
import { colors } from '@hb/shared'
import React from 'react'

export const InlineLink = (props: ButtonProps) => (
  <Button
    variant="link"
    fontFamily="Open Sans"
    fontWeight={500}
    color={colors.green.hex}
    {...props}
  />
)
