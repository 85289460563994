import { useEffect, useState } from 'react'

export const useRecentlyUploaded = (uploadedOn: number | undefined) => {
  const [recentlyUploaded, setRecentlyUploaded] = useState(false)
  useEffect(() => {
    if (uploadedOn) {
      const now = Date.now()
      if (now - uploadedOn < 2000) {
        setRecentlyUploaded(true)
        setTimeout(() => {
          setRecentlyUploaded(false)
        }, 2000)
      }
    }
  }, [uploadedOn])

  return recentlyUploaded
}
