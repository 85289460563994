import { Fax } from '@hb/shared'

import React, { PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { assignFaxToAssessment } from '../../backend'
import { PopUpMessageContext } from '../../contexts'
import { useDocument, useFile } from '../../hooks'
import { FaxesViewContext } from './context'
import { SelectedFaxPdfModal } from './SelectedFaxPdfModal'
import { FaxData, FaxesViewData, FaxType, UseFaxAssignmentData } from './types'

const useFaxAssignment = (): UseFaxAssignmentData => {
  const [assigningToFaxId, setAssigningToFaxId] = useState<string | null>(null)
  const [assigningToPatientId, setAssigningToPatientId] = useState<string | null>(null)
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const { processResponse } = useContext(PopUpMessageContext)

  const assignToFax = useCallback(
    (assignedId: string | null) => {
      if (assigningToPatientId || assigningToFaxId) {
        if (!assignedId) {
          setAssigningToFaxId(null)
          setAssigningToPatientId(null)
          setError(null)
        }
        return
      }
      setAssigningToFaxId(assignedId)
    },
    [assigningToFaxId, assigningToPatientId],
  )

  const assignToPatient = useCallback(
    (patientId: string | null) => {
      if (!assigningToFaxId) return
      setAssigningToPatientId(patientId)
    },
    [assigningToFaxId],
  )

  const unassignAssessmentFromFax = useCallback(
    async (faxId: string) => {
      try {
        // unassign assessment from fax
        await assignFaxToAssessment({ assessmentId: null, faxId })
      } catch (e: any) {
        return processResponse({
          error: e?.message || 'Error occurred while unassigning assessment from fax',
        })
      }
      return processResponse({
        success: 'Assessment unassigned from fax successfully',
      })
    },
    [processResponse],
  )

  const onSubmit = useCallback(
    async (assessmentId: string) => {
      if (!assigningToFaxId) {
        setError('Please select a fax')
        return { error: 'Please select a fax' }
      }
      setSubmitting(true)
      try {
        await assignFaxToAssessment({ assessmentId, faxId: assigningToFaxId })
        setAssigningToFaxId(null)
        setAssigningToPatientId(null)
      } catch (e: any) {
        setSubmitting(false)
        setError(e?.message)
        return processResponse({
          error: e?.message || 'Error occurred while assigning fax to assessment',
        })
      } finally {
        setSubmitting(false)
      }
      return processResponse({
        success: 'Fax assigned to assessment successfully',
      })
    },
    [assigningToFaxId, processResponse],
  )

  return useMemo(
    () => ({
      assigningToFaxId,
      assigningToPatientId,
      submitting,
      error,
      assignToFax,
      unassignAssessmentFromFax,
      assignToPatient,
      onSubmit,
    }),
    [
      assigningToFaxId,
      assigningToPatientId,
      submitting,
      error,
      assignToFax,
      unassignAssessmentFromFax,
      assignToPatient,
      onSubmit,
    ],
  )
}

export const useFaxesViewData = (): FaxesViewData => {
  const { pathname } = useLocation()
  const { faxId } = useParams<{ faxId?: string }>()
  const history = useHistory()
  const [, , type] = useMemo(() => pathname.split('/').filter(x => !!x), [pathname])
  const [viewedAssessmentId, setViewedAssessmentId] = useState<string | null>(null)

  const tabIndex = useMemo(() => {
    // ignore admin prefix and fax prefix
    if (type === 'received') return 1
    return 0
  }, [type])

  const collectionPath = useMemo(() => (type === 'received' ? 'fax-received' : 'fax-sent'), [type])
  const { data: fax, loading: pathLoading } = useDocument<Fax>(collectionPath, faxId, !!faxId)
  const { loading, url } = useFile({ path: fax?.storagePath, pathLoading })

  const faxAssignment = useFaxAssignment()

  return useMemo(
    () => ({
      viewedFax: fax,
      viewFax: (data: FaxData) => history.push(`/admin/fax/${data.type}/${data.id}`),
      closeFax: () => history.push(`/admin/fax/${type}`),
      viewedFile: url,
      viewedAssessmentId,
      viewAssessment: (assessmentId: string | null) => setViewedAssessmentId(assessmentId),
      tabIndex: tabIndex || 0,
      onChangeTab: (index: number) => {
        if (index === 0) history.push('/admin/fax/sent')
        else history.push('/admin/fax/received')
      },
      loading,
      faxId,
      faxData:
        faxId && type
          ? {
              id: faxId,
              type: type as FaxType,
            }
          : null,
      faxAssignment,
    }),
    [fax, faxId, faxAssignment, loading, tabIndex, type, url, viewedAssessmentId, history],
  )
}

export const FaxesViewProvider = ({ children }: PropsWithChildren) => {
  const data = useFaxesViewData()
  return (
    <FaxesViewContext.Provider value={data}>
      {children}
      <SelectedFaxPdfModal
        onClose={data.closeFax}
        viewedFile={data.viewedFile}
        viewedFax={data.viewedFax}
      />
    </FaxesViewContext.Provider>
  )
}
