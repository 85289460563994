import { Center, CircularProgress, Flex } from '@chakra-ui/react'
import { AnyObject, getReverseName, isListDividerItem, ListItem, WithId } from '@hb/shared'
import React, { forwardRef, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { GridChildComponentProps, VariableSizeGrid } from 'react-window'
import { useApp } from '../../contexts/AppContext'
import { SearchBarContext } from '../../contexts/SearchBarContext'
import { DataGridContext } from '../../contexts/UserGridContext'
import { usePopulatedItem } from '../../hooks/backend/usePopulatedItem'
import { useAuth } from '../../store'
import { DataTableProps, UserCellComponentProps } from '../../types/data'
import { getRowBackground } from '../DataView/utils'
import { ListDividerView } from './dividers/ListDividerView'
import { usePreview } from './hooks'
import { DesktopRowHeaders } from './RowHeaders'
import { getFlexColumnWidth, getItemsWithDividers } from './utils'

const UserCell = <Snippet extends WithId, ExtraColumnProps extends AnyObject = AnyObject>({
  columnIndex,
  rowIndex,
  style,
  isScrolling,
  data,
}: GridChildComponentProps<UserCellComponentProps<Snippet, ExtraColumnProps>>) => {
  const {
    items,
    columns,
    preview,
    auth,
    app,
    tabName,
    tab,
    onRowClick,
    listWidth,
    extraColumnProps,
    searchQuery,
    admins,
  } = data
  const { Render: render, flexProps, width } = columns[Object.keys(columns)[columnIndex]]
  const item = items[rowIndex]
  if (isListDividerItem(item)) {
    if (columnIndex === 0) {
      return (
        <div style={{ ...style, width: `${listWidth}px` }}>
          <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <ListDividerView item={item} />
          </div>
        </div>
      )
    }
    return null
  }
  const background = tab.getItemBackgroundColor
    ? tab.getItemBackgroundColor(item, rowIndex)
    : getRowBackground(rowIndex)
  return (
    <Flex
      bg={background}
      px={1}
      flex={width ? undefined : 1}
      overflow="hidden"
      width={`${width || 0}px`}
      fontSize="sm"
      align="center"
      onClick={onRowClick ? () => onRowClick(item.id) : undefined}
      key={`${columnIndex}_${rowIndex}`}
      {...flexProps}
      style={{ ...flexProps?.style, ...style }}
      _hover={
        onRowClick
          ? {
              cursor: 'pointer',
              bg: 'gray.100',
              ...flexProps?._hover,
            }
          : flexProps?._hover
      }>
      {render({
        data: item,
        preview,
        tabName,
        auth,
        admins,
        app,
        isScrolling,
        searchQuery,
        cell: { columnIndex, rowIndex },
        ...extraColumnProps,
      })}
    </Flex>
  )
}

export const outerElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const { closePreview } = useContext(DataGridContext)
  return <div onWheel={closePreview} ref={ref} {...props} />
})

export const DesktopDataList = forwardRef<
  VariableSizeGrid<UserCellComponentProps<WithId<AnyObject>>>,
  DataTableProps<any, any>
>(
  (
    {
      items,
      itemHeight = 36,
      columns,
      onRowClick,
      collection,
      tabName,
      tab,
      admins,
      width,
      fullWidth,
      sortKey,
      extraColumnProps,
      loading,
      sortAsc,
      height,
    },
    ref,
  ) => {
    const [displayedMessage, setDisplayedMessage] = useState('')
    const [scrollTop, setScrollTop] = useState(0)
    const previewState = usePreview()
    const { preview } = previewState
    const { searchQuery } = useContext(SearchBarContext)
    const { arr, map } = useMemo(() => {
      const values = Object.entries(items || {}).map(([key, val]) => ({
        ...val,
        id: key,
      }))
      return {
        arr: values as Array<WithId<ListItem>>,
        map: values.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.id]: curr,
          }),
          {} as Record<string, WithId<ListItem>>,
        ),
      }
    }, [items])
    const previewItem = useMemo(() => (preview?.id ? map[preview.id] : null), [preview, map])
    const populated = usePopulatedItem(previewItem, collection)
    const reverseName = useMemo(() => getReverseName(populated), [populated])
    const auth = useAuth()
    const app = useApp()

    const flexColWidth = useMemo(() => getFlexColumnWidth({ width, columns }), [columns, width])

    const [remounting, setRemounting] = useState(false)
    useEffect(() => {
      setRemounting(true)
      const timeout = setTimeout(() => {
        setTimeout(() => setRemounting(false), 100)
      }, 500)
      return () => clearTimeout(timeout)
    }, [flexColWidth, tab])

    const itemsWithDividers = useMemo(
      () => getItemsWithDividers(arr, columns, sortKey, sortAsc ? 'asc' : 'desc'),
      [arr, columns, sortKey, sortAsc],
    )

    const itemData = useMemo<UserCellComponentProps<any>>(
      () => ({
        columns,
        auth,
        items: itemsWithDividers,
        app,
        tabName,
        listHeight: height,
        listWidth: width,
        onRowClick,
        tab,
        flexColWidth,
        searchQuery,
        extraColumnProps,
        preview: previewState,
        admins,
      }),
      [
        app,
        auth,
        columns,
        flexColWidth,
        height,
        width,
        previewState,
        searchQuery,
        tab,
        tabName,
        admins,
        onRowClick,
        extraColumnProps,
        itemsWithDividers,
      ],
    )

    // const keys = Object.keys(columns)
    // const numCols = keys.length
    const { keys, numCols } = useMemo(() => {
      const ks = Object.keys(columns)
      return { keys: ks, numCols: ks.length }
    }, [columns])

    const rowHeight = useCallback(
      (idx: number) => {
        if (isListDividerItem(itemsWithDividers[idx])) return 30
        return typeof itemHeight === 'number' ? itemHeight : itemHeight(itemsWithDividers[idx])
      },
      [itemsWithDividers, itemHeight],
    )

    return (
      <DataGridContext.Provider
        value={{
          data: map || null,
          columns,
          item: populated,
          collection,
          display: setDisplayedMessage,
          width,
          scrollTop,
          height,
          ...previewState,
          itemHeight,
          // item: populated,
          reverseName,
          clearMessage: () => setDisplayedMessage(''),
        }}>
        <Flex w="100%" pos="relative" direction="column">
          <Flex bg="gray.50" borderBottom="1px solid #cdcdcd" w="100%" pos="relative">
            <DesktopRowHeaders keys={keys} columns={columns} flexColWidth={flexColWidth} />
            <Flex
              opacity={displayedMessage ? 1 : 0}
              pos="absolute"
              right="10px"
              shadow="md"
              p={2}
              zIndex={3}
              bg="white"
              borderRadius="md">
              {displayedMessage}
            </Flex>
          </Flex>
          {remounting || loading ? (
            <Center width={`${width}px`} height={`${height}px`}>
              <CircularProgress isIndeterminate color="#777" size={10} />
            </Center>
          ) : (
            <VariableSizeGrid
              ref={ref}
              outerElementType={outerElementType}
              itemKey={e => `${e.rowIndex}_${e.columnIndex}`}
              style={{
                background: 'white',
                // overflow: 'overlay',
                transition: 'height 500ms',
                overflowX: 'hidden',
              }}
              onScroll={e => {
                previewState.closePreview()
                setScrollTop(e.scrollTop)
              }}
              itemData={itemData}
              columnCount={numCols}
              useIsScrolling
              rowCount={itemsWithDividers.length}
              rowHeight={rowHeight}
              width={fullWidth}
              height={height}
              columnWidth={(index: number) => columns[keys[index]].width || flexColWidth}>
              {UserCell}
            </VariableSizeGrid>
          )}
        </Flex>
      </DataGridContext.Provider>
    )
  },
)
