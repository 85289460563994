import { CloseIcon } from '@chakra-ui/icons'
import {
  Center,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import {
  CheckboxField,
  colors,
  DropdownField,
  DropdownOptionItem,
  FieldTypes,
  getFullName,
  LocalCollectionState,
  UserRoleItem,
} from '@hb/shared'
import React, { useCallback, useContext, useMemo, useRef } from 'react'
import { DataListContext } from '../../../../contexts/DataListContext'
import { StandaloneInput } from '../../../forms'
import { InputRef } from '../../../forms/Input/types'
import { SortColorCircle } from '../../../Users/UrgentBadge'

const colorOptions: DropdownOptionItem[] = Object.entries(colors).map(([name, { hex }]) => ({
  id: hex,
  text: name,
}))

const baseColorField: DropdownField = {
  type: FieldTypes.DROPDOWN,
  placeholder: 'Color',
  options: colorOptions,
  optional: true,
}

const urgentField: CheckboxField = {
  type: FieldTypes.CHECKBOX,
  placeholder: 'Urgent',
}

const ColorOption = ({
  admins,
  option,
}: {
  admins: LocalCollectionState<UserRoleItem>
  option: DropdownOptionItem
}) => {
  const admin = admins.items?.find(a => a.adminColor === option.id)

  return (
    <Flex align="center" gap={2} w="100%">
      <SortColorCircle admins={admins} color={option.id} />
      <Text opacity={0.8}>{admin ? getFullName(admin) : 'No admin assigned'}</Text>
    </Flex>
  )
}

export const ListUrgentHeader = ({
  sortKey = 'urgentSort',
  urgentKey,
}: {
  sortKey?: string
  urgentKey?: string
}) => {
  const { filters, filterBy, removeFilter, admins } = useContext(DataListContext)
  // const { urgentColor = colors.red.hex } = getIsUrgent(item) || {}

  const colorFilter = useMemo(() => filters.find(f => f[0] === sortKey), [filters, sortKey])

  const urgentFilter = useMemo(
    () => (urgentKey ? filters.find(f => f[0] === urgentKey) : null),
    [filters, urgentKey],
  )

  const colorValue = useMemo(() => (colorFilter ? colorFilter[2] : null), [colorFilter])

  const colorField = useMemo<DropdownField>(
    () => ({
      ...baseColorField,
      options: colorOptions.sort((a, b) => {
        const aAdmin = admins.items.find(i => i.adminColor === a.id)
        const bAdmin = admins.items.find(i => i.adminColor === b.id)
        if (aAdmin && bAdmin) {
          return (aAdmin.fname || '').localeCompare(bAdmin.lname || '')
        }
        if (aAdmin) return -1
        if (bAdmin) return 1
        return 0
      }),
      renderOption: option => <ColorOption admins={admins} option={option} />,
    }),
    [admins],
  )
  const handleColorSelect = useCallback(
    (color: string | null) => {
      if (color) {
        filterBy([sortKey, '==', color])
      } else {
        removeFilter(sortKey)
      }
    },
    [filterBy, removeFilter, sortKey],
  )

  const handleUrgentToggle = useCallback(
    (urgent: boolean) => {
      if (!urgentKey) return
      if (urgent) {
        filterBy([urgentKey, '==', true])
      } else {
        removeFilter(urgentKey)
      }
    },
    [filterBy, removeFilter, urgentKey],
  )

  const inputRef = useRef<InputRef>(null)

  return (
    <Center px={1}>
      <Popover
        placement="right"
        onOpen={() => {
          setTimeout(() => {
            if (inputRef.current && !colorValue) inputRef.current.focus()
          }, 100)
        }}
        // closeOnBlur={false}
        isLazy
        strategy="fixed">
        {({ onClose }) => (
          <>
            <PopoverTrigger>
              <SortColorCircle
                admins={admins}
                mx="auto"
                color={colorValue}
                // urgent
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <Flex py={2} direction="column" px={2} w="100%">
                <Flex gap={1} pr={1}>
                  <Flex flex={1} minW="0">
                    <StandaloneInput
                      field={colorField}
                      value={colorValue}
                      ref={inputRef}
                      // theme='detailed'
                      onChange={handleColorSelect}
                    />
                  </Flex>
                  <IconButton
                    variant="ghost"
                    w={6}
                    h={6}
                    minW={0}
                    aria-label="close"
                    size="xs"
                    borderRadius="full"
                    onClick={onClose}
                    icon={<CloseIcon w={2.5} h={2.5} color="gray.500" />}
                  />
                </Flex>
                <Flex px={2}>
                  {urgentKey ? (
                    <StandaloneInput
                      field={urgentField}
                      value={!!urgentFilter}
                      onChange={handleUrgentToggle}
                    />
                  ) : null}
                </Flex>
              </Flex>
            </PopoverContent>
          </>
        )}
      </Popover>
    </Center>
  )
}
