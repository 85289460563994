import FocusLock from '@chakra-ui/focus-lock'
import {
  Box,
  Button,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverTrigger,
  Stack,
  StackDivider,
  Text,
} from '@chakra-ui/react'
import { Log, LogEntry } from '@hb/shared'

import React, { useCallback, useContext, useMemo } from 'react'

import { doc } from 'firebase/firestore'
import { db } from '../../../../backend'
import { DataGridContext } from '../../../../contexts'
import { useLogInfo, useUpdateDoc } from '../../../../hooks'
import { GridItemPreviewContent } from '../../../DataView/GridItemPreviewContent'
import { LogEntryPopover, LogEntryView } from '../../../Users/Profile/Log/LogEntry'

export const LogColumnPopover = () => {
  const { preview, item, closePreview, reverseName, collection } = useContext(DataGridContext)
  const { log } = (item as { log?: Log }) || {}
  const { lastCreatedKey, lastCreated, lastUpdated, lastUpdatedKey } = useLogInfo(log)

  const updateCollection = useMemo(() => {
    switch (collection) {
      case 'assessment-snippets':
        return 'assessments-admin'
      case 'claims':
        return 'claims'
      default:
        return ''
    }
  }, [collection])
  const update = useUpdateDoc()
  const onSave = useCallback(
    async (updated: LogEntry, createdOn?: number) => {
      if (!preview) return { error: 'no id' }
      if (!updateCollection) return { error: 'no update collection' }
      const docRef = doc(db, updateCollection, preview.id)
      return update(docRef, 'log', {
        [createdOn || Date.now()]: updated,
      })
    },
    [updateCollection, preview, update],
  )
  return (
    <Popover
      onClose={closePreview}
      isLazy
      isOpen={!!item && preview?.item === 'log'}
      closeOnBlur={false}
      trigger="click">
      <PopoverTrigger>
        <Text>LOG</Text>
      </PopoverTrigger>
      <GridItemPreviewContent p={2} placement="left" previewId="log">
        <FocusLock persistentFocus={false} restoreFocus>
          <PopoverCloseButton />
          <PopoverArrow />
          <Text px={2}>{reverseName}</Text>
          {log && lastCreatedKey ? (
            <Stack width="349px" p={2} divider={<StackDivider />}>
              <Box>
                <Text fontWeight={500} color="blackAlpha.600" fontSize="sm">
                  Last created
                </Text>
                <LogEntryView
                  placement="left"
                  update={updated => onSave(updated, lastCreatedKey)}
                  createdOn={lastCreatedKey}
                  {...lastCreated!}
                />
              </Box>
              {lastUpdatedKey && lastCreatedKey !== lastUpdatedKey ? (
                <Box>
                  <Text fontWeight={500} color="blackAlpha.600" fontSize="sm">
                    Last updated
                  </Text>
                  <LogEntryView
                    update={updated => onSave(updated, lastUpdatedKey)}
                    createdOn={lastUpdatedKey}
                    {...lastUpdated!}
                  />
                </Box>
              ) : null}
            </Stack>
          ) : (
            <Text color="gray.399" pt={2} px={4}>
              No log entries
            </Text>
          )}

          <LogEntryPopover isNew placement="left" onSubmit={added => onSave(added)}>
            <Button
              aria-label="add log entry"
              ml="auto"
              fontWeight={499}
              size="xs"
              height="auto"
              py={1}
              color="gray.499"
              m={1}
              variant="outline">
              + ADD ENTRY
            </Button>
          </LogEntryPopover>
        </FocusLock>
      </GridItemPreviewContent>
    </Popover>
  )
}
