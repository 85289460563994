import { UserWithPracticeData } from '../../types/users/admin'
import { UserRoleItem } from '../../types/users/role'
import { adminCollectionAccess, practiceCollectionAccess } from '../constants'
import { MIDWIVES, USER_ROLES } from '../names'
import { Collection } from '../types/collection'

export const getPracticePatientDocPath = (midwifeId: string, patientId: string) =>
  `${MIDWIVES}/${midwifeId}/${USER_ROLES}/${patientId}`

export const getPracticeUsersCollectionPath = (midwifeId: string) =>
  `${MIDWIVES}/${midwifeId}/${USER_ROLES}`

export const patientsCollection: Collection<UserRoleItem> = {
  id: 'patients',
  _type: 'firestoreCollection',
  name: 'Patients',
  refPath: USER_ROLES,
  access: adminCollectionAccess,
  filters: [['role', '==', 'user']],
  noRanks: true,
  defaultSort: 'lname',
  shouldNotFetchAll: true,
}

export const getPracticePatientsCollection: (
  practiceId: string,
) => Collection<UserWithPracticeData> = practiceId => ({
  id: 'patients',
  _type: 'firestoreCollection',
  name: 'Patients',
  refPath: getPracticeUsersCollectionPath(practiceId),
  access: practiceCollectionAccess,
  filters: [['role', '==', 'user']],
  noRanks: true,
  defaultSort: 'lname',
  shouldNotFetchAll: true,
})
