import { Center, Collapse, Flex, Text, VStack } from '@chakra-ui/react'
import { UploadProgress } from '@hb/shared'
import React from 'react'
import { useArrayFromRecord } from '../../../hooks/useRecordFromArray'
import { AnimatedLoadingBar } from '../../shared/AnimatedLoadingBar'

export const UploadProgressView = ({
  uploads,
}: {
  uploads?: Record<string, UploadProgress> | null
}) => {
  const uploadArr = useArrayFromRecord(uploads || {})
  return (
    <Collapse style={{ width: '100%' }} in={!!uploadArr.length}>
      <VStack spacing={1} w="100%">
        {uploadArr.map(({ id, progress, label, index }) => (
          <Flex key={id} w="100%" p={2}>
            <Center
              overflow="hidden"
              borderRadius="full"
              position="relative"
              bg="gray.300"
              w="100%">
              <AnimatedLoadingBar progress={progress} />
              <Text
                textShadow="1px 1px 3px #00000077"
                fontFamily="Open Sans"
                zIndex={2}
                fontSize="sm"
                maxW="100%"
                minW="0"
                isTruncated
                color="white"
                position="absolute">
                {label}
                {index !== undefined ? ` ${index + 1}` : ''}
              </Text>
            </Center>
          </Flex>
        ))}
      </VStack>
    </Collapse>
  )
}
