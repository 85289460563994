import { Flex, Text } from '@chakra-ui/react'
import { ListDividerItem } from '@hb/shared'
import React, { CSSProperties } from 'react'

export const ListDividerView = ({
  item,
  style,
  mobileLayout,
}: {
  item: ListDividerItem
  style?: CSSProperties
  mobileLayout?: boolean
}) => (
  <Flex
    align="center"
    // bg={'#e1e1e1'}
    bg="#ffaead"
    position="relative"
    borderTop="1px solid #cdcdcd"
    borderBottom="1px solid #cdcdcd"
    left={0}
    justify={mobileLayout ? 'center' : 'flex-start'}
    height="100%"
    width="100%"
    px={3}
    style={style}>
    <Text as="span" textShadow="1px 1px 3px #00000066" fontSize="sm" fontWeight={600} color="white">
      {item.label.toUpperCase()}
    </Text>
  </Flex>
)
