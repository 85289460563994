import { Text, TextProps } from '@chakra-ui/react'
import React from 'react'

export const Subheader = (props: TextProps) => (
  <Text fontFamily="Hero-New" color="gray.600" {...props} />
)

export const Subheader2 = (props: TextProps) => (
  <Text fontFamily="Open Sans" color="gray.600" fontSize="sm" {...props} />
)
