const hbSites = [
  'hamiltonbilling.com',
  'hamiltonbilling-staging.web.app',
  'hbportal-staging.web.app',
  'localhost',
  'localhost',
  'localhost',
]

export const isHBPathname = (hostname: string) => hbSites.includes(hostname)
export const isHBSite = (url: string) => {
  try {
    const { hostname } = new URL(url)
    return isHBPathname(hostname)
  } catch (_e) {
    return false
  }
}
