import { EditIcon } from '@chakra-ui/icons'
import { Box, Collapse, Flex, HStack, IconButton, Text, VStack } from '@chakra-ui/react'
import {
  capitalizeFirstLetter,
  Comment,
  getDateTimeString,
  makePlural,
  objectToArray,
} from '@hb/shared'
import { FORM_ERROR } from 'final-form'
import { DocumentReference } from 'firebase/firestore'
import React, { useMemo, useState } from 'react'
import { useAuth } from '../../store'
import { ActionLog } from '../ActionLog'
import { ActionButton, DeleteButton } from '../Buttons'
import { Editable, SimpleForm } from '../forms'
import { Reactions } from '../Reactions'
import { commentField, commentTextField } from './constants'
import { useSaveComment } from './hooks'
import { deleteComment, editComment } from './utils'

export const CommentsView = ({
  comments,
  docRef,
  fieldPath,
  noReplies,
  itemName = 'comment',
}: {
  comments: Record<string, Comment>
  docRef: DocumentReference
  fieldPath: string
  noReplies?: boolean
  itemName?: string
}) => {
  const [addingComment, setAddingComment] = useState(false)
  const commentsArray = useMemo(
    () => objectToArray(comments).sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10)),
    [comments],
  )
  const { saveComment, savingComment } = useSaveComment(docRef, fieldPath)
  return (
    <VStack pb={2} align="flex-start" spacing={noReplies ? 1 : 0} w="100%">
      <HStack w="100%">
        <Text fontSize="sm" fontWeight={600} fontFamily="Open Sans" color="gray.500">
          {capitalizeFirstLetter(makePlural(itemName))}
        </Text>
        <ActionButton
          bg="white"
          onClick={() => setAddingComment(!addingComment)}
          ml="auto"
          isLoading={savingComment}
          size="xs">
          {addingComment
            ? `- ADDING ${makePlural(itemName).toUpperCase()}`
            : `+ ADD ${itemName.toUpperCase()}`}
        </ActionButton>
      </HStack>
      <VStack
        bg={noReplies ? 'gray.50' : 'transparent'}
        border={noReplies ? '1px solid #cdcdcd' : 'none'}
        borderRadius={4}
        w="100%">
        {commentsArray.length ? (
          commentsArray.map(comment => (
            <CommentView
              noReplies={noReplies}
              key={comment.id}
              docRef={docRef}
              comment={comment}
              fieldPath={`${fieldPath}.${comment.id}`}
            />
          ))
        ) : (
          <Text fontStyle="italic" fontSize="sm" fontFamily="Open Sans" color="gray.600">
            No {makePlural(itemName)} yet
          </Text>
        )}
      </VStack>
      <Collapse unmountOnExit style={{ width: '100%' }} in={addingComment}>
        <Flex flexDirection="column" gap={1} p={1} w="100%" bg="gray.50">
          <SimpleForm
            theme="detailed"
            onCancel={() => setAddingComment(false)}
            field={commentField}
            boxProps={{ p: 0 }}
            onSubmit={async values => {
              setAddingComment(true)
              try {
                await saveComment(null, values.text)
              } catch (err: any) {
                console.error(err)
                return {
                  [FORM_ERROR]: err?.message || 'An error occurred adding the comment',
                }
              }
              setAddingComment(false)
              return undefined
            }}
          />
        </Flex>
      </Collapse>
    </VStack>
  )
}

export const CommentView = ({
  comment: {
    createdBy,
    createdOn,
    text,
    updatedOn,
    updatedBy,
    updatedByGroup,
    createdByGroup,
    reactions,
    editedOn,
    deletedOn,
    replies,
  },
  noReplies,
  docRef,
  fieldPath,
}: {
  comment: Comment
  docRef: DocumentReference
  noReplies?: boolean
  fieldPath: string
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const authUser = useAuth(s => s.authUser)
  const { uid } = authUser || {}
  return (
    <VStack
      borderRadius={4}
      spacing={2}
      bg={noReplies ? 'whiteAlpha.100' : 'transparent'}
      w="100%"
      p={2}>
      <HStack w="100%">
        <ActionLog action="Posted" by={createdBy} on={createdOn} group={createdByGroup} />
        <Flex gap={1} ml="auto">
          {updatedBy !== createdBy ? (
            <ActionLog action="Updated" by={updatedBy} on={updatedOn} group={updatedByGroup} />
          ) : null}
          {uid === createdBy ? (
            <IconButton
              size="xs"
              variant="ghost"
              aria-label="Edit comment"
              icon={<EditIcon />}
              onClick={() => setIsEditing(!isEditing)}
            />
          ) : null}
          {uid === createdBy ? (
            <DeleteButton onDelete={() => deleteComment(docRef, fieldPath)} itemName="comment" />
          ) : null}
        </Flex>
      </HStack>
      {isEditing ? (
        <Editable
          value={text}
          initEditing
          closeCallback={() => setIsEditing(false)}
          onSubmit={val => editComment(val, docRef, fieldPath)}
          field={commentTextField}
        />
      ) : (
        <VStack
          w="100%"
          bg={noReplies ? 'white' : '#fcfcfc'}
          align="flex-start"
          border="1px solid #cdcdcd"
          p={1}
          px={2}
          spacing={0}
          borderRadius={4}>
          {deletedOn ? (
            <Text fontStyle="italic" fontSize="sm" fontFamily="Open Sans" color="gray.600">
              Comment deleted
            </Text>
          ) : (
            <Text whiteSpace="pre-wrap" fontSize="sm" fontFamily="Open Sans">
              {text}
            </Text>
          )}
          <HStack w="100%">
            {editedOn ? (
              <Text
                fontSize="xs"
                fontFamily="Open Sans"
                pt="0.1rem"
                whiteSpace="nowrap"
                color="gray.500"
                fontStyle="italic">
                Edited {getDateTimeString(editedOn, 'short')}
              </Text>
            ) : null}
            <Reactions docRef={docRef} fieldPath={`${fieldPath}.reactions`} reactions={reactions} />
          </HStack>
        </VStack>
      )}
      {noReplies ? null : (
        <Box px={1} py={1} w="100%">
          <Box w="100%" pl={2} borderLeft="1px solid #cdcdcd">
            <CommentsView
              noReplies
              itemName="reply"
              comments={replies}
              docRef={docRef}
              fieldPath={`${fieldPath}.replies`}
            />
          </Box>
        </Box>
      )}
    </VStack>
  )
}
