import { baseMidwifeFields } from '../../collections'
import { stateField } from '../../fields'
import { Field, FileDBValue, PracticeWithAdmin, ShortcutArgs, WithAlternate } from '../../types'
import { addressToString, formatDropdownValue } from '../data'
import { formatPhoneNumber, getFieldFromPath } from '../fields'
import { resolveProviderClaimsAddress } from '../providers'
import { getFieldValue } from './utils'

const getMidwifeNameValue = (path: string, shortcutArgs: ShortcutArgs): string | undefined => {
  switch (path) {
    case 'fname':
      return shortcutArgs?.practice?.fname
    case 'lname':
      return shortcutArgs?.practice?.lname
    case 'name':
      return shortcutArgs?.practice?.name
    default:
      return undefined
  }
}

const getMidwifeNpiValue = (path: string, shortcutArgs: ShortcutArgs): string | undefined => {
  const practice = shortcutArgs?.practice as PracticeWithAdmin
  switch (path) {
    case 'referringNpi':
      return practice?.npi
    case 'billingNpi':
      return practice?.isGroup ? practice?.group?.npi : practice?.npi
    default:
      return undefined
  }
}

const getClaimsAddress = (shortcutArgs: ShortcutArgs) => {
  const { midwife } = shortcutArgs?.assessment || {}
  const resolvedClaimsAddress = resolveProviderClaimsAddress(midwife)
  return resolvedClaimsAddress ? addressToString(resolvedClaimsAddress) : undefined
}

const getCorporateAddress = (shortcutArgs: ShortcutArgs) => {
  const { midwife } = shortcutArgs?.assessment || {}
  return midwife?.corporateAddress ? addressToString(midwife?.corporateAddress) : undefined
}

const getFirstServicingAddress = (shortcutArgs: ShortcutArgs) => {
  const { midwife } = shortcutArgs?.assessment || {}
  return midwife?.servicingAddresses?.length
    ? addressToString(midwife?.servicingAddresses[0])
    : undefined
}

const getMidwifeAddressValue = (path: string, shortcutArgs: ShortcutArgs): string | undefined => {
  switch (path) {
    case 'claimsAddress':
      return getClaimsAddress(shortcutArgs)
    case 'corporateAddress':
      return getCorporateAddress(shortcutArgs)
    case 'servicingAddress':
      return getFirstServicingAddress(shortcutArgs)
    default:
      return undefined
  }
}

const getMidwifeCollaborativePhysicianValue = (
  path: string,
  shortcutArgs: ShortcutArgs,
): string | undefined => {
  const { midwife } = shortcutArgs?.assessment || {}
  const [indexStr, fieldName] = path.split('.')
  const index = parseInt(indexStr, 10)
  const npiValue = midwife?.collaborativePhysicians?.[index]?.npi as any
  if (Number.isNaN(index)) return undefined
  switch (fieldName) {
    case 'npi':
      return typeof npiValue === 'string' ? npiValue : (npiValue as WithAlternate<string>)?.main
    case 'name':
      return midwife?.collaborativePhysicians?.[index]?.name
    default:
      return undefined
  }
}

const getMidwifeAffiliatedHospitalValue = (
  path: string,
  shortcutArgs: ShortcutArgs,
): string | undefined => {
  const { midwife } = shortcutArgs?.assessment || {}
  const [indexStr, fieldName] = path.split('.')
  const index = parseInt(indexStr, 10)
  const hospitalAddress = midwife?.affiliatedHospitals?.[index]?.serviceAddress
  const hospitalPhone = midwife?.affiliatedHospitals?.[index]?.phone
  if (Number.isNaN(index)) return undefined
  switch (fieldName) {
    case 'name':
      return midwife?.affiliatedHospitals?.[index]?.name
    case 'npi':
      return midwife?.affiliatedHospitals?.[index]?.npi
    case 'phone':
      return hospitalPhone ? formatPhoneNumber(hospitalPhone) : undefined
    case 'address':
      return hospitalAddress ? addressToString(hospitalAddress) : undefined
    case 'address2':
      return hospitalAddress ? addressToString(hospitalAddress, true) : undefined
    default:
      return undefined
  }
}

const getMidwifeLicenseValue = (path: string, shortcutArgs: ShortcutArgs): string | undefined => {
  const { midwife } = shortcutArgs?.assessment || {}
  const [indexStr] = path.split('.')
  const index = parseInt(indexStr, 10)
  if (Number.isNaN(index)) return undefined
  const license = midwife?.licenses?.[index]
  if (!license) return undefined
  const licenseState = formatDropdownValue(license.state, stateField.options)
  const credentials =
    Object.entries(license.credentials || {})
      .filter(([, v]) => v)
      .map(([k]) => k) || 'None'
  return `${licenseState} license #: ${license.number} | Credentials: ${credentials}`
}

const getReferringMedicaidIdValue = (
  path: string,
  shortcutArgs: ShortcutArgs,
): string | undefined => {
  const { midwife } = shortcutArgs?.assessment || {}
  const [indexStr] = path.split('.')
  const index = parseInt(indexStr, 10)
  if (Number.isNaN(index)) return undefined
  const medicaidId = midwife?.medicaidIds?.[index]
  if (!medicaidId) return undefined
  const state = formatDropdownValue(medicaidId.state, stateField.options)
  return `${state} Medicaid ID: ${medicaidId.id}`
}

const getGroupMedicaidIdValue = (path: string, shortcutArgs: ShortcutArgs): string | undefined => {
  const { midwife } = shortcutArgs?.assessment || {}
  const [indexStr] = path.split('.')
  const index = parseInt(indexStr, 10)
  if (Number.isNaN(index)) return undefined
  const medicaidId = midwife?.group?.medicaidIds?.[index]
  if (!medicaidId) return undefined
  const medicaidState = formatDropdownValue(medicaidId.state, stateField.options)
  return `${medicaidState} Medicaid ID: ${medicaidId.id}`
}

const getMidwifeMedicaidValue = (path: string, shortcutArgs: ShortcutArgs): string | undefined => {
  const [fieldPath, ...rest] = path.split('.')
  switch (fieldPath) {
    case 'referringMedicaidIds':
      return getReferringMedicaidIdValue(rest.join('.'), shortcutArgs)
    case 'groupMedicaidIds':
      return getGroupMedicaidIdValue(rest.join('.'), shortcutArgs)
    default:
      return undefined
  }
}

const getFallbackMidwifeValue = (
  path: string,
  shortcutArgs: ShortcutArgs,
  fieldType: any,
): string | undefined => {
  const field = getFieldFromPath(baseMidwifeFields, path) || {
    type: fieldType,
    placeholder: '',
  }
  return getFieldValue(field as Field, path, shortcutArgs.assessment?.midwife)
}

export const getMidwifeValue = (
  path: string,
  shortcutArgs: ShortcutArgs,
): string | FileDBValue | undefined => {
  // ex: payments.received.0
  const [, fieldPath, ...rest] = path.split('.')
  switch (fieldPath) {
    case 'name':
      return getMidwifeNameValue(rest.join('.'), shortcutArgs)
    case 'npi':
      return getMidwifeNpiValue(rest.join('.'), shortcutArgs)
    case 'billingTaxId':
      return shortcutArgs?.assessment?.midwife?.taxId
    case 'addresses':
      return getMidwifeAddressValue(rest.join('.'), shortcutArgs)
    case 'collaborativePhysicians':
      return getMidwifeCollaborativePhysicianValue(rest.join('.'), shortcutArgs)
    case 'affiliatedHospitals':
      return getMidwifeAffiliatedHospitalValue(rest.join('.'), shortcutArgs)
    case 'licenses':
      return getMidwifeLicenseValue(rest.join('.'), shortcutArgs)
    case 'logo':
      return shortcutArgs.practice?.logoFile || undefined
    case 'medicaid':
      return getMidwifeMedicaidValue(rest.join('.'), shortcutArgs)
    default:
      return getFallbackMidwifeValue([fieldPath, ...rest].join('.'), shortcutArgs, fieldPath)
  }
}
