import { UserGroup } from '@hb/shared'
import { useMemo } from 'react'
import { useApp } from '../../../contexts'
import { useAppRole } from './useAppRole'

export const useAppGroup = (): UserGroup => {
  const { appName } = useApp()
  const role = useAppRole()
  return useMemo(() => {
    if (role === 'user') return 'patient'
    return appName === 'app' ? 'admin' : 'practice'
  }, [appName, role])
}
