import { HStack, TextProps } from '@chakra-ui/react'
import React from 'react'

export const DataHeader = (props: TextProps) => (
  <HStack
    fontSize="sm"
    fontWeight={600}
    p={1}
    color="gray.700"
    fontFamily="Comfortaa"
    flex={1}
    {...props}
  />
)
