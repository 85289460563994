import { Box, Divider, Flex, HStack, Text, TextProps, VStack } from '@chakra-ui/react'
import { colors, PracticeInvoice, WithId } from '@hb/shared'
import React, { useMemo } from 'react'
import { useApp } from '../../../contexts'
import { InvoiceAdditionalPatientsView } from '../AdditionalPatients/InvoiceAdditionalPatientsView'
import { FeeBadge } from '../FeeBadge'
import { InvoiceAdminNotes } from './InvoiceAdminNotes'
import { InvoiceAssessmentBreakdown } from './InvoiceAssessmentBreakdown'

const AssessmentsHeaderText = (props: TextProps) => (
  <Text
    textShadow="1px 1px 2px rgba(0,0,0,0.2)"
    fontWeight={600}
    fontSize="sm"
    color="white"
    fontFamily="Comfortaa"
    {...props}
  />
)

const InvoiceAssessmentsFooter = ({ invoice }: { invoice: PracticeInvoice }) => {
  const totalAmount = useMemo(
    () =>
      Object.values(invoice.assessments).reduce(
        (total, assessment) => total + assessment.chargedAmount,
        0,
      ),
    [invoice],
  )
  return (
    <HStack
      bg="gray.100"
      borderTop="1px solid #cdcdcd"
      borderBottomRadius={6}
      px={2}
      py={1}
      w="100%">
      <Text pl={1} flex={1} fontSize="medium" color="gray.500" width="100px" fontWeight={600}>
        Totals
      </Text>
      <FeeBadge
        amount={totalAmount}
        fontSize="md"
        color="gray.500"
        width="120px"
        fontWeight={600}
      />
      <FeeBadge fontSize="md" amount={invoice.amount} />
      <Box w="20px" />
    </HStack>
  )
}

const ClearingHouseFeeView = ({
  index,
  clearingHouseFee,
}: {
  index: number
  clearingHouseFee?: number
}) => (
  <HStack
    spacing={3}
    // bg={`${colors.green.hex}77`}
    py={1}
    px={1}
    key={index}
    w="100%">
    <Flex flex={1}>
      <Text
        whiteSpace="nowrap"
        pl={2}
        color="gray.500"
        fontFamily="Comfortaa"
        fontSize="sm"
        fontWeight={600}>
        Clearing House Fee
      </Text>
    </Flex>
    <HStack width="120px" spacing={1}></HStack>
    <HStack width="80px" spacing={1}>
      {clearingHouseFee ? (
        <FeeBadge amount={clearingHouseFee} />
      ) : (
        <Text fontSize="sm" color="gray.600">
          Loading...
        </Text>
      )}
    </HStack>
    <Box w="20px" />
  </HStack>
)

export const InvoiceAssessmentsBreakdown = ({
  invoice,
  onClose,
}: {
  invoice: WithId<PracticeInvoice>
  onClose: () => void
}) => {
  const sortedByLastName = useMemo(
    () =>
      Object.entries(invoice.assessments).sort(([, a], [, b]) => {
        const aName = a?.lname || ''
        const bName = b.lname || ''
        return aName.localeCompare(bName)
      }),
    [invoice],
  )
  const { appName } = useApp()
  return (
    <VStack borderRadius={4} spacing={0} w="100%" align="start">
      <HStack bg={colors.green.hex} px={2} py={1} w="100%">
        <AssessmentsHeaderText px={1}>#</AssessmentsHeaderText>
        <AssessmentsHeaderText flex={1}>Patient</AssessmentsHeaderText>
        <AssessmentsHeaderText w="120px">Amount</AssessmentsHeaderText>
        <AssessmentsHeaderText w="80px">Fee</AssessmentsHeaderText>
        <Box w="20px" />
      </HStack>
      <Divider />
      {invoice.assessments
        ? sortedByLastName.map(([assessmentId], index) => (
            <InvoiceAssessmentBreakdown
              key={assessmentId}
              assessmentId={assessmentId}
              invoice={invoice}
              index={index}
            />
          ))
        : null}
      <InvoiceAdditionalPatientsView
        numAssessments={Object.keys(invoice.assessments || {}).length}
        invoice={invoice}
      />
      <ClearingHouseFeeView
        index={Object.keys(invoice.assessments).length}
        clearingHouseFee={invoice.clearingHouseFee}
      />
      <InvoiceAssessmentsFooter invoice={invoice} />
      {appName === 'app' ? <InvoiceAdminNotes onClose={onClose} invoice={invoice} /> : null}
    </VStack>
  )
}
