import { EmailIcon } from '@chakra-ui/icons'
import { Center, HStack, Portal, Text } from '@chakra-ui/react'
import { adminAccess, superAdminAccess, userAccess, version } from '@hb/shared'
import {
  AdminsView,
  AppHeader,
  AssessmentsList,
  BugReportsView,
  ContactView,
  Container,
  CookieBanner,
  EditableStateProvider,
  FaxesView,
  InvoicesView,
  LoadingOverlay,
  Menu,
  PopUpMessageOverlay,
  RedirectView,
  RootModals,
  ScreenContext,
  SignInRedirect,
  SignInView,
  siteVersions,
  useAuth,
  UserAssessmentsProvider,
  View,
} from '@hb/shared-frontend'
import { MyThreadsListProvider } from '@hb/shared-frontend/components/Messaging/contexts'
import { MessagesWidget } from '@hb/shared-frontend/components/Messaging/MessagesWidget'
import { TemplatesView } from '@hb/shared-frontend/components/Templates/TemplatesView'
import { useFilteredViews } from '@hb/shared-frontend/hooks/useFilteredViews'
import { useScreen } from '@hb/shared-frontend/hooks/useScreen'
import { AssessmentIcon } from '@hb/shared-frontend/icons'
import assignmentIcon from '@hb/shared-frontend/icons/assignment.svg'
import { SvgIcon } from '@hb/shared-frontend/icons/SvgIcon'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { InsurersView } from './Admin/Insurers/InsurersView'
import { AdminProvidersView } from './Admin/Midwives/AdminProvidersView'
import { MyConsentForms } from './Views/ConsentForms/MyConsentForms'
import { MyDocuments } from './Views/Documents/MyDocuments'
import FormsView from './Views/Forms/FormsView'
import HomeView from './Views/Home/HomeView'
import PatientsView from './Views/Patients/PatientsView'
import ProvidersView from './Views/Providers/ProvidersView'
import ResourcesView from './Views/Resources/ResourcesView'

const invoicingTabs = [
  'pending-approval',
  'current',
  'sent',
  'paid',
  'settings',
  'archived',
  'deleted',
]

const views: View[] = [
  {
    name: 'Home',
    path: '/',
    exact: true,
    component: HomeView,
    onMenu: true,
  },
  {
    name: 'Patients',
    path: '/patients',
    component: PatientsView,
    onMenu: true,
  },
  {
    name: 'Providers',
    path: '/providers',
    component: ProvidersView,
    onMenu: true,
  },
  {
    name: 'FAQ',
    path: '/faq',
    component: ResourcesView,
    onMenu: true,
  },
  {
    name: 'Contact',
    path: '/contact',
    component: ContactView,
    onMenu: true,
  },
  {
    name: 'Sign In',
    path: '/sign-in',
    component: SignInView,
    onMenu: false,
  },
  {
    name: 'Sign in Redirect',
    path: '/sign-in/:inviteId',
    component: SignInRedirect,
  },
  // {
  //   name: 'Form',
  //   access: ['user', 'midwife', 'admin'],
  //   path: [
  //     '/assessment/:assessmentIndex/:formId/:stageId',
  //     '/assessment/:assessmentIndex/:formId',
  //   ],
  //   component: FormWizard,
  // },
  {
    name: (
      <HStack spacing={1}>
        <AssessmentIcon width="16px" opacity={0.6} />
        <Text fontFamily="Open Sans">My Assessment</Text>
      </HStack>
    ),
    id: 'assessment',
    access: userAccess,
    path: ['/assessment', '/assessment/:assessmentId'],
    signIn: true,
    component: FormsView,
    onMenu: true,
  },
  {
    name: (
      <HStack>
        <EmailIcon width="14px" opacity={0.5} />
        <Text fontFamily="Open Sans">My Documents</Text>
      </HStack>
    ),
    path: ['/documents', '/documents/:documentId'],
    access: userAccess,
    signIn: true,
    component: MyDocuments,
    onMenu: true,
  },
  {
    name: (
      <HStack>
        <SvgIcon src={assignmentIcon} width="16px" opacity={0.6} />
        <Text fontFamily="Open Sans">My Consent Forms</Text>
      </HStack>
    ),
    path: ['/consent-forms', '/consent-forms/:consentFormId'],
    access: userAccess,
    signIn: true,
    component: MyConsentForms,
    onMenu: true,
  },
  {
    name: 'Admin Dashboard',
    path: [
      '/admin',
      '/admin/assessments/',
      '/admin/claims',
      '/admin/assessments/:assessmentId',
      '/admin/claims/:claimId',
      '/admin/assessments/:assessmentId/:displayedItem',
      '/admin/claims/:claimId/:displayedItem',
      '/admin/patients',
      '/admin/patients/:patientId',
      '/admin/patients/:patientId/:displayedItem',
      '/admin/patients/:patientId/:assessmentId/:displayedItem',
    ],
    access: adminAccess,
    component: AssessmentsList,
    onMenu: true,
  },
  {
    name: 'Templates',
    path: ['/admin/templates', '/admin/templates/:templateId'],
    access: adminAccess,
    component: TemplatesView,
    onMenu: true,
  },
  {
    name: 'Providers',
    path: '/admin/providers',
    access: adminAccess,
    component: AdminProvidersView,
    onMenu: true,
  },
  {
    name: 'Insurers',
    path: '/admin/insurers',
    access: adminAccess,
    component: InsurersView,
    onMenu: true,
  },
  {
    name: 'Admins',
    path: ['/admin/admins', '/admin/admins/:adminId', '/admin/admins/:adminId/:displayedItem'],
    access: adminAccess,
    component: AdminsView,
    onMenu: true,
  },
  {
    name: 'Faxes',
    path: [
      '/admin/fax',
      '/admin/fax/sent',
      '/admin/fax/received',
      '/admin/fax/sent/:faxId',
      '/admin/fax/received/:faxId',
    ],
    access: adminAccess,
    component: FaxesView,
    onMenu: true,
  },
  {
    name: 'Invoices',
    path: [
      '/super-admin/invoices',
      ...invoicingTabs
        .map(tab => [
          `/super-admin/invoices/${tab}`,
          `/super-admin/invoices/${tab}/:invoiceId`,
          `/super-admin/invoices/${tab}/:invoiceId/assessment/:assessmentId`,
          `/super-admin/invoices/${tab}/:invoiceId/assessment/:assessmentId/:assessmentTab`,
        ])
        .flat(),
      '/super-admin/invoices/:invoiceId/',
      '/super-admin/invoices/:invoiceId/assessment/:assessmentId',
      '/super-admin/invoices/:invoiceId/assessment/:assessmentId/:assessmentTab',
    ],
    access: superAdminAccess,
    onMenu: true,
    component: InvoicesView,
  },
  {
    name: 'Bug Reports',
    path: ['/admin/bug-reports', '/admin/bug-reports/:bugReportId'],
    access: adminAccess,
    component: BugReportsView,
  },
]

const App = () => {
  const loading = useAuth(s => s.loading)
  const filteredViews = useFilteredViews(views)
  const screen = useScreen()
  const { height } = screen
  return (
    <ScreenContext.Provider value={screen}>
      <UserAssessmentsProvider>
        <EditableStateProvider>
          <Portal>
            <MyThreadsListProvider>
              <Container
                style={{
                  justifyContent: 'flex-start',
                  opacity: loading ? 0 : 1,
                  transition: `opacity 333ms ease ${loading ? 0 : 333}ms`,
                  boxSizing: 'border-box',
                  height: '100vh',
                  background: siteVersions[version].background,
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}>
                <AppHeader views={filteredViews} />
                <Center position="relative" w="100%" height={`${height - 55}px`}>
                  <Switch>
                    {!loading
                      ? filteredViews.map(({ path, component }: View) => (
                          <Route
                            key={`route_to_${path}`}
                            exact={true}
                            path={path}
                            render={() => React.createElement(component)}
                          />
                        ))
                      : null}
                    <Route path="/sign-in" component={SignInView}></Route>

                    {!loading ? <Route render={() => <RedirectView loading={loading} />} /> : null}
                  </Switch>
                </Center>
                <Menu views={filteredViews} />
                <MessagesWidget />
              </Container>
              {/* <UserPanel /> */}
              {/* <TestButton /> */}
              <RootModals />
              <LoadingOverlay
                style={{ fontSize: '21px', fontFamily: 'Comfortaa' }}
                height={100}
                loading={loading}
                text="Hamilton Billing"
                withSignOut
              />
              <CookieBanner />
            </MyThreadsListProvider>
          </Portal>
          <PopUpMessageOverlay />
        </EditableStateProvider>
      </UserAssessmentsProvider>
    </ScreenContext.Provider>
  )
}

export default App
