import { Path } from 'slate'
import { FieldTypes } from '../../constants'
import { Field, FieldMap } from './fields'
import { Form } from './form'

export type Shortcut = {
  children?: never
  itemFields?: never
  info?: never
  name: string
  shortcut: true
  type: FieldTypes
}

export interface ShortcutMap {
  itemFields?: never
  info?: never
  shortcut: true
  name: string
  children: Record<string, Shortcut | ShortcutMap>
  isTemplateFieldMap?: never
  isTemplateField?: never
}

export type TemplateField = Field & {
  isTemplateField: true
}

export type TemplateNodeField<F extends Field = Field> = F & {
  id: string
  path: Path
}

export type TemplateNodeFieldMap = Omit<FieldMap, 'children'> & {
  children: Record<string, TemplateNodeField>
}

export type TemplateFieldMap = Omit<FieldMap, 'children'> & {
  isTemplateFieldMap: true
  children: Record<string, TemplateField>
}

export const isShortcutMap = (
  v: Shortcut | ShortcutMap | Form | FieldMap | Field | TemplateFieldMap,
): v is ShortcutMap => 'children' in v && 'shortcut' in v

export const isTemplateFieldMap = (
  v: TemplateFieldMap | FieldMap | Field | Form | Shortcut | ShortcutMap,
): v is TemplateFieldMap => 'children' in v && 'isTemplateFieldMap' in v

export const isTemplateField = (v: Field | TemplateField | Shortcut): v is TemplateField =>
  'isTemplateField' in v
