import { EmailIcon } from '@chakra-ui/icons'
import { Box, IconButton, Tooltip } from '@chakra-ui/react'
import { colors } from '@hb/shared'
import React, { useContext, useState } from 'react'
import { useApp } from '../../../contexts'
import { PopUpMessageContext } from '../../../contexts/PopUpMessage/PopUpMessageContext'
import { markThreadReadStatus } from '../utils'
import { ThreadViewContext } from './contexts'

export const ThreadUnreadButton = ({
  manuallyMarkedUnread,
  setManuallyMarkedUnread,
}: {
  manuallyMarkedUnread: boolean
  setManuallyMarkedUnread: (unread: boolean) => void
}) => {
  const { appName } = useApp()
  const { threadId, threadType } = useContext(ThreadViewContext)
  const { processResponse } = useContext(PopUpMessageContext)
  const [markingUnread, setMarkingUnread] = useState(false)
  return (
    <Box>
      <Tooltip
        placement="top"
        hasArrow
        bg="whitesmoke"
        color="gray.600"
        label={manuallyMarkedUnread ? 'Manually marked unread' : 'Mark as unread'}>
        <IconButton
          ml="auto"
          aria-label="Mark as unread"
          isLoading={markingUnread}
          bg={manuallyMarkedUnread ? colors.green.hex : 'gray.50'}
          borderRadius={6}
          filter="drop-shadow(1px 1px 3px #00000077)"
          color={manuallyMarkedUnread ? 'whiteAlpha.800' : 'gray.500'}
          onClick={async () => {
            if (!appName) return
            setMarkingUnread(true)
            setManuallyMarkedUnread(!manuallyMarkedUnread)
            try {
              if (!threadId || !threadType) return
              if (manuallyMarkedUnread) {
                await markThreadReadStatus(appName, threadType, threadId, true)
                setMarkingUnread(false)
                return
              }
              await markThreadReadStatus(appName, threadType, threadId, false)
              setMarkingUnread(false)
            } catch (e: any) {
              console.error(e)
              processResponse({
                error: `Error marking thread ${
                  manuallyMarkedUnread ? 'read' : 'unread'
                }${e.message ? ` - ${e.message}` : ''}`,
              })
              setMarkingUnread(false)
            }
          }}
          // bg={manuallyMarkedUnread ? 'whiteAlpha.700' : 'whiteAlpha.400'}
          icon={<EmailIcon />}
        />
      </Tooltip>
    </Box>
  )
}
