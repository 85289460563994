import { FieldTypes } from '../../constants/FieldTypes'
import { FieldMap } from '../../types/forms/fields'
import { createFileField } from '../../utils/fields'

export const insuranceCardFrontField = createFileField({
  placeholder: 'Front of Card',
  name: 'insurance-card-front',
})

export const insuranceCardBackField = createFileField({
  placeholder: 'Back of Card',
  condition: v => !v?.frontImageIncludesBack,
  name: 'insurance-card-back',
})
export const insuranceCardField: FieldMap = {
  name: 'Insurance Card',
  initExpanded: true,
  condition: v => !v?.noMemberId,
  // labels: [{text: 'Please upload images of your insurance card'}],
  children: {
    front: insuranceCardFrontField,
    frontImageIncludesBack: {
      type: FieldTypes.CHECKBOX,
      placeholder: 'Back of card included in previous image',
    },
    back: insuranceCardBackField,
  },
}
