import { PracticeInvoice } from '../../invoicing/types/invoice'
import { adminCollectionAccess } from '../constants'
import { INVOICES } from '../names'
import { Collection } from '../types/collection'

export const invoicesCollection: Collection<PracticeInvoice> = {
  id: 'invoices',
  _type: 'firestoreCollection',
  name: 'Invoices',
  refPath: INVOICES,
  access: adminCollectionAccess,
  noRanks: true,
  defaultSort: 'dueDate',
  shouldNotFetchAll: true,
}
