import { Box, Grid, HStack, Image, Text, VStack } from '@chakra-ui/react'
import {
  addressToString,
  capitalizeFirstLetter,
  CollectionItem,
  formatDollarValue,
  formatPaymentType,
  formatPhoneNumber,
  getAdjustedDepositsTotal,
  getDateString,
  InvoiceAssessmentPaymentsSnippet,
  InvoicingSettings,
  INVOICING_SETTINGS,
  lineItemGroupIsAdditionalPatient,
  midwivesCollection,
  objectToArray,
  Practice,
  PracticeInvoice,
  PracticeInvoiceAdditionalPatient,
  PracticeInvoiceLineItem,
  PracticeInvoiceOmission,
  separatePayments,
} from '@hb/shared'

import React, { CSSProperties, PropsWithChildren, useContext, useMemo } from 'react'
import { ScreenContext } from '../../../contexts'
import { useCollectionItem, useDocument } from '../../../hooks'
import { getRowBackground } from '../../DataView'

const headerTextStyle: CSSProperties = {
  background: '#777',
  color: 'white',
  padding: '0.2rem 0.2rem',
  fontSize: '1rem',
  fontWeight: 600,
}

const bodyTextStyle = {
  padding: '0.1rem 0.2rem',
  fontSize: '0.9rem',
  // lineHeight: 1,
}

const clearingHouseFeeTextStyle = {
  ...bodyTextStyle,
  fontWeight: 600,
  borderTop: '1px solid #cdcdcd',
  borderBottom: '1px solid #cdcdcd',
  background: '#eee',
  // lineHeight: 1.2,
  padding: '0.3rem 0.2rem',
}

const subtotalsTextStyle = {
  ...bodyTextStyle,
  fontWeight: 600,
  fontSize: '1rem',
  background: '#f4f4f4',
  // lineHeight: 1.2,
  padding: '0.3rem 0.2rem',
}

// const totalsTextStyle = {
//   ...bodyTextStyle,
//   fontWeight: 600,
//   background: '#f4f4f4',
//   // lineHeight: 1.2,
//   padding: '0.5rem 0.2rem',
// }

const getBodyMainTextStyle = (index: number): CSSProperties => ({
  ...bodyTextStyle,
  background: getRowBackground(index),
  fontWeight: 600,
  // lineHeight: 1.2,
  padding: '0.6rem 0.2rem 0rem 0.2rem',
})

const getBodySubTextStyle = (index: number): CSSProperties => ({
  ...bodyTextStyle,
  background: getRowBackground(index),
  fontSize: '0.9rem',
  lineHeight: 1.1,
})

const InvoicePDFBreakdownHeader = () => (
  <>
    <span style={headerTextStyle} />
    <Text style={headerTextStyle}>Patient</Text>
    <Text style={headerTextStyle}>Received</Text>
    <Text style={headerTextStyle}>Fee</Text>
    <span style={headerTextStyle} />
  </>
)

const InvoicePdfPaymentRow = ({
  payment,
  assessmentIndex,
}: {
  payment: InvoiceAssessmentPaymentsSnippet['chargedPayments'][string]
  assessmentIndex: number
}) => {
  const textStyle = getBodySubTextStyle(assessmentIndex)
  return (
    <>
      <span style={textStyle} />
      <Text style={textStyle}>
        {formatDollarValue(payment.amount)} {formatPaymentType(payment.type)} paid by{' '}
        {payment.paidBy} - {getDateString(payment.date, 'short')}
        {payment.previousFeeAmount ? ' (partial)' : ''}
      </Text>
      <span style={textStyle} />
      <span style={textStyle} />
      <span style={textStyle} />
    </>
  )
}

const InvoicePdfLineItemRow = ({
  lineItem,
  groupIndex,
}: {
  lineItem: PracticeInvoiceLineItem
  groupIndex: number
}) => {
  const textStyle = getBodySubTextStyle(groupIndex)
  const displayedText = lineItem.receivedAmount
    ? `${formatDollarValue(lineItem.receivedAmount)} ${lineItem.description}`
    : lineItem.description
  return (
    <>
      <span style={textStyle} />
      <Text style={textStyle}>{displayedText}</Text>
      <span style={textStyle} />
      <span style={textStyle} />
      <span style={textStyle} />
    </>
  )
}

const InvoicePDFDepositsRow = ({
  assessmentIndex,
  assessment,
}: {
  assessmentIndex: number
  assessment: InvoiceAssessmentPaymentsSnippet
}) => {
  const { deposits } = useMemo(() => separatePayments(assessment.chargedPayments), [assessment])
  const textStyle = getBodySubTextStyle(assessmentIndex)
  const totalDepositsAmount = useMemo(() => getAdjustedDepositsTotal(assessment), [assessment])
  const datesString = useMemo(
    () =>
      Object.values(deposits)
        .reduce((dates, deposit) => {
          const dateString = getDateString(deposit.date, 'short')
          if (!dates.includes(dateString)) {
            dates.push(dateString)
          }
          return dates
        }, [] as string[])
        .map(date => getDateString(date, 'short'))
        .join(', '),
    [deposits],
  )
  if (!totalDepositsAmount) {
    return null
  }
  return (
    <>
      <span style={textStyle} />
      <Text style={textStyle}>
        {formatDollarValue(totalDepositsAmount)} in deposits paid by patient - {datesString}
      </Text>
      <span style={textStyle} />
      <span style={textStyle} />
      <span style={textStyle} />
    </>
  )
}

const InvoicePdfAssessmentRows = ({
  assessment,
  additionalLineItems,
  omissions,
  index,
}: {
  assessment: InvoiceAssessmentPaymentsSnippet
  additionalLineItems?: Record<string, PracticeInvoiceLineItem>
  omissions?: Record<string, PracticeInvoiceOmission>
  index: number
}) => {
  const textStyle = getBodyMainTextStyle(index)
  // const { deposits, other } = useMemo(() => ({
  //   deposits: Object.values(assessment.chargedPayments).filter(
  //     (payment) => payment.type === 'deposit',
  //   ),
  //   other: Object.values(assessment.chargedPayments).filter(
  //     (payment) => payment.type !== 'deposit',
  //   ),
  // }), [assessment])

  const { retainers, homeBirthRetainers, other } = useMemo(
    () => separatePayments(assessment.chargedPayments),
    [assessment.chargedPayments],
  )

  return (
    <>
      <span style={textStyle} />
      <Text style={{ ...textStyle }}>
        {assessment.fname} {assessment.lname}
      </Text>
      <Text style={textStyle}>{formatDollarValue(assessment.chargedAmount)}</Text>
      <Text style={textStyle}>{formatDollarValue(assessment.practiceFee)}</Text>
      <span style={textStyle} />
      {Object.entries(retainers).map(([paymentId, payment]) =>
        omissions?.[`${assessment.assessmentId}-${paymentId}`] ? null : (
          <InvoicePdfPaymentRow assessmentIndex={index} key={paymentId} payment={payment} />
        ),
      )}
      {Object.entries(homeBirthRetainers).map(([paymentId, payment]) =>
        omissions?.[`${assessment.assessmentId}-${paymentId}`] ? null : (
          <InvoicePdfPaymentRow assessmentIndex={index} key={paymentId} payment={payment} />
        ),
      )}
      {omissions?.[`${assessment.assessmentId}-deposits`] ? null : (
        <InvoicePDFDepositsRow assessmentIndex={index} assessment={assessment} />
      )}
      {Object.entries(other).map(([paymentId, payment]) =>
        omissions?.[`${assessment.assessmentId}-${paymentId}`] ? null : (
          <InvoicePdfPaymentRow assessmentIndex={index} key={paymentId} payment={payment} />
        ),
      )}
      {additionalLineItems
        ? Object.entries(additionalLineItems).map(([lineItemId, lineItem]) => (
            <InvoicePdfLineItemRow key={lineItemId} groupIndex={index} lineItem={lineItem} />
          ))
        : null}
      <span
        style={{
          ...textStyle,
          height: '10px',
          gridColumn: 'span 5',
        }}
      />
    </>
  )
}

const InvoicePdfAdditionalPatientRows = ({
  additionalPatient,
  index,
}: {
  additionalPatient: PracticeInvoiceAdditionalPatient
  index: number
}) => {
  const totalAmount = useMemo(
    () =>
      Object.values(additionalPatient.lineItems).reduce((total, item) => total + item.amount, 0),
    [additionalPatient],
  )
  const textStyle = getBodyMainTextStyle(index)
  if (Object.keys(additionalPatient.lineItems).length === 0) return null
  return (
    <>
      <span style={textStyle} />
      <Text style={textStyle}>
        {additionalPatient.fname} {additionalPatient.lname}
      </Text>
      <Text style={textStyle} />
      <Text style={textStyle}>{formatDollarValue(totalAmount)}</Text>
      <span style={textStyle} />
      {Object.entries(additionalPatient.lineItems).map(([lineItemId, lineItem]) => (
        <InvoicePdfLineItemRow key={lineItemId} groupIndex={index} lineItem={lineItem} />
      ))}
      <span
        style={{
          ...textStyle,
          height: '10px',
          gridColumn: 'span 5',
        }}
      />
    </>
  )
}

const ClearingHouseFeeRow = ({ invoice }: { invoice: PracticeInvoice }) => {
  const { clearingHouseFee } = invoice || {}
  return (
    <>
      <span style={clearingHouseFeeTextStyle} />
      <Text style={clearingHouseFeeTextStyle}>Clearing house fee</Text>
      <span style={clearingHouseFeeTextStyle} />
      <Text style={clearingHouseFeeTextStyle}>
        {clearingHouseFee ? formatDollarValue(clearingHouseFee) : ''}
      </Text>
      <span style={clearingHouseFeeTextStyle} />
    </>
  )
}

const SubtotalRow = ({ invoice }: { invoice: PracticeInvoice }) => {
  const { amount } = invoice || {}
  return (
    <>
      <span style={subtotalsTextStyle} />
      <Text style={subtotalsTextStyle}>Subtotal</Text>
      <span style={subtotalsTextStyle} />
      <Text style={subtotalsTextStyle}>{formatDollarValue(amount)}</Text>
      <span style={subtotalsTextStyle} />
    </>
  )
}

const InvoicePDFBreakdownGrid = ({ invoice }: { invoice: PracticeInvoice }) => {
  const sortedByLname = useMemo(
    () =>
      [
        ...objectToArray(invoice.assessments || {}).filter(a => Math.abs(a.practiceFee) >= 0.01),
        ...objectToArray(invoice.additionalPatients || {}),
      ].sort((a, b) => {
        if (a.lname < b.lname) {
          return -1
        }
        if (a.lname > b.lname) {
          return 1
        }
        return 0
      }),
    [invoice],
  )
  return (
    <Grid templateColumns="20px 1fr 100px 100px 20px">
      <InvoicePDFBreakdownHeader />
      {sortedByLname.map((item, index) =>
        lineItemGroupIsAdditionalPatient(item) ? (
          <InvoicePdfAdditionalPatientRows additionalPatient={item} key={item.id} index={index} />
        ) : (
          <InvoicePdfAssessmentRows
            omissions={invoice.omissions}
            additionalLineItems={invoice.additionalLineItems?.[item.assessmentId]}
            key={item.assessmentId}
            index={index}
            assessment={item}
          />
        ),
      )}
      <ClearingHouseFeeRow invoice={invoice} />
      <SubtotalRow invoice={invoice} />
    </Grid>
  )
}

const ContactHeader = ({ style, children }: PropsWithChildren<{ style?: CSSProperties }>) => (
  <b style={{ color: '#666', fontSize: '0.9rem', ...style }}>{children}</b>
)

const InvoiceHeaderContactSection = ({ title, value }: { title: string; value?: string }) => (
  <Text style={{ textAlign: 'right', lineHeight: 1.2, fontSize: '0.9rem' }}>
    {value ? (
      <>
        <ContactHeader>{capitalizeFirstLetter(title)}:</ContactHeader>
        <span> {formatPhoneNumber(value)}</span>
      </>
    ) : (
      `ERROR: Missing header ${title} (Invoicing Settings Page)`
    )}
  </Text>
)

const InvoiceHeader = ({ invoice }: { invoice: PracticeInvoice }) => {
  const { data: templateSettings } = useDocument<InvoicingSettings['template']>(
    INVOICING_SETTINGS,
    'template',
  )
  const { isMobile } = useContext(ScreenContext)
  const { header: headerSettings } = templateSettings || {}
  return (
    <HStack
      align="flex-start"
      style={{
        position: 'relative',
        padding: '1rem',
        paddingBottom: isMobile ? '2.2rem' : '1rem',
        borderBottom: '2px solid #cdcdcd',
      }}
      w="100%">
      <VStack align="flex-start" h="100%" justify="space-between">
        <Image width={isMobile ? '90px' : '160px'} src="/images/hblogo.png" alt="logo" />
      </VStack>
      <VStack spacing={0} style={{ gap: '0.5rem', alignItems: 'flex-end' }} flex={1}>
        <h3
          style={{
            fontFamily: 'arial',
            color: '#222',
            fontSize: '2rem',
            fontWeight: 500,
            opacity: 1,
            margin: 0,
            lineHeight: 1,
          }}>
          INVOICE
        </h3>
        <div>
          <Text style={{ fontWeight: 600, color: '#222', textAlign: 'right' }}>
            Katherine Baker / Hamilton Billing
          </Text>
          <Text
            style={{
              whiteSpace: 'pre',
              textAlign: 'right',
              lineHeight: 1.2,
              fontSize: '0.9rem',
            }}>
            {headerSettings?.address
              ? `${addressToString(headerSettings?.address, true)}\nUnited States`
              : 'ERROR: Missing header address (Invoicing Settings Page)'}
          </Text>
        </div>
        <div>
          <InvoiceHeaderContactSection title="phone" value={headerSettings?.phone} />
          <InvoiceHeaderContactSection title="fax" value={headerSettings?.fax} />
          <InvoiceHeaderContactSection title="mobile" value={headerSettings?.mobile} />
        </div>
      </VStack>
      <Text
        style={{
          fontSize: '1rem',
          color: '#777',
          fontWeight: 600,
          position: 'absolute',
          bottom: '0.6rem',
          left: '1rem',
        }}>
        INVOICE {invoice.invoiceNumber ? `#${invoice.invoiceNumber}` : 'DRAFT'} |{' '}
        {invoice.practiceName}
      </Text>
    </HStack>
  )
}

const InvoiceBody = ({
  invoice,
  practice,
}: {
  invoice: PracticeInvoice
  practice: Practice | undefined | null
}) => {
  const { dueDate } = invoice
  const dueDateMonth = useMemo(
    () =>
      dueDate
        ? new Date(dueDate).toLocaleString('default', { month: 'long' })
        : 'Error getting month',
    [dueDate],
  )
  return (
    <Box bg="white" py={3} px={6}>
      <Text mb={1} fontWeight={600}>
        Hi {practice?.fname},
      </Text>
      <Text mb={1}>
        Please see your current invoice for the month of {dueDateMonth}. Please remit payment by the
        25th of {dueDateMonth} via one of the following payment options:
      </Text>
      <Text width="100%" fontSize="sm" mb={1} pb={1}>
        Zelle/Chase Quickpay to: katygunn@gmail.com
      </Text>
      <Text fontSize="small">
        Or, please mail a check to the following address: <br /> Katherine Baker / Hamilton Billing
        211 N 5th St. <br />
        #1B Brooklyn, New York 11211
      </Text>
    </Box>
  )
}

export const InvoicePDFPreview = ({ invoice }: { invoice: PracticeInvoice }) => {
  const { practiceId } = invoice
  const { item: practice } = useCollectionItem(midwivesCollection, practiceId)

  return (
    <Box border="1px solid #cdcdcd" bg="white" w="100%">
      <InvoiceHeader invoice={invoice} />
      <InvoiceBody practice={practice as CollectionItem<Practice> | null} invoice={invoice} />
      <InvoicePDFBreakdownGrid invoice={invoice} />
    </Box>
  )
}
