import { CalendarIcon, ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  TextProps,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  capitalizeFirstLetter,
  CollectionFilter,
  colors,
  formatDollarValue,
  getDateString,
  getFullName,
  INVOICES,
  INVOICES_DELETED,
  LocalCollectionState,
  PracticeInvoice,
  PracticeWithAdmin,
} from '@hb/shared'
import React from 'react'
import { SortButton } from '../../components/DataList/SortButton'
import { InvoicesViewContextValue } from '../../components/Invoices/InvoicesViewProvider'
import { NotesPopover } from '../../components/NotesPopover'
import { DataColumn, DataListTab } from '../../types'

export type ExtraInvoiceColumnProps = {
  invoicesView: InvoicesViewContextValue
  practices: LocalCollectionState<PracticeWithAdmin>
}
export type InvoiceColumn = DataColumn<PracticeInvoice, ExtraInvoiceColumnProps>
export type InvoiceDataListTab = DataListTab<PracticeInvoice, ExtraInvoiceColumnProps>

export const paymentTypeAndNotesColumn: InvoiceColumn = {
  title: 'Payment Type & Notes',
  Header: () => <Text px={2}>Payment Type</Text>,
  Render: ({ data: invoice }) => (
    <Flex align="center" w="100%">
      <InvoiceColumnText>{invoice.paymentType || 'Unassigned'}</InvoiceColumnText>
      <Box flex={1} minW="0">
        <NotesPopover notes={invoice.paymentNotes || ''} />
      </Box>
    </Flex>
  ),
  sortKey: 'paymentType',
}

export const invoiceNumberColumn: InvoiceColumn = {
  title: 'Invoice No.',
  width: 84,
  Header: () => <SortButton sortKey="invoiceNumber">No.</SortButton>,
  Render: ({ data: invoice, invoicesView: { selectInvoice } }) => {
    return (
      <Button
        onClick={() => selectInvoice(invoice.id)}
        color="gray.500"
        fontWeight={500}
        variant="link"
        px={2}>
        {invoice.invoiceNumber ? `#${invoice.invoiceNumber}` : 'Unsent'}
        <ExternalLinkIcon ml={1} color="#777" />
      </Button>
    )
  },
  sortKey: 'invoiceNumber',
}

export const practiceInvoiceNumberColumn: InvoiceColumn = {
  title: 'Invoice No.',
  Header: () => (
    <Box px={2}>
      <SortButton sortKey="invoiceNumber">No.</SortButton>
    </Box>
  ),
  Render: ({ data: invoice, invoicesView: { selectInvoice } }) => {
    const { paidOn, installments = {}, amount } = invoice || {}
    const paidAmount = Object.values(installments).reduce(
      (acc, installment) => acc + installment.amount,
      0,
    )
    const paidPercent = paidAmount / amount
    return (
      <Button
        onClick={() => selectInvoice(invoice.id)}
        fontWeight={500}
        w="100%"
        bg={paidOn ? 'green.400' : 'gray.400'}
        _hover={{
          bg: paidOn ? 'green.300' : 'gray.300',
        }}
        textShadow="1px 1px 3px #00000077"
        color="white"
        size="xs"
        boxShadow="inset 1px 1px 3px rgba(0,0,0,0.5)"
        position="relative"
        fontSize="sm"
        overflow="hidden"
        borderRadius={6}
        px={2}>
        {paidOn ? null : (
          <Box
            position="absolute"
            zIndex={0}
            top={0}
            left={0}
            w={`${paidPercent * 100}%`}
            bg="green.400"
            h="100%"
          />
        )}
        <Text zIndex={1} flex={1}>
          {invoice.invoiceNumber ? `#${invoice.invoiceNumber}` : 'Unsent'}
        </Text>
        <ExternalLinkIcon
          zIndex={1}
          filter="drop-shadow(1px 1px 3px #00000077)"
          ml={1}
          color="white"
        />
      </Button>
    )
  },
}

export const statusColumn: InvoiceColumn = {
  title: 'Status',
  Header: () => (
    <Box px={2}>
      <SortButton sortKey="status">Status</SortButton>
    </Box>
  ),
  width: 120,
  Render: ({ data: invoice }) => (
    <Text px={2} color="gray.700" fontFamily="Hero-New">
      {capitalizeFirstLetter(invoice.status || '')}
    </Text>
  ),
  sortKey: 'status',
}

export const practiceStatusColumn: InvoiceColumn = {
  title: 'Status',
  Header: () => (
    <Box px={2}>
      <SortButton sortKey="status">Status</SortButton>
    </Box>
  ),
  width: 120,
  Render: ({ data: invoice }) => (
    <Text px={2} color="gray.700" fontFamily="Hero-New">
      {capitalizeFirstLetter(invoice.status === 'sent' ? 'outstanding' : invoice.status || '')}
    </Text>
  ),
  sortKey: 'status',
}

export const InvoiceColumnText = (props: TextProps) => (
  <Text
    fontFamily="Open Sans"
    fontSize="md"
    px={1}
    fontWeight={600}
    whiteSpace="nowrap"
    color="gray.500"
    {...props}
  />
)

export const dueDateColumn: InvoiceColumn = {
  title: 'Due Date',
  width: 160,
  Header: () => <SortButton sortKey="dueDate">Due Date</SortButton>,
  Render: ({ data: invoice }) => (
    <InvoiceColumnText>{getDateString(invoice.dueDate, 'short')}</InvoiceColumnText>
  ),
  mobile: {
    width: 140,
    Render: ({ data: invoice }) => (
      <Flex px={2} gap={1} align="center">
        <CalendarIcon color="gray.500" />
        <InvoiceColumnText fontSize="sm">
          Due {getDateString(invoice.dueDate, 'short')}
        </InvoiceColumnText>
      </Flex>
    ),
  },
  sortKey: 'dueDate',
}

const renderAssessmentsColumn =
  (isMobile: boolean): InvoiceColumn['Render'] =>
  ({ data: invoice, invoicesView: { selectAssessment } }) => {
    const { numWithPayments, numWithoutPayments, totalPayments } = Object.values(
      invoice.assessments || {},
    ).reduce(
      (acc, assessmentSnippet) => {
        if (Object.keys(assessmentSnippet.chargedPayments).length > 0) {
          acc.numWithPayments += 1
        } else {
          acc.numWithoutPayments += 1
        }
        acc.totalPayments += 1
        return acc
      },
      { numWithPayments: 0, numWithoutPayments: 0, totalPayments: 0 },
    )
    return (
      <Popover placement="top" strategy="fixed" trigger={isMobile ? 'click' : 'hover'}>
        {({ isOpen }) => (
          <>
            <PopoverTrigger>
              <Center px={2} flexFlow="column" w="100%">
                {isMobile ? (
                  <Text
                    w="100%"
                    textAlign={totalPayments ? 'center' : 'right'}
                    fontSize="xs"
                    color="gray.600"
                    fontWeight={600}>
                    {totalPayments ? 'PATIENTS' : 'NO PATIENTS'}
                  </Text>
                ) : null}
                <Center w="100%">
                  {numWithPayments ? (
                    <Center
                      cursor="pointer"
                      fontSize="xs"
                      flex={numWithPayments}
                      borderRadius="full"
                      bg="green.400"
                      py={isMobile ? '0.05rem' : '0.15rem'}
                      color="white"
                      minW="20px"
                      fontWeight={600}
                      borderLeftRadius="full"
                      borderRightRadius={numWithoutPayments === 0 ? 'full' : 0}
                      textShadow="0 0 2px rgba(0,0,0,0.5)"
                      textAlign="center">
                      {numWithPayments}
                    </Center>
                  ) : null}
                  {numWithoutPayments ? (
                    <Center
                      flex={numWithoutPayments}
                      borderRadius="full"
                      fontSize="xs"
                      bg="yellow.400"
                      py={isMobile ? '0.05rem' : '0.15rem'}
                      minW="20px"
                      borderLeftRadius={numWithPayments === 0 ? 'full' : 0}
                      borderRightRadius="full"
                      color="white"
                      textShadow="0 0 2px rgba(0,0,0,0.5)"
                      textAlign="center"
                      fontWeight={600}>
                      {numWithoutPayments}
                    </Center>
                  ) : null}
                </Center>
              </Center>
            </PopoverTrigger>
            {isOpen ? (
              <Portal>
                <PopoverContent minW="300px" maxW="100vw" width="auto">
                  <PopoverArrow />
                  <VStack
                    divider={<Divider />}
                    borderRadius={4}
                    bg="gray.50"
                    align="start"
                    maxH="320px"
                    overflowY="auto"
                    spacing={0}>
                    <HStack
                      zIndex={2}
                      position="sticky"
                      top="0"
                      bg={colors.green.hex}
                      py="0.15rem"
                      px={2}
                      w="100%"
                      boxShadow="0 2px 3px rgba(0,0,0,0.25)">
                      <Text minW="140px" fontWeight={600} color="white" flex={1}>
                        Patient
                      </Text>
                      <Text fontWeight={600} color="white" w="100px">
                        Received
                      </Text>
                      <Text fontWeight={600} color="white" w="100px">
                        Fee
                      </Text>
                    </HStack>
                    {Object.entries(invoice.assessments || {}).map(
                      ([assessmentSnippetId, assessmentSnippet]) => (
                        <HStack key={assessmentSnippetId} py={1} px={2} w="100%">
                          <Button
                            fontFamily="Open Sans"
                            color={
                              assessmentSnippet.chargedAmount ? colors.green.hex : 'yellow.500'
                            }
                            size="sm"
                            variant="link"
                            justifyContent="flex-start"
                            textAlign="left"
                            isTruncated
                            fontWeight={600}
                            onClick={() =>
                              selectAssessment(invoice.id, assessmentSnippet.assessmentId)
                            }
                            flex={1}
                            minW="0">
                            {assessmentSnippet.fname} {assessmentSnippet.lname}
                          </Button>
                          <Text fontWeight={600} fontFamily="Open Sans" fontSize="sm" w="100px">
                            {formatDollarValue(assessmentSnippet.chargedAmount)}
                          </Text>
                          <Text fontWeight={600} fontFamily="Open Sans" fontSize="sm" w="100px">
                            {formatDollarValue(assessmentSnippet.practiceFee)}
                          </Text>
                        </HStack>
                      ),
                    )}
                  </VStack>
                </PopoverContent>
              </Portal>
            ) : null}
          </>
        )}
      </Popover>
    )
  }

export const assessmentsColumn: InvoiceColumn = {
  title: 'Patients',
  width: 120,
  Header: () => <Text px={2}>Patients</Text>,
  Render: renderAssessmentsColumn(false),
  mobile: {
    width: 120,
    Render: renderAssessmentsColumn(true),
  },
}

export const scheduledForColumn: InvoiceColumn = {
  title: 'Scheduled For',
  width: 160,
  Header: () => <SortButton sortKey="scheduledFor">Scheduled For</SortButton>,
  Render: ({ data: invoice }) => (
    <InvoiceColumnText>
      {invoice.scheduledFor ? getDateString(invoice.scheduledFor, 'short') : 'Unassigned'}
    </InvoiceColumnText>
  ),
  sortKey: 'scheduledFor',
}

// const amountColumn: InvoiceColumn = {
//   width: 120,
//   flexProps: { width: '100px' },
//   Header: () => <Text px={2}>Total Amount</Text>,
//   Render: ({ data: invoice }) => (
//     <Text px={2}>{formatDollarValue(invoice.amount)}</Text>
//   ),
//   sortKey: 'amount',
// }

export const amountDueColumn: InvoiceColumn = {
  title: 'Amount Due',
  width: 160,
  flexProps: { width: '100px' },
  Header: () => <Text px={2}>Invoice Total</Text>,
  Render: ({ data: invoice }) => (
    <InvoiceColumnText color="gray.600">{formatDollarValue(invoice.amount)}</InvoiceColumnText>
  ),
  mobile: {
    width: 160,
    Render: ({ data: invoice }) => (
      <HStack spacing={1}>
        <Text whiteSpace="nowrap" color="gray.600" fontWeight={600} pl={2}>
          Total invoiced:
        </Text>
        <InvoiceColumnText color="gray.600">{formatDollarValue(invoice.amount)}</InvoiceColumnText>
      </HStack>
    ),
  },
  sortKey: 'amount',
}

export const amountPaidColumn: InvoiceColumn = {
  title: 'Amount Paid',
  width: 160,
  flexProps: { width: '100px' },
  Header: () => <Text px={2}>Amount Paid</Text>,
  Render: ({ data: invoice }) => (
    <InvoiceColumnText color={colors.green.hex}>
      {formatDollarValue(invoice.paidAmount || 0)}
    </InvoiceColumnText>
  ),
  mobile: {
    width: 160,
    Render: ({ data: invoice }) => (
      <HStack spacing={1}>
        <Text whiteSpace="nowrap" color={colors.green.hex} fontWeight={600} pl={2}>
          Total paid:
        </Text>
        <InvoiceColumnText color={colors.green.hex}>
          {formatDollarValue(invoice.paidAmount || 0)}
        </InvoiceColumnText>
      </HStack>
    ),
  },
  sortKey: 'paidAmount',
}

export const practiceNameColumn: InvoiceColumn = {
  title: 'Practice',
  Header: () => (
    <Box px={2}>
      <SortButton sortKey="practiceName">Practice</SortButton>
    </Box>
  ),
  Render: ({
    data: invoice,
    invoicesView: { selectPractice, selectInvoice },
    practices: { items },
  }) => {
    const practice = items.find(i => i.id === invoice.practiceId)
    return (
      <HStack spacing={0}>
        <Button
          size="sm"
          color={colors.green.hex}
          maxW="100%"
          display="block"
          isTruncated
          onClick={() => selectInvoice(invoice.id)}
          variant="link"
          px={2}>
          {invoice.practiceName} | {getFullName(practice)}
        </Button>
        <Tooltip placement="top" hasArrow label="View practice">
          <IconButton
            p={0}
            size="xs"
            variant="ghost"
            colorScheme="green"
            aria-label="View practice"
            onClick={() => selectPractice(invoice.practiceId)}
            icon={<ExternalLinkIcon />}
          />
        </Tooltip>
      </HStack>
    )
  },
  sortKey: 'practiceName',
}

export const scheduledInvoiceColumns: Record<string, InvoiceColumn> = {
  practiceName: practiceNameColumn,
  // status: statusColumn,
  scheduledFor: scheduledForColumn,
  dueDate: dueDateColumn,
  assessments: assessmentsColumn,
  amount: amountDueColumn,
}

const getSentInvoiceColumns = (practiceId?: string | null): Record<string, InvoiceColumn> =>
  practiceId
    ? {
        invoiceNumber: practiceInvoiceNumberColumn,
        // status: practiceId ? practiceStatusColumn : statusColumn,
        // scheduledFor: scheduledForColumn,
        dueDate: dueDateColumn,
        assessments: assessmentsColumn,
        amountPaid: amountPaidColumn,
        amount: amountDueColumn,
      }
    : {
        invoiceNumber: invoiceNumberColumn,
        practiceName: practiceNameColumn,
        // status: statusColumn,
        // scheduledFor: scheduledForColumn,
        dueDate: dueDateColumn,
        assessments: assessmentsColumn,
        amountPaid: amountPaidColumn,
        amount: amountDueColumn,
      }

const getPaidInvoiceColumns = (practiceId?: string | null): Record<string, InvoiceColumn> =>
  practiceId
    ? {
        invoiceNumber: practiceInvoiceNumberColumn,
        // status: statusColumn,
        // scheduledFor: scheduledForColumn,
        dueDate: { ...dueDateColumn, width: 320 },
        assessments: assessmentsColumn,
        amount: amountDueColumn,
      }
    : {
        invoiceNumber: invoiceNumberColumn,
        practiceName: practiceNameColumn,
        paymentType: paymentTypeAndNotesColumn,
        // status: statusColumn,
        // scheduledFor: scheduledForColumn,
        dueDate: { ...dueDateColumn, width: 320 },
        assessments: assessmentsColumn,
        amount: amountDueColumn,
      }

const notArchivedFilter: CollectionFilter = ['archivedOn', '==', null]
const archivedFilter: CollectionFilter = ['archivedOn', '!=', null]

export const getSentInvoicesTabs = (
  practiceId?: string | null,
): Record<string, InvoiceDataListTab> => ({
  sent: {
    collection: INVOICES,
    searchStringPath: 'searchString',
    columns: getSentInvoiceColumns(practiceId),
    defaultSortKey: 'invoiceNumber',
    filters: [
      ['status', '==', 'sent'],
      practiceId ? ['practiceId', '==', practiceId] : notArchivedFilter,
    ],
    itemName: 'Invoice',
  },
})

export const getPaidInvoicesTabs = (
  practiceId?: string | null,
): Record<string, InvoiceDataListTab> => ({
  paid: {
    collection: INVOICES,
    columns: getPaidInvoiceColumns(practiceId),
    searchStringPath: 'searchString',
    defaultSortKey: 'invoiceNumber',
    filters: [
      ['status', '==', 'paid'],
      practiceId ? ['practiceId', '==', practiceId] : notArchivedFilter,
    ],
    itemName: 'Invoice',
  },
})

export const archivedInvoicesTabs: Record<string, InvoiceDataListTab> = {
  archived: {
    searchStringPath: 'searchString',
    collection: INVOICES,
    columns: getSentInvoiceColumns(),
    defaultSortKey: 'practiceName',
    filters: [archivedFilter],
    itemName: 'Invoice',
  },
}

export const deletedInvoicesTabs: Record<string, InvoiceDataListTab> = {
  deleted: {
    searchStringPath: 'searchString',
    collection: INVOICES_DELETED,
    columns: getSentInvoiceColumns(),
    // searchStringPath:
    defaultSortKey: 'practiceName',
    itemName: 'Invoice',
  },
}

export const getPracticeInvoiceTabs = (
  tabs: Record<string, InvoiceDataListTab>,
  practiceId: string,
) => ({
  ...Object.entries(tabs).reduce(
    (acc, [key, tab]) => ({
      ...acc,
      [key]: {
        ...tab,
        filters: [
          ...(tab.filters || []).filter(f => f[0] !== 'archivedOn'),
          ['practiceId', '==', practiceId],
        ],
      },
    }),
    {},
  ),
})
