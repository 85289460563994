import { CloseIcon } from '@chakra-ui/icons'
import {
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react'
import { DateField, getDateString } from '@hb/shared'
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react'
import { DayPicker } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import { ThemeContext } from '../../../../contexts'
import { InputElement, InputProps } from '../../../../types/fields'

const currYear = new Date().getFullYear()

export const PopoverDateInput: InputElement<DateField> = forwardRef<
  { focus: () => void },
  InputProps<DateField>
>((props, ref) => {
  const {
    input,
    field: {
      placeholder,
      minDate,
      defaultToNow,
      // maxDate,
      optional,
      isoFormat,
    },
    disabled,
    style,
    meta: { active },
  } = props
  const { onChange, value, name, onFocus, onBlur } = input

  const mounted = useRef(false)
  useEffect(() => {
    if (!mounted.current && defaultToNow) {
      if (!value) {
        onChange(isoFormat ? new Date().toISOString().split('T')[0] : new Date().getTime())
      }
      mounted.current = true
    }
  }, [defaultToNow, isoFormat, onChange, value])

  const date = useMemo(() => {
    if (!value) return new Date()
    const d = new Date(value)
    d.setTime(d.getTime() + d.getTimezoneOffset() * 60 * 1000)
    return d
  }, [value])
  const minYear = useMemo(() => {
    if (!minDate) return 1923
    if (minDate === 'now') return new Date().getFullYear()
    return new Date(minDate).getFullYear()
  }, [minDate])

  useImperativeHandle(ref, () => ({
    focus: onFocus,
  }))

  const { theme } = useContext(ThemeContext)

  const placeholderWithOptional = useMemo(() => {
    if (optional) return `${placeholder} (optional)`
    return placeholder
  }, [optional, placeholder])
  return (
    <Popover
      key={Math.random()}
      isOpen={active}
      onOpen={onFocus}
      onClose={onBlur}
      trigger="click"
      strategy="fixed"
      closeDelay={100}
      placement="top">
      <HStack>
        <PopoverTrigger>
          <HStack
            spacing={2}
            px={theme === 'basic' ? 2 : 3}
            pl={theme === 'basic' ? 1 : 2}
            cursor="pointer"
            py={theme === 'basic' ? 0 : 2}
            minW={theme === 'basic' ? '0' : '270px'}
            background="white"
            pointerEvents={disabled ? 'none' : 'auto'}
            boxShadow="md"
            border={theme === 'basic' ? undefined : '1px solid #cdcdcd'}
            _hover={{ background: 'gray.50' }}
            borderRadius={6}
            color={value ? 'gray.700' : 'gray.500'}
            style={style}>
            <Text
              // w='100%'
              fontFamily="hero-new"
              bg="transparent"
              flex={theme === 'basic' ? undefined : 1}
              variant="unstyled"
              display="flex"
              justifyContent="flex-start"
              // mx={1}
              // fontSize={theme === 'basic' ? 'sm' : 'md'}
              fontSize="sm"
              // size='sm'
              px={1}
              py={theme === 'basic' ? 1 : 0}
              color="inherit"
              fontWeight="normal">
              {value ? getDateString(value, 'short', true) : placeholderWithOptional}
            </Text>
            {value ? (
              <IconButton
                ml="auto"
                size="xs"
                width="20px"
                minW="0"
                height="20px"
                bg="gray.100"
                borderRadius="full"
                color="white"
                aria-label="Clear"
                icon={<CloseIcon color="gray.500" w={2} h={2} />}
                onClick={e => {
                  e.stopPropagation()
                  onChange(undefined)
                }}
              />
            ) : null}
          </HStack>
        </PopoverTrigger>
      </HStack>
      <Portal>
        <PopoverContent width="auto" zIndex={2} onFocus={onFocus}>
          <PopoverArrow />
          <DayPicker
            required={!optional}
            // disabled={disabled}
            defaultMonth={date}
            captionLayout="dropdown-buttons"
            fromYear={minYear}
            toYear={currYear + 5}
            styles={{
              caption: {
                fontFamily: 'hero-new',
                fontWeight: '400',
                color: '#555',
              },
              head: {
                fontFamily: 'hero-new',
              },
              nav: {
                fontFamily: 'hero-new',
              },
              cell: {
                width: '32px',
                height: '32px',
              },
              nav_button_next: {
                height: '32px',
                width: '32px',
              },
              nav_button_previous: {
                height: '32px',
                width: '32px',
              },
              nav_button: {
                height: '32px',
                width: '32px',
              },
              day: {
                padding: 0,
                height: '32px',
                width: '32px',
              },
            }}
            modifiersStyles={{
              selected: {
                background: 'rgb(67, 173, 158)',
              },
            }}
            id={name}
            mode="single"
            selected={date}
            onMonthChange={updated => {
              if (!value) return
              const modified = new Date(updated.getTime() + updated.getTimezoneOffset() * 60 * 1000)
              modified.setUTCDate(date.getUTCDate())
              onChange(isoFormat ? modified?.toISOString().split('T')[0] : modified?.getTime())
            }}
            onDayClick={updated => {
              if (isoFormat) {
                onChange(updated?.toISOString().split('T')[0])
              } else {
                onChange(
                  updated ? updated.getTime() - updated.getTimezoneOffset() * 60 * 1000 : undefined,
                )
                onBlur()
              }
            }}
          />
        </PopoverContent>
      </Portal>
    </Popover>
  )
})
