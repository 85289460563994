import { createContext } from 'react'
import { useUserFromId } from '../hooks/backend/user/useUserFromId'
import { emptyPopulatedAssessmentInterface } from './AssessmentContext'

export const UserContext = createContext<ReturnType<typeof useUserFromId>>({
  user: null,
  viewedTemplate: null,
  adminPatientRef: null,
  loading: false,
  patientRef: null,
  emailVerification: { verified: false, loading: false, refetch: () => {} },
  admin: false,
  setViewedTemplateKey: () => {},
  assessments: null,
  selectAssessment: () => {},
  claims: { data: null, error: null, loading: false },
  selectedAssessment: emptyPopulatedAssessmentInterface,
  assessmentId: undefined,
  practicePatientRef: null,
})
