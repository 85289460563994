import {
  EditorVersion,
  isLinkNode,
  isPositionedNode,
  isSignatureFieldNode,
  isVariableOrFieldNode,
} from '@hb/shared'
import { Editor, EditorFragmentDeletionOptions, Element, Path, Transforms } from 'slate'
import { ReactEditor } from 'slate-react'
import { CustomEditor } from '../../types/editor'

const withVariablesV1 = (editor: ReactEditor): ReactEditor => {
  const asCustomEditor = editor as CustomEditor
  const { insertText, deleteBackward, deleteForward, normalizeNode, deleteFragment } = editor

  editor.insertBreak = () => {
    const [match] = Editor.nodes(asCustomEditor, {
      match: n => !Editor.isEditor(n) && Element.isElement(n) && isVariableOrFieldNode(n),
    })
    if (match) {
      Editor.withoutNormalizing(asCustomEditor, () => {
        Transforms.insertNodes(
          asCustomEditor,
          {
            type: 'span',
            children: [{ text: '' }],
          },
          {
            at: [match[1][0] + 1],
          },
        )
        Transforms.move(asCustomEditor, { distance: 2, unit: 'line' })
      })
    } else {
      Editor.withoutNormalizing(asCustomEditor, () => {
        Transforms.insertNodes(asCustomEditor, {
          type: 'span',
          children: [{ text: '' }],
        })
      })
    }
  }

  editor.insertText = (...args) => {
    const [match] = Editor.nodes(asCustomEditor, {
      match: n => !Editor.isEditor(n) && Element.isElement(n) && isVariableOrFieldNode(n),
    })
    if (match) {
      Transforms.insertNodes(
        asCustomEditor,
        {
          type: 'span',
          children: [{ text: '' }],
        },
        {
          at: [match[1][0] + 1],
        },
      )
      Transforms.select(asCustomEditor, [match[1][0] + 1])
    }
    insertText(...args)
  }

  editor.deleteBackward = (...args) => {
    if (editor.selection) {
      const prev = Editor.before(asCustomEditor, editor.selection)
      if (prev) {
        const [parentNode, parentPath] = Editor.parent(asCustomEditor, prev)
        if (parentNode && isVariableOrFieldNode(parentNode)) {
          Transforms.removeNodes(asCustomEditor, {
            match: n => !Editor.isEditor(n) && Element.isElement(n) && isVariableOrFieldNode(n),
            at: parentPath,
          })
          return
        }
      }
    }

    deleteBackward(...args)
  }

  editor.deleteForward = (...args) => {
    const { selection } = editor
    if (selection) {
      const after = Editor.after(asCustomEditor, selection)
      if (after) {
        const [parentNode, parentPath] = Editor.parent(asCustomEditor, after)
        if (parentNode && isVariableOrFieldNode(parentNode)) {
          Transforms.removeNodes(asCustomEditor, {
            match: n => !Editor.isEditor(n) && Element.isElement(n) && isVariableOrFieldNode(n),
            at: parentPath,
          })
          return
        }
      }
    }
    deleteForward(...args)
  }

  editor.deleteFragment = (...args) => {
    const { selection } = editor
    if (selection) {
      const [match] = Editor.nodes(asCustomEditor, {
        match: n => !Editor.isEditor(n) && Element.isElement(n) && isVariableOrFieldNode(n),
      })
      if (match) {
        Transforms.removeNodes(asCustomEditor, {
          match: n => !Editor.isEditor(n) && Element.isElement(n) && isVariableOrFieldNode(n),
        })
      }
    }
    deleteFragment(...args)
  }

  editor.normalizeNode = entry => {
    const [node, path] = entry

    // If the element is a paragraph, ensure its children are valid.
    if (Element.isElement(node) && node.type === 'span') {
      const deletedKeys = Object.keys(node).filter(k => !['children', 'type'].includes(k))
      if (deletedKeys.length) {
        Transforms.unsetNodes(asCustomEditor, deletedKeys, { at: path })
      }
    }

    // Fall back to the original `normalizeNode` to enforce other constraints.
    normalizeNode(entry)
  }

  return editor
}

const withVariablesV2 = (editor: ReactEditor): ReactEditor => {
  const { isInline, insertText, deleteForward, deleteBackward, normalizeNode, deleteFragment } =
    editor || {}

  editor.isInline = element => {
    if (isSignatureFieldNode(element)) {
      return false
    }
    if (isVariableOrFieldNode(element) || isLinkNode(element) || !!element.isInline) {
      return true
    }
    return isInline(element)
  }

  const asCustomEditor = editor as CustomEditor

  editor.insertText = (...args) => {
    const [match] = Editor.nodes(asCustomEditor, {
      match: n => !Editor.isEditor(n) && Element.isElement(n) && isVariableOrFieldNode(n),
    })
    if (match) {
      Transforms.insertNodes(
        asCustomEditor,
        [
          {
            type: 'span',
            children: [{ text: '' }],
          },
        ],
        {
          at: Path.next(match[1]),
        },
      )
      Transforms.select(asCustomEditor, [match[1][0] + 1])
    }
    insertText(...args)
  }
  editor.deleteFragment = (opts: EditorFragmentDeletionOptions | undefined) => {
    const { selection } = editor
    if (selection) {
      // split selection into parts, excluding variable nodes with imageOptions.position or image nodes with imageOptions.position

      const ignoredNodes = Editor.nodes(asCustomEditor, {
        at: selection,
        match: n =>
          !Editor.isEditor(n) &&
          Element.isElement(n) &&
          (isVariableOrFieldNode(n) || n.type === 'image'),
      })

      const ignoredPaths = Array.from(ignoredNodes, ([, path]) => path)

      if (ignoredPaths.length === 0) {
        deleteFragment(opts)
      } else {
        const [start, end] = Editor.edges(asCustomEditor, selection)
        const startPath = start.path
        const endPath = end.path
        const startOffset = start.offset
        const endOffset = end.offset

        const startNode = Editor.node(asCustomEditor, startPath)
        const endNode = Editor.node(asCustomEditor, endPath)

        if (startPath.length === 0 || endPath.length === 0) {
          return
        }
        if (startNode[0].type === 'span' && endNode[0].type === 'span') {
          if (startPath[0] === endPath[0]) {
            if (
              startOffset === 0 &&
              endOffset === startNode[0].children.length &&
              (startNode[0].children.length === 0 ||
                startNode[0].children.every(
                  (c: any) => c.text === '' || (Element.isElement(c) && c.type === 'image'),
                ))
            ) {
              Transforms.removeNodes(asCustomEditor, {
                at: startPath,
              })
            }
          }
        }
      }
    }
  }

  editor.deleteForward = (...args) => {
    if (!editor.selection) return
    const [positionedMatch] = Editor.nodes(asCustomEditor, {
      match: n => !Editor.isEditor(n) && isPositionedNode(n),
      at: Editor.after(asCustomEditor, editor.selection.focus),
    })
    if (positionedMatch) {
      // move selection to the next node
      const afterPoint = Editor.after(asCustomEditor, editor.selection.focus)
      if (afterPoint) {
        editor.select(afterPoint)
        editor.deleteForward(...args)
      }
      return
    }
    const [match] = Editor.nodes(asCustomEditor, {
      match: n => !Editor.isEditor(n) && Element.isElement(n) && isVariableOrFieldNode(n),
      at: Editor.after(asCustomEditor, editor.selection.focus),
    })
    if (match) {
      Transforms.removeNodes(asCustomEditor, {
        at: match[1],
      })
      return
    }
    deleteForward(...args)
  }

  editor.deleteBackward = (...args) => {
    if (!editor.selection) return

    const [positionedMatch] = Editor.nodes(asCustomEditor, {
      match: n => !Editor.isEditor(n) && isPositionedNode(n),
      at: Editor.before(asCustomEditor, editor.selection.focus),
    })
    if (positionedMatch) {
      // move selection to the previous node
      const beforePoint = Editor.before(asCustomEditor, editor.selection.focus)
      if (beforePoint) {
        editor.select(beforePoint)
        editor.deleteBackward(...args)
      }
      return
    }

    const [match] = Editor.nodes(asCustomEditor, {
      match: n => !Editor.isEditor(n) && Element.isElement(n) && isVariableOrFieldNode(n),
      at: Editor.before(asCustomEditor, editor.selection.focus),
    })
    if (match) {
      Transforms.removeNodes(asCustomEditor, {
        at: match[1],
      })
      return
    }
    deleteBackward(...args)
  }

  editor.insertFragment = fragment => {
    if (editor.selection) {
      if (fragment.length === 1 && fragment[0].type === 'span') {
        Transforms.insertNodes(asCustomEditor, fragment[0].children, {
          at: editor.selection,
          mode: 'lowest',
          select: true,
        })
      } else {
        Transforms.insertNodes(asCustomEditor, fragment, {
          at: editor.selection,
          mode: 'lowest',
          select: true,
        })
      }
    }
  }

  editor.normalizeNode = entry => {
    // if element is not a span but is a direct child of the editor, wrap it in a span
    normalizeNode(entry)
    const [node, path] = entry
    if (isLinkNode(node)) {
      if (node.children.length === 0 || node.children[0].text === '') {
        Transforms.removeNodes(asCustomEditor, { at: path })
        return
      }
    }
    if (Element.isElement(node) && node.type !== 'span' && path.length === 1) {
      Transforms.wrapNodes(asCustomEditor, { type: 'span', children: [] }, { at: path })
    }
  }

  // editor.insertBreak = () => {
  //   Transforms.insertNodes(asCustomEditor, {
  //     type: 'span',
  //     children: [{ text: '' }],
  //   })
  // }

  return editor
}

export const getWithVariables = (version: EditorVersion) => {
  switch (version) {
    case 'v1':
      return withVariablesV1
    case 'v2':
      return withVariablesV2
    default:
      throw new Error('Invalid editor version')
  }
}
