import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tab,
  TabList,
  TabProps,
  Tabs,
} from '@chakra-ui/react'
import React, { useContext, useMemo } from 'react'
import { ScreenContext } from '../../contexts'
import { DataListTab } from '../../types/data'

const SectionTab = (props: TabProps) => (
  <Tab
    _selected={{
      fontWeight: 500,
      background: 'white',
      borderLeft: '1px solid #cdcdcd',
      borderRight: '1px solid #cdcdcd',
      borderBottom: 'none',
    }}
    zIndex={2}
    background="#efefef"
    {...props}
  />
)

const DesktopDataListTabs = ({
  onChange: handleTabChange,
  index: tabIndex,
  tabs,
}: {
  onChange: (index: number) => void
  index: number
  tabs: Record<string, DataListTab<any>>
}) => {
  const { rightJustifyTabs, leftJustifyTabs } = useMemo(() => {
    const tabNames = Object.keys(tabs)
    const rightJustify = tabNames.filter(t => tabs[t].rightJustifyTab)
    const leftJustify = tabNames.filter(t => !tabs[t].rightJustifyTab)
    return { rightJustifyTabs: rightJustify, leftJustifyTabs: leftJustify }
  }, [tabs])

  return (
    <Tabs variant="enclosed-colored" onChange={handleTabChange} index={tabIndex}>
      <TabList>
        {leftJustifyTabs.map(tName => (
          <SectionTab key={tName}>{tName}</SectionTab>
        ))}
        <Flex ml="auto">
          {rightJustifyTabs.map(tName => (
            <SectionTab key={tName}>{tName}</SectionTab>
          ))}
        </Flex>
      </TabList>
    </Tabs>
  )
}

const MobileDataListTabs = ({
  onChange: handleTabChange,
  index: tabIndex,
  tabs,
}: {
  onChange: (index: number) => void
  index: number
  tabs: Record<string, DataListTab<any>>
}) => {
  const tabNames = Object.keys(tabs)
  return (
    <Flex w="100%">
      <Menu matchWidth>
        <MenuButton
          bg="transparent"
          _hover={{
            bg: 'transparent',
          }}
          _active={{
            bg: 'transparent',
          }}
          w="100%"
          as={Button}
          rightIcon={<ChevronDownIcon />}>
          {tabNames[tabIndex]}
        </MenuButton>
        <MenuList>
          {tabNames.map((tName, i) => (
            <MenuItem key={tName} onClick={() => handleTabChange(i)}>
              {tName}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Flex>
  )
}

export const DataListTabs = (props: {
  onChange: (index: number) => void
  index: number
  tabs: Record<string, DataListTab<any, any>>
}) => {
  const { isMobile } = useContext(ScreenContext)

  return isMobile ? <MobileDataListTabs {...props} /> : <DesktopDataListTabs {...props} />
}
