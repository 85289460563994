import { Badge } from '@chakra-ui/react'
import {
  BaseInsuranceCoverage,
  getDateString,
  getDurationString,
  InsuranceCoverage,
} from '@hb/shared'
import React, { useMemo } from 'react'
import { useCachedInsurer } from '../../../collections/hooks/cached'

export type TerminationStatus = {
  text: string
  status: 'terminated' | 'pending'
}
export const useTerminationStatus = (
  coverage: InsuranceCoverage | BaseInsuranceCoverage | null | undefined,
): TerminationStatus | null => {
  const { terminationDate } = coverage || {}
  return useMemo(() => {
    if (!terminationDate) return null
    const now = Date.now()
    if (terminationDate < now) {
      return {
        text: `Terminated on ${getDateString(terminationDate, 'short')}`,
        status: 'terminated',
      }
    }
    return {
      text: `Terminates in ${getDurationString(now, terminationDate).split(',')[0]}`,
      status: 'pending',
    }
  }, [terminationDate])
}

export const CoverageTerminationStatus = ({
  coverage,
  terminationStatus,
  wrap,
  simple,
}: {
  coverage: InsuranceCoverage | BaseInsuranceCoverage | null
  terminationStatus: TerminationStatus | null
  wrap?: boolean
  simple?: boolean
}) => {
  const { data: insurer } = useCachedInsurer(coverage?.insuranceProviderId || null)
  return terminationStatus ? (
    <Badge
      boxShadow="1px 1px 3px #00000033"
      maxW={wrap ? '175px' : 'none'}
      px={2}
      textAlign="center"
      whiteSpace={wrap ? 'wrap' : 'nowrap'}
      colorScheme={terminationStatus.status === 'terminated' ? 'red' : 'orange'}>
      {simple
        ? terminationStatus.text
        : `${insurer?.name || 'Insurance'} plan ${terminationStatus.text}`}
    </Badge>
  ) : null
}
