import { CloseIcon } from '@chakra-ui/icons'
import {
  Button,
  Collapse,
  Divider,
  HStack,
  IconButton,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { colors, ThreadType, UserGroup } from '@hb/shared'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { createNewThreadFunction } from '../../backend'
import { PopUpMessageContext, useApp } from '../../contexts'
import { useAccessLevelField } from '../../hooks/backend/user/useAccessLevelField'
import { StandaloneInput } from '../forms'
import { CollapseHorizontal } from '../shared'
import { SearchUsersList } from '../Users/SearchUsersList'
import { THREAD_LIST_HEIGHT } from './constants'
import { useThreadsList } from './contexts'
import { groupThreadTitleField } from './fields'

export const CreateThreadView = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const {
    widget: { update: updateWidgetState },
  } = useThreadsList()

  // const [type, setType] = useState<ThreadType | ''>('')
  const [title, setTitle] = useState('')
  const [accessLevel, setAccessLevel] = useState<UserGroup | ''>()
  const [members, setMembers] = useState<Array<string>>([])
  const { appName } = useApp()

  const accessLevelField = useAccessLevelField('User Group')

  const error = useMemo(() => {
    if (!members.length) return 'Please add at least one user'
    if (!title && members.length > 1) return 'Please enter a title'
    return null
  }, [title, members])
  const { showError } = useContext(PopUpMessageContext)
  const [submitting, setSubmitting] = useState(false)
  const onSubmit = useCallback(() => {
    // if (!type) return
    if (!title && members.length > 1) return
    const usedAccessLevel = appName === 'providers-app' ? 'practice' : accessLevel
    if (!usedAccessLevel) return
    if (!members.length) return
    setSubmitting(true)
    createNewThreadFunction({
      members,
      title,
      accessLevel: usedAccessLevel,
    })
      .then(thread => {
        const { id } = thread.data as any
        updateWidgetState({
          thread: {
            id,
            type: ThreadType.GROUP,
            title,
          },
        })
        setSubmitting(false)
        onClose()
      })
      .catch(e => {
        showError(e.message || 'Error creating thread')
        setSubmitting(false)
        console.error(e)
      })
  }, [title, members, onClose, showError, updateWidgetState, accessLevel, appName])

  useEffect(() => {
    if (!isOpen) {
      setTitle('')
      setAccessLevel('')
      setMembers([])
    }
  }, [isOpen])

  return (
    <CollapseHorizontal
      withShadow
      width={360}
      borderRadius={6}
      height={THREAD_LIST_HEIGHT}
      style={{ marginLeft: isOpen ? '8px' : '0px' }}
      in={isOpen}>
      <VStack
        h="100%"
        width="360px"
        spacing={0}
        borderRadius={6}
        divider={<Divider />}
        overflowY="auto">
        <HStack
          borderTopRadius={6}
          bg={colors.green.hex}
          color="white"
          height="40px"
          px={3}
          userSelect="none"
          borderBottom="1px solid #ababab"
          w="100%">
          <Text fontWeight={500} textShadow="1px 1px 3px black" fontFamily="Hero-New">
            New Thread
          </Text>
          <HStack ml="auto">
            <IconButton
              size="xs"
              bg="whiteAlpha.300"
              _hover={{ bg: 'whiteAlpha.500' }}
              color="white"
              aria-label="Close"
              icon={<CloseIcon />}
              onClick={() => onClose()}
            />
          </HStack>
        </HStack>
        <VStack
          bg="gray.100"
          borderBottomRadius={6}
          minH="300px"
          w="100%"
          px={3}
          height="100%"
          py={2}
          overflowY="auto">
          <VStack borderRadius={6} w="100%" py={2} px={3} bg="gray.50">
            <VStack align="flex-start" w="100%" spacing={1}>
              {accessLevelField ? (
                <>
                  <Text fontWeight={600} fontSize="xs" color="gray.500">
                    USER GROUP
                  </Text>
                  <StandaloneInput
                    field={accessLevelField}
                    theme="detailed"
                    value={accessLevel}
                    onChange={v => setAccessLevel(v as UserGroup)}
                  />
                </>
              ) : null}
              <Collapse
                in={appName === 'providers-app' || !!accessLevel}
                style={{
                  width: '100%',
                  padding: '0.2rem',
                  display: 'flex',
                  flexFlow: 'column',
                  gap: 6,
                }}>
                <SearchUsersList
                  group={accessLevel || 'admin'}
                  selected={members}
                  excludeMe
                  onChange={setMembers}
                  type="user"
                  label="Add Users"
                />
              </Collapse>
              <Collapse
                in={members.length > 1}
                style={{
                  width: '100%',
                  padding: '0.2rem',
                  display: 'flex',
                  flexFlow: 'column',
                  gap: 6,
                }}>
                <Text fontWeight={600} fontSize="xs" color="gray.500">
                  THREAD TITLE
                </Text>
                <StandaloneInput
                  field={groupThreadTitleField}
                  theme="detailed"
                  value={title}
                  onChange={setTitle}
                />
              </Collapse>
            </VStack>
            <Tooltip placement="top" hasArrow bg="orange.500" label={error}>
              <Button
                isLoading={submitting}
                onClick={onSubmit}
                opacity={!error ? 1 : 0.5}
                bg={colors.green.hex}
                color="white"
                size="sm">
                Create Thread
              </Button>
            </Tooltip>
          </VStack>
        </VStack>
      </VStack>
    </CollapseHorizontal>
  )
}
