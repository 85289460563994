import { Heading, HeadingProps, Text } from '@chakra-ui/react'
import React, { useMemo } from 'react'

export const Header = (props: HeadingProps) => (
  <Heading
    fontSize="md"
    textAlign="center"
    py={1}
    m={0}
    fontWeight={500}
    fontFamily="hero-new"
    {...props}
  />
)

export const PageHeader = ({ children, ...props }: HeadingProps) => {
  const rendered = useMemo(() => {
    if (typeof children === 'string') {
      const words = children.split(' ')
      return words.map((w, i) => (
        <Text as="span" key={`${w}_${i}`}>
          {i === 0 ? '' : ' '}
          <Text fontSize="2rem" as="span">
            {w[0].toUpperCase()}
          </Text>
          <Text fontSize="1.8rem" as="span">
            {w.substring(1).toUpperCase()}
          </Text>
        </Text>
      ))
    }
    return children
  }, [children])
  return (
    <Header fontSize="2rem" mt={12} padding={0} fontWeight={500} color="black" {...props}>
      {rendered}
    </Header>
  )
}
