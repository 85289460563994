import { CheckIcon } from '@chakra-ui/icons'
import { CircularProgress, Flex, HStack, Text } from '@chakra-ui/react'
import { colors, Descendant, getRandomId, Template } from '@hb/shared'
import isEqual from 'lodash.isequal'

import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react'
import saveIcon from '../../icons/save_old_green.svg'
import { SvgIcon } from '../../icons/SvgIcon'
import { Toolbar } from '../../types/editor'
import { ActionButton } from '../Buttons/ActionButton'
import { TextInputElement } from '../forms/Input/Text/styles'
import { TemplateToolbar } from '../RichText/TemplateToolbar'
import { TextEditor } from '../RichText/TextEditor'
import { TextEditorProps } from '../RichText/types'
import { useTemplateEditor } from './contexts'
import { useTemplateData } from './contexts/data'
import { getWithVariables } from './withVariables'

const sleep = (ms: number) =>
  new Promise(resolve => {
    setTimeout(resolve, ms)
  })

const convertValueToV2 = (value: Descendant[]): Descendant[] =>
  value.map(node => {
    if (node.type === 'variable') {
      return {
        type: 'span',
        children: [{ text: '' }, node, { text: '' }],
      }
    }
    return node
  })

export const TemplateEditor = ({
  style,
  width,
  height,
  baseZoom,
}: {
  style?: CSSProperties
  width: TextEditorProps['width']
  height: TextEditorProps['height']
  baseZoom: TextEditorProps['baseZoom']
}) => {
  const { onSubmit, template, isLoading: templateLoading } = useTemplateEditor()
  const { type: templateType } = useTemplateData()
  const [templateName, setTemplateName] = useState(template?.name || '')
  useEffect(() => setTemplateName(template?.name || ''), [template])
  const [isLoading, setIsLoading] = useState(false)
  const [remountingForConvert, setRemountingForConvert] = useState(false)
  const [convertingToV2, setConvertingToV2] = useState(false)
  const [editedValue, setValue] = useState<Descendant[]>(template?.templateText || [])
  const dirty = useMemo(
    () => !isEqual(template?.templateText, editedValue),
    [template, editedValue],
  )

  // if value, assume it's a v1 template if not specified
  // if no value, start with v2
  const usedVersion = useMemo(() => {
    if (convertingToV2) return 'v2'
    return template?.templateText ? template.editorVersion || 'v1' : 'v2'
  }, [template, convertingToV2])

  const convertToV2 = useCallback(async () => {
    setRemountingForConvert(true)
    await sleep(20)
    setValue(convertValueToV2(editedValue))
    setConvertingToV2(true)
    await sleep(200)
    setRemountingForConvert(false)
  }, [editedValue])

  const [initializing, setInitializing] = useState(true)
  useEffect(() => {
    if (initializing && !templateLoading) {
      setValue(template?.templateText || [])
      setInitializing(false)
    }
  }, [initializing, template, templateLoading])

  const handleSubmit = useCallback(async () => {
    setIsLoading(true)
    const submitted: Template = {
      id: template?.id || getRandomId(),
      name: templateName,
      templateText: editedValue,
      editorVersion: usedVersion,
    }

    const res = await onSubmit(submitted)
    setIsLoading(false)
    return res
  }, [template, templateName, onSubmit, editedValue, usedVersion])

  const canUpgradeVersion = useMemo(
    () => template?.templateText && (!usedVersion || usedVersion === 'v1'),
    [usedVersion, template],
  )

  const decorators = useMemo(() => [getWithVariables(usedVersion)], [usedVersion])
  const toolbars = useMemo<Toolbar[]>(() => [TemplateToolbar], [])
  if (remountingForConvert || initializing) {
    return (
      <HStack p={4} pr={16} border={6}>
        <CircularProgress color={colors.green.hex} isIndeterminate size={5} />
        <Text fontFamily="Open Sans">
          {remountingForConvert ? 'Converting to v2...' : 'Template loading...'}{' '}
        </Text>
      </HStack>
    )
  }
  return (
    <TextEditor
      version={usedVersion}
      value={editedValue || []}
      onChange={v => {
        setValue(v)
      }}
      width={width}
      height={height}
      baseZoom={baseZoom}
      toolbars={toolbars}
      style={style}
      decorators={decorators}>
      <Flex pl={3} gap={2} width="100%" minH={12} align="center" pr={12}>
        <Flex align="center" flex={1}>
          <TextInputElement
            placeholder={templateType === 'consentForm' ? 'Consent Form Name' : 'Template Name'}
            value={templateName}
            style={{
              fontFamily: 'Hero-New',
              fontSize: '1rem',
              border: '1px solid #cdcdcd',
              background: 'white',
              borderRadius: '5px',
              padding: '0.5rem 0.6rem',
            }}
            onChange={({ target: { value: v } }) => {
              setTemplateName(v)
            }}
          />
        </Flex>
        {canUpgradeVersion && (
          <ActionButton isLoading={remountingForConvert} onClick={convertToV2} bg="white" size="xs">
            Convert to v2
          </ActionButton>
        )}
        <ActionButton gap={1} bg="gray.50" isLoading={isLoading} onClick={handleSubmit} size="xs">
          {dirty ? (
            <>
              <SvgIcon width="16px" src={saveIcon} />
              <Text>Save Template</Text>
            </>
          ) : (
            <>
              <CheckIcon />
              <Text>Saved</Text>
            </>
          )}
        </ActionButton>
      </Flex>
    </TextEditor>
  )
}
