import { CalendarIcon } from '@chakra-ui/icons'
import {
  Button,
  Flex,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spinner,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  AssessmentInvoiceSnippet,
  colors,
  formatDollarValue,
  getDateString,
  getInvoiceSent,
  INVOICES,
  PracticeInvoice,
  WithId,
} from '@hb/shared'
import React, { useMemo } from 'react'
import { useDocument } from '../../hooks/backend/useDocument'
import { InvoiceStatusView } from './InvoiceStatusView'
import { MovePaymentPopover } from './MovePaymentPopover'
import { InvoicePaymentHistoryBody } from './PaymentHistory'

const InvoiceDetails = ({ invoice }: { invoice: PracticeInvoice }) => (
  <VStack align="flex-start" spacing={0}>
    <Text w="100%" pb={1} fontWeight={600} fontSize="sm" color="gray.600">
      #{invoice.invoiceNumber} - {invoice.practiceName}
    </Text>
    <VStack
      border="1px solid #cdcdcd"
      borderRadius={4}
      w="100%"
      p={2}
      bg="white"
      align="flex-start"
      spacing={0}>
      <InvoiceStatusView invoice={invoice} />
      <Text fontWeight={600} color={colors.green.hex} fontSize="sm">
        Fee: {formatDollarValue(invoice.amount)}
      </Text>
    </VStack>
  </VStack>
)
export const InvoicePopover = ({ invoiceId }: { invoiceId: string }) => {
  const { data: invoice, error, loading } = useDocument<PracticeInvoice>(INVOICES, invoiceId)

  return (
    <Popover isLazy placement="top" trigger="hover">
      <PopoverTrigger>
        <Button variant="link" size="sm">
          {invoice ? <Text mr={1}>#{invoice.invoiceNumber}</Text> : 'Loading...'}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody bg="whitesmoke">
          {loading && <Spinner />}
          {error && <Text>Something went wrong: {error}</Text>}
          {invoice && <InvoiceDetails invoice={invoice} />}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
export const InvoicePaymentPopover = ({
  invoiceSnippets,
  assessmentId,
  paymentId,
  removeLoading,
  onRemove,
}: {
  invoiceSnippets: Record<string, AssessmentInvoiceSnippet>
  assessmentId?: string
  paymentId: string
  removeLoading: boolean
  onRemove: (invoiceId: string) => Promise<void>
}) => {
  const unsentInvoice = useMemo<WithId<AssessmentInvoiceSnippet> | null>(() => {
    const unsent = Object.entries(invoiceSnippets).filter(
      ([, invoice]) => !getInvoiceSent(invoice),
    )[0]
    return unsent ? { id: unsent[0], ...unsent[1] } : null
  }, [invoiceSnippets])

  const mostRecentInvoice = useMemo<WithId<AssessmentInvoiceSnippet> | null>(() => {
    const mostRecent = Object.entries(invoiceSnippets).sort(
      ([, a], [, b]) =>
        new Date(b.scheduledFor || 0).getTime() - new Date(a.scheduledFor || 0).getTime(),
    )[0]
    return mostRecent ? { id: mostRecent[0], ...mostRecent[1] } : null
  }, [invoiceSnippets])

  const { invoiceNumber, scheduledFor } = mostRecentInvoice || {}
  const monthString = useMemo(
    () => (scheduledFor ? getDateString(scheduledFor, 'short').substring(0, 5) : ''),
    [scheduledFor],
  )

  return (
    <Flex h="100%" align="center" w="100%">
      <HStack
        spacing={0}
        bg="white"
        w="100%"
        borderRadius={6}
        divider={<StackDivider />}
        border={`1px solid ${colors.green.hex}`}>
        <Popover isLazy strategy="fixed" placement="top" trigger="hover">
          <PopoverTrigger>
            <Button
              flex={1}
              isLoading={removeLoading}
              color={colors.green.hex}
              borderRightRadius={unsentInvoice ? 0 : 6}
              _hover={{ bg: 'transparent' }}
              bg="transparent"
              size="xs">
              {invoiceNumber ? (
                <Text fontSize="sm" mr={2}>
                  {`#${invoiceNumber}`}
                </Text>
              ) : (
                <HStack spacing={1}>
                  <CalendarIcon />
                  <Text fontSize="sm" mr={2}>
                    {monthString}
                  </Text>
                </HStack>
              )}
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent p={0}>
              <PopoverArrow bg="#d9efec" />
              <PopoverBody maxH="320px" overflowY="auto" bg="#d9efec">
                {assessmentId ? (
                  <InvoicePaymentHistoryBody
                    invoiceSnippets={invoiceSnippets}
                    // assessmentId={assessmentId}
                    paymentId={paymentId}
                    removeLoading={removeLoading}
                    onRemove={onRemove}
                  />
                ) : (
                  <Text color="red.600">Missing assessment id</Text>
                )}
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
        {unsentInvoice ? (
          <MovePaymentPopover
            paymentId={paymentId}
            assessmentId={assessmentId}
            invoice={unsentInvoice}
          />
        ) : null}
      </HStack>
    </Flex>
  )
}
