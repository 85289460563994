import { ButtonProps, forwardRef } from '@chakra-ui/react'
import React from 'react'
import { ActionButton } from './ActionButton'

export const BlueButton = forwardRef((props: ButtonProps, ref) => (
  <ActionButton
    _hover={{ background: 'blue.300' }}
    ref={ref}
    background="#002FAA"
    color="white"
    {...props}
  />
))
