import { Button } from '@chakra-ui/react'
import React, { useCallback, useContext, useState } from 'react'
import { deleteThreadFunction } from '../../../backend'
import { PopUpMessageContext } from '../../../contexts/PopUpMessage/PopUpMessageContext'
import { DeleteConfirmAlert } from '../../Buttons'
import { ThreadViewContext } from '../ThreadView/contexts'

export const DeleteThreadButton = () => {
  const { threadId, threadType, onBack } = useContext(ThreadViewContext)

  const [confirmDelete, setConfirmDelete] = useState(false)
  const { processResponse } = useContext(PopUpMessageContext)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const handleDeleteClick = useCallback(async () => {
    if (!confirmDelete) {
      setConfirmDelete(true)
      return
    }
    setDeleteLoading(true)
    try {
      await deleteThreadFunction({ threadId, threadType })
      onBack()
    } catch (e: any) {
      processResponse({
        error: `Error deleting thread: ${e.message || e}`,
      })
    }
    setDeleteLoading(false)
  }, [confirmDelete, onBack, processResponse, threadId, threadType])
  return (
    <>
      <Button
        isLoading={deleteLoading}
        w="100%"
        size="xs"
        color="white"
        _hover={{
          bg: 'red.300',
        }}
        lineHeight={1}
        bg="red.600"
        fontFamily="Open Sans"
        onClick={handleDeleteClick}>
        DELETE THREAD
      </Button>
      <DeleteConfirmAlert
        itemName="thread"
        actionLoading={deleteLoading}
        confirmActive={confirmDelete}
        onCancel={() => setConfirmDelete(false)}
        onConfirm={handleDeleteClick}
      />
    </>
  )
}
