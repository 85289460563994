import { AnyObject, CollectionFilter, LocalCollectionState, UserRoleItem } from '@hb/shared'
import { FieldPath } from 'firebase/firestore'
import { Context, createContext, useContext } from 'react'
import { DataListTab } from '../types/data'

export type DataListContextType<
  T extends AnyObject,
  ExtraColumnProps extends AnyObject = AnyObject,
> = {
  sortKey: string | FieldPath
  sortBy: (k: string | FieldPath, direction?: 'asc' | 'desc') => void
  toggleSortDirection: () => void
  filterBy: (filter: CollectionFilter) => void
  removeFilter: (filterKey: string | FieldPath) => void
  gridView: boolean
  filters: Array<CollectionFilter>
  userFilters: Array<CollectionFilter>
  admins: LocalCollectionState<UserRoleItem>
  tab: DataListTab<T, ExtraColumnProps> | null
  sortDesc: boolean
}

export const DataListContext = createContext<DataListContextType<any, any>>({
  sortKey: 'urgentSort',
  filterBy: () => {},
  sortBy: () => {},
  removeFilter: () => {},
  toggleSortDirection: () => {},
  admins: {
    error: null,
    items: [],
    loading: false,
  },
  gridView: false,
  filters: [],
  userFilters: [],
  tab: null,
  sortDesc: true,
})

export const useDataListContext = <T extends AnyObject>() =>
  useContext(DataListContext as Context<DataListContextType<any>>) as DataListContextType<T>
