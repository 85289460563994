import { AbilityClaim } from '../types/claims/ability'

// self,partner,parent
export const getDefaultPatientRelateCode = (relationship: string) => {
  switch (relationship) {
    case 'partner':
      return '01'
    case 'parent':
      return '19'
    default:
      return '18'
  }
}

export const getNewServiceDiagnosisCode = (value?: {
  customDiagnosisCode?: string
  diagnosisCodeSelect?: string
}) => {
  if (!value?.diagnosisCodeSelect) return 'No diagnosis code selected'
  if (value.diagnosisCodeSelect === 'other') {
    return value.customDiagnosisCode || 'No diagnosis code selected'
  }
  return value.diagnosisCodeSelect
}

const pendingStatuses: Array<AbilityClaim['TransStatus']> = [
  'ACK_FINAL',
  'ACK_PENDING',
  'HOLD',
  'MODIFIED',
  'PROCESSING',
  'TRANSMIT',
  'VALIDATED',
  'RELEASED',
]

export const getClaimIsProcessing = (claim: AbilityClaim) => {
  const { TransStatus } = claim
  return pendingStatuses.includes(TransStatus)
}
