import { FieldMap, FieldTypes, TextField } from '@hb/shared'

export const calendlyLinkUrlField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Calendly Link',
  optional: true,
}

export const calendlyDescriptionField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'Calendly Description',
  optional: true,
}

export const calendlyLinkField: FieldMap = {
  name: 'Calendly Link',
  children: {
    calendlyLink: calendlyLinkUrlField,
    calendlyDescription: calendlyDescriptionField,
  },
}
