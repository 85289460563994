import { FieldTypes } from '../../constants/FieldTypes'
import { BooleanField, CheckboxField, Stage } from '../../types/forms/fields'

export const deductibleBasedOnCalendarYearField: BooleanField = {
  type: FieldTypes.BOOLEAN,
  hints: [
    {
      text: 'If no, please indicate date plan renews in the notes box below',
    },
  ],
  labels: [{ text: 'Is my deductible based on a calendar year?' }],
  placeholder: 'Deductible based on calendar year',
}

export const checkboxDeductibleBasedOnCalendarYearField: CheckboxField = {
  ...deductibleBasedOnCalendarYearField,
  type: FieldTypes.CHECKBOX,
}

const inNetwork: Stage = {
  storagePath: 'insurance-info.primaryCoverage.in-network',
  name: 'In-Network Coverage',
  children: {
    deductible: {
      type: FieldTypes.DOLLAR_AMOUNT,
      labels: [
        { text: 'What is my individual annual ' },
        { text: 'in network', style: { textDecoration: 'underline' } },
        { text: ' deductible?' },
      ],
      placeholder: 'In Network Deductible',
    },
    deductibleBasedOnCalendarYear: deductibleBasedOnCalendarYearField,
    coinsurance: {
      type: FieldTypes.PERCENTAGE,
      labels: [
        {
          text: 'What is the percentage coinsurance my plan will cover after any deductible applies? ',
        },
      ],
      hints: [{ text: 'Coinsurance is normally a percentage (%) for the year' }],
      // validate: (v) => {
      //   if (parseInt(v) < 50) {
      //     return 'Should be higher than 50%'
      //   }
      //   return undefined
      // },
      placeholder: 'In Network Coinsurance',
    },
    outOfPocketMax: {
      type: FieldTypes.DOLLAR_AMOUNT,
      labels: [
        { text: 'What is my individual annual ' },
        { text: 'in network', style: { textDecoration: 'underline' } },
        { text: ' out of pocket max?' },
      ],
      placeholder: 'In Network Out of Pocket Max',
    },
    deductibleCountsTowardOOPM: {
      type: FieldTypes.BOOLEAN,
      labels: [{ text: 'Does my deductible count towards the out of pocket max?' }],
      placeholder: 'Deductible counts toward OOPM',
    },
    notes: {
      type: FieldTypes.TEXTAREA,
      optional: true,
      placeholder: 'Notes...',
    },
  },
}

export default inNetwork
