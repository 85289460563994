import { DropdownOptionItem, NumberField, TextField, FieldTypes, padZeros } from '@hb/shared'

export const minuteOptions: DropdownOptionItem<number>[] = Array.from(
  { length: 60 / 5 },
  (_, i) => ({
    id: i * 5,
    text: padZeros(i * 5, 2),
  }),
)

export const minuteField: NumberField = {
  type: FieldTypes.NUMBER,
  placeholder: '00',
  numDigits: 2,
  min: 0,
  max: 59,
}

export const hourOptions: DropdownOptionItem<number>[] = Array.from({ length: 12 }, (_, i) => ({
  id: i + 1,
  text: `${i + 1}`,
}))

export const hourField: NumberField = {
  type: FieldTypes.NUMBER,
  placeholder: '00',
  min: 1,
  max: 12,
}

export const amPmOptions: DropdownOptionItem[] = [
  { id: 'AM', text: 'AM' },
  { id: 'PM', text: 'PM' },
]

export const amPmField: TextField = {
  type: FieldTypes.TEXT,
  placeholder: 'AM/PM',
}
